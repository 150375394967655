export default {
	"cbiInvtRefound":"跨境统一版进口退货申请单表头(操作表)",
	"cbiInvtRefoundItem":"跨境统一版进口退货申请单表体(操作表)",
	"cbiInvtRefound_kj5PartionKey":"kj5PartionKey",
	"cbiInvtRefound_platNo":"平台编号",
	"cbiInvtRefound_copCode":"传输企业代码",
	"cbiInvtRefound_syMark":"状态",
	"cbiInvtRefound_inputDate":"创建时间",
	"cbiInvtRefound_guid":"guid",
	"cbiInvtRefound_customsCode":"关区代码",
	"cbiInvtRefound_orderNo":"原始订单编号",
	"cbiInvtRefound_ebpCode":"电商平台代码",
	"cbiInvtRefound_ebpName":"电商平台名称",
	"cbiInvtRefound_ebcCode":"电商企业代码",
	"cbiInvtRefound_ebcName":"电商企业名称",
	"cbiInvtRefound_logisticsNo":"物流运单编号",
	"cbiInvtRefound_logisticsCode":"物流企业代码",
	"cbiInvtRefound_logisticsName":"物流企业名称",
	"cbiInvtRefound_copNo":"企业内部编号",
	"cbiInvtRefound_preNo":"预录入编号",
	"cbiInvtRefound_invtNo":"清单编号",
	"cbiInvtRefound_buyerIdType":"订购人证件类型",
	"cbiInvtRefound_buyerIdNumber":"订购人证件号码",
	"cbiInvtRefound_buyerName":"订购人姓名",
	"cbiInvtRefound_buyerTelephone":"订购人电话",
	"cbiInvtRefound_agentCode":"申报企业代码",
	"cbiInvtRefound_agentName":"申报企业名称",
	"cbiInvtRefound_reason":"退货原因",
	"cbiInvtRefound_note":"备注",
	"cbiInvtRefound_messageId":"消息id",
	"cbiInvtRefound_appType":"报送类型",
	"cbiInvtRefound_appTime":"报送时间",
	"cbiInvtRefound_appStatus":"业务状态"
}
