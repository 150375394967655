/**
 * @description: Request result set
 */
export enum ResultEnum {
  SUCCESS = 0,
  ERROR = -1,
  TIMEOUT = 400,
  UNAUTHORIZED = 401,
  INTERNAL_SERVER_ERROR = 500,
  TYPE = 'success',
  NEED_USER_CONFIRM = 208,
  TIMEOUT_LOGIN = 800
}

/**
 * @description: request method
 */
export enum RequestEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

/**
 * @description:  contentType
 */
export enum ContentTypeEnum {
  // json
  JSON = 'application/json;charset=UTF-8',
  // form-data qs
  FORM_URLENCODED = 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  upload
  FORM_DATA = 'multipart/form-data;charset=UTF-8'
}

/*
* @description:  businessType
* */
export enum BusinessType {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
  EXPORT = "QUERY",
  OTHER = 'OTHER'
}
