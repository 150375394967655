/**
 * Created by 顾栋梁 on 2018/05/04
 */
// import { basecfg } from 'config/'
import {SettingCore} from "@core/setting";

class SessionStorage {
  constructor () {
    // this.localStorage = window.sessionStorage
    this.sessionStorage = sessionStorage
    this.prefix = SettingCore.localStoragePrefix
  }

  set (key, value, fn) {
    try {
      value = JSON.stringify(value)
    } catch (e) {
    }

    this.sessionStorage.setItem(this.prefix + key, value)

    fn && fn()
  }

  get (key, fn) {
    if (!key) {
      throw new Error('没有找到key。')
    }
    if (typeof key === 'object') {
      throw new Error('key不能是一个对象。')
    }
    var value = this.sessionStorage.getItem(this.prefix + key)
    if (value !== null) {
      try {
        value = JSON.parse(value)
      } catch (e) {
      }
    }

    return value
  }

  remove (key) {
    this.sessionStorage.removeItem(this.prefix + key)
  }

  clear () {
    var len = this.sessionStorage.length
    var keyList = []
    for (var i = 0; i < len; i++) {
      var getKey = localStorage.key(i)
      if (getKey.indexOf(this.prefix) > -1) {
        keyList.push(getKey)
      }
    }
    keyList.forEach(key => {
      this.sessionStorage.removeItem(key)
    })
    // this.localStorage.clear()
  }
}

export default new SessionStorage()
