<!--
 * new page
 * @author:秦永强
 * @since:2023-08-16
-->
<template>
  <TgDialog
    :title="title"
    ref="tgProgressDialogRef"
    class="tg-progress-dialog"
    width="650px"
    :fullScreen="false"
    :canFullscreen="false"
    :canMini="false"
    @close="afterCloseProgress">
    <div class="tg-progress">
      <el-row>
        <el-col :span="24">
          <span class="task-name">{{ taskName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="info">
            <div>{{ t("tg.page.progress.elapsedTime") }}</div>
            <!-- 已经用时 -->
            <span :class="{ 'info-error': isError }">{{ usedTime }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="info">
            <div>{{ t("tg.page.progress.currentProcess") }}</div>
            <!-- 当前处理 -->
            <span :class="{ 'info-error': isError }">{{ currStep }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div style="margin: 10px 0">
            <el-progress
              :strokeWidth="strokeWidth"
              :percentage="curPercent"
              :text-inside="true"
              :status="progressStatus">
            </el-progress>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="info">
            <div>{{ t("tg.page.progress.currentFeedback") }}</div>
            <!-- 当前反馈 -->
            <span :class="{ 'info-error': isError }">{{ message }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <template #footer>
      <slot name="footer"></slot>
      <el-button
        type="primary"
        size="small"
        @click="closeProgress"
        :disabled="!isCompleted"
        >{{ btnText }}</el-button
      >
    </template>
  </TgDialog>
</template>

<script lang="ts" setup name="TgProgress">
import { nextTick, ref, getCurrentInstance, computed } from "vue";
import { TgHttp } from "@coreHttp";
import { SettingCore } from "@core/setting";
import apis from "@appTiangong/apis";
import { tmn, tm, t } from "@i18nHelper";

//父组件可传递给子组件的属性
const props = defineProps({
  autoClose: { type: Boolean },
  afterClose: { type: Function },
});

let progressStatus = ref<"success" | "warning" | "exception" | "">("");
let title = ref(t("tg.page.progress.progressDisplay")); //进度展示
let taskId: string;
//进度条宽度
const strokeWidth = ref(24);
//任务名称
let taskName = ref("");
//当前执行步骤
let currStep = ref("");
//当前进度
let curPercent = ref(0);
//当前返回的信息
let message = ref("");
//已经用时时分秒
let usedTime = ref("");
//是否完成
let isCompleted = ref(false);
//是否错误
let isError = ref(false);
// 关闭按钮显示文字
const btnText = computed(() => {
  return isCompleted.value
    ? t("action.close")
    : t("tg.page.progress.taskInProgress"); //关闭：任务执行中
});

//获取进度
function startGetProgress() {
  const request = new TgHttp();
  request.entity = {
    id: taskId,
  };

  request
    .build(apis.sysTaskInfo.load)
    .post()
    .then((data) => {
      if (data.code === 10001) {
        //正常相应
        displayType1(data.data);
      } else {
        //无返回结果
        errStatus();
        message.value = t("tg.page.progress.noProgressData"); //无法获取进度！
      }
    });
}

let i = 0;
let progressArr = [
  {
    taskName: "taskName0",
    taskProcessDesc: "currentStepName0",
    remark: "message0",
    taskProcess: 0,
    taskStatus: "0",
  },
  {
    taskName: "taskName1",
    taskProcessDesc: "currentStepName1",
    remark: "message1",
    taskProcess: 10,
    taskStatus: "0",
  },
  {
    taskName: "taskName2",
    taskProcessDesc: "currentStepName2",
    remark: "message2",
    taskProcess: 20,
    taskStatus: "0",
  },
  {
    taskName: "taskName3",
    taskProcessDesc: "currentStepName3",
    remark: "message3",
    taskProcess: 30,
    taskStatus: "0",
  },
  {
    taskName: "taskName4",
    taskProcessDesc: "currentStepName4",
    remark: "message4",
    taskProcess: 40,
    taskStatus: "0",
  },
  {
    taskName: "taskName5",
    taskProcessDesc: "currentStepName5",
    remark: "message5",
    taskProcess: 50,
    taskStatus: "0",
  },
  {
    taskName: "taskName6",
    taskProcessDesc: "currentStepName6",
    remark: "message6",
    taskProcess: 60,
    taskStatus: "0",
  },
  {
    taskName: "taskName7",
    taskProcessDesc: "currentStepName7",
    remark: "message7",
    taskProcess: 70,
    taskStatus: "0",
  },
  {
    taskName: "taskName8",
    taskProcessDesc: "currentStepName8",
    remark: "message8",
    taskProcess: 80,
    taskStatus: "0",
  },
  {
    taskName: "taskName9",
    taskProcessDesc: "currentStepName9",
    remark: "message9",
    taskProcess: 90,
    taskStatus: "0",
  },
  {
    taskName: "taskName10",
    taskProcessDesc: "currentStepName10",
    remark: "message10",
    taskProcess: 100,
    taskStatus: "1",
  },
];
function startGetProgress1() {
  if (progressArr[i]) {
    //正常相应
    displayType1(progressArr[i]);
    i = i + 1;
  } else {
    //无返回结果
    errStatus();
    message.value = t("tg.page.progress.noProgressData"); //无法获取进度！
  }
}

//获取进度
function getProgress(): void {
  if (!isCompleted.value) {
    setTimeout(() => {
      startGetProgress();
    }, 1000);
  }
}

//显示进度
function displayType1(res) {
  taskName.value = res.taskName;
  currStep.value = res.taskProcessDesc;
  message.value = res.remark;
  curPercent.value =
    res.taskProcess && res.taskProcess > 100 ? 100 : res.taskProcess;

  //返回异常
  if (res.taskStatus === "2") {
    errStatus();
    return;
  }

  if (curPercent.value == 100) {
    completed();
  } else {
    getProgress();
  }
}

//进度100
function completed() {
  succStatus();
  message.value = t("tg.page.progress.taskCompleted"); //任务执行完毕

  //是否设置了自动关闭
  if (props.autoClose) {
    closeProgress();
  }
}

//计时
let sec = 0;
let timeoutId;
function showTimeCost() {
  if (!isCompleted.value) {
    timeoutId = setTimeout(() => {
      sec = sec + 1;
      getUsedTime();
    }, 1000);
  }
}

//计算已经用时
function getUsedTime(): void {
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = sec % 60;
  //usedTime.value = h ? `${h}时${m}分${s}秒` : m ? `${m}分${s}秒` : `${s}秒`;
  usedTime.value = h
    ? t("tg.page.progress.hhmmss", [h, m, s])
    : m
    ? t("tg.page.progress.mmss", [m, s])
    : t("tg.page.progress.ss", [s]);
  showTimeCost();
}

let { proxy } = getCurrentInstance();
let tgProgressDialogRef = ref(null);
//弹窗
//function showProgress(taskId: string,title1:string) {
function showProgress(param: { taskId: string; title: string; other: {} }) {
  nextTick(() => {
    title.value = param.title;
    taskId = param.taskId;
    initStatus();
    proxy.$refs["tgProgressDialogRef"].show();
    showTimeCost();
    startGetProgress();
  });
}

function clearTgProgressInstance() {
  //清除进度条单例
  document.body.removeChild(
    window.tgProgressInstance._instance.vnode.el.parentElement
  );
  window.tgProgressInstance.unmount();
  window.tgProgressInstance = null;
}

//关窗
function closeProgress() {
  nextTick(() => {
    proxy.$refs["tgProgressDialogRef"].close();
  });
}

//窗户关闭后回调
function afterCloseProgress() {
  clearTgProgressInstance();
  isCompleted.value = true;
  //父组件回调方法
  if (props.afterClose) {
    props.afterClose();
  }
}

//初始化弹窗数据
function initStatus() {
  sec = 0;
  currStep.value = "";
  curPercent.value = 0;
  message.value = "";
  usedTime.value = "";
  isCompleted.value = false;
  isError.value = false;
}

//进度加载失败
function errStatus() {
  isCompleted.value = true;
  isError.value = true;
  progressStatus.value = "exception";
  clearTimeout(timeoutId);
}

//进度加载正常
function succStatus() {
  isCompleted.value = true;
  isError.value = false;
  progressStatus.value = "success";
  clearTimeout(timeoutId);
}

//变换进度条颜色
// function setProgressStroColor(isError: Boolean) {
//   // 获取根元素
//   let r = document.querySelector('.tg-progress') as HTMLElement
//   if (!r) return
//   let rs = getComputedStyle(r)
//   let colorStart: string
//   let colorEnd: string
//   if (isError) {
//     colorStart = rs.getPropertyValue('--err-color-start')
//     colorEnd = rs.getPropertyValue('--err-color-end')
//   } else {
//     colorStart = rs.getPropertyValue('--suc-color-start')
//     colorEnd = rs.getPropertyValue('--suc-color-end')
//   }
//   r.style.setProperty('--color-start', colorStart)
//   r.style.setProperty('--color-end', colorEnd)
// }

//将显示此弹窗方法曝露给父组件调用
defineExpose({ showProgress, closeProgress });
</script>

<style lang="scss" scoped></style>
