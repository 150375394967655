<template>
  <TgDialog
    :title="dialogTitle"
    ref="tgImportDialogRef"
    @close="onDialogClose"
    :fullScreen="true"
    :canFullscreen="false"
    :can-mini="false">
    <div class="import-data-total-content" style="height: 100%">
      <div class="import-data-process">
        <div class="title-text pf-font-family">
          {{ t("tg.page.import.M00112") }}
        </div>
        <div class="import-data-process-detail">
          <div class="warning-info">
            <div class="warning-info-flex">
              <div class="warning-icon"></div>

              <div
                v-if="showTypeFlag == '5'"
                class="warning-info-text pfr-font-family">
                {{ t("tg.page.import.M00098") }}
              </div>
              <div
                v-if="uploadModel == 1 && showTypeFlag != '5'"
                class="warning-info-text pfr-font-family">
                {{ t("tg.page.import.M00099") }}
                <span class="red-warning-text">{{
                  t("tg.page.import.M00100")
                }}</span
                >，{{ t("tg.page.import.M00101") }}
                <span class="red-warning-text" @click="changeUploadModel(2)">{{
                  t("tg.page.import.M00102")
                }}</span>
                {{ t("tg.page.import.M00103") }}
              </div>
              <div
                v-else-if="uploadModel == 2 && showTypeFlag != '5'"
                class="warning-info-text pfr-font-family">
                {{ t("tg.page.import.M00099") }}
                <span class="red-warning-text">{{
                  t("tg.page.import.M00102")
                }}</span
                >{{ t("tg.page.import.M00104") }}
                <span class="red-warning-text">{{
                  buttonList[2].dataTotal
                }}</span
                >{{ t("tg.page.import.M00105") }}
                <span class="red-warning-text" @click="changeUploadModel(1)">{{
                  t("tg.page.import.M00100")
                }}</span>
              </div>
            </div>

            <span class="download-excel-sapn" @click="generateAndDownloadExcel">
              {{ tm("tg.page.import.downloadTemplate", "下载模板") }}
            </span>
          </div>
          <div class="upload-file-info">
            <div class="file-info">
              <div class="left-info">
                <div class="file-img">
                  <img src="@core/assets/uploadExcelImg/importDataIcon.png" />
                </div>
                <div>
                  <div class="file-name pf-font-family">
                    {{ t("tg.page.import.M00107", [uploadFileName]) }}
                  </div>
                  <div class="file-note pfr-font-family">
                    {{ showNoteText }}
                  </div>
                </div>
              </div>
              <el-upload
                ref="excelUploadRef"
                v-model:file-list="fileList"
                action="#"
                :auto-upload="false"
                :on-change="onChange"
                :on-exceed="handleExceed"
                :before-remove="beforeRemove"
                :on-remove="handleRemove"
                :limit="1"
                :show-file-list="false"
                accept=".xls,.xlsx">
                <div class="right-info pointer" v-if="!chooseFileDisabled">
                  <div class="down-icon">
                    <img
                      src="@core/assets/uploadExcelImg/downloadDataIcon.png" />
                  </div>
                  <div class="down-button pfr-font-family">
                    {{ chooseFileName }}
                  </div>
                </div>
              </el-upload>

              <!-- <div class="right-info pointer" @click="goBackImportModule">
                <div class="down-icon">
                  <img src="@core/assets/uploadExcelImg/downloadDataIcon.png" />
                </div>
                <div class="down-button pfr-font-family">导入其他模板</div>
              </div> -->
            </div>
          </div>
        </div>

        <div class="step-total-part">
          <div class="step-part">
            <el-steps :active="dealStep" finish-status="success">
              <el-step
                :title="t('tg.page.import.readFile')"
                :status="stepStatus(1)" />
              <div class="nextStepIcon">
                <img src="@core/assets/uploadExcelImg/nextStepIcon.png" />
              </div>
              <el-step
                :title="t('tg.page.import.dealData')"
                :status="stepStatus(2)" />
              <div class="nextStepIcon">
                <img src="@core/assets/uploadExcelImg/nextStepIcon.png" />
              </div>
              <el-step
                :title="t('tg.page.import.M00113')"
                :status="stepStatus(3)" />
              <div class="nextStepIcon">
                <img src="@core/assets/uploadExcelImg/nextStepIcon.png" />
              </div>
              <el-step
                :title="t('tg.page.import.M00114')"
                :status="stepStatus(4)" />
              <div class="nextStepIcon">
                <img src="@core/assets/uploadExcelImg/nextStepIcon.png" />
              </div>
              <el-step
                :title="t('tg.page.import.M00167')"
                :status="stepStatus(5)" />
              <div class="nextStepIcon">
                <img src="@core/assets/uploadExcelImg/nextStepIcon.png" />
              </div>
              <el-step
                :title="t('tg.page.import.M00115')"
                :status="stepStatus(6)" />
              <div class="nextStepIcon">
                <img src="@core/assets/uploadExcelImg/nextStepIcon.png" />
              </div>
              <el-step
                :title="t('tg.page.import.M00117')"
                :status="stepStatus(7)" />
            </el-steps>
          </div>
          <div class="last-step-part"></div>
        </div>
        <div class="progress-total-part">
          <div v-for="(item, index) in percentList" :key="index">
            <el-progress
              :percentage="item.percentValue"
              :stroke-width="4"
              :color="item.color"
              :show-text="false" />
          </div>
        </div>
      </div>
      <div class="import-data-content">
        <div class="title-text pf-font-family">
          {{ t("tg.page.import.M00108") }}
          <span class="sheet-select-part">
            <el-select
              v-model="selectedSheet"
              class="m-2"
              placeholder="Select"
              @change="getOneSheetDetail()"
              style="width: 10%">
              <el-option
                v-for="(item, index) in sheetOptions"
                :key="index"
                :label="item.sheetName"
                :value="item.sheetIndex"
                v-html="item.sheetName" />
            </el-select>
          </span>
          <span class="note-text pfr-font-family"
            >{{
              t("tg.page.import.M00109", [
                noteData.notice_total,
                noteData.notice_success,
              ])
            }}
            <span class="error-note-text">{{
              t("tg.page.import.M00110", [noteData.notice_fail])
            }}</span
            >{{ t("tg.page.import.M00111") }}</span
          >
        </div>
        <div
          class="data-type-button"
          :class="{ adminDataButtonPart: showType === 'administrator' }">
          <div class="total-info-part">
            <div class="item-button-left">
              <div>
                <img
                  class="button-icon"
                  src="@core/assets/uploadExcelImg/total-icon.png" />
              </div>
              <div class="button-text pf-font-family">
                {{ t("tg.page.import.M00118") }}
              </div>
            </div>
            <div class="item-button-right pf-font-family">
              {{ totalRowNum }}
            </div>
          </div>

          <div v-for="(item, index) in buttonList" :key="index">
            <div class="item-data-button-total">
              <div
                class="item-data-button pointer"
                :class="{ selectedBorder: apiResultSelected === index }"
                @click="getItemTypeData(index)">
                <div class="item-button-left">
                  <div class="button-icon"><img :src="item.imgUrl" /></div>
                  <div class="button-text pf-font-family">{{ item.name }}</div>
                </div>
                <div class="item-button-right pf-font-family">
                  {{ item.dataTotal }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="other-item-button pointer pf-font-family fail-records-button"
            :class="{ 'disabled-div': noteData.notice_fail == 0 }"
            @click="downloadFailData">
            {{ t("tg.page.import.M00119") }}
          </div>

          <div
            v-if="
              ((isComplete == 1 && noteData.notice_fail == 0) ||
                isComplete == 0) &&
              noteData.notice_success > 0 &&
              hasRkApi == 1 &&
              showTypeFlag == '3'
            "
            class="other-item-button pointer pf-font-family fail-records-button"
            :class="{ 'disabled-div': hasClickImport }"
            @click="importZs">
            {{ t("action.import") }}
          </div>

          <div
            v-if="dealStep > 0 && (showTypeFlag == '3' || showTypeFlag == '5')"
            class="other-item-button pointer pf-font-family font-red"
            @click="importRedo">
            {{ t("action.redo") }}
          </div>

          <!-- <el-upload
            ref="excelUploadRef"
            v-model:file-list="fileList"
            action="#"
            :auto-upload="false"
            :on-change="onChange"
            :on-exceed="handleExceed"
            :before-remove="beforeRemove"
            :on-remove="handleRemove"
            :limit="1"
            :show-file-list="false"
            accept=".xls,.xlsx">
            <div
              class="other-item-button pointer pf-font-family reimport-data-button">
              {{ importBtnName }}
            </div>
          </el-upload> -->
        </div>

        <div
          v-if="
            (showTypeFlag == '2' ||
              showTypeFlag == '3' ||
              showTypeFlag == '4' ||
              showTypeFlag == '5') &&
            templateLogDetailId != '0'
          "
          class="table-data-total-part">
          <div class="table-data">
            <el-table
              ref="tableRef"
              v-loading="dataListLoading"
              :max-height="330"
              :cell-class-name="getCellStyle"
              :data="dataList"
              :span-method="spanMethods"
              border
              style="width: 100%">
              <el-table-column
                v-if="tableHeaderData.length > 0"
                :label="t('tg.page.import.rowNo')"
                prop="row_index"
                fixed="left"
                header-align="left"
                align="left"
                width="80"></el-table-column>
              <el-table-column
                v-if="tableHeaderData.length > 0"
                :label="t('tg.page.import.M00121')"
                fixed="left"
                header-align="left"
                align="left"
                width="100">
                <template #default="scope">
                  <div
                    v-if="scope.row.insert_status == '失败'"
                    class="failedText pf-font-family">
                    {{ t("tg.page.import.error") }}
                  </div>
                  <div
                    v-else-if="scope.row.insert_status != '失败'"
                    class="successText pf-font-family">
                    {{ scope.row.insert_status }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="tableHeaderData.length > 0"
                :label="t('tg.page.import.M00123')"
                prop="insert_remark"
                fixed="left"
                header-align="left"
                align="left"
                width="150">
                <template #default="scope">
                  <!-- 错误数据行 -->
                  <span
                    v-if="
                      scope.row.isRowData === true && scope.row.status === '1'
                    "
                    class="little-error-text pointer"
                    @click="getErrorDataInfo(scope.row)">
                    <ElIcon
                      v-if="scope.row.isShowMoreError === false"
                      :size="12">
                      <CaretRight />
                    </ElIcon>
                    <ElIcon
                      v-else-if="scope.row.isShowMoreError === true"
                      :size="12">
                      <CaretBottom />
                    </ElIcon>
                    <span>{{ scope.row.insert_remark }}</span>
                  </span>
                  <!-- 错误详情行 -->
                  <span
                    v-else-if="scope.row.isRowData === false"
                    class="all-error-text"
                    >{{ scope.row.insert_remark }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                v-if="
                  tableHeaderData.length > 0 &&
                  dataList[0] &&
                  dataList[0].sub_table_ids &&
                  dataList[0].sub_table_ids.length > 0
                "
                :label="t('tg.page.import.body')"
                fixed="left"
                header-align="left"
                align="left"
                width="150">
                <template #default="scope">
                  <div class="bodyTextPart">
                    <div
                      v-for="(itemBody, indexBody) in scope.row.sub_table_ids"
                      :key="indexBody">
                      <div class="bodyItemTextPart pointer">
                        <div
                          class="bodyText pf-font-family"
                          @click="
                            openOneDetail(
                              itemBody,
                              indexBody,
                              scope.row.main_data_id
                            )
                          ">
                          {{ t("tg.page.import.detail") }}{{ indexBody + 1 }}
                        </div>
                        <div class="bodyTextLine"></div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(item, index) in tableHeaderData"
                :key="index"
                :prop="item.prop"
                :label="item.label"
                min-width="150"
                show-overflow-tooltip>
                <template #default="scope">
                  <div v-if="scope.row.status === '1'">
                    <div
                      v-for="(itemErrorProp, errorIndexNo) in scope.row
                        .errorProp"
                      :key="errorIndexNo">
                      <div
                        v-if="itemErrorProp.prop === item.prop"
                        class="error-cell-text pfr-font-family">
                        {{ scope.row[item.prop] }}
                      </div>
                      <div
                        v-if="
                          itemErrorProp.prop === item.prop &&
                          itemErrorProp.errorInfo
                        "
                        class="error-detail-info-icon">
                        <el-tooltip
                          effect="dark"
                          placement="bottom"
                          popper-class="error-info-tooltip">
                          <template #content>
                            <div class="error-info-text">
                              {{ itemErrorProp.errorInfo }}
                            </div>
                          </template>
                          <img
                            src="@core/assets/uploadExcelImg/cellErrorIcon.png" />
                        </el-tooltip>
                      </div>
                    </div>
                    <div
                      v-for="(itemRightProp, rightIndexNo) in scope.row
                        .rightProp"
                      :key="rightIndexNo">
                      <div v-if="itemRightProp === item.prop">
                        {{ scope.row[item.prop] }}
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="import-data-pagination">
            <el-pagination
              v-model:current-page="pageInfo.pageNum"
              :pager-count="6"
              layout="prev, pager, next,sizes"
              :page-sizes="[10, 50, 100, 200, 300, 400]"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="currentChangeHandle" />
          </div>
        </div>

        <div
          v-if="
            (showTypeFlag === '2' ||
              showTypeFlag === '3' ||
              showTypeFlag === '4' ||
              showTypeFlag === '5' ||
              showTypeFlag === '0') &&
            templateLogDetailId === '0'
          "
          class="import-data-fail-part">
          <div class="fail-icon-part">
            <img
              class="wait-icon"
              src="@core/assets/uploadExcelImg/waitToImportDataIcon.png" />
          </div>
          <div v-if="showTypeFlag != '0'" class="fail-text pfr-font-family">
            {{ t("tg.page.import.M00128")
            }}<!-- 该sheet暂无模板信息 -->
          </div>
          <div
            v-else-if="showTypeFlag == '0'"
            class="fail-text pfr-font-family">
            {{ t("tg.page.import.M00130")
            }}<!-- 请导入模板数据… -->
          </div>
        </div>
        <div v-if="showTypeFlag == '1'" class="import-data-fail-part">
          <div class="fail-icon-part">
            <img
              class="wait-icon"
              src="@core/assets/uploadExcelImg/waitToImportDataIcon.png" />
          </div>
          <div class="fail-text pfr-font-family">
            {{ t("tg.page.import.M00131")
            }}<!-- 您的文件已导入，正在等待系统处理… -->
          </div>
        </div>
      </div>

      <!--    保存结果弹窗 开始-->
      <tg-dialog
        v-model="detailDialogVisible"
        :title="detailDialogTitle"
        width="80%">
        <div class="detail-dialog-part">
          <div class="table-data-total-part">
            <div class="table-data">
              <el-table
                ref="detailTableRef"
                v-loading="detailDataListLoading"
                :max-height="400"
                :cell-class-name="getCellStyleNew"
                :data="detailDataList"
                :span-method="spanDetailMethods"
                border
                style="width: 100%">
                <el-table-column
                  v-if="detailTableHeaderData.length > 0"
                  :label="t('tg.page.import.importResult')"
                  fixed="left"
                  header-align="left"
                  align="left"
                  width="100">
                  <template #default="scope">
                    <div
                      v-if="scope.row.insert_status == '失败'"
                      class="failedText pf-font-family">
                      {{ t("tg.page.import.error") }}
                    </div>
                    <div
                      v-else-if="scope.row.insert_status != '失败'"
                      class="successText pf-font-family">
                      {{ scope.row.insert_status }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="detailTableHeaderData.length > 0"
                  :label="t('tg.page.import.M00123')"
                  prop="insert_remark"
                  fixed="left"
                  header-align="left"
                  align="left"
                  width="150">
                  <template #default="scope">
                    <!-- 错误数据行 -->
                    <span
                      v-if="
                        scope.row.isRowData === true && scope.row.status === '1'
                      "
                      class="little-error-text pointer"
                      @click="getDetailErrorDataInfo(scope.row)">
                      <ElIcon
                        v-if="scope.row.isShowMoreError === false"
                        :size="12">
                        <CaretRight />
                      </ElIcon>
                      <ElIcon
                        v-else-if="scope.row.isShowMoreError === true"
                        :size="12">
                        <CaretBottom />
                      </ElIcon>
                      <span>{{ scope.row.insert_remark }}</span>
                    </span>
                    <!-- 错误详情行 -->
                    <span
                      v-else-if="scope.row.isRowData === false"
                      class="all-error-text"
                      >{{ scope.row.insert_remark }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="tableHeaderData.length > 0"
                  :label="t('tg.page.import.rowNo')"
                  prop="row_index"
                  fixed="left"
                  header-align="left"
                  align="left"
                  width="100"></el-table-column>
                <el-table-column
                  v-for="(item, index) in detailTableHeaderData"
                  :key="index"
                  :prop="item.prop"
                  :label="item.label"
                  min-width="150"
                  show-overflow-tooltip>
                  <template #default="scope">
                    <div v-if="scope.row.status === '1'">
                      <div
                        v-for="(itemErrorProp, errorIndexNo) in scope.row
                          .errorProp">
                        <div
                          v-if="itemErrorProp.prop === item.prop"
                          class="error-cell-text-new pfr-font-family">
                          {{ scope.row[item.prop] }}
                        </div>
                        <div
                          v-if="
                            itemErrorProp.prop === item.prop &&
                            itemErrorProp.errorInfo
                          "
                          class="error-detail-info-icon-new">
                          <el-tooltip
                            effect="dark"
                            placement="bottom"
                            popper-class="error-info-tooltip">
                            <template #content>
                              <div class="error-info-text">
                                {{ itemErrorProp.errorInfo }}
                              </div>
                            </template>
                            <img
                              src="@core/assets/uploadExcelImg/cellErrorIcon.png" />
                          </el-tooltip>
                        </div>
                      </div>
                      <div
                        v-for="(itemRightProp, rightIndexNo) in scope.row
                          .rightProp">
                        <div v-if="itemRightProp === item.prop">
                          {{ scope.row[item.prop] }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="import-data-pagination">
              <el-pagination
                v-model:current-page="detailPageInfo.pageNum"
                :pager-count="6"
                layout="prev, pager, next,sizes"
                :page-sizes="[10, 50, 100, 200, 300, 400]"
                :total="detailPageInfo.total"
                @size-change="detailHandleSizeChange"
                @current-change="detailCurrentChangeHandle" />
            </div>
          </div>
        </div>
      </tg-dialog>
      <!--    保存结果弹窗 结束-->

      <!-- ******************* 确定导入弹窗开始 ***************-->
      <el-dialog
        v-model="importDataVisible"
        :title="t('action.import')"
        width="480px"
        :before-close="importDataHandleClose">
        <div class="import-tip-text pfr-font-family">
          {{ t("tg.page.import.M00133", [uploadFileData.fileName]) }}
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelImportData">{{
              t("action.cancel")
            }}</el-button>
            <el-button type="primary" @click="gotoImportData">{{
              t("action.import")
            }}</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- ******************* 确定导入弹窗结束 ***************-->
    </div>
  </TgDialog>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref, getCurrentInstance, computed } from "vue";
import AllRecordsIcon from "@core/assets/uploadExcelImg/AllRecordsIcon.png";
import SuccessRecordsIcon from "@core/assets/uploadExcelImg/SuccessRecordsIcon.png";
import FailureRecordsIcon from "@core/assets/uploadExcelImg/FailureRecordsIcon.png";
import {
  downExcelErrorApi,
  getExcelPageListApi,
  getTemplateLogDetailApi,
  uploadExcelTempApi,
  generateAndDownloadExcelApi,
  IFileData,
  importDataApi,
  ItempUploadData,
  IPercentList,
  IDetailTableHeaderData,
  ISheetOptions,
} from "./importExcelData";
import { ElMessage } from "element-plus";
import { CaretRight, CaretBottom } from "@element-plus/icons-vue";
import { getExcelUseInfo } from "@core/utils/TgTools";
import { tmn, tm, t } from "@i18nHelper";
import tg from "@coreTgPlus";
import { Base64 } from "js-base64";
import { TgHttp } from "@coreHttp";

const BaseUrl = window.website.serverConfig.importConfig.server;

// const props = defineProps({
//   uploadParam: {
//     type: String,
//     default() {
//       return "";
//     },
//   },
// });

const totalRowNum = ref(0);
const showType = ref(localStorage.getItem("showType"));
const emit = defineEmits(["backPage"]);
const percentageData = ref("0%");

const detailTableRef = ref();
const tableRef = ref();
// 导入弹窗显示与否
const importDataVisible = ref(false);
const uploadFileName = ref(t("tg.page.import.M00140")); // 文件名称
const percentageNo = ref(0);
const templateLogId = ref(""); // 表对应的ID
const showNoteText = ref(t("tg.page.import.waitingImport"));
const importBtnName = ref(t("action.import"));
const chooseFileDisabled = ref(false);
const chooseFileName = ref(t("action.chooseFileBtn"));
const hasClickImport = ref(false);
const uploadParam = ref("");
// 显示控制
//'0'-等待模板数据导入 '1'-文件已上传 待导入 '2'-数据处理中 在导入  '3'-所有数据成功导入 '4'-数据导入完成,有失败记录 '5'-数据导入失败
const showTypeFlag = ref("0");
const uploadModel = ref(1); // 1-使用上传模式为极速模式 2-全文模式
const noteData = ref({
  notice_fail: 0,
  notice_success: 0,
  notice_total: 0,
});
const getBodyInfo = ref({
  contactDataId: "",
  tableId: "",
});
// 上传的文件数据
const uploadFileData = ref({
  fileName: "",
  fileData: {},
});
const selectedSheet = ref(""); // 选择的sheet
const templateLogDetailId = ref("0");
const sheetOptions = ref<ISheetOptions[]>([]); // sheet选择信息
const percentList = ref<IPercentList[]>([
  {
    stepNo: 1,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 2,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 3,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 4,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 5,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 6,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 7,
    percentValue: 0,
    color: "#197AFE",
  },
]); //每一步对应的百分比
const percentResetList = ref<IPercentList[]>([
  {
    stepNo: 1,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 2,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 3,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 4,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 5,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 6,
    percentValue: 0,
    color: "#197AFE",
  },
  {
    stepNo: 7,
    percentValue: 0,
    color: "#197AFE",
  },
]); //每一步对应的百分比
const detailDialogVisible = ref(false);
const templateId = ref(""); //  模板 ID
const detailDialogTitle = ref("");
const dialogTitle = ref("");
const buttonList = ref([
  {
    imgUrl: AllRecordsIcon,
    name: t("tg.page.import.allRecords"),
    bgColor: "#FFFFFF",
    borderColor: "#197AFE",
    dataTotal: 0,
  },
  {
    imgUrl: SuccessRecordsIcon,
    name: t("tg.page.import.M00144"),
    bgColor: "#FFFFFF",
    borderColor: "#197AFE",
    dataTotal: 0,
  },
  {
    imgUrl: FailureRecordsIcon,
    name: t("tg.page.import.M00146"),
    bgColor: "#FFFFFF",
    borderColor: "#197AFE",
    dataTotal: 0,
  },
]);
// 页码信息
const pageInfo = ref({
  pageNum: 1,
  pageSize: 10,
  total: 0,
});
// 获取不同类型的数据 的标记 null-全部 1-成功 2-失败
const insertStatus = ref<string | null>(null);

const batchNo = ref(""); //导入数据的批次号
const apiResultSelected = ref(0); // 获取不同类型的数据 的标记 0-全部 1-成功 2-失败
// 页码信息
const detailPageInfo = ref({
  pageNum: 1,
  pageSize: 10,
  total: 0,
});
//上传 的文件列表
const fileList = ref([]);

const dealStep = ref(0); //  步骤条到哪一步了
const hasRkApi = ref(0);
const isComplete = ref(0);

// 切换sheet 获取对应的详情信息
const getOneSheetDetail = () => {
  nextTick(() => {
    insertStatus.value = null;
    apiResultSelected.value = 0;
    pageInfo.value.pageNum = 1;
    let tempTemplateLogDetailId = sheetOptions.value.find(
      (obj) => obj.sheetIndex.toString() === selectedSheet.value
    );
    templateLogDetailId.value = tempTemplateLogDetailId?.templateLogDetailId;
    if (templateLogDetailId.value != "0") {
      getTableData();
    } else {
      buttonList.value[0].dataTotal = 0;
      buttonList.value[1].dataTotal = 0;
      buttonList.value[2].dataTotal = 0;
      totalRowNum.value = 0;
    }
  });
};

const onChange = async (file: any) => {
  uploadFileData.value.fileData = file;
  uploadFileData.value.fileName = file.name;
  importDataVisible.value = true;
};

// 确定导入弹窗关闭
const importDataHandleClose = () => {
  importDataVisible.value = false;
};

// 取消数据导入
const cancelImportData = () => {
  importDataVisible.value = false;
  fileList.value = [];
};

// 去上传
const gotoImportData = () => {
  // 关闭定时器
  window.clearInterval(intervalID.value);
  chooseFileDisabled.value = true;
  fileList.value = [];
  let excelUseInfo = getExcelUseInfo();
  let tempCompanyCode = excelUseInfo.companyCode
    ? excelUseInfo.companyCode
    : "";
  let tempUploadData: IFileData = {
    templateId: templateId.value,
    uploadModel: uploadModel.value,
    companyCode: tempCompanyCode,
    fileData: uploadFileData.value,
  };

  importDataVisible.value = false;
  showNoteText.value = t("tg.page.import.M00147");
  importBtnName.value = t("tg.page.import.M00149"); //"重新导入";
  percentageNo.value = 0;
  showTypeFlag.value = "1";
  insertStatus.value = null;
  dealStep.value = 0;
  apiResultSelected.value = 0;
  uploadFileName.value = uploadFileData.value.fileName;
  buttonList.value[0].dataTotal = 0;
  buttonList.value[1].dataTotal = 0;
  buttonList.value[2].dataTotal = 0;
  totalRowNum.value = 0;
  gotoUploadFile(tempUploadData, uploadParam.value);
  // emit('uploadFile',tempUploadData)
};

const handleExceed = () => {};

const beforeRemove = (file: object, fileList: []) => {
  // return proxy.$confirm(`确定移除 ${ file.name }？`);
};

const handleRemove = () => {};
// 表头表格加载标记
const dataListLoading = ref(false);
// 表体表格加载标记
const detailDataListLoading = ref(false);
const detailTableHeaderData = ref<IDetailTableHeaderData[]>([]);
const detailDataList = ref([]);
const dataList = ref([
  // {
  //   status:'1',
  //   test1:'test1',
  //   test2:'test2',
  //   test3:'test3',
  //   test4:'test4',
  //   test5:'test5',
  //   errorProp:[
  //     {
  //       prop:'test1',
  //       errorInfo:'test1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfotest1的errorInfo'
  //     },
  //     {
  //       prop:'test2',
  //       errorInfo:'test2的errorInfo'
  //     },
  //   ],
  //   rightProp:['test3','test4','test5']
  // },
  // {
  //   status:'1',
  //   test1:'test1',
  //   test2:'test2',
  //   test3:'test3',
  //   test4:'test4',
  //   test5:'test5',
  //   errorProp:[
  //     {
  //       prop:'test2',
  //       errorInfo:'test2的errorInfo1'
  //     },
  //     {
  //       prop:'test3',
  //       // errorInfo:'test3的errorInfo1'
  //     },
  //   ],
  //   rightProp:['test1','test4','test5']
  // },
  // {
  //   status:'2',
  //   test1:'test1',
  //   test2:'test2',
  //   test3:'test3',
  //   test4:'test4',
  //   test5:'test5',
  // },
]);
const tableHeaderData = ref([
  // {
  //   prop:'test1',
  //   label:'第1列'
  // },
  // {
  //   prop:'test2',
  //   label:'第2列'
  // },
  // {
  //   prop:'test3',
  //   label:'第3列'
  // },
  // {
  //   prop:'test4',
  //   label:'第4列'
  // },
  // {
  //   prop:'test5',
  //   label:'第5列'
  // },
]);

// 定时器的id
const intervalID = ref(0);
// 详情页面改变表格一页数据大小
const detailHandleSizeChange = (val: number) => {
  nextTick(() => {
    detailTableRef.value.$refs.scrollBarRef.setScrollTop(0);
  });
  detailPageInfo.value.pageNum = 1;
  detailPageInfo.value.pageSize = val;
  getDetailTableData();
};

// 详情页面跳转到第几页
const detailCurrentChangeHandle = (val: number) => {
  nextTick(() => {
    detailTableRef.value.$refs.scrollBarRef.setScrollTop(0);
  });
  detailPageInfo.value.pageNum = val;
  getDetailTableData();
};

// 改变一页的大小
const handleSizeChange = (val: number) => {
  pageInfo.value.pageNum = 1;
  pageInfo.value.pageSize = val;
  if (templateLogDetailId.value != 0) {
    getTableData();
  } else {
    ElMessage.warning(t("tg.page.import.M00128"));
  }
};

// 跳转到第几页
const currentChangeHandle = (val: number) => {
  pageInfo.value.pageNum = val;

  if (templateLogDetailId.value != 0) {
    getTableData();
  } else {
    ElMessage.warning(t("tg.page.import.M00128"));
  }
};

// 点击某一详情
const openOneDetail = (
  itemBody: object,
  indexBody: number,
  main_data_id: string
) => {
  detailDialogVisible.value = true;
  detailDialogTitle.value = t("tg.page.import.detail") + (indexBody + 1);
  getBodyInfo.value.contactDataId = main_data_id;
  getBodyInfo.value.tableId = itemBody;
  detailPageInfo.value.pageNum = 1;
  nextTick(() => {
    detailTableRef.value.$refs.scrollBarRef.setScrollTop(0);
  });
  getDetailTableData();
};

// 获取详情表格数据
const getDetailTableData = () => {
  let tempData = {
    pageNum: detailPageInfo.value.pageNum,
    pageSize: detailPageInfo.value.pageSize,
    contactDataId: getBodyInfo.value.contactDataId,
    tableId: getBodyInfo.value.tableId,
    // templateLogId: templateLogId.value
    templateLogDetailId: templateLogDetailId.value,
  };
  getExcelPageListApi(tempData).then((res) => {
    let tempHeaderRes = res.data.columnHead;
    let tempHeader: string[] = [];
    if (tempHeaderRes.length > 0) {
      tempHeaderRes.forEach((itemHeader: object, headerIndex: number) => {
        let tempItem = Object.keys(itemHeader);
        tempHeader.push(tempItem[0]);
      });
    }
    detailTableHeaderData.value = [];
    if (tempHeader.length > 0) {
      tempHeader.forEach((itemProp: string, indexNo: number) => {
        if (
          itemProp != "insert_remark" &&
          itemProp != "insert_status" &&
          itemProp != "row_index"
        ) {
          let tempItemHeader = {
            prop: itemProp,
            label: res.data.columnHead[indexNo][itemProp],
          };
          detailTableHeaderData.value.push(tempItemHeader);
        }
      });
    }
    detailDataList.value = res.data.pageInfo.list;
    if (detailDataList.value && detailDataList.value.length > 0) {
      detailDataList.value.forEach((itemData: object, itemIndex: number) => {
        if (itemData.insert_status === "失败") {
          itemData.status = "1";
          itemData.isShowMoreError = false;
          itemData.isRowData = true;
          if (itemData.err_validate.length === 0) {
            itemData.rightProp = tempHeader;
          } else if (itemData.err_validate.length > 0) {
            let tempErrValidate = itemData.err_validate;
            let tempErrorProp = [];
            let tempErrorPropList = [];
            tempErrValidate.forEach((itemField: object, indexNo: any) => {
              let tempRightProp = Object.keys(itemField);
              let tempField = tempRightProp[0];
              let tempItemHeader = {
                prop: tempField,
                errorInfo: itemField[tempField],
              };
              tempErrorProp.push(tempItemHeader);
              tempErrorPropList.push(tempField);
            });
            itemData.errorProp = tempErrorProp;
            let tempRightProp = [];
            //
            tempHeader.forEach((itemHeadField: object, indexHead: number) => {
              if (tempErrorPropList.includes(itemHeadField) != true) {
                tempRightProp.push(itemHeadField);
              }
            });
            itemData.rightProp = tempRightProp;
          }
        } else {
          itemData.status = "2"; //  成功标记
        }
      });
    }
    detailPageInfo.value.total = res.data.pageInfo.total;
  });
};

const getCellStyle = (row: object) => {
  if (row.row.status === "1") {
    let tempLabel = row.column.label;
    let tempProp = tableHeaderData.value.find((obj) => obj.label === tempLabel);
    if (tempProp && tempProp.prop && row.row.errorProp) {
      let tempErrorCell = row.row.errorProp.find(
        (itemError) => itemError.prop === tempProp.prop
      );
      if (tempErrorCell) {
        return "error-cell-type";
      }
    }
  }
};

const getCellStyleNew = (row: object) => {
  if (row.row.status === "1") {
    let tempLabel = row.column.label;
    let tempProp = detailTableHeaderData.value.find(
      (obj) => obj.label === tempLabel
    );
    if (tempProp && tempProp.prop && row.row.errorProp) {
      let tempErrorCell = row.row.errorProp.find(
        (itemError) => itemError.prop === tempProp.prop
      );
      if (tempErrorCell) {
        return "error-cell-type";
      }
    }
  }
};

// 获取表格数据
const getTableData = () => {
  nextTick(() => {
    tableRef.value?.$refs?.scrollBarRef?.setScrollTop(0);
  });
  let tempData = {
    pageNum: pageInfo.value.pageNum,
    pageSize: pageInfo.value.pageSize,
    // contactDataId: 1383687200940032,
    // tableId:1383676912312320,
    insertStatus: insertStatus.value,
    templateLogDetailId: templateLogDetailId.value,
    // templateLogId: templateLogId.value
  };
  getExcelPageListApi(tempData).then((res) => {
    buttonList.value[0].dataTotal = res.data.totalRowNum
      ? res.data.totalRowNum
      : 0;
    buttonList.value[1].dataTotal = res.data.totalRowNumSuccess
      ? res.data.totalRowNumSuccess
      : 0;
    buttonList.value[2].dataTotal = res.data.totalRowNumFail
      ? res.data.totalRowNumFail
      : 0;
    totalRowNum.value = res.data.totalRowNumSuccessImport
      ? res.data.totalRowNumSuccessImport
      : 0;
    let tempHeaderRes = res.data.columnHead;
    tableHeaderData.value = [];
    // let tempHeader=Object.keys(res.data.columnHead)
    let tempHeader = [];
    if (tempHeaderRes.length > 0) {
      tempHeaderRes.forEach((itemHeader: object, headerIndex: number) => {
        let tempItem = Object.keys(itemHeader);
        tempHeader.push(tempItem[0]);
      });
    }

    if (tempHeader.length > 0) {
      tempHeader.forEach((itemProp: string, indexNo: number) => {
        if (
          itemProp != "insert_remark" &&
          itemProp != "insert_status" &&
          itemProp != "row_index"
        ) {
          let tempItemHeader = {
            prop: itemProp,
            label: res.data.columnHead[indexNo][itemProp],
          };
          tableHeaderData.value.push(tempItemHeader);
        }
      });
    }
    dataList.value = res.data.pageInfo.list;
    //console.log("dataList.value", dataList.value);

    if (dataList.value && dataList.value.length > 0) {
      dataList.value.forEach((itemData: object, itemIndex: number) => {
        if (itemData.insert_status == "失败") {
          itemData.status = "1";
          itemData.isShowMoreError = false;
          itemData.isRowData = true;
          if (itemData.err_validate.length === 0) {
            itemData.rightProp = tempHeader;
          } else if (itemData.err_validate.length > 0) {
            let tempErrValidate = itemData.err_validate;
            let tempErrorProp = [];
            let tempErrorPropList = [];
            tempErrValidate.forEach((itemField: object, indexNo: any) => {
              let tempRightProp = Object.keys(itemField);
              let tempField = tempRightProp[0];
              let tempItemHeader = {
                prop: tempField,
                errorInfo: itemField[tempField],
              };
              tempErrorProp.push(tempItemHeader);
              tempErrorPropList.push(tempField);
            });
            itemData.errorProp = tempErrorProp;
            let tempRightProp = [];
            //
            tempHeader.forEach((itemHeadField: object, indexHead: number) => {
              if (tempErrorPropList.includes(itemHeadField) != true) {
                tempRightProp.push(itemHeadField);
              }
            });
            itemData.rightProp = tempRightProp;
          }
        } else {
          itemData.status = "2"; //  成功标记
        }
      });
    }
    pageInfo.value.total = res.data.pageInfo.total;
  });
};

const getErrorDataInfo = (row: object) => {
  let tempDataList = JSON.parse(JSON.stringify(dataList.value));
  let tempIndex;
  if (dataList.value.length > 1) {
    tempIndex = row.row_index - 1;
  } else if (dataList.value.length == 1 && row.row_index == 1) {
    tempIndex = 0;
  } else {
    tempIndex = row.row_index;
  }
  if (row.row_index > 1) {
    let tempDataIndex = tempDataList.findIndex(
      (item: object) => item.row_index === row.row_index
    );
    tempIndex = tempDataIndex;
  }

  if (row.isShowMoreError === false) {
    tempDataList[tempIndex].isShowMoreError = true;
    let tempData = {
      isRowData: false,
      insert_remark: row.insert_remark,
    };
    tempDataList.splice(tempIndex + 1, 0, tempData);
  } else if (row.isShowMoreError === true) {
    if (tempIndex == -1) {
      tempIndex = 0;
    }
    tempDataList[tempIndex].isShowMoreError = false;
    tempDataList.splice(tempIndex + 1, 1);
  }
  dataList.value = JSON.parse(JSON.stringify(tempDataList));
};

const getDetailErrorDataInfo = (row: object) => {
  let tempDataList = JSON.parse(JSON.stringify(detailDataList.value));
  let tempIndex;
  if (detailDataList.value.length > 1) {
    tempIndex = row.row_index - 1;
  } else if (detailDataList.value.length == 1 && row.row_index == 1) {
    tempIndex = 0;
  } else {
    tempIndex = row.row_index;
  }
  if (row.row_index > 1) {
    let tempDataIndex = tempDataList.findIndex(
      (item: object) => item.row_index === row.row_index
    );
    tempIndex = tempDataIndex;
  }
  if (row.isShowMoreError === false) {
    tempDataList[tempIndex].isShowMoreError = true;
    let tempData = {
      isRowData: false,
      insert_remark: row.insert_remark,
    };
    tempDataList.splice(tempIndex + 1, 0, tempData);
  } else if (row.isShowMoreError === true) {
    if (tempIndex == -1) {
      tempIndex = 0;
    }
    tempDataList[tempIndex].isShowMoreError = false;
    tempDataList.splice(tempIndex + 1, 1);
  }
  detailDataList.value = JSON.parse(JSON.stringify(tempDataList));
};

// 获取 全部记录、正确记录、错误记录对应的数据
const getItemTypeData = (indexType: number) => {
  if (indexType == 0) {
    insertStatus.value = null;
  } else if (indexType == 1) {
    insertStatus.value = 1;
  } else if (indexType == 2) {
    insertStatus.value = 2;
  }
  apiResultSelected.value = indexType;
  pageInfo.value.pageNum = 1;
  if (templateLogDetailId.value != 0) {
    getTableData();
  } else {
    ElMessage.warning(t("tg.page.import.M00128"));
  }
};

// 下载失败记录
const downloadFailData = () => {
  downExcelErrorApi(templateLogId.value).then((res) => {
    let tempErrorUrl = res.data;
    if (tempErrorUrl != "") {
      // window.open(`${import.meta.env.VITE_API_URL}${tempErrorUrl}`)
      window.open(`${BaseUrl}${tempErrorUrl}`);
    } else {
      ElMessage.warning(t("tg.page.import.M00151")); //未获取到文件地址，请联系管理员！
    }
  });
};

const spanMethods = ({ row, column, rowIndex, columnIndex }) => {
  if (
    row.isRowData === true &&
    row.isShowMoreError === true &&
    dataList.value[rowIndex + 1].isRowData === false &&
    row.status === "1" &&
    (columnIndex === 0 || columnIndex === 1)
  ) {
    return {
      rowspan: 2,
      colspan: 1,
    };
  } else if (
    row.isRowData === false &&
    (columnIndex === 0 || columnIndex === 1)
  ) {
    return {
      rowspan: 0,
      colspan: 0,
    };
  } else if (
    row.isRowData === false &&
    dataList.value[rowIndex - 1].isRowData === true
  ) {
    if (columnIndex === 2) {
      //let tempLength = tableHeaderData.value.length
      return [1, 19];
    } else {
      return {
        rowspan: 1,
        colspan: 1,
      };
    }
  }
};

const spanDetailMethods = ({ row, column, rowIndex, columnIndex }) => {
  if (
    row.isRowData === true &&
    row.isShowMoreError === true &&
    detailDataList.value[rowIndex + 1].isRowData === false &&
    row.status === "1" &&
    columnIndex === 0
  ) {
    return {
      rowspan: 2,
      colspan: 1,
    };
  } else if (row.isRowData === false && columnIndex === 0) {
    return {
      rowspan: 0,
      colspan: 0,
    };
  } else if (
    row.isRowData === false &&
    detailDataList.value[rowIndex - 1].isRowData === true &&
    columnIndex >= 1
  ) {
    let tempLength = detailTableHeaderData.value.length;
    return [1, tempLength + 3];
  }
};

// 获取表头导入情况
const getTemplateLogDetail = () => {
  noteData.value.notice_fail = 0;
  noteData.value.notice_success = 0;
  noteData.value.notice_total = 0;
  //percentList.value = JSON.parse(JSON.stringify(percentResetList.value));
  getTemplateLogDetailApi(templateLogId.value).then((res) => {
    console.log("res", res);

    uploadFileName.value = res.data.uploadFileName;
    templateId.value = res.data.templateId;
    showTypeFlag.value = res.data.dealStatus.toString();
    showNoteText.value = res.data.dealStepName;
    sheetOptions.value = res.data.sheetList;
    hasRkApi.value = res.data.hasRkApi;
    isComplete.value = res.data.isComplete;
    //console.log("sheetOptions.value", sheetOptions.value);

    noteData.value.notice_fail = res.data.notice_fail;
    noteData.value.notice_success = res.data.notice_success;
    noteData.value.notice_total = res.data.notice_total;
    batchNo.value = res.data.batchNo;
    // 若状态是处理完成，则请求表格数据
    if (sheetOptions.value.length > 0) {
      // selectedSheet.value = sheetOptions.value[0].sheetIndex
      // templateLogDetailId.value = sheetOptions.value[0].templateLogDetailId

      let tempselectedSheet = sheetOptions.value.find(
        (item) => item.active === 1
      );
      selectedSheet.value = tempselectedSheet.sheetIndex;
      templateLogDetailId.value = tempselectedSheet.templateLogDetailId;
    } else {
      selectedSheet.value = "";
      templateLogDetailId.value = "";
    }
    pageInfo.value.pageNum = 1;
    tableHeaderData.value = [];
    //dataList.value = [];
    // if (res.data.dealStep === 6) {
    // 	dealStep.value = res.data.dealStep
    // 	if (templateLogDetailId.value != 0) {
    // 		getTableData()
    // 	} else {
    // 		// ElMessage.warning('该sheet暂无模板信息！')
    // 	}
    // } else {
    // 	dealStep.value = res.data.dealStep - 1
    // }

    if (
      templateLogDetailId.value != 0 &&
      (showTypeFlag.value == "3" ||
        showTypeFlag.value == "4" ||
        showTypeFlag.value == "5" ||
        showTypeFlag.value == "-1")
    ) {
      getTableData();
    } else {
      // ElMessage.warning('该sheet暂无模板信息！')
    }
    //if (res.data.dealStep === 6) {
    dealStep.value = res.data.dealStep;
    //} else {
    //  dealStep.value = res.data.dealStep - 1;
    //}

    percentList.value.forEach((itemStep: IPercentList, indexStep: number) => {
      itemStep.color = "#197AFE"; //蓝色进行中
      if (itemStep.stepNo <= dealStep.value) {
        itemStep.percentValue = 100;
        if (itemStep.stepNo == dealStep.value) {
          if (showTypeFlag.value == "5") {
            itemStep.color = "#f56c6c"; //红色异常
          } else if (showTypeFlag.value == "3") {
            itemStep.color = "#72c22d"; //绿色通过
          }
          if (itemStep.stepNo < dealStep.value) {
            itemStep.color = "#72c22d"; //绿色通过
          }
        }
      } else {
        itemStep.percentValue = 0;
      }
    });

    uploadModel.value = res.data.uploadModel ? res.data.uploadModel : 1;

    percentageNo.value = res.data.percent ? res.data.percent : 0;
    percentageData.value = percentageNo.value.toString() + "%";

    if (
      showTypeFlag.value == "3" ||
      showTypeFlag.value == "4" ||
      showTypeFlag.value == "5" ||
      showTypeFlag.value == "-1"
    ) {
      // 任务结束 关闭定时器任务
      window.clearInterval(intervalID.value);
      // 任务结束后调用表格接口
      // getTableData()
    }
    // 待处理 不用调取获取表格数据接口
    if (showTypeFlag.value == "1") {
    } else {
      /**************
       *  其他情况都要调用一下是为了避免上次的状态没有调用表格数据，
       *  此次的状态也表示不用调用表格接口而不调用的情况下
       *  会出现表格没有数据的情况
       ****************/
      // pageInfo.value.pageNum = 1
      // getTableData()
    }
  });
};

// 返回模板列表页面
const goBackImportModule = () => {
  emit("backPage");
};

// 去 上传
const gotoUploadFile = (fileData: IFileData, uploadParam: string) => {
  showTypeFlag.value = "1";
  uploadExcelTempApi(fileData, batchNo.value, uploadParam).then((res) => {
    if (res.data.id) {
      templateLogId.value = res.data.id;
      pageInfo.value.pageNum = 1;
      showTypeFlag.value = res.data.dealStatus.toString();
      // showTypeFlag.value = '2'
    }
    // 数据已上传 待处理
    if (showTypeFlag.value == "1" || showTypeFlag.value == "5") {
      setTimeout(() => {
        getTemplateLogDetail();
      }, 1000);
    } else if (
      showTypeFlag.value == "2" ||
      showTypeFlag.value == "3" ||
      showTypeFlag.value == "4"
    ) {
      setTimeout(() => {
        getTemplateLogDetail();
        // getTableData() // 取消 定时 调用表格接口
      }, 1000);
    }
    if (showTypeFlag.value == "1" || showTypeFlag.value == "2") {
      startTimerGetData();
    }

    // if(res.data.id){
    //   templateLogId.value=res.data.id
    //   pageInfo.value.pageNum=1
    //   getTemplateLogDetail()
    //   getTableData()
    // }
  });
};

// 定时开启查询数据
const startTimerGetData = () => {
  intervalID.value = setInterval(getTemplateLogDetail, 1000);
};

const getOneDataDetailInfo = (id: string) => {
  insertStatus.value = null;
  apiResultSelected.value = 0;
  templateLogId.value = id;
  pageInfo.value.pageNum = 1;
  getTemplateLogDetail();
};

//切换导入模式
const changeUploadModel = (uploadModelTemp: number) => {
  uploadModel.value = uploadModelTemp;
};

const importZs = () => {
  hasClickImport.value = true;
  let msg = t("tg.page.import.importZcHint"); //确认要执行导入操作吗
  tg.msg.confirm(msg).then(() => {
    startTimerGetData();
    importDataApi(templateLogId.value).then((res) => {});
  });
};

const importRedo = () => {
  let msg = t("tg.page.import.redoHint"); //确认要重新导入吗
  tg.msg.confirm(msg).then(() => {
    batchNo.value = "";
    dealStep.value = 0;
    hasClickImport.value = false;
    showTypeFlag.value = "0";
    dataList.value = [];
    uploadFileName.value = t("tg.page.import.M00140");
    showNoteText.value = t("tg.page.import.waitingImport");
    chooseFileDisabled.value = false;
    noteData.value.notice_fail = 0;
    noteData.value.notice_success = 0;
    noteData.value.notice_total = 0;
    totalRowNum.value = 0;
    selectedSheet.value = "";
    sheetOptions.value = [];
    templateLogDetailId.value = "0";
    buttonList.value.forEach((item: object, index: number) => {
      item.dataTotal = 0;
    });
    percentList.value = JSON.parse(JSON.stringify(percentResetList.value));
    hasRkApi.value = 0;
    isComplete.value = 0;
  });
};

const generateAndDownloadExcel = () => {
  //generateAndDownloadExcelApi(templateId.value);

  let request = new TgHttp();
  request.type = "file";
  request.entity.id = templateId.value;
  request
    .build(BaseUrl + "/tgExcelTemplate/generateAndDownloadExcelTg")
    .download(dialogTitle.value);
};

//获取step当前状态
const stepStatus = (curStep) => {
  if (curStep == dealStep.value && showTypeFlag.value == "5") {
    return "error";
  }
  return "";
};

//关闭弹框的时候，清空window.tgImportInstance
function onDialogClose() {
  document.body.removeChild(
    window.tgImportInstance._instance.vnode.el.parentElement
  );
  window.tgImportInstance.unmount();
  window.tgImportInstance = null;
}

let { proxy } = getCurrentInstance();
let tgImportDialogRef = ref(null);

function show(param: ItempUploadData) {
  nextTick(() => {
    templateId.value = param.templateId;
    uploadModel.value = param.uploadModel;
    dialogTitle.value = param.dialogTitle;
    if (param.uploadParam) {
      uploadParam.value = Base64.encode(param.uploadParam);
    }
    proxy.$refs["tgImportDialogRef"].show();
  });
}

onMounted(() => {});
defineExpose({
  gotoUploadFile,
  getOneDataDetailInfo,
  show,
});
</script>
<style lang="scss" scoped>
::v-deep(.el-select .el-input__wrapper) {
  width: 190px !important;
}

.sheet-select-part {
  margin-left: 12px;
}

.note-text {
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  padding-left: 12px;
}

.error-note-text {
  color: #ff4d4f;
}
.font-red {
  border-radius: 4px;
  border: 1px solid red !important;
  color: #ff4d4f !important;
}

.failedText {
  font-size: 14px;
  font-weight: 600;
  color: #ff4d4f;
  background: rgba(255, 77, 79, 0.1);
  border-radius: 8px;
  padding: 4px 18px;
  width: 64px;
}

.successText {
  font-size: 14px;
  font-weight: 600;
  color: #42d4a8;
  background: rgba(81, 201, 161, 0.1);
  border-radius: 8px;
  padding: 4px 18px;
  width: 64px;
}

.import-data-total-content {
  margin-top: -10px;

  .warning-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 4px 4px 0px 0px;
    padding: 17px 24px;

    .warning-info-flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    //margin-top: 12px;
    .warning-icon {
      width: 6px;
      margin: 0px 8px 0px 0px;
      height: 6px;
      background: #1677ff;
      border-radius: 50%;
    }

    .warning-info-text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .red-warning-text {
      color: #197afe;
      text-decoration: underline;
      cursor: pointer;
    }

    .download-excel-sapn {
      color: #197afe;
      text-decoration: underline;
      cursor: pointer;
      margin-right: 0;
    }
  }

  .upload-file-info {
    margin-top: 8px;

    .file-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 11px 24px 19px 24px;
      //background: #f5f6f7;
      border-radius: 4px;

      .left-info {
        display: flex;
        align-items: center;

        .file-img {
          width: 44px;
          padding-right: 15px;
        }

        .file-name {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
        }

        .file-note {
          font-size: 14px;
          font-weight: 500;
          color: #999999;
          margin-top: 4px;
        }
      }

      .right-info {
        display: flex;
        align-items: center;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        padding: 2px 12px;
        user-select: none;

        .down-icon {
          width: 20px;
          padding-right: 2px;
          margin-top: 3px;
        }

        .down-button {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }
      }
    }

    .progress-part {
      margin-top: -8px;
      position: relative;

      .percentage-info {
        font-size: 12px;
        font-weight: 600;
        color: #197afe;
        position: absolute;
        top: -2px;
        //left:10%;
        left: var(--leftData);
      }
    }
  }

  .import-data-process {
    .title-text {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin: 24px 0px 16px 0px;
    }

    .import-data-process-detail {
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
    }
  }

  .import-data-content {
    .title-text {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin: 24px 0px 16px 0px;
    }

    .adminDataButtonPart {
      width: calc(100vw - 333px) !important;
    }

    .data-type-button {
      width: calc(100vw - 85px);
      display: grid;
      //grid-template-columns: repeat(5, 20%);
      grid-template-columns: 15% 15% 15% 15% 12% 12% 12%;
      grid-gap: 20px;

      .total-info-part {
        border: 1px solid #d9d9d9;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px 0px 23px;
        border-radius: 4px;
        user-select: none; /* 禁止用户选择文本 */

        .item-button-left {
          display: flex;
          align-items: center;

          .button-icon {
            width: 40px;
            height: 40px;
          }

          .button-text {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
            padding-left: 12px;
          }
        }

        .item-button-right {
          font-size: 16px;
          font-weight: 600;
          color: #999999;
        }
      }

      .item-data-button {
        //background: var(--itemButtonBg);

        border: 1px solid #d9d9d9;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px 0px 23px;
        border-radius: 4px;

        .button-text {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          padding-left: 12px;
        }

        .item-button-left {
          display: flex;
          align-items: center;
        }

        .item-button-left :hover {
          background: none !important;
        }

        .item-button-right {
          font-size: 16px;
          font-weight: 600;
          color: #999999;
        }
      }

      .item-data-button-total :hover {
        background: rgba(25, 122, 254, 0.08);
        border-color: #197afe;
      }

      .item-data-button-total {
        background: #ffffff;
        user-select: none; /* 禁止用户选择文本 */
      }

      .other-item-button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        color: #197afe;
        background: #ffffff;
        user-select: none; /* 禁止用户选择文本 */
      }

      .fail-records-button {
        border-radius: 4px;
        border: 1px solid #197afe !important;
      }

      .reimport-data-button {
        background: #197afe;
        border-radius: 4px;
        color: #ffffff;
        height: 66px;
      }
    }

    .table-data {
      margin-top: 16px;

      .error-cell-text {
        font-size: 14px;
        font-weight: 500;
        color: #ff4d4f;
        position: relative;
      }

      .error-detail-info-icon {
        position: absolute;
        top: -2px;
        left: 2px;
      }
    }

    .import-data-fail-part {
      margin-bottom: 100px;

      .fail-icon-part {
        display: flex;
        justify-content: center;
      }

      .fail-icon {
        width: 150px;
        margin-top: 185px;
      }

      .wait-icon {
        width: 96px;
        margin: 80px 0px 24px 0px;
      }

      .fail-text {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}

.selectedBorder {
  background: rgba(25, 122, 254, 0.08) !important;
  border: 1px solid #197afe !important;
  border-radius: 4px;
}

::v-deep(.fail-records-button:hover) {
  background: rgba(25, 122, 254, 0.08) !important;
}

::v-deep(.item-button-right:hover) {
  background: none !important;
}

::v-deep(.item-button-left:hover) {
  background: none !important;
}

::v-deep(.el-progress-bar__outer) {
  background: #f5f6f7 !important;
}

::v-deep(
    .el-table th.el-table__cell,
    .el-table.is-scrolling-none th.el-table-fixed-column--left,
    .el-table.is-scrolling-none th.el-table-fixed-column--right
  ) {
  //background: yellow !important;
  background: #fafafa !important;
  //border-radius: 4px 0px 0px 0px;
  font-size: 14px !important;
  font-family: "PingFangMedium";
  font-weight: 600;
  color: #333333 !important;
  padding: 8px 0px 8px 0px !important;
  line-height: 22px !important;
}

::v-deep(.el-table .el-table__cell) {
  padding: 7px 0px !important;
}

::v-deep(.el-table .cell) {
  font-family: "PingFangMedium";
  color: #333333 !important;
  padding: 0px 0px 0px 20px !important;
}

::v-deep(.el-pager li) {
  font-family: "PingFangRegular";
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.88);
}

::v-deep(.el-pager li.is-active) {
  font-weight: 600;
  color: #1677ff;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #1677ff;
}

::v-deep(.el-pagination .el-select .el-input) {
  width: 100px !important;
  border-radius: 6px !important;
}

.bodyTextPart {
  display: flex;
  flex-wrap: wrap;

  .bodyItemTextPart {
    margin-right: 12px;

    .bodyText {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
      display: flex;
      flex-wrap: nowrap;
    }

    .bodyTextLine {
      border-top: 2px solid #333333;
      margin-top: -3px;
    }
  }
}

::v-deep(.el-dialog__body) {
  padding-top: 0px !important;
}

::v-deep(.el-dialog__title) {
  font-size: 16px;
  font-family: "PingFangMedium";
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
}

::v-deep(.oneHundredPercentage-info .el-progress-bar__innerText) {
  display: none !important;
}

::v-deep(.el-upload) {
  width: 100%;
  height: 100%;
  background: #197afe;
  border-radius: 4px;
}

::v-deep(.el-upload:hover) {
  background: #106ae4;
}

::v-deep(.el-upload:hover .reimport-data-button) {
  background: #106ae4 !important;
}

.import-tip-text {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  padding-left: 16px;
}

::v-deep(.el-step__main) {
  margin: -33px 0px 0px 36px !important;
}

::v-deep(.el-step.is-horizontal .el-step__line) {
  left: 104px !important;
  right: 8px !important;
  top: 15px !important;
  display: none;
}

::v-deep(.el-step.is-horizontal .el-step__line) {
  color: #1677ff;
  border-color: #f0f0f0;
  height: 1px;
  display: none;
}

::v-deep(.el-step__head.is-success) {
  color: #1677ff;
  border-color: #1677ff;
}

::v-deep(.el-step__title.is-success) {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}

::v-deep(.el-step__title) {
  font-family: "PingFangRegular";
}

::v-deep(.el-step__icon) {
  width: 28px !important;
  height: 28px !important;
}

::v-deep(.el-step__head.is-process .el-step__icon) {
  background: #1677ff !important;
}

::v-deep(.el-step__head.is-process .el-step__icon.is-text) {
  //border-radius: 0px !important;
  border: 0px solid;
  color: white !important;
}

.step-total-part {
  display: grid;
  grid-template-columns: 92% 8%;
  //grid-gap: 18px;
  margin: 24px 0px 25px 0px;

  .nextStepIcon {
    width: 22px;
    margin: 5px 18px 0px 0px;
  }
}

.progress-total-part {
  margin-top: -10px;
  display: grid;
  //grid-template-columns: repeat(6, 15.8%);
  grid-template-columns: 13% 13% 13% 13% 13% 13% 13%;
  grid-gap: 27.5px;
}

.little-error-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.all-error-text {
  color: #ff4d4f;
  word-wrap: break-word;
}

.error-cell-text-new {
  font-size: 14px;
  font-weight: 500;
  color: #ff4d4f;
  position: relative;
}

.error-detail-info-icon-new {
  position: absolute;
  top: -2px;
  left: 2px;
}

.disabled-div {
  pointer-events: none; /* 阻止鼠标事件 */
  opacity: 0.5; /* 降低透明度以表示不可用 */
  cursor: not-allowed; /* 更改光标形状为禁止符号 */
  user-select: none; /* 禁止用户选择文本 */
}
</style>
<style>
.error-cell-type {
  background: rgba(255, 77, 79, 0.08);
}

.error-info-tooltip {
  max-width: 200px !important;
}
</style>
