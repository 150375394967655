import {TgHttp} from "@coreHttp";
import {PouchdbHelper} from "@core/store/modules/PouchdbHelper";
import apis from "@core/apis";
import localStorage from "@core/utils/TgLocalStorage";
import TgLocalStorage from "@core/utils/TgLocalStorage";

const prefix1: string = '_pouch_DH_'
const prefix2: string = 'DH_'


//尝试构建缓存
export async function tryBuildCacheByAPI(response: any) {
    const clientVersion = getDataHelpVersion();
    const token = TgLocalStorage.get("token");
    let dbNames = await getAllDbs()
    if (token && dbNames.length > 0) {
        if (clientVersion !== response.cacheVersion) {
            let lastCheckDataHelpVersionTime = Number(localStorage.get("lastCheckDataHelpVersionTime"));

            if (lastCheckDataHelpVersionTime) {
                let nowTime: number = new Date().getTime();
                const timeDiff = Math.abs(nowTime - lastCheckDataHelpVersionTime)

                let second = Math.floor(timeDiff / 1000)
                if (second <= 10) {
                    console.info("获取缓存间隔太频繁,本次操作被取消,10秒后尝试")
                    return;
                }
            }
            localStorage.set('lastCheckDataHelpVersionTime', new Date().getTime())
            console.info("本次检测由API调用触发");
            await buildCache(response)
        }
    }
}


//构建缓存
export async function buildCache(params: any) {
    params = params || {}
    //获取所有需要缓存得Code值

    //获取本地当前版本

    //如果本地版本号与服务器版本号不一致

    console.log('开始构建缓存信息,版本:' + params.cacheVersion)
    let dbNames = await getAllDbs()
    let request: TgHttp = new TgHttp();
    request.entity["queryKey"] = dbNames.join(',');
    //获取所有缓存数据
    let response = await request.build(apis.coreChoose.getCacheList).post()
    // let data = []
    if (response != null && response.success) {
        if (response.data != undefined) {
            response.data.forEach(({queryKey, cacheData}) => {
                //遍历插入缓存数据
                const dbName = prefix2 + queryKey
                let pouchdbHelper = new PouchdbHelper(dbName)
                pouchdbHelper.bulkDocs(cacheData, true)
            })
            //更新本地版本号
            const dataHelpVersion = response.cacheVersion
            updateDataHelpVersion(dataHelpVersion)
        }
    }
}

//获取所有indexDbs
async function getAllDbs() {
    return (await indexedDB.databases()).filter(item => item.name?.startsWith(prefix1))
        .map(item => item.name?.substring(prefix1.length))
}

//获取本地版本号
function getDataHelpVersion() {
    return localStorage.get('dataHelpVersion')
}

//更新本地版本号
function updateDataHelpVersion(version: string) {
    localStorage.set('dataHelpVersion', version)
}

//清除放大镜境缓存
export async function clearDataHelpCache(key: string) {
    const dbName: string = prefix2 + key
    try {
        let pouchdbHelper = new PouchdbHelper(dbName)
        if (await pouchdbHelper.hasDatas()) {
            await pouchdbHelper.destroy()
        }
    } catch (err) {
        console.log(err);
    }
}

//清除所有放大镜缓存
export async function clearAllDataHelpCache() {
    const keys = await getAllDbs()
    for (const key of keys) {
        if (key != undefined) {
            await clearDataHelpCache(key)
        }
    }
}

