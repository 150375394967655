import { computed, ref } from 'vue';
import { getSysDataHelpList } from '@core/apis/sysDataHelp';

export default props => {
  const originalOptions = ref([]);
  const options = ref([]);

  /**
   * @description 获取用户自定义option，如果为string格式须转换格式
   */
  const getCustomOptions = computed(() => {
    if (typeof props.customOptions === 'string') {
      return props.customOptions.split(',').map(item => {
        const option = item.split('|');
        return {
          value: option[0] ?? '',
          label: option[1] ?? ''
        };
      });
    }
    return props.customOptions;
  });

  /**
   * @description 从接口获取下拉框数据
   * @returns {{label: string; value: string | number}[]}
   */
  const getOptionsData = async () => {
    const data = await getSysDataHelpList({
      queryKey: props.dataType,
      queryType: '1'
    });
    const optionData = data[props.dataType] ?? [];
    return optionData.map(item => {
      return {
        label: item.name,
        value: item.code
      };
    });
  };

  /**
   * @description 设置下拉框option， 优先使用用户传入自定义option
   * @returns {void}
   */
  const setOptions = async () => {
    if (String(props.customOptions) === '' && props.dataType !== '') {
      originalOptions.value = await getOptionsData();
      options.value = originalOptions.value;
      // 根据正则表达式匹配指定数据
      if(props.dataRegExp) {
        originalOptions.value = originalOptions.value.filter((item)=>{
          return props.dataRegExp.test(item.value)
        })
        options.value = options.value.filter(item=>{
          return props.dataRegExp.test(item.value)
        })
      }
      return;
    }
    originalOptions.value = getCustomOptions.value;
    options.value = originalOptions.value;
  };

  setOptions();

  const filterMethod = e => {
    options.value = originalOptions.value.filter(option => {
      return option.label.includes(e) || option.value.includes(e);
    });
  };

  return {
    options,
    originalOptions,
    setOptions,
    filterMethod
  };
};
