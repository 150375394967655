export default {
  "emsHead_preNo": "预录入号",
  "emsHead_emsNo": "平台账册号",
  "emsHead_hEmsNo": "H2010账册编号",
  "emsHead_emsType": "账册类型",
  "emsHead_emsClass": "账册种类",
  "emsHead_emsAttribute": "账册属性",
  "emsHead_emsFlag": "账册标识",
  "emsHead_tradeCode": "经营单位代码",
  "emsHead_tradeName": "经营单位名称",
  "emsHead_ownerCode": "加工单位",
  "emsHead_ownerName": "加工单位名称",
  "emsHead_declareCode": "申请单位代码",
  "emsHead_declareName": "申请单位名称",
  "emsHead_districtCode": "地区代码",
  "emsHead_tradeMode": "贸易方式",
  "emsHead_cutMode": "征免性质",
  "emsHead_masterFtc": "主管外经贸",
  "emsHead_manageObject": "管理对象",
  "emsHead_modifyMark": "修改标志",
  "emsHead_equipMode": "单耗申报环节",
  "emsHead_endDate": "结束有效期",
  "emsHead_modifyTimes": "变更次数",
  "emsHead_hchxSuject": "核算主体",
  "emsHead_hchxType": "核算方式",
  "emsHead_hchxCycle": "核算周期",
  "emsHead_hchxSdate": "核算开始日期",
  "emsHead_hchxEdate": "核算结束日期",
  "emsHead_hchxStockdate": "库存申报日期",
  "emsHead_areaCode": "区域代码",
  "emsHead_customsCode": "主管海关",
  "emsHead_inputCode": "录入单位代码",
  "emsHead_inputName": "录入单位名称",
  "emsHead_stepId": "当前环节",
  "emsHead_preStepId": "前置环节",
  "emsHead_operType": "操作类型",
  "emsHead_operResult": "操作结果",
  "emsHead_createPerson": "创建人",
  "emsHead_createDate": "创建时间",
  "emsHead_declarePerson": "申报人",
  "emsHead_declareDate": "申报时间",
  "emsHead_approvePerson": "审批人",
  "emsHead_approveDate": "审批时间",
  "emsHead_operPerson": "岗位操作人",
  "emsHead_operDate": "岗位分配时间",
  "emsHead_outerComment": "外部审批意见",
  "emsHead_innerComment": "内部审批意见",
  "emsHead_usedFlag": "启用/停用",
  "emsHead_usedFlagReason": "启用/停用原因",
  "emsHead_platNo": "平台代码",
  "emsHead_workNo": "预申请单号",
  "emsHead_note": "备注",
  "emsHead_qpStatus": "TCS状态",
  "emsHead_contrIn": "进口合同（TCS）",
  "emsHead_note1": "备用标志1（TCS）",
  "emsHead_produceType": "加工种类（TCS）",
  "emsHead_apprImgAmt": "备案进口总额（TCS）",
  "emsHead_apprExgAmt": "备案进口总额（TCS）",
  "emsHead_exCurr": "出口币制（TCS）",
  "emsHead_imCurr": "进口币制（TCS）",
  "emsHead_copEmsNo": "企业内部编号",
  "emsHead_emsApprNo": "批准证编号",
  "emsHead_foreignCoName": "外商公司",
  "emsHead_imgAmount": "进口总金额",
  "emsHead_exgAmount": "出口总金额",
  "emsHead_imgItems": "进口货物项数",
  "emsHead_exgItems": "出口货物项数",
  "emsHead_contrOut": "出口合同号",
  "emsHead_agreeNo": "协议号",
  "emsHead_payMode": "保税方式",
  "emsHead_inputEr": "录入员代号",
  "emsHead_storeVol": "仓库体积",
  "emsHead_storeArea": "仓库面积",
  "emsHead_productRatio": "生产能力",
  "emsHead_noteAmount": "备用金额",
  "emsHead_foreignMgr": "外商经理人",
  "emsHead_inRatio": "内销比率",
  "emsHead_transMode": "成交方式",
  "emsHead_tradeCountry": "起抵地",
  "emsHead_licenseNo": "许可证编号",
  "emsHead_iEPort": "进出口岸",
  "emsHead_declareType": "申报类型",
  "emsHead_batchNo": "批次号"
}