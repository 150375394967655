<template>
  <el-card class="tg_tableList_plus">
    <div class="tg_btnWap">
      <div>
        <slot name="tg_button" />
      </div>
        <!--    右侧设置按钮,原来的版本,配置：1  -->
        <div v-if="SettingCore().VITE_TG_TABLE_SETTING_STYLE === '1'">
              <span
                      class="tg_setting"
                      @click="handleTableSetOld"
                      v-if="
            SettingCore().VITE_TG_TABLE_LIST_SETTING === '1' && showFilterSetting
          ">
          {{ t("tg.page.components.tgTable.tableSetting")
                  }}<el-icon><DArrowRight/></el-icon>
            </span>
        </div>

        <!--    右侧设置按钮， 新版本,配置：2  -->
        <div v-else class="tgTableListPlusSetting">

        <!--
        密度
        如下 el-tooltip 需要用 一个容器包住，否则 el-dropdown 对应的事件不执行
        -->
        <el-dropdown trigger="click">
          <div>
            <el-tooltip
                :content="tm('tg.page.components.tgTable.density','密度')"
                placement="top"
            >
              <span class="icon iconfont icon-midu"></span>
            </el-tooltip>
          </div>
          <template #dropdown>
            <el-dropdown-menu class="tg_tableListPlus_dropdown">
              <el-dropdown-item memo="宽松" @click="setTableSize('big')" :class="{active: tableSize === 'big'}">{{tm("tg.page.components.tgTable.big","宽松")}} </el-dropdown-item>
              <el-dropdown-item memo="中等" @click="setTableSize('normal')" :class="{active: tableSize === 'normal'}">{{tm("tg.page.components.tgTable.normal","中等")}}</el-dropdown-item>
              <el-dropdown-item memo="紧凑" @click="setTableSize('small')" :class="{active: tableSize === 'small'}">{{tm("tg.page.components.tgTable.small","紧凑")}}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <!--  列设置 -->
        <el-dropdown
            trigger="click"
            placement="left-start"
            v-if="SettingCore().VITE_TG_TABLE_LIST_SETTING === '1' && showFilterSetting"
        >
          <div>
            <el-tooltip
                :content="tm('tg.page.components.tgTable.columnSetting','列设置')"
                placement="top"
            >
              <el-icon
                  :size="18"
                  @click="handleTableSet"
              ><Setting /></el-icon>
            </el-tooltip>
          </div>
          <template #dropdown>
            <table-config
                ref="TableConfigRef"
                v-if="SettingCore().VITE_TG_TABLE_LIST_SETTING === '1' && showFilterSetting"
                :data="getTableColumns()"
                :moreData="getTabConfigMore()"
                :version="getTableVersion()"
                @updateConfig="loadConfig"
                @updateFilterConfig="filterConfig"
                :exportConfig="exportConfig" />
          </template>
        </el-dropdown>

          <div v-if="cancelFullScreen" class="full_screen">

              <el-tooltip
                      :content="getIsFullScreen"
                      placement="top"
              >
                  <el-icon
                          :size="18"
                          @click="handleFullScreen"
                  >
                      <img v-if="page.data.isFullScreen" src="@coreImages/table-fullscreen-exit-outlined.png" alt="" />
                      <img  v-else src="@coreImages/table-fullscreen-outlined.png" alt="" />
                  </el-icon>
              </el-tooltip>
          </div>
      </div>
    </div>

    <div :class="getTableSize()">
      <div class="eci-table" :class="class">
        <el-table
            v-tg-table:value="getTableArgs()"
            ref="table"
            :row-class-name="getRowClassName"
            :show-summary="showSummary"
            :sum-text="t('tg.page.components.tgTable.summay')"
            :summary-method="getSum"
            @select="selectOne"
            v-bind="$attrs"
            @select-all="selectAllAction"
            :height="calHeight"
            :data="rows"
            style="width: 100%"
            @sort-change="sortChange"
            @selection-change="selectionChange"
            @row-click="rowClickEx"
            @row-dblclick="rowClick"
            border
            v-loading="loading"
            @header-dragend="changeColWidth"
            element-loading-text="Loading.."
            empty-text=" "
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)"
            :stripe="stripe"
            :tg-filter="tgTableFilter">

          <!--  复选框 -->
          <el-table-column
              v-if="isCheckbox"
              type="selection"
              memo="复选框"
              width="46"
              align="center"
              fixed
          />

            <!--操作列插槽，固定在左边-->
            <template v-if="this.operFixed=='left'">
                <slot>
                </slot>
            </template>

          <!-- 序号列 -->
          <el-table-column
              v-if="getShowIndex()"
              type="index"
              memo="序号"
              :label="t('tg.page.components.tgTable.serialNumber')"
              width="60"
              align="center"
              fixed
          >
            <template #default="scope">
              <div class="tg_tableListPlus_index">
                {{ scope.$index + 1 + (paging.pageNum - 1) * paging.pageSize }}
              </div>
            </template>
          </el-table-column>

<!--            表格列-->
          <template
              v-for="(item, key) in page.data.tableColumns"
              :key="`${key}TgTablePlusList`">
            <el-table-column
                v-if="!!item.show"
                :prop="item.field"
                :label="item.label"
                :min-width="item.width || '160px'"
                :sortable="!!item.columnProps?.sortable"
                :header-align="item.columnProps?.['header-align']"
                :align="item.columnProps?.align || 'left'"
                :show-overflow-tooltip="item.columnProps?.['show-overflow-tooltip']"
                :fixed="item.fixed"
                :sort-by="item.columnProps?.['sortField'] || ''">

                <!--表格查询条件的插槽-->
                <template #headerFilter>
                    <slot :name="`headerFilter__${item.field}`">
                        <tg-input v-model="page.data.entity[item.field]"  />
                    </slot>
                </template>

              <template #default="scope">
                <!-- 表格内容配置，插槽 -->
                <slot :name="item.field" :scope="scope">
                  {{ scope.row[item.field] }}
                </slot>
              </template>
            </el-table-column>
          </template>

            <!--操作列插槽，固定在右边-->
            <template v-if="this.operFixed=='right'">
                <slot>
                </slot>
            </template>

        </el-table>

        <div class="eci_table_pagination" >
            <div class="table_pagination_sum" ></div>
          <div class="block table_pagination_info" >
            <el-pagination
                v-if="showPage"
                background
                ref="pagination"
                @size-change="sizeChange"
                @current-change="indexChange"
                :current-page="paging.pageNum"
                :page-sizes="size"
                :page-size="paging.pageSize"
                :layout="pagingLayout"
                :total="total"></el-pagination>
              <div class="table_pagination_number"   v-else-if="!hideTotal">
                  共 {{total}} 条数据
              </div>
          </div>
        </div>
      </div>
    </div>

      <!--更多设置以前的版本-->
      <TableConfigOld
              ref="TableConfigRefOld"
              v-if="SettingCore().VITE_TG_TABLE_LIST_SETTING === '1' && showFilterSetting"
              :data="getTableColumns()"
              :moreData="getTabConfigMore()"
              :version="getTableVersion()"
              @updateConfig="loadConfig"
              :exportConfig="exportConfig" />

  </el-card>
</template>

<script>
import tg from "@coreTgPlus";
import TableConfig from "./TableConfigPlus.vue";
import TableConfigOld from "./TableConfigPlusOld.vue";
import { SettingCore } from "@core/setting";
import { tm ,t} from "@i18nHelper";
import store from "@core/store";

export default {
  props: {
    isCheckbox: {
      type: Boolean,
      default: () => false,
    },
    page: {
      type: Object,
      default() {
        return {
          keyField: "",
        };
      },
    },
    // 导出配置项：是否显示
    exportConfig: {
      type: Boolean,
      default: () => true,
    },
    //配合操作列的固定，永远在左边或者右边
    operFixed:{
        type: String,
        default: "right",
    },
    table: {
      default() {
        return {
          rows: [],
          paging: {
            pageSize: 10,
            pageNum: 1,
          },
          total: 100,
          loading: false,
        };
      },
    },

    tableArgs: {
      default() {
        return {
          offset: 0,
          pageType: "page",
        };
      },
    },

    //表格是否为斑马纹
    stripe: {
      type: Boolean,
      default: true,
    },

    showPage: {
      type: Boolean,
      default: true,
    },
      //隐藏总的条数
      hideTotal: {
          type: Boolean,
          default: false,
      },
    maxSize: {
      default() {
        return 500;
      },
    },

    fixSize: {
      default() {
        return 0;
      },
    },
    // pageSize: {
    //     default() {
    //         return 10;
    //     },
    // },

    height: {
      default() {
        return 400;
      },
    },

    pagingLayout: {
      default() {
        return "total,sizes, prev, pager, next, jumper";
      },
    },
    showFilterSetting: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
      default: "",
    },
    cancelFullScreen:{
      type:Boolean,
        default(){
            return true
        }
    },
    // 是否显示表头过滤项
    tgFilter:{
        type: Boolean,
        default: false,
    }
  },
  data() {
    return {
      rows: [],
      paging: {
        pageSize: this.page.data.tableProps.defaultPageSize,
        pageNum: 1,
      },
      //控制表格是否显示过滤项
      tgTableFilter:this.tgFilter,
      calHeight: this.height,
      size: this.page.data.tableProps.pageSizeOptions, //.[10, 20, 30, 40, 50, 100, 500],

      total: 0,
      loading: false,
      selectedRows: [],
      tableSelectInfo: {
        num: 0,
      },
      sumData: {},
      showSummary: false,
      byIndex: false,
      pageAction: "next",
      dataCache: {},
      cachedKeys: {},
      restoring: false,
      cacheSelect: false,
      pageType: "page",
      keyField: "",
      maxPage: 5,
    };
  },
  components: { TableConfig,TableConfigOld },

  computed: {
      getIsFullScreen(){
          if(this.page.data.isFullScreen){
             return tm('tg.page.components.tgTable.cancelFullScreen','取消全屏')
          }else{
              return tm('tg.page.components.tgTable.fullScreen','全屏')
          }
      },
    pageCount() {
      let count = 0;
      if (this.total) {
        count = parseInt(this.total / this.paging.pageSize);
        count = count + (this.total % this.paging.pageSize > 0 ? 1 : 0);
      }

      return count;
    },
    // commonPaging() {
    //     return this.pageType.indexOf("page") >= 0;
    // },
    /**
     * 表格的风格
     *  big 宽松
     *  normal 中等
     *  small 紧凑
     * */
    tableSize() {
      let tableSize = store.state.layout.tgTableListPlusDensity
      return tableSize
    }
  },

  mounted() {
    this.rows = this.table.rows;
    //this.paging.pageSize = this.pageSize;
    this.paging.pageNum = 1;

    if (this.tableArgs && this.tableArgs.pageType) {
      this.pageType = this.tableArgs.pageType;
    }

    this.cacheSelect = this.tableArgs.cacheSelect;

    if (!this.pageType) {
      this.pageType = "page";
    }

    if (this.fixSize > 0) {
      this.size = [this.fixSize];
      //固定界面，每页的条数
      this.paging.pageSize = this.fixSize;
    } else if (this.maxSize < 500) {
      let array = [];
      this.size.forEach((item) => {
        if (item <= this.maxSize) {
          array.push(item);
        }
      });

      this.size = array;
    }

    this.maxPage = this.tableArgs.maxPage;

    this.keyField = this.page.data.keyField;

    if (this.page.getRowClassName) {
      this.getRowClassName = this.page.getRowClassName;
    }
  },
  methods: {
      tm,
t,
    SettingCore() {
      return SettingCore;
    },
    getShowFilterSetting() {
      return (
        this.SettingCore().VITE_TG_TABLE_LIST_SETTING === "1" &&
        this.showFilterSetting
      );
    },
    loadConfig(refresh) {
      console.log("refresh", refresh);
        this.page.data.tableMoreSettings.tableImmediateSearch = refresh;
      this.page.getConfig();
    },
    //动态切换表格查询是否显示
    filterConfig(data){
        this.tgTableFilter=data
    },
    // 获取配置项列
    getTableColumns() {
      return this.page?.data?.tableColumns || [];
    },
    /**
     * @description  是否展示序号列
     * */
    getShowIndex() {
      let arr = this.getTableColumns();
      let rtn = arr[0]?.columnNumber;
      // 全局里面配置是否显示序号列
      if(SettingCore.VITE_TG_TABLE_LIST_PLUS_SHOW_INDEX && SettingCore.VITE_TG_TABLE_LIST_PLUS_SHOW_INDEX === 'false') {
        rtn = false;
      }
      return rtn;
    },
    //表格更多设置
    getTabConfigMore() {
      return this.page?.data?.tableMoreSettings || {};
    },
    // 返回版本号
    getTableVersion() {
      return this.page.context.name;
    },
    // 是否显示导出
    handleTableSet() {
      this.$refs.TableConfigRef.show();
    },

    //表格以前风格的：更多设置
    handleTableSetOld(){
        this.$refs.TableConfigRefOld.show();
    },
      //全屏
    handleFullScreen(){
        //获取当前组件的父亲，一直循环找到第一个tg-page-container
        let parentContainer = this.$parent;
        while (parentContainer) {
            if (parentContainer.$el.classList.contains('tg-page-container')) {
                // console.log(parentContainer.$el);
                parentContainer = parentContainer.$el;
                break;
            }
            parentContainer = parentContainer.$parent;
        }

      if(this.page.data.isFullScreen){
          // 获取所有子元素
          const children = parentContainer?.children;
          // 遍历子元素
          Array.from(children).forEach(child => {
              // 检查是否包含指定类名
              child.style.display = '';
          });
          this.page.data.isFullScreen=false
      }else{
          // 获取 tg-page-container 元素
          // const pageContainer = document.querySelector('.tg-page-container');
          // 获取所有子元素
          const children = parentContainer?.children;
          // 遍历子元素
          Array.from(children).forEach(child => {
              // 检查是否包含指定类名
              if (!child.classList.contains('el-card') ||
                  !child.classList.contains('is-always-shadow') ||
                  !child.classList.contains('tg_tableList_plus')) {
                  // 隐藏不符合条件的元素
                  child.style.display = 'none';
              }
          });
          //全屏
          this.page.data.isFullScreen=true
      }
    },
    getTableArgs() {
        let rtn = {
        ...this.tableArgs,
        page: this.page,
        // 标签启动页对应store的值
        storeSysParametersWebIsTab: store.state.sysParameters.userThemesetting.webIsTab,
          //tg-filter-plus,是否展开更多的查询条件，对应的值
          tgFilterIsOpen:this.page.data.tgFilter.isOpen,
          //表格是否全屏，对应的值
          isFullScreen:this.page.data.isFullScreen,
      }
      // console.log('~~~~~rtn~~~~',rtn)
      return rtn;
    },
    getTable() {
      return this.$refs.table;
    },

    selectInfo(data) {
      if (data) {
        this.tableSelectInfo = data;
      } else {
        return this.tableSelectInfo;
      }
    },

    selectOne(selection, row) {
      if (row.children) {
        this.selectOneWithChildren(selection, row);
      } else {
        let checked = selection.indexOf(row) >= 0;

        this.$emit("selectOne", selection, row, checked);
      }
    },

    selectOneWithChildren(selection, row) {
      if (
        selection.find((val) => {
          return val == row;
        })
      ) {
        row.children.forEach((val) => {
          this.$refs.table.toggleRowSelection(val, true);
          selection.push(val);
          if (val.children) {
            this.selectOne(selection, val);
          }
        });
      } else {
        this.toggleRowSelection(selection, row);
      }
    },

    changeColWidth(newWidth, oldWidth, column, event) {
      let prop = column.property;
      let columns = this.page.data.tableColumns;

      if (!columns) {
        return;
      }
      for(let p in columns) {
        let item = columns[p];
        if(item.field === prop) {
          columns[p].width = newWidth;
        }
      }

      this.$refs.TableConfigRef.save(columns)
    },
    selectAllAction(selection) {
      if (selection && selection.length === this.table.rows.length) {
        selection.forEach((val) => {
          this.selectOne(selection, val);
        });
      } else {
        this.$refs.table.clearSelection();
        this.clearCachedKeys();
      }
    },

    selectAll() {
      this.$refs.table.clearSelection();
      this.$refs.table.toggleAllSelection(true);
    },

    selectionChange(val) {
      this.selectedRows = val;

      if (!this.restoring && this.cacheSelect) {
        this.collectCachedKeys();
      }

      let selectedRows = this.selection();

      selectedRows.forEach((row) => {
        row.checked = true;
      });

      let unSelectedRows = this.unSelection();

      unSelectedRows.forEach((row) => {
        row.checked = false;
      });

      this.updateNum(val.length);
    },

    updateNum(num) {
      this.tableSelectInfo.num = num;
      this.num = num;

      if (this.cacheSelect) {
        this.num = this.getCachedKeyLength();
      }
      this.page.data.selectInfo.num = this.num;
    },

    getCachedKeyLength() {
      let count = 0;
      for (let x in this.cachedKeys) {
        count++;
      }

      return count;
    },

    getCachedKeyList() {
      var values = [];

      for (let x in this.cachedKeys) {
        values.push(x);
      }

      var keyValue = values.join(",");

      return keyValue;
    },

    clearSelection() {
      this.$refs.table.clearSelection();
    },

    toggleRowSelection(row, selected) {
      //注意这里的children也是后台返回数据的children字段
      if (selected.children) {
        selected.children.forEach((val) => {
          this.$refs.table.toggleRowSelection(val, false);
          if (val.children) {
            this.toggleRowSelection(row, val);
          }
        });
      } else {
        this.$refs.table.toggleRowSelection(row, selected);
      }
    },

    doLayout() {
      this.$refs.table.doLayout();
    },
    setTableHeight(height) {
      this.calHeight = height;
    },

    setCurrentRow(row) {
      this.$refs.table.setCurrentRow(row);
    },

    sizeChange(val) {
      let oldIndex = this.paging.pageNum;

      this.paging.pageSize = val;
      this.paging.pageNum = 1;

      let state = {
        byPage: true,
      };

      this.search(state);

      if (state.cancel) {
        setTimeout(() => {
          this.paging.pageNum = oldIndex;
        }, 100);
      }
    },

    preQuery() {
      if (this.paging.pageNum == 1) {
        return 1;
      }

      let val = this.paging.pageNum - 1;

      this.indexChange(val);

      return val;
    },
    nextQuery() {
      if (this.paging.pageNum >= this.pageCount) {
        return this.paging.pageNum;
      }

      let val = this.paging.pageNum + 1;
      this.indexChange(val);

      return val;
    },

    indexChange(val) {
      this.byIndex = true;
      let oldIndex = this.paging.pageNum;
      this.paging.pageNum = val;
      let state = {
        byPage: true,
      };
      this.searchExecute(state);
      if (state.cancel) {
        setTimeout(() => {
          this.paging.pageNum = oldIndex;
        }, 100);
      }
    },

    sortChange(val) {
      var sort = val;
      var sortField = "";
      this.paging.sortField = "";
      this.paging.asc = false;

      if (sort.order != null) {
        if (val.column.sortBy) {
          sortField = val.column.sortBy;
          /**
           * 如果字段是代码转名称出来的，在数据库中不存在时
           * sortBy采用这个简写用于方便用户使用
           */
          // if (sortField == "*") {
          //     sortField =
          //         "A." + tg.propertyToField(sort.prop.replace("Name", ""));
          // }
        } else {
          sortField = "A." + sort.prop; // tg.propertyToField(sort.prop);
        }

        if (sort.order === "descending") {
          // this.paging.sortField = sortField + " DESC";
          this.paging.sortField = sortField;
          this.paging.asc = false;
        } else {
          // this.paging.sortField = sortField + " ASC";
          this.paging.sortField = sortField;
          this.paging.asc = true;
        }

        this.searchExecute({
          bySort: true,
        });
      } else {
        this.searchExecute({
          bySort: true,
        });
      }
    },

    search() {
      this.page.search({});
    },

    searchExecute(state) {
      this.page.searchExecute(state);
    },

    rowClick: function (row, column, event) {
      this.page.rowClick && this.page.rowClick(row);
    },

    rowClickEx: function (row, column, event) {
      if (this.page.rowClickEx) {
        this.page.rowClickEx(row);

        if (event.ctrlKey) {
          this.toggleRowSelection(row, !row.checked);
        }
        if (event.esc) {
          this.clearSelection();
        }
      }
    },

    selection() {
      const selectedRows = this.$refs.table.getSelectionRows();
      return selectedRows;
    },

    unSelection() {
      let expandData = [];
      this.getParentData(this.rows, expandData);
      const selectedRows = this.$refs.table.getSelectionRows();
      let unSelectedRows = [];
      for (let i = 0; i < expandData.length; i++) {
        let isSelect = false;
        let item = expandData[i];
        for (let j = 0; j < selectedRows.length; j++) {
          if (item == selectedRows[j]) {
            isSelect = true;
            break;
          }
        }
        if (!isSelect) {
          if (unSelectedRows.indexOf(item) < 0) {
            unSelectedRows.push(item);
          }
        }
      }
      return unSelectedRows;
    },

    getParentData(data, expandData) {
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        expandData.push(item);
        if (item.children) {
          this.getParentData(item.children, expandData);
        }
      }
    },

    getPaging() {
      let paging = tg.copy(this.paging);
      if (!this.showPage) {
        paging.pageSize = 0;
      }
      return paging;
    },

    setPageIndex(index) {
      this.paging.pageNum = index;
    },

    getSum(param) {
      const { columns, data } = param;
      const sums = [];

      if (!this.sumData) {
        this.sumData = {};
      }

      columns.forEach((column, index) => {
        let prop = column.property;
        if (index === 0 && !prop) {
          sums[index] = t("tg.page.components.tgTable.summay"); //汇总
          return;
        }

        let value = this.sumData[prop];

        if (value) {
          sums[index] = value;
        }
      });

      return sums;
    },

    collectCachedKeys() {
      this.rows.forEach((row) => {
        delete this.cachedKeys[row[this.keyField]];
      });

      this.selectedRows.forEach((row) => {
        this.cachedKeys[row[this.keyField]] = 1;
      });
    },

    restoreSelection() {
      return;
      setTimeout(() => {
        this.restoring = true;
        this.rows.forEach((row) => {
          let key = row[this.keyField];
          if (this.cachedKeys[key]) {
            this.toggleRowSelection(row, true);
          }
        });

        this.restoring = false;
      }, 100);
    },

    clearCachedKeys() {
      this.cachedKeys = {};
      this.updateNum(0);
    },

    setToCache(index) {
      this.dataCache["data" + index] = this.rows;
    },

    getFromCache(index) {
      let data = this.dataCache["data" + index];

      return data;
    },

    clearCache() {
      this.dataCache = {};
    },
    getRowClassName({ row, rowIndex }) {
      let name = row._className;

      if (row.checked) {
        if (name) {
          name += " ";
        }

        name += "eci-checked-row";
      }

      return name;
    },
    /**
     * @description 设置表格的风格
     * */
    setTableSize(size='normal') {
      store.commit("updateTgTableListPlusDensity", size);
    },

    /**
     * @description 获取表格的风格
     * */
    getTableSize() {
      let sizeClass = {
        "big":"tg_tableListPlus_sizeBig",
        "normal":"tg_tableListPlus_sizeNormal",
        "small":"tg_tableListPlus_sizeSmall"
      }

      return sizeClass[this.tableSize];
    }
  },

  watch: {
    table: {
      handler(val, oldVal) {
        if (this.table.total != undefined) {
          this.total = this.table.total;
        }

        this.paging.total = this.total;
        this.paging.pageCount = this.pageCount;

        this.rows = this.table.rows;
        this.loading = this.table.loading;
        this.sumData = this.table.sumData;

        if (this.table.sumData) {
          this.showSummary = true;
        }

        if (val.state && val.state.isTrusted) {
          return;
        }
      },
      deep: true,
    },
    fixSize: {
      handler(val, oldVal) {
        if (val > 0) {
          this.size = [val];
          //固定界面，每页的条数
          this.paging.pageSize = val;
        }
      },
      deep: true,
    },
    "page.data.paging.pageSize": {
      //监听，如果表格配置发生变化，就要修改这个组件的值
      handler(val, oldVal) {
        if (val > 0 && val != oldVal) {
          this.paging.pageSize = val;
          // this.sizeChange(val)
        }
      },
      deep: true,
    },
  },
};
</script>

