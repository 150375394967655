import tgProgress from './TgProgress.vue'
import {createApp} from 'vue'
import {
  ElButton,
  ElDialog,
  ElRow,
  ElCol,
  ElProgress,
  ElIcon,
} from "element-plus";
import TgDialog from "@core/components/base/TgDialog.vue";
import TgButton from "@core/components/base/TgButton.vue";
const TgProgress = function (options = {}) {
  if (window.tgProgressInstance) return
  const mountNode = document.createElement('div')
  mountNode.setAttribute('class', 'tg-progress')

  const progressList = createApp(tgProgress, options)
  progressList.component("ElDialog", ElDialog);
  progressList.component("ElButton", ElButton);
  progressList.component("ElRow", ElRow);
  progressList.component("ElCol", ElCol);
  progressList.component("ElIcon", ElIcon);
  progressList.component("ElProgress", ElProgress);
  progressList.component("TgDialog", TgDialog);
  progressList.component("TgButton", TgButton);
  progressList.mount(mountNode)
  document.body.appendChild(mountNode)
  window.tgProgressInstance = progressList
  return progressList._instance
}

export default {
  tgProgress: TgProgress,
  async install(app) {
    app.provide('tg-progress', TgProgress)
  }
}
