export default {
	"cbeInventory":"跨境统一版出口清单表头(操作表)",
	"cbeInventoryItem":"跨境统一版出口清单表体(操作表)",
	"cbeInventory_kj5PartionKey":"kj5PartionKey",
	"cbeInventory_platNo":"平台编号",
	"cbeInventory_syMark":"状态",
	"cbeInventory_b2bWorkNo":"b2bWorkNo",
	"cbeInventory_copCode":"传输企业代码",
	"cbeInventory_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeInventory_appType":"申报类型",
	"cbeInventory_appTime":"申报时间",
	"cbeInventory_appStatus":"申报状态",
	"cbeInventory_customsCode":"申报海关代码",
	"cbeInventory_ebpCode":"电商平台代码",
	"cbeInventory_ebpName":"电商平台名称",
	"cbeInventory_orderNo":"订单编号",
	"cbeInventory_logisticsCode":"物流企业代码",
	"cbeInventory_logisticsName":"物流企业名称",
	"cbeInventory_logisticsNo":"物流运单编号",
	"cbeInventory_copNo":"企业内部编号",
	"cbeInventory_preNo":"电子口岸标志唯一编号",
	"cbeInventory_invtNo":"清单编号",
	"cbeInventory_ieFlag":"进出口标记",
	"cbeInventory_portCode":"出口口岸代码",
	"cbeInventory_ieDate":"进出口时间",
	"cbeInventory_statisticsFlag":"申报业务类型",
	"cbeInventory_agentCode":"申报单位代码",
	"cbeInventory_agentName":"申报单位名称",
	"cbeInventory_ebcCode":"电商企业代码",
	"cbeInventory_ebcName":"电商企业名称",
	"cbeInventory_ownerCode":"生产销售单位代码",
	"cbeInventory_ownerName":"生产销售单位名称",
	"cbeInventory_iacCode":"仓储企业代码",
	"cbeInventory_iacName":"仓储企业名称",
	"cbeInventory_emsNo":"账册备案号",
	"cbeInventory_tradeMode":"贸易方式",
	"cbeInventory_trafMode":"运输方式",
	"cbeInventory_trafName":"运输工具名称",
	"cbeInventory_voyageNo":"航班航次号",
	"cbeInventory_billNo":"提运单号",
	"cbeInventory_totalPackageNo":"总包号",
	"cbeInventory_loctNo":"监管场所代码",
	"cbeInventory_licenseNo":"许可证编号",
	"cbeInventory_country":"运抵国（地区）",
	"cbeInventory_pod":"目的港代码",
	"cbeInventory_freight":"运费",
	"cbeInventory_fCurrency":"运费币制",
	"cbeInventory_fFlag":"运费标志",
	"cbeInventory_insuredFee":"保费",
	"cbeInventory_iCurrency":"保费币制",
	"cbeInventory_iFlag":"保费标志",
	"cbeInventory_wrapType":"包装种类",
	"cbeInventory_packNo":"件数（包裹数量）",
	"cbeInventory_grossWeight":"毛重(kg)",
	"cbeInventory_netWeight":"净重(kg)",
	"cbeInventory_note":"备注",
	"cbeInventory_messageId":"消息ID",
	"cbeInventory_inputDate":"创建日期"
}
