/**
 * @description 加入快捷工作区 动画封装
 * @author Jerome.luo
 * @date 2022/02/10
 * @export
 * @return { * }
 */

import { getCurrentInstance, reactive  } from 'vue'

export default function () {
    const { proxy } = getCurrentInstance()
    
    // 动画小球数据状态
    const ballPramas = reactive({
        balls: [
            { show: false },
            { show: false },
            { show: false }
        ],
        dropBalls: []
    })
    const beforeEnter = (el) => {
        let count = ballPramas.balls.length
        while (count--) {
            let ball = ballPramas.balls[count]
            if (ball.show) {
                let rect = 200 // 获取小球的相对于视口的位移(小球高度)
                let x = -300
                let y = rect - 90 // 负数是从左上角往下的的方向, 正数是往上
                el.style.display = '' // 清空display
                el.style.webkitTransform = `translate3d(0, ${y}px, 0)`
                el.style.transform = `translate3d(0, ${y}px, 0)`
                // 处理内层动画
                let inner = el.getElementsByClassName('inner-hook')[0] // 使用inner-hook类来单纯被js操作
                inner.style.webkitTransform = `translate3d(${x}px, 0, 0)`
                inner.style.transform = `translate3d(${x}px, 0, 0)`
            }
        }
    }
    const enter = (el, done) => {
        let rf = el.offsetHeight // 触发重绘html
        proxy.$nextTick(() => {
            // 让动画效果异步执行,提高性能
            el.style.webkitTransform = 'translate3d(0, 0, 0)'
            el.style.transform = 'translate3d(0, 0, 0)'
            // 处理内层动画
            let inner = el.getElementsByClassName('inner-hook')[0] // 使用inner-hook类来单纯被js操作
            inner.style.webkitTransform = 'translate3d(0, 0, 0)'
            inner.style.transform = 'translate3d(0, 0, 0)'
            el.addEventListener('transitionend', done) // Vue为了知道过渡的完成，必须设置相应的事件监听器。
        })
    }
    const afterEnter = (el) => {
        let ball = ballPramas.dropBalls.shift() // 完成一次动画就删除一个dropBalls的小球
        if (ball) {
            ball.show = false
            el.style.display = 'none' // 隐藏小球
        }
    }
    const handleJoinModal = (flag, name) => {
        // 设置小球滚动显隐
        for (let i = 0; i < ballPramas.balls.length; i++) {
            let ball = ballPramas.balls[i]
            if (!ball.show) {
                ball.show = true
                // ball.el = target
                ballPramas.dropBalls.push(ball)
                return
            }
        }
    }

    return { ballPramas, beforeEnter, enter, afterEnter, handleJoinModal }
}