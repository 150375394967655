<template>
  <div>
    <el-drawer
        v-model="visiable"
        :title="$t(`tg.page.main.theme.themeSet`)"
    >
      <div class="right-menu-box">


        <UserConfig/>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import {ref} from "vue"
import UserConfig from './userConfig.vue'

const visiable = ref(false)

const show = (type) => {
  visiable.value = !visiable.value
}

defineExpose({
  show
})
</script>

<style scoped lang='less'>
.right-menu-box {
  width: 100%;
  overflow-y: auto;
  border-radius: 3px;
  z-index: 100;
  -webkit-animation: slideInRight 0.05s;
  animation: slideInRight 0.05s;
  box-sizing: border-box;
}

:deep(.el-tabs--border-card) {
  border: none;
  box-shadow: initial;
}

.slide-enter-active {
  animation: slideInRight 0.3s;
}

.slide-leave-active {
  animation: slideOutRight 0.3s;
}

:deep(.el-drawer__body) {
  background-color: #fff;
}
</style>
