<template>
  <el-drawer  append-to-body v-model="state.visible" title="最近请求" size="500px" class="allRequestDialog">
    <template v-for="(item,index) in state.list">
        <el-card
            class="tgList"
            :class="{active: index === state.active}"
            @click="clickOne(index)"
            v-if="isError(item,state.type)"
        >
          <div class="layoutBtnWap">
            <div>
              {{  item.config.url}}
            </div>
            <el-button class="responseTimeBtn" plain :type="getResponseTime(item).flag ? 'success':'warning'">{{ getResponseTime(item).time }}</el-button>
          </div>
        </el-card>
    </template>

<!--    <el-table :data="state.list" style="width: 100%">-->
<!--      <el-table-column prop="date" label="URL">-->
<!--        <template #default="scope">-->
<!--          {{ scope.row.config.baseURL + scope.row.config.url}}-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
    <template #footer><div></div> </template>
  </el-drawer>
</template>
<script setup lang="ts">
import {reactive} from 'vue'
const $emit = defineEmits('update')
const state = reactive({
  visible: false,
  list: [],
  active: -1,
  type: ''
})

const show = ({list,type='all'})=>{
  state.visible = true
  state.type = type
  state.list = list
  state.active = -1
}

const close = ()=>{
  state.visible = false
}

const clickOne = (index)=>{
  state.active = index
  $emit('update',index)
}

const isError = (item,type)=>{
  let flag = true
  if(type ==='error' ) {
    flag = !(item.data && item.data.code && (item.data.code+'').substring(0,1) === '1')
  }

  return flag
}

// 此处需要优化：响应时间
const getResponseTime = (item)=>{
  let time = 0
  try{
    time = item.config.headers['TgRequestEndTime'] - item.config.headers['TgRequestStartTime']
  }catch(e){}
  return {
    time,
    flag: time<1000
  }
}

defineExpose({
  show,
  close
})
</script>
<style lang="less" scoped>
.allRequestDialog {
  .tgList {
    margin-bottom: 10px;
    cursor: pointer;
    word-break: break-all;
    &.active {
      background: var(--theme-color);
      color: #fff;
    }
  }

}

.layoutBtnWap {
  display:flex;justify-content: space-between;
  .responseTimeBtn{
    margin-left: 10px;
  }
}

</style>
