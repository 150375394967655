import tg from '@coreTgPlus'
import localStorage from "@/tiangongCore/utils/TgLocalStorage";
import {computed, getCurrentInstance, inject, nextTick, onBeforeMount, onMounted, reactive, ref, useAttrs} from "vue";
import $ from "jquery"
import {t, tm} from '@i18nHelper'

export default function (page) {
    page = page || {}
    //页面的上下文信息
    page.asHead = false//EditTab中，是否是head
    page.editPage = null//编辑界面
    page.root = false//是否是root界面
    page.sub = false//是否是子页面
    page.editPart = false
    page.context = page.context || {}
    page.pageChoose = []  //页面的放大镜数组
    page.eciruntime = {eciFit: [], eciTable: [], eciTab: []} //运行时动态收集
    page.context.instance = getCurrentInstance() //当前页面实例信息vue提供的
    page.context.root = page.context.instance//等价于proxy
    page.context.rootPage = page//当前界面的page包括方法和data
    page.context.name = ""//页面name,唯一性,表格和form都用它来动态加载
    page.context.children = []//子页面信息
    page.context.parent = null //父页面
    page.hasCheckVersion = false//检测是否下拉框更新版本
    page.isBasePage = true//是基础页面
    //页面展示方式
    page.pagePopType = {
        popType: 'page',//dialog,newWindow,tagWindow
        newWindowPath: '',
        component: '',
        openOption: '',
        popName: ''
    }

    const {proxy} = getCurrentInstance();

    /**
     * page 数据初始化
     * @type {{debugTag: Ref<UnwrapRef<string>>, sub: Ref<UnwrapRef<boolean>>, keyField: Ref<UnwrapRef<string>>, editPage: null, visible: Ref<UnwrapRef<boolean>>, keyValue: Ref<UnwrapRef<string>>, isView: Ref<UnwrapRef<boolean>>, cascade: null, pageInfo: Ref<UnwrapRef<{code: string, type: string}>>, title: Ref<UnwrapRef<string>>, headRowIsNull: Ref<UnwrapRef<boolean>>, loading: Ref<UnwrapRef<boolean>>, row: Ref<UnwrapRef<{}>>, headRow: Ref<UnwrapRef<{}>>, entity: Ref<UnwrapRef<{}>>, isPage: Ref<UnwrapRef<boolean>>}}
     */
    const baseData = reactive({
        debugTag: '',
        pageInfo: {type: 'BasePage', code: '', name: '', showed: false, notify: false},//页面信息
        title: tm('tg.page.msg.title.edit', '编辑页面'),//编辑
        visible: false, //判断编辑界面是否显示
        isDialog: false,//是否是dialog  //最为编辑画面，如果是dialog 如果不是dialog那么会刷新列表页
        keyField: '',//主键
        keyValue: '',//值
        entity: {},//查询表单，编辑表单
        entityDefault: {},//默认的实体信息
        entityCheck: {}, //放大镜的验证信息
        entityConfig: {}, //表单entity配置信息
        row: {},
        headRow: {},//主表行数据
        headRowIsNull: true,//headRow是否为空
        formLoading: false, //表单加载……
        exportLoading: false, //导出加载……
        loading: false, //加载....
        isPage: true,
        isView: false,//是否是查看模式
        cascade: null,// {field: "", headField: "", allowNull: false},//级联关系:{allowNull:是否允许为空}
        formData: {}, //隐藏表单信息
        dialogConfig: {width: "auto", height: "auto", keepSize: false}, //弹窗配置
        dataChanged: false, //数据是否改变
        rules: {}, //表单校验规则
        //界面配置信息
        pageConfig: {
            listRefresh: true,//编辑页面保存以后，查询界面，是否要重新查询。默认：true
            editMode: "dialog",
            editSaveClose: false
        },
        dsp: '',
        editFormRef: 'editForm',//编辑form表单的ref名称
        isAdd: false,//是否是新增
        isChoose: false,//是否是放大镜
        bizName: null, //业务对象名
        lockFlag: false, //锁定标记
        pageClearWithParent: true, //当前页面清空设定 是否随主页面清空而同时清空
        coreUI: false,//是否是配置
        hasLoadUI: false,

    })

    page.data = baseData

    //判断当前界面，headRow是否为空
    page.data.headRowIsNull = computed(() => {
        if (page.data.headRow && Object.keys(page.data.headRow).length == 0) {
            return true
        } else {
            return false
        }
    })

    //用户信息
    page.context.getUserInfo = () => {
        let userinfo = localStorage.get("userinfo")
        if (userinfo && userinfo.userInfo) {
            return userinfo.userInfo
        }
    }

    //加载界面的的弹出类型
    handlerAttr2PageProps()

    onBeforeMount(() => {
        //拷贝之前的实体的值
        for (let x in page.data.entity) {
            page.data.entityDefault[x] = page.data.entity[x];
        }

        //建立界面的父子关系
        build(page)
    })

    onMounted(() => {
        page.data.dsp = tg.guid32()
    })

    /**
     * page 建立父子关系
     * @param page 父页面的page
     * 说明：sub：sub页面，edit：编辑页面，
     */
    const build = function (page) {

        if (page.data.isChoose) {
            return
        }

        const attrs = page.context.instance?.attrs || {}
        // page.context.name = page.context.instance?.type.__name

        //是否是子页面
        if (attrs.sub == '') {
            page.sub = true
        }

        if (attrs.root == '') {
            page.root = true
        }
        if (attrs.editPart == '') {
            page.editPart = true
        }

        //获取界面的refCode
        if (attrs.refCode != "" && attrs.refCode != undefined) {
            page.refCode = attrs.refCode
        }

        //编辑界面
        let isEditPage = false
        if (attrs.editPage == '') {
            //当前页面
            isEditPage = true
        }
        if (attrs.page) {
            const parent = attrs.page
            if (parent) {
                page.context.pageId = page.context.pageId || ''
                page.context.root = parent.context.root
                page.context.rootPage = parent.context.rootPage
                page.context.parent = parent
                parent.context.children.push(page)

                if (isEditPage) {
                    if (page.data.pageInfo.type == 'EditPage' || page.data.pageInfo.type == 'EditTab') {
                        parent.editPage = page
                    }
                }
            }
        }
    }

    /**
     * 通过配置refCode,然后获取refcode对应的page信息，方便父页面调用
     * @param code
     * @returns {string|void}
     */
    //通过RefCode获取子页面
    page.context.getRefByCode = function (code) {
        let refPage = "";
        for (let i = 0; i < page.context.children.length; i++) {
            let item = page.context.children[i];
            if (item.refCode && code == item.refCode) {
                refPage = item;
            }
        }
        if (refPage == "") {
            return tg.msg.alertError(t('tg.page.tgPage.basePage.M00028') + "：" + code);//没有找到注册界面
        }
        return refPage;
    }

    //通过refName获取当前界面的组件
    page.context.getRef = (refName) => {
        return page.context.instance?.refs[refName]
    }

    /**
     * 获取父节点的实例instance
     * @returns {ComponentInternalInstance|void}
     */
    page.context.getContextInstance = () => {
        if (page.context?.parent) {
            return page.context.parent.context?.instance
        } else {
            return t("tg.page.tgPage.basePage.M00029")//当前界面没有找到父界面
        }
    }
    //获取父页面信息
    page.context.getContextPage = () => {
        if (page.context.parent) {
            return page.context.parent
        } else {
            return null
        }

    }
    //获取某个示例的，ref
    /**
     * 通过refName 获取instance 下面的组件
     * @param instance
     * @param refName
     * @returns {*}
     */
    page.context.getRefByInstance = (instance, refName) => {
        return instance.refs[refName]
    }


    //注册放大镜
    page.regChoose = function (instance, codeField) {
        page.regChooseRule(codeField)

        page.pageChoose.push({
            chooseInstance: instance,
            eciType: instance.props.dataType,
            pageName: page.context.name,
        })
    }

    //注册放大镜校验规则
    page.regChooseRule = function (field) {

        const validateChoose = async (rule, value, callback) => {

            let field = rule.field
            let message = page.data.entityCheck[field]
            if (message) {
                return callback(new Error(message))
            }
            callback()
        }

        if (!field) {
            throw Exception(t("tg.page.tgPage.basePage.M00053"))//注册放大镜验证规则失败field为空
            //注册放大镜验证规则失败field为空
        }

        if (page.data.rules) {
            let item = page.data.rules[field]
            if (!item) {
                item = []
                page.data.rules[field] = item
            } else {
                //存在校验
                for (let key in item) {
                    //判断是否存在放大镜校验
                    if (item[key].trigger == 'change' && item[key].validator?.name == 'validateChoose') {
                        return
                    }
                }
            }

            let isArray = item instanceof Array
            if (!isArray) {
                // 验证规则填写错误,应该是数组
                throw Exception(field + ":" + t("tg.page.tgPage.basePage.M00049"))
            }

            item.push({
                validator: validateChoose,
                trigger: "change"
            })

            //清除编辑界面，一加载出现的那个红色校验
            nextTick(() => {
                page?.clearValidate && page?.clearValidate()
            })

        }
    }

    /**
     * show方法
     * @param row
     * @param state
     */
    page.show = function (row, state) {
        page.baseShow(row, state)
    }

    /**
     * show方法
     * @param row
     * @param state
     */
    page.baseShow = function (row, state) {

        page.data.dataChanged = false

        page.data.visible = true

        //判断是否有dialog打开并且最小化
        if (page.pagePopType.popType != 'page') {
            const ret = page.dialogOpen()
            //如果有最小化的dialog，就直接打开了
            if (ret == false) {
                return
            }
        }

        page.setView(state)

        //对外接口
        if (page.showed) {
            page.showed(row, state)
        }

        page.clearValidate()
    }

    //将页面的数据挂到page.data上面
    page.useData = function (props) {
        for (let key in props) {
            page.data[key] = ref(props[key])
        }

    }

    //useDialog：将dialog身上的方法挂载到page.dialog上
    page.useDialog = function () {

        //将当前界面，挂到界面的page.dialog上面
        if (page.pagePopType.popType == 'dialog') {
            //获取dialog的方法
            let dialogMethod = page.context.getRef("tgPage")?.getDialog()

            //如果界面没有tgPage,就查询dialog组件
            if (dialogMethod == undefined) {
                dialogMethod = page.context.getRef("dialog")
            }
            page.dialog = dialogMethod
            page.data.isDialog = true
        }
    }
    //dialog打开
    page.dialogOpen = function () {
        if (page.dialog?.isDialogMini() == true) {
            //有未保存的数据最小化，是否继续编辑
            tg.msg.confirm(t("tg.page.tgPage.basePage.M00050")).then(async () => {
                page.dialog?.recoverModal && page.dialog?.recoverModal()
                return
            })
            return false
        }
        if (page.dialog && !page.dialog.getVisibile() && page.pagePopType.popType == 'dialog') {
            page.dialog.show()
            if (page.dialogOpened) {
                return page.dialogOpened()
            }
            return true
        }
        return false
    }
    //dialog关闭
    page.dialogClose = function () {
        if (page.context.getContextPage() && page.context.getContextPage().data.pageInfo &&
            page.context.getContextPage().data.pageInfo.type == 'QueryPage') {
            if (page.data.dataChanged) {
                page.context.getContextPage().onDataChanged()
            }
        }

        page.pageClear()
        page.data.visible = false
        page.clearValidate()

        let closeNode = page
        while (closeNode.context.parent != null && closeNode.pagePopType.popType != 'dialog') {
            closeNode = closeNode.context.parent
        }

        if (closeNode.dialog && closeNode.pagePopType.popType == 'dialog') {
            const visible = closeNode.dialog?.getVisibile()
            if (visible) {
                closeNode.dialog?.close()
            }
        }

        if (page.dialogClosed) {
            page.dialogClosed()
        }
    }
    //设置界面的是否是弹框或者是page，如果添加了popType，默认是dialog，
    // 如果是page界面不应该加，默认就是page
    function handlerAttr2PageProps() {
        const attrs = useAttrs()
        if (attrs.popType == undefined) {
            page.pagePopType.popType = 'page'
        } else {
            if (attrs.popType == 'newWindow' || attrs.popType == 'tagWindow') {
                page.pagePopType.popType = attrs.popType
                page.pagePopType.popName = attrs.popName
                if (attrs.openOption) {
                    page.pagePopType.openOption = attrs.openOption
                }
                if (attrs.popcomp) {
                    page.pagePopType.component = attrs.popcomp
                }
            } else {
                page.pagePopType.popType = 'dialog'
            }
        }
    }


    //获取route参数
    page.req = function (param) {
        let value = proxy.$route.query[param]
        if (!value) {
            value = ""
        }
        return value
    }

    //通过pageid获取子页面
    page.get = function (pageId) {
        page.context.children.some((element) => {
            if (element.context.pageId == pageId) {
                return element
            }
        })
        //无法获取子页面
        throw new Exception(t("tg.page.tgPage.basePage.M00051") + ':' + pageId)
    }

    //设置headRow信息
    page.setHeadRow = function (headRow) {
        if (headRow == undefined) {
            headRow = null
        }
        page.data.headRow = headRow
        if (page.data.cascade != null) {
            const unrefCascade = page.data.cascade
            if (unrefCascade) {
                if (page.data.headRow) {
                    const value = page.data.headRow[unrefCascade.headField]
                    page.data.entity[unrefCascade.field] = value
                    const map = unrefCascade.map
                    if (map) {
                        for (const key in map) {
                            page.data.entity[key] = page.data.headRow[map[key]]
                        }
                    }
                } else {
                    page.data.entity[unrefCascade.field] = ''

                    const map = unrefCascade.map
                    if (map) {
                        for (const key in map) {
                            page.data.entity[key] = ''
                        }
                    }
                }
            }
        }
        nextTick(() => {
            if (page.editPage) {
                page.editPage.setHeadRow(headRow)
            }
            //开放接口，setHeadRow以后调用
            if (page.setHeadRowed) {
                page.setHeadRowed(headRow)
            }
        })

        //如果当前界面是edit界面，headrow，就不往下传递
        if (page.data.pageInfo.type == 'EditPage') {
            return
        }
        page.setHeadRowSub(headRow)
    }

    //设置headRowSub信息
    page.setHeadRowSub = function (headRow) {
        for (const index in page.context.children) {
            const child = page.context.children[index]
            if (child.sub) {
                //作为页面的子表的列表页
                child.setHeadRow(headRow)
                if (headRow) {
                    //有headRow进行查询
                    page.search && page.search()
                } else {
                    //没有进行清空
                    page.search && page.clearTable()
                }
            }
        }
    }

    //form校验
    page.formValidate = function (prop, result) {
        if (result == false) {
            nextTick(() => {
                $(".el-form-item__error").unbind("click")
                $(".el-form-item__error").click(function () {
                    page.clearValidate(true)
                })
            })
        }
    }
    //form清除校验
    page.clearValidate = function (needRecover) {
        if (page.editPage) {
            page.editPage.data.entityCheck = {} //清除放大镜的验证
            page.editPage.clearValidate() // 只清除清除验
            let entityCheck = page.data.entityCheck;
            setTimeout(() => {
                page.editPage.data.entityCheck = {} //清除放大镜的验证
                page.editPage.clearValidate() // 只清除清除验
                if (needRecover)
                    page.data.entityCheck = entityCheck;
            }, 100)
        }
    }

    //清空查询界面的放大镜校验
    page.clearSearchValidate = () => {
        if (page.context.getRef("queryForm")) {
            page.data.entityCheck = {} //清除放大镜的验证
            page.context.getRef("queryForm").clearValidate()
        }
    }

    //设置是否预览
    page.setView = function (state) {
        if (state?.isView == true) {
            page.data.isView = true
        } else {
            page.data.isView = false
        }

        for (const index in page.context.children) {
            const child = page.context.children[index]
            if (child.sub) {
                child.setView(state)
            }
        }
    }
    //设置父子表的级联字段
    page.setCascade = function (cascade) {
        page.data.cascade = cascade
        nextTick(() => {
            if (page.editPage) {
                page.editPage.setCascade(cascade)
            }
        })
    }
    //清空界面
    page.pageClearWarp = function () {
        for (const index in page.context.children) {
            const child = page.context.children[index]
            if (child.data.pageClearWithParent) {
                //子页面的pageclear
                child.pageClear()
                child.pageClearWarp()
            }
        }
        page.pageClearXt()
        //预留接口
        page.pageCleared && page.pageCleared()
    }
    //协同清空子界面
    page.pageClearXt = function () {
        if (page.editPage) {
            page.editPage.setHeadRow(null)
        }
        for (const index in page.context.children) {
            const child = page.context.children[index]
            if (child.sub) {
                child.setHeadRow(null)
            }
        }
    }

    page.clearValidate = function () {
        if (page.editPage) {
            page.editPage.entityCheck = {}//清楚放大镜验证
            page.editPage.clearValidate()
        }
    }

    page.pageClear = function () {
        page.basePageClear()
    }

    page.basePageClear = function () {
        for (let index in page.context.children) {
            let item = page.context.children[index]
            if (item.pageClearWithParent) {
                item.pageClear()
            }
        }
        page.pageClearXT()
        if (page.pageCleared) {
            page.pageCleared()
        }

    }

    //页面清空协同
    page.pageClearXT = function () {
        if (page.editPage) {
            page.editPage.setHeadRow(null)
        }

        for (let index in page.context.children) {
            let item = page.context.children[index]
            if (item.sub) {
                //作为子页面
                item.setHeadRow(null)
            } else if (page.editPart) {
                item.add();
            }
        }
    }

    page.lockAction = function () {
        page.data.lockFlag = true
    }
    page.unlockAction = function () {
        page.data.lockFlag = false
    }
    //通知其他界面
    page.noticeContextPage = function (options) {
        page.context.getContextPage().onNotice(options)
    }

    //注册下拉框
    page.regEciSelect = function () {
        if (page.hasCheckVersion) {
            return;
        }

        tg.checkEciSelectVersion();

        page.hasCheckVersion = true;
    }

    page.layout = function (state) {
        if (!state) {
            state = {}
        }
        page.eciruntime.eciFit.forEach(item => {
            tg.fitAction(item.el, item.binding, item.vnode)
        })

        page.eciruntime.eciTable.forEach(item => {
            tg.doResize(item.el, item.binding, item.vnode, state)
        })

        setTimeout(() => {
            page.context.children.forEach(page => {
                page.layout()
            })
        }, 0)
    }

    page.redoTableHeight = function () {
        page.context.children.forEach((item) => {
            item.redoTableHeight()
        })
    }


    page.addRuleEx = function (tableName, codeList) {
        let array = codeList.split(",")

        for (let index in array) {
            let item = array[index]
            let rule = page.data.rules[item]
            if (!rule) {
                rule = []
            }

            rule.push({
                required: true,
                message: t("tg.page.tgPage.basePage.NotNull"),//不能为空
                trigger: "blur"
            })
            page.data.rules[item] = rule
        }
    }

    //创建规则
    page.createRule = function (options) {
        let validateCode = (rule, value, callback) => {
            if (!value) {
                let langCode = rule.langCode
                return callback(new Error(page.$tnn(langCode)))
            } else {
                return callback()
            }
        }


        let prop = options.prop

        let rule = {
            validator: validateCode,
            trigger: options.trigger,
            langCode: options.langCode
        }
        page.data.rules[prop] = [rule]
    }

    //移除规则
    page.removeRule = function (codeList) {
        let array = codeList.split(",")
        for (let index in array) {
            let item = array[index]

            let rule = page.data.rules[item]
            if (rule) {
                for (let i = 0; i < page.data.rules[item].length; i++) {
                    page.data.rules[item][i].required = false
                }
            }
        }
    }

    //添加规则
    page.addRule = function (codeList) {
        let array = codeList.split(",")

        for (let index in array) {
            let item = array[index]
            let rule = page.data.rules[item]
            if (!rule) {
                rule = []
            }

            rule.push({
                required: true,
                message: t("tg.page.tgPage.basePage.NotNull"),//不能为空
                // trigger: ['blur', 'change']
                trigger: 'blur'
            })
            page.data.rules[item] = rule
        }
    }

    //合并两个rules对象
    page.mergeRules = function (rules1, rules2) {
        // 合并两个 rules 对象
        const mergedRules = {};
        for (let key in rules1) {
            if (rules2[key]) {
                mergedRules[key] = Array.isArray(rules1[key]) ? rules1[key] : [rules1[key]];
                mergedRules[key].push(...(Array.isArray(rules2[key]) ? rules2[key] : rules2[key]));
            } else {
                mergedRules[key] = rules1[key];
            }
        }
        for (let key in rules2) {
            if (!rules1[key]) {
                mergedRules[key] = rules2[key];
            }
        }
        return mergedRules
    }
    //转换日期格式
    page.convertDate = function (data, format) {
        if (!data) {
            return ""
        }

        let dt = new Date(data)

        if (!format) {
            format = "yyyy-MM-dd HH:mm:ss"
        }
        return dt.format(format)
    }
    //转换日期格式
    page.formatDate = function (row, column, cellValue, index) {
        let data = row[column.property]
        if (data == null || data == "") {
            return null
        }

        let dt = new Date(data)

        let format = column.type

        if (format == "default") {
            format = "yyyy-MM-dd"
        }

        return dt.format(format)
    }
    //转换日期格式
    page.formatDateSS = function (row, column, cellValue, index) {
        let data = row[column.property]
        if (data == null || data == "") {
            return null
        }

        let dt = new Date(data)

        let format = website.dateFormat.dateSS

        return dt.format(format)
    }

    //转换日期格式
    page.formatDateDD = function (row, column, cellValue, index) {
        let data = row[column.property]
        if (data == null || data == "") {
            return null
        }

        let dt = new Date(data)

        let format = website.dateFormat.dateDD

        return dt.format(format)
    }


    /**
     * 开始设置entity的属性=‘’
     * @param formId
     */
    page.autoEntity = function (formId) {

        let form = page.context.getRef(formId)

        if (form) {
            page.getNodeForEntity(form)
            page.data.entity = tg.copy(page.data.entity)
        } else {
            nextTick(function () {
                let form = proxy.$refs[formId]
                if (form == null) {
                    return
                }
                page.getNodeForEntity(form)
                page.data.entity = tg.copy(page.data.entity)
            })
        }
    }

    //循环
    page.getNodeForEntity = function (child) {
        // if (child) {
        //     if (child.__vnode.data.model) {
        //         let expression = child.__vnode.data.model.expression
        //         if (expression.indexOf("entity.") >= 0) {
        //             let field = expression.replace("entity.", "")
        //
        //             if (!page.data.entity[field]) {
        //                 page.data.entity[field] = ""
        //             }
        //         }
        //     }
        //     if (child.$children.length > 0) {
        //         child.$children.forEach(function (obj) {
        //             page.getNodeForEntity(obj)
        //         })
        //     }
        // }

    }


    //关闭
    page.close = function (state) {

        if (page.data.pageInfo.type == "EditTab") {
            return page.closeEditTab(state);
        }

        page.data.pageInfo.showed = false
        // this.defaultArea && this.defaultArea.clearValidate()

        let contextPage = page.context.getContextPage()
        let parentIsEditBox = contextPage && contextPage.isBasePage && contextPage.data.pageInfo.type == "EditBox";
        let parentIsEditTab = contextPage && contextPage.isBasePage && contextPage.data.pageInfo.type == "EditTab";
        let parentIsQueryBox = contextPage && contextPage.isBasePage && contextPage.data.pageInfo.type == "QueryBox";

        let parentIsContainer = parentIsEditBox || parentIsEditTab || parentIsQueryBox

        let parentIsDialog = contextPage && contextPage.isBasePage && contextPage.data.isDialog

        if (page.data.isDialog) {
            page.data.visible = false

            if (
                contextPage &&
                contextPage.data.pageInfo &&
                contextPage.data.pageInfo.type == "QueryPage"
            ) {
                if (page.data.dataChanged) {
                    contextPage.onDataChanged()
                }
            }
        } else {

            if (parentIsDialog) {
                contextPage.data.visible = false
            }

            contextPage.endEdit && contextPage.endEdit(state)

            if (page.data.dataChanged) {
                if (parentIsContainer) {
                    contextPage.context.parent.onDataChanged()
                } else {
                    contextPage.onDataChanged()
                }
            }
            page.pageClear()
        }

        //关闭弹框
        let closeNode = page
        while (closeNode.context.parent != null && closeNode.pagePopType.popType != 'dialog') {
            closeNode = closeNode.context.parent
        }
        if (closeNode.dialog && closeNode.pagePopType.popType == 'dialog') {
            const visible = closeNode.dialog.getVisibile()
            if (visible) {
                closeNode.dialog?.close()
            }
        }

        if (page.closed) {
            page.closed(state)
        }
    }

    page.closeEditTab = function (state) {
        page.data.visible = false;
        page.data.row = null;
        page.data.isAdd = true;

        if (page.data.dataChanged) {
            page.context.rootPage.onDataChanged()
        }

        page.data.pageInfo.showed = false

        if (!page.data.isDialog) {
            page.endEdit && page.endEdit(state)

            if (page.data.dataChanged) {
                page.onDataChanged()
            }

            page.pageClear()
        }

        if (page.closed) {
            page.closed(state)
        }
    }

    page.clear = function () {
        let form = page.editPage
        page.getNodeForEntity(form)
        page.data.entity = tg.copy(page.data.entity)
    }

    //全局注册导入页面
    const tgImportDialog = inject('tg-import', {})
    //调用导入组件的方法
    page.tgImport = function (options) {
        if (tgImportDialog) {
            const tgImportinstance = tgImportDialog({})
            if (tgImportinstance && tgImportinstance.exposed) {
                let tempUploadData = {...options}
                tgImportinstance.exposed.show(tempUploadData)
            }
        }
    }


    return page
}
