<!--
 * @Author: eci jerome.luo
 * @Date: 2022-12-13 17:19:41
 * @LastEditors: eci jerome.luo@ecidh.com
 * @LastEditTime: 2023-03-27 16:34:46
 * @Description: 日期，时间段
-->
<template>
  <div ref="TgDatePickerWapRef" class="TgDatePickerWap">
    <el-date-picker
      :type="type"
      v-bind="$attrs"
      :range-separator="rangeSeparator"
      :shortcuts="$attrs.shortcuts || (defaultShortcuts ? shortcuts : [])"
      :format="$attrs.format || defaultFormat()"
      :value-format="$attrs['value-format'] || defaultFormat()" />
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance, onMounted } from "vue";
import { nanoid } from "nanoid";
import $ from "jquery";
import { focusNext } from "@core/utils/TgTools";
import { SettingCore } from "@core/setting";
import { t, tm } from "@i18nHelper";

const { proxy } = getCurrentInstance();
const TgDatePickerWapRef = ref();
const props = defineProps({
  type: {
    // 日期控件类型， 默认date. [date,daterange 日期选择，datetime 日期时间选择，datetimerange 日期段选择，]
    type: String,
    default: "date",
  },
  rangeSeparator: {
    type: String,
    default: () => {
      return t("tg.page.common.to"); //至
    },
  },
  defaultShortcuts: {
    type: Boolean, //false-不适用默认的快捷选项，true-在用户没有自定义快捷选项的时候使用默认的快捷选项
    default: false,
  },
});

const shortcuts = computed(() => {
  // 日期快捷选择
  let cuts = null;
  if (proxy.type === "date" || proxy.type === "datetime") {
    cuts = [
      { text: tm("tg.page.common.today", "今天"), value: new Date() },
      {
        text: tm("tg.page.common.yesterday", "昨天"),
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: tm("tg.page.common.aWeekAgo", "一周前"),
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];
  } else if (proxy.type === "year") {
    cuts = [
      {
        text: tm("tg.page.common.lastYear", "去年"),
        value: () => {
          const now = new Date();
          return new Date(now.getFullYear() - 1, 0, 1);
        },
      },
      {
        text: tm("tg.page.common.thisYear", "今年"),
        value: () => {
          const now = new Date();
          return new Date(now.getFullYear(), 0, 1);
        },
      },
      {
        text: tm("tg.page.common.nextYear", "明年"),
        value: () => {
          const now = new Date();
          return new Date(now.getFullYear() + 1, 0, 1);
        },
      },
    ];
  } else if (proxy.type === "month") {
    cuts = [
      {
        text: tm("tg.page.common.lastMonth", "上月"),
        value: () => {
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth(), 0, 1);
        },
      },
      {
        text: tm("tg.page.common.thisMonth", "本月"),
        value: () => {
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth() + 1, 0, 1);
        },
      },
      {
        text: tm("tg.page.common.sixMonthAge", "半年前"),
        value: () => {
          const now = new Date();
          return new Date(now.getFullYear(), now.getMonth() - 5, 0, 1);
        },
      },
    ];
  } else if (proxy.type === "week") {
    cuts = [
      {
        text: tm("tg.page.common.lastWeek", "最近一周"),
        value: () => {
          const now = new Date();
          return now.setTime(now.getTime() - 3600 * 1000 * 24 * 7);
        },
      },
      {
        text: tm("tg.page.common.thisWeek", "本周"),
        value: () => {
          const currentDate = ref(new Date());
          const startOfWeek = new Date(
            currentDate.value.getFullYear(),
            currentDate.value.getMonth(),
            currentDate.value.getDate() - currentDate.value.getDay()
          ); // 获取本周的第一天
          return startOfWeek;
        },
      },
    ];
  } else if (proxy.type === "monthrange") {
    cuts = [
      {
        text: tm("tg.page.common.lastMonth", "上月"),
        value: () => {
          const now = new Date();
          const startDate = new Date(now.getFullYear(), now.getMonth(), 0, 1);
          const endDate = new Date(now.getFullYear(), now.getMonth(), 0, 1);
          return [startDate, endDate];
        },
      },
      {
        text: tm("tg.page.common.thisMonth", "本月"),
        value: () => {
          const now = new Date();
          const startDate = new Date(
            now.getFullYear(),
            now.getMonth() + 1,
            0,
            1
          );
          const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 1);
          return [startDate, endDate];
        },
      },
      {
        text: tm("tg.page.common.sixMonthAge", "半年前"),
        value: () => {
          const now = new Date();
          const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 1);
          const startDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth() - 5,
            0,
            1
          );
          return [startDate, endDate];
        },
      },
    ];
  } else {
    cuts = [
      {
        text: tm("tg.page.common.thisWeek", "本周"),
        value: () => {
          const currentDate = ref(new Date());
          const startOfWeek = new Date(
            currentDate.value.getFullYear(),
            currentDate.value.getMonth(),
            currentDate.value.getDate() - currentDate.value.getDay()
          ); // 获取本周的第一天
          const endOfWeek = new Date(startOfWeek); // 创建一个副本以避免修改原始值
          endOfWeek.setDate(endOfWeek.getDate() + 6); // 将其设置为本周的最后一天
          endOfWeek.setTime(endOfWeek.getTime() + 3600 * 1000 * 24 - 1000);
          return [startOfWeek, endOfWeek];
        },
      },
      {
        text: tm("tg.page.common.thisMonth", "本月"),
        value: () => {
          const now = new Date();
          const monthStart = new Date(now.getFullYear(), now.getMonth(), 1);
          const monthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0); // 获取月底日期
          monthEnd.setTime(monthEnd.getTime() + 3600 * 1000 * 24 - 1000);
          return [monthStart, monthEnd];
        },
      },
      {
        text: tm("tg.page.common.thisYear", "今年"),
        value: () => {
          const now = new Date();
          const startDate = new Date(now.getFullYear(), 0, 1);
          const endDate = new Date(now.getFullYear(), 11, 31);
          return [
            startDate,
            endDate.setTime(endDate.getTime() + 3600 * 1000 * 24 - 1000),
          ];
        },
      },
    ];
  }
  return cuts || [];
});

// 时间控件：默认格式
const defaultFormat = () => {
  if (props.type === "date" || props.type === "daterange") {
    return "YYYY-MM-DD";
  } else if (
    props.type === "dateTime" ||
    props.type === "datetime" ||
    props.type === "datetimerange"
  ) {
    return "YYYY-MM-DD HH:mm:ss";
  }
};

const enterEvent = ($event) => {
  if ($event.keyCode === 13) {
    // 此处延时：解决一次 shift+enter 会执行两次
    if (SettingCore.VITE_TG_ENTER === "1") {
      setTimeout(() => {
        focusNext($event);
      }, 100);
    }
  }
};

onMounted(() => {
  // debugger;
  const a = $(TgDatePickerWapRef);
  // console.log(a)
  let child = a[0].value.children[0].children;
  let len = child.length;
  let input1;
  let input2;
  if (len > 3) {
    input1 = $(child[1]);
    input2 = $(child[3]);
    input2.attr("focustypes", nanoid());
    input2.keydown(enterEvent);
  } else {
    input1 = $(child[0].children[1]);
  }

  input1.attr("focustypes", nanoid());
  input1.keydown(enterEvent);
});
</script>

<style lang="less" scoped>
.TgDatePickerWap {
  width: 100%;
}
</style>
