export default {
	"cbeOrderItem":"跨境统一版出口订单表体（接口表）",
	"cbeOrderItem_kj5PartionKey":"kj5PartionKey",
	"cbeOrderItem_platNo":"平台编号",
	"cbeOrderItem_autoId":"表体主键",
	"cbeOrderItem_messageId":"messageId",
	"cbeOrderItem_gNum":"商品序号",
	"cbeOrderItem_itemNo":"企业商品货号",
	"cbeOrderItem_itemName":"企业商品名称",
	"cbeOrderItem_itemDescribe":"企业商品描述",
	"cbeOrderItem_barCode":"条形码",
	"cbeOrderItem_unit":"计量单位",
	"cbeOrderItem_currency":"币制",
	"cbeOrderItem_qty":"数量",
	"cbeOrderItem_price":"单价",
	"cbeOrderItem_totalPrice":"总价",
	"cbeOrderItem_note":"备注"
}