<!--
 * @Author: tg xiaohui.chen
 * @Date: 2023-04-19 16:00:00
 * @LastEditors: tg xiaohui.chen
 * @LastEditTime: 2023-04-19 16:00:00
 * @Description: tab 组件
    使用样例
    <tg-tabs :tabsSource="[{label:'表头',name:'first',disabled:false},{label:'表体',name:'second',disabled:true}]" activeTabs="second" @handleClick="handleClick">
      <template #first>
        first
      </template>
      <template #second>
        second
      </template>
      <el-tab-pane label="清单表头" name="head">
        清单表头
      </el-tab-pane>
      <el-tab-pane label="清单表体" name="body">
        清单表体
      </el-tab-pane>
    </tg-tabs>
-->
<template>
  <el-tabs type="border-card" v-model="state.activeName" v-bind="$attrs" @tab-click="handleClick" :before-leave="beforeLeave">
    <el-tab-pane
        v-for="(item, index) in tabsSource"
        :label="item.label"
        :name="item.name"
        :disabled="item.disabled"
    >
      <div class="tg-detail-part">
        <slot :name="item.name" :activeName="state.activeName" />
      </div>
    </el-tab-pane>
    <slot ></slot>
  </el-tabs>
</template>

<script setup>
import { reactive,watch,getCurrentInstance } from 'vue'
const $emit = defineEmits(['handleClick','beforeLeave'])
const { proxy } = getCurrentInstance()
const props=defineProps({
  tabsSource: Array, // tab数据
  tabsData:[
    {
      name:'first',
      label:'第一个',
      disabled:false
    },
    {
      name:'second',
      label:'第二个',
      disabled:true
    },
  ],
  activeTabs:''
})

const state = reactive({
  // tab-pan name绑定
  activeName: '',
})

const handleClick = (tab) => {
  $emit('handleClick', tab.props)
}

const beforeLeave=(activeName, oldActiveName)=>{
  $emit('beforeLeave', activeName, oldActiveName)
}

watch(() => props.activeTabs, (newValue, oldValue) => {
  if(newValue!=''){
    state.activeName=newValue
  }
},{
  immediate: true,
  deep: true
})
</script>

<style lang="less"  scoped>
.tg-detail-part{
  height: calc(100vh - 110px);
  overflow-y: auto;
  margin-left: -20px;
  margin-right: -15px;
  margin-bottom: -15px;
  padding-left: 20px;
  padding-right: 16px;
}
</style>
