<template>
  <tg-dialog ref="tgdialog" :visible.sync="dialogVisible" :title="title">
    <vue-office-docx v-if="isDoc"
                     :src="srcDoc"
                     style="height: 100vh;"
                     @rendered="renderedHandler"
                     @error="errorHandler"
    />
    <vue-office-excel v-if="isXls"
                      :src="srcXls"
                      :options="options"
                      style="height: 100vh;"
                      @rendered="renderedHandler"
                      @error="errorHandler"
    />
    <vue-office-pdf v-if="isPdf"
                    :src="srcPdf"
                    @rendered="renderedHandler"
                    @error="errorHandler"
    />
  </tg-dialog>

</template>

<script setup name="DocView">
import apis from "@core/apis";
import {TgHttp} from "@coreHttp";
import {getCurrentInstance, ref} from "vue";
import {tm} from '@i18nHelper'
import {fileTypeFromFile} from 'type-teller'
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'
//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'
//引入VueOfficePdf组件
import VueOfficePdf from '@vue-office/pdf'

const {proxy} = getCurrentInstance()


let title = tm('tg.page.title.previewDoc', '预览文档')
let srcDoc = ref("")
let srcXls = ref("")
let srcPdf = ref("")

let dialogVisible = false
let isDoc = ref(false)
let isXls = ref(false)
let isPdf = ref(false)

let options = ref({
  xls: false,       //预览xlsx文件设为false；预览xls文件设为true
  minColLength: 0,  // excel最少渲染多少列，如果想实现xlsx文件内容有几列，就渲染几列，可以将此值设置为0.
  minRowLength: 0,  // excel最少渲染多少行，如果想实现根据xlsx实际函数渲染，可以将此值设置为0.
  widthOffset: 0,  //如果渲染出来的结果感觉单元格宽度不够，可以在默认渲染的列表宽度上再加 Npx宽
  heightOffset: 0, //在默认渲染的列表高度上再加 Npx高
  // beforeTransformData: (workbookData) => {
  //   return workbookData
  // }, //底层通过exceljs获取excel文件内容，通过该钩子函数，可以对获取的excel文件内容进行修改，比如某个单元格的数据显示不正确，可以在此自行修改每个单元格的value值。
  // transformData: (workbookData) => {
  //   return workbookData
  // }, //将获取到的excel数据进行处理之后且渲染到页面之前，可通过transformData对即将渲染的数据及样式进行修改，此时每个单元格的text值就是即将渲染到页面上的内容
})
defineExpose({show})

async function show(file) {
  isDoc.value = false
  srcDoc.value = ""
  isXls.value = false
  srcXls.value = ""
  isPdf.value = false
  srcPdf.value = ""
  title = tm('tg.page.title.preview+', '预览:{0}', [file.name])
  dialogVisible = true
  proxy.$refs.tgdialog.show()

  await initData(file.id)
}

async function initData(id) {
  if (id == null || id == undefined) {
    return tg.msg.error(tm('tg.page.msg.M00319', '获取文件信息失败：传参为空'))
  }
  let request = new TgHttp();
  request.entity.guid = id

  const response = await request.build(apis.fileInfo.getFileInfo).post()
  const type = fileTypeFromFile(response.data.fileName)
  let typeHeader = 'data:' + type.mime + ';base64,' // 定义base64 头部文件类型

  let converedBase64 = typeHeader + response.data.fileData;  // 拼接最终的base64
  if (type.mime.includes('docx')) {
    isDoc.value = true
    srcDoc.value = converedBase64
  }
  if (type.mime.includes('excel') || type.mime.includes("sheet")) {
    isXls.value = true
    srcXls.value = converedBase64
  }
  if (type.mime.includes('pdf')) {
    isPdf.value = true
    srcPdf.value = converedBase64
  }
}


function renderedHandler() {
  console.log("渲染完成")
}

function errorHandler() {
  console.log("渲染失败")
}
</script>
