export default {
	"cbiDelivery":"跨境统一版进口入库明细单表头(操作表)",
	"cbiDeliveryItem":"CbiDeliveryItem",
	"cbiDelivery_kj5PartionKey":"kj5PartionKey",
	"cbiDelivery_platNo":"平台编号",
	"cbiDelivery_syMark":"状态",
	"cbiDelivery_inputDate":"创建日期",
	"cbiDelivery_copCode":"传输企业代码",
	"cbiDelivery_guid":"企业系统生成36位唯一序号（英文字母大写）",
	"cbiDelivery_appType":"企业报送类型",
	"cbiDelivery_appTime":"企业报送时间",
	"cbiDelivery_appStatus":"业务状态",
	"cbiDelivery_customsCode":"关区代码",
	"cbiDelivery_copNo":"企业内部标识单证的编号",
	"cbiDelivery_preNo":"电子口岸标识单证的编号",
	"cbiDelivery_rkdNo":"入库单编号",
	"cbiDelivery_operatorCode":"监管场所经营人编号",
	"cbiDelivery_operatorName":"监管场所经营人名称",
	"cbiDelivery_iEFlag":"I进口/E出口",
	"cbiDelivery_trafMode":"运输方式",
	"cbiDelivery_trafNo":"运输工具",
	"cbiDelivery_voyageNo":"航次编号",
	"cbiDelivery_billNo":"提运单号",
	"cbiDelivery_logisticsCode":"物流企业编号",
	"cbiDelivery_logisticsName":"物流企业名称",
	"cbiDelivery_unloadLocation":"货物卸货位置",
	"cbiDelivery_note":"备注",
	"cbiDelivery_messageId":"消息ID"
}
