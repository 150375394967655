import type {LocaleType} from './localeType'
// import axios from 'axios'

import {set} from 'lodash-es'
// import { data } from './lang/langUI.json'

export const loadLocalePool: string[] = []

export function setHtmlPageLang(locale) {
    document.querySelector('html')?.setAttribute('lang', locale)
}

export function setLoadLocalePool(cb: (loadLocalePool: string[]) => void) {
    cb(loadLocalePool)
}

