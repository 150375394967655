<template>
  <el-input
      ref="myInput"
      :maxLength="maxLength"
      :model-value="state.myValue"
      v-bind="$attrs"
      clearable
      @focus="handleFocus"
      @keyup="proving"
      @input="srInput"
      @blur="srBlur"
      :disabled="disabled"
      autocomplete="on">
    <template #prefix v-if="$slots.prefix">
      <slot name="prefix"></slot>
    </template>
    <template #suffix v-if="$slots.suffix">
      <slot name="suffix"></slot>
    </template>
    <template #append v-if="$slots.append">
      <slot name="append"></slot>
    </template>
    <template #prepend v-if="$slots.prepend">
      <slot name="prepend"></slot>
    </template>
  </el-input>
</template>

<script setup>
import {getCurrentInstance, reactive, ref, watch} from "vue";

const $emit = defineEmits(["update:modelValue"]);
const {proxy} = getCurrentInstance();

const props = defineProps({
  isNumber: {
    // 是否数字框
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
  // 是否禁用
  disabled: {
    type: Boolean,
    default: false,
  },
  // 最大保留小数位数
  fixMax: {
    type: Number,
    default: 0,
  },
  //输入最大长度
  maxLength: {
    type: Number,
    default: 20,
  },
  //获取焦点，自动全选
  focusSelect: {
    type: Boolean,
    default: false,
  },
});
const state = reactive({
  myValue: null, // 输入值
});

const srInput = (val) => {
  // 输入框值变化时
  state.myValue = val;
  $emit("update:modelValue", val);
};
const srBlur = () => {
  //文本框鼠标失去焦点，去空格
  !props.isNumber && state.myValue && (state.myValue = state.myValue.toString().trim())
}
const proving = () => {
  if (props.isNumber) {
    if (state.myValue == null) {
      return;
    }

    let reg = new RegExp("^\\d*(\\.?\\d{0," + props.fixMax + "})", "g");
    // //需要输入负数
    // if (props.sign) {
    //     reg = new RegExp("^-?\\d*(\\.?\\d{0," + props.fixMax + "})", "g")
    // }

    state.myValue = ("" + state.myValue).match(reg)[0] || null;

    if (state.myValue == null) {
      state.myValue = "";
    }

    //可以输入的整数位数,小数位数
    let isDecimal = false;
    if (state.myValue.toString().indexOf(".") > 0) {
      isDecimal = true;
    }

    let zsLength = props.maxLength - props.fixMax;
    let zsValue = state.myValue;
    let xsValue = "";

    //如果存在负号，整数的长度需要+1
    if (state.myValue.toString().indexOf("-1") >= 0) {
      zsLength = props.maxLength + 1 - props.fixMax;
    }

    if (isDecimal) {
      zsValue = state.myValue
          .toString()
          .substring(0, state.myValue.toString().indexOf("."));
      xsValue = state.myValue
          .toString()
          .substring(state.myValue.toString().indexOf(".") + 1);
    }

    if (zsValue.length > zsLength) {
      zsValue = zsValue.toString().substring(0, zsLength);
    } else if (xsValue.length > props.fixMax) {
      xsValue = xsValue.substr(0, props.fixMax);
    }

    if (isDecimal) {
      state.myValue = zsValue + "." + xsValue;
    } else {
      state.myValue = zsValue;
    }
  }

  $emit("update:modelValue", state.myValue);
};

let myInput = ref(null);
const handleFocus = () => {
  if (props.focusSelect == true) {
    myInput.value.select();
  }
};

watch(
    () => props.modelValue,
    (val) => {
      state.myValue = val;
    },
    {
      immediate: true,
    }
);
</script>

<style lang="less" scoped></style>
