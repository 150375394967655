import * as extension from '@/web/extension'

/**
 *  @description: 主题设置
 *  @param { Object } data.sendData - 主题设置数据
 */
const themeSet = async (data)=> {
  if(extension.default?.theme?.default?.themeSet) {
    return new Promise(async(resolve)=>{
      let rtn = await extension.default?.theme?.default?.themeSet(data);
      return resolve(rtn);
    })
  }
  return new Promise( (resolve, reject) =>{
    resolve({
      flag: false,
      core: false,
      data: {}
    })
  })
}

export {
  themeSet
}
