<!--
  开发助手 出现方式：shift+l
-->
<template>
  <tg-dialog
    ref="TgDialogRef"
    class="TgFullDialog tgDevAssistant"
    v-model="visible"
    title="开发助手"
    @close="close"
  >
    <div class="tgOptionWap">
      <el-space :size="10">
        <el-button
          type="primary"
          @click="getBefore"
          :disabled="state.current < 1"
          ><CaretLeft class="buttonIcon" />上一个</el-button
        >
        <el-select v-model="state.current" class="selectOne">
          <el-option
            v-for="(item, index) in requestList"
            :key="index"
            :label="`${index + 1}/${requestList.length}  ${item.config.url}`"
            :value="index"
          />
        </el-select>
        <el-button
          type="primary"
          @click="getAfter"
          :disabled="state.current >= requestList.length - 1"
          style="margin-right: 20px"
          >下一个<CaretRight class="buttonIcon"
        /></el-button>
        <el-button
            v-if="requestList.length > 0"
            :type="getResponseTime().flag ? 'success':'warning'"
        >总耗时：{{getResponseTime().time}}毫秒</el-button>

        <el-badge
          :value="requestList.length"
          class="item"
          style="margin-right: 20px"
        >
          <el-button
            @click="showError('all')"
            :disabled="requestList.length === 0"
            >总请求数</el-button
          >
        </el-badge>
        <el-badge
          :value="errorList.length"
          class="item"
          style="margin-right: 20px"
        >
          <el-button
            @click="showError('error')"
            :disabled="errorList.length === 0"
            >错误请求数</el-button
          >
        </el-badge>
        <el-button
          type="primary"
          v-if="getCurrent() && getCurrent().config"
          @click="getAnalyze(getCurrent().config.data)"
          >分析请求内容</el-button
        >
        <el-button
          type="primary"
          v-if="getCurrent()"
          @click="getAnalyze(JSON.stringify(getCurrent().data))"
          :disabled="!getCurrent().data"
          >分析响应内容</el-button
        >
        <!--        <el-button type="success" @click="getSQL()">查看SQL语句</el-button>-->
        <el-button type="warning" @click="close()">关闭并清空</el-button>
      </el-space>
    </div>
    <div v-if="getCurrent() && getCurrent().config">
      <el-card class="DevAssistantMt">
        请求API:
        <div
          class="DevAssistantMt"
          @click="
            getAnalyze(
              getCurrent().config.url,
              false
            )
          "
        >
          <highlightjs
            autodetect
            :code="getCurrent().config.url"
          />
        </div>
      </el-card>

      <!--  执行语句 -->
      <el-card
        class="DevAssistantMt"
        v-if="state.sqlList.length > 0 || state.sqlMsg"
      >
        <div>执行语句：</div>
        <template v-if="state.sqlList.length > 0">
          <div
            class="DevAssistantMt"
            v-for="(item, index) in state.sqlList"
            @click="getAnalyze(item, false)"
            :key="`sqlList${index}}}`"
          >
            <highlightjs autodetect :code="item" />
          </div>
        </template>
        <div v-if="state.sqlMsg">
          <el-alert
            :title="state.sqlMsg"
            type="warning"
            effect="dark"
            class="DevAssistantMt"
            :closable="false"
          />
        </div>
      </el-card>
      <!-- 请求数据 -->
      <el-card
        class="DevAssistantMt"
        v-if="getCurrent().config && getCurrent().config.data"
      >
        <div>
          请求数据：
          <el-button
            type="success"
            plain
            @click="getAnalyze(getCurrent().config.data)"
            >COPY</el-button
          >
          <!--          <el-button type="warning" plain>JSON</el-button>-->
        </div>
        <div class="DevAssistantMt">
          <pre @click="getAnalyze(getCurrent().config.data, false)">{{
            JSON.parse(getCurrent().config.data)
          }}</pre>
          <!--          <highlightjs autodetect :code="getCurrent().config.data" />-->
        </div>
      </el-card>
      <!-- 响应数据 -->
      <el-card class="DevAssistantMt">
        <div>
          响应数据：
          <el-button
            type="success"
            plain
            @click="getAnalyze(JSON.stringify(getCurrent().data))"
            :disabled="!getCurrent().data"
            >COPY</el-button
          >
          <!--          <el-button type="warning" plain>JSON</el-button>-->
        </div>
        <div class="DevAssistantMt">
          <pre @click="getAnalyze(JSON.stringify(getCurrent().data), false)">{{
            getCurrent().data || requestList[state.current]
          }}</pre>
          <!--  <highlightjs autodetect :code="JSON.stringify(getCurrent().data) || JSON.stringify(requestList[state.current])" /> -->
        </div>
      </el-card>
    </div>
  </tg-dialog>
  <all-request-dialog ref="allRequestDialogRef" @update="updateCurrent" />
</template>
<script setup lang="ts">
import { reactive, toRefs, onMounted, computed, watch, ref } from "vue";
import useAssistantHooks from "@appTiangong/DevPlatform/assistant/useAssistantHooks.js";
import clipboard3 from "vue-clipboard3";
const { toClipboard } = clipboard3();
import TgRequest from "@coreRequest";
import allRequestDialog from "./allRequestDialog.vue";
import keymaster from "keymaster";
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";

const state = reactive({
  visible: false,
  current: -1,
  allUrl: "",
  url: "https://www.json.cn/json/jsononline.html",
  sqlList: [],
  sqlMsg: "",
});

const { requestList, clearRequest, getCurrent, getSqlUrl,getResponseTime } =
  useAssistantHooks(state);

const TgDialogRef = ref();

const { visible } = toRefs(state);
const allRequestDialogRef = ref();
// 请求错误的列表
const errorList = computed(() => {
  return requestList.value.filter((item) => {
    return !(
      item.data &&
      item.data.code &&
      (item.data.code + "").substring(0, 1) === "1"
    );
  });
});

// 初始化时，获取第一个元素
// const getCurrentIndex = ()=>{
//  return  requestList.value.length>0 ? 0: -1
// }

const show = () => {
  state.visible = true;
  TgDialogRef.value?.show();

  // 获取第一个数据
  // state.current = getCurrentIndex()
  state.current = requestList.value.length > 0 ? 0 : "";
};

// 上一个
const getBefore = () => {
  state.current--;
};

// 下一个
const getAfter = () => {
  state.current++;
};

const updateCurrent = (index) => {
  state.current = index;
};

/*
 * 分析数据
 *   string 1 跳转页面
 * */
const getAnalyze = async (str: string, type: boolean = true) => {
  const rtn = await toClipboard(str);
  type && rtn.text && window.open(state.url);
  tg.msg.success("复制成功!")
};

// 查看 SQL
const getSQL = () => {
  let data = getCurrent();

  try {
    const request = new TgHttp();
    request.entity = {
      traceId: getCurrent().data.traceId,
    }
    request
        .build(getSqlUrl())
        .post()
        .then((res) => {
          state.sqlList = [];
          state.sqlMsg = "";
          if (res.code === 10001) {
            state.sqlList = res.data.sqlList;
          } else {
            state.sqlMsg = res.msg;
          }
        });
  } catch (e) {
    state.sqlList = [];
    console.log("~~~开发助手~~~", e);
  }
};

// 关闭并清空
const close = () => {
  state.current = "";
  state.visible = false;
  TgDialogRef.value.close();
  clearRequest();
};

const showError = (type) => {
  allRequestDialogRef.value.show({ list: requestList.value, type });
};

watch(
  () => state.current,
  (val) => {
    ((val && val > -1) || val + "" === "0") && getSQL();
  },
  {
    immediate: true,
  }
);

onMounted(() => {
  // 关闭开发助手快捷键
  document.onkeydown = function (e) {
    e.code === "Escape" && state.visible && close();
  };

  keymaster("shift+l", () => {
    !state.visible && show();
  });
});
</script>

<style lang="less" scoped>
.tgDevAssistant {
  .tgOptionWap {
    display: flex;

    // 图标
    .buttonIcon {
      width: 16px;
      height: 16px;
    }
    .selectOne {
      width: 360px;
    }
  }

  .DevAssistantMt {
    margin-top: 10px;
  }
}
// 代码高亮展示换行
:deep(pre code.hljs) {
  white-space: pre-wrap;
}
</style>
