<template>
  <!-- 筛选条件： 默认版本 -->
    <el-card class="tg_filterWap_card" v-bind="$attrs">
        <div class="tg_filterWap">
      <span class="tg_title">{{
          t("tg.page.components.tgFilter.filterSettings")
          }}</span>
            <div class="tg_moreSetting">
                <!-- todo 此处动画待完善-->
                <template v-if="filterNum()">
          <span
                  class="tg_filterMore"
                  @click="handleViewMore"
                  v-if="state.isOpen">
            {{
              t("tg.page.components.tgFilter.collapseMore")
              }}<el-icon style="transform: rotate(-90deg)"
          ><DArrowRight
          /></el-icon>
          </span>
                    <span
                            class="tg_filterMore"
                            @click="handleViewMore"
                            v-if="!state.isOpen">
            {{
                        t("tg.page.components.tgFilter.showMore")
                        }}<el-icon style="transform: rotate(90deg)"
                    ><DArrowRight
                    /></el-icon>
          </span>
                </template>

                <span
                        class="tg_filterMore tg_filterSetting"
                        v-if="SettingCore.VITE_TG_FILTER_SETTING === '1'"
                        @click="handleFilterSet"
                >{{
                    t("tg.page.components.tgFilter.filterSettings")
                    }}<el-icon><DArrowRight/></el-icon
                    ></span>
            </div>
        </div>
        <!-- todo 此处动画待完善-->
        <!--    <div class="tg_filterCont" :style="{height: !state.isOpen || data.length<6 ? '': '90px'}">-->
        <div class="tg_filterCont" :style="{ height: formHeightComputed() }">
            <el-form
                    ref="formRef"
                    :rules="rules"
                    :model="form"
                    :label-width="
          env.VITE_TG_FILTER_LABEL_WIDTH
            ? env.VITE_TG_FILTER_LABEL_WIDTH
            : '150px'
        "
                    label-position="left">
                <el-row :gutter="16">
                    <template v-for="(item, key) in data" :key="`tgFilter${key}`">
                        <el-col :span="item.span" v-if="!!item.show">
                            <el-form-item
                                    :label="item.labelName || $t(item.label)"
                                    v-if="item.type"
                                    :prop="item.columnCode">
                                <slot :name="item.columnCode">
                                    <!--  输入框 -->
                                    <tg-input
                                            v-if="item.type === 'text'"
                                            v-model="form[item.columnCode]"
                                            :placeholder="item.placeholder || ''"/>
                                    <!--  textarea  筛选框中不建议放此控件，高度不好控制 -->
                                    <!--  <tg-input v-if="item.type === 'textarea'" type="textarea" v-model="form[item.columnCode]" />-->
                                    <!--  放大镜 -->
                                    <tg-select
                                            v-if="item.type === 'select'"
                                            v-model="formTemp[item.columnCode]"
                                            :placeholder="
                      item.placeholder || t('tg.page.common.chooseHint')
                    "
                                            :data-type="item.dataType">
                                    </tg-select>

                                    <!--  时间控件 -->
                                    <tg-date-picker
                                            v-if="item.type === 'date'"
                                            v-model="formTemp[item.columnCode]"
                                            type="daterange"
                                            format="YYYY-MM-DD"
                                            value-format="YYYY-MM-DD"
                                            @change="dateChange($event, item.columnCode)"
                                            :start-placeholder="item['start-placeholder']"
                                            :end-placeholder="item['end-placeholder']"/>
                                    <!--  时间控件 -->
                                    <tg-date-picker
                                            v-if="item.type === 'dateTime' || item.type === 'datetime'"
                                            v-model="formTemp[item.columnCode]"
                                            type="datetimerange"
                                            format="YYYY-MM-DD HH:mm:ss"
                                            value-format="YYYY-MM-DD HH:mm:ss"
                                            @change="dateChange($event, item.columnCode)"
                                            :start-placeholder="item['start-placeholder']"
                                            :end-placeholder="item['end-placeholder']"/>
                                </slot>
                            </el-form-item>
                            <!--            <slot name="seqNo" />-->
                        </el-col>
                    </template>
                </el-row>
            </el-form>
            <!--  <slot  />  -->
        </div>
        <div class="tg_btnWap">
      <span>
        <tg-button @click="handleSearch" icon="Search" name="查询"/>
        <tg-button @click="handleReset" icon="Brush" plain name="重置"/>
          <!-- 按钮插槽 -->
        <slot name="tg_button"/>
      </span>
            <!--   校验：提示信息，有可能输入框没有显示在可视区   -->
            <span class="tg_validate_message">
        <slot name="tg_validate" v-if="!state.validateFlag">
          <el-alert
                  :title="validateMessage"
                  type="error"
                  :closable="false"
                  v-if="!state.validateFlag"/>
        </slot>
      </span>
        </div>
    </el-card>

  <!-- 设置 -->
    <filter-config
            ref="FilterConfigRef"
            :data="data"
            :version="$attrs.version || ''"
            @updateConfig="$emit('updateConfig')"/>
</template>
<script setup>
import {reactive, toRefs, ref, nextTick, watch} from "vue";
import FilterConfig from "./FilterConfig.vue";

const $emit = defineEmits(["search", "updateConfig"]);
const env = import.meta.env;
import {SettingCore} from "@core/setting";
import {t} from "@i18nHelper";

const props = defineProps({
    data: {
        type: Array,
        default: () => [],
    },
    /*
     * 筛选条件版本：
     *   normal 默认版本
     *   simple 简单版本  用于表头表体
     * */
    // edition: {
    //   type: String,
    //   default: ()=> 'normal'
    // }
    // 校验规则
    rules: {
        type: Object,
        default: () => {
        },
    },
    // 校验不通过的提示信息
    validateMessage: {
        type: String,
        default: () => t("tg.page.common.inputHint"),
    },
});
const FilterConfigRef = ref();
const state = reactive({
    isOpen: false, // false 展示部分  true 显示全部
    form: {},
    formTemp: {}, // 临时变量
    span: 8,
    validateFlag: true, // 校验  true 校验通过  false 校验不通过
});

const {form, span, formTemp} = toRefs(state);
const formRef = ref();

// 查看全部
const handleViewMore = () => {
    state.isOpen = !state.isOpen;
};
// 筛选配置
const handleFilterSet = () => {
    FilterConfigRef.value.show();
};

// 筛选项显示的个数
const filterNum = () => {
    // let num =
    let arr = props.data.filter((item) => {
        return item.show;
    });
    return arr.length > 6;
};
// 计算高度：查询条件
const formHeightComputed = () => {
    let str = "";
    if (filterNum() && !state.isOpen) {
        str = "72px";
        // str = "90px";
    }
    return str;
};

// 查询
const handleSearch = () => {
    formRef.value.validate((valid) => {
        state.validateFlag = !!valid;
        if (valid) {
            $emit("search", JSON.parse(JSON.stringify(state.form)));
        }
    });
};

// 重置
const handleReset = () => {
    state.form = {};
    state.formTemp = {};
    $emit("reset");
};

// 时间控件切换
const dateChange = (data, code) => {
    // 此处设置值，目的将动态匹配查询字段规则
    state.form[code] = data ? data.toString() : null;
    state.form[`${code}Start`] = "";
    state.form[`${code}End`] = "";
    try {
        state.form[`${code}Start`] = data[0];
        state.form[`${code}End`] = data[1];
    } catch (e) {
    }
};

// 监听校验规则
watch(
    () => props.rules,
    (val) => {
        nextTick(() => {
            setTimeout(() => {
                // 清空检验规则
                formRef.value?.clearValidate();
            }, 50);
        });
    }
);
</script>
<style lang="less" scoped>
.tg_filterWap_card {
  box-shadow: none;
}

.tg_filterWap {
  margin-top: -15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--el-border-color);
  margin-bottom: 18px;

  .tg_title {
    color: var(--theme-color);
    height: 40px;
    line-height: 40px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: var(--theme-color);
    }
  }

  .tg_moreSetting {
    color: #999;
  }

  .tg_filterSetting {
    margin-left: 30px;
  }

  .tg_filterMore {
    cursor: pointer;

    &:hover {
      color: var(--theme-color);
    }
  }
}

// 内容区
.tg_filterCont {
  overflow: hidden;
}

// 按钮
.tg_btnWap {
  padding-top: 16px;
  border-top: 1px solid var(--el-border-color);
  display: flex;

  .tg_validate_message {
    margin-left: 8px;
    margin-top: -3px;
  }
}

// 日期控件
@media screen and (max-width: 1370px) {
  :deep(.el-range-editor) {
    &.el-input__wrapper {
      padding-right: 4px;
    }

    .el-range-separator {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
}
</style>
