<template>
  <div class="tg_page">
    <TgPage :page="page">
      <tg-table-list-plus ref="table" :page="page" :table="page.data.table" class="fit"
                          :showFilterSetting="false">

        <template #fileNameOri="{scope}">
          <el-link @click="showImg(scope.row)" type="primary">{{ scope.row["fileNameOri"] }}</el-link>
        </template>
        <el-table-column :label="tm('action.oper','操作')" fixed="right" width="160px">
          <template #default="scope">
            <tg-button @click="page.del(scope.row)" icon="Delete" text :name="tm('action.delete','删除')"/>
            <tg-button @click="enableAct(scope.row)" icon="Check" :disabled="lockStatus(scope.row,'Y')" text
                       :name="tm('action.enable','启用')"/>
            <tg-button @click="disableAct(scope.row)" icon="Close" :disabled="lockStatus(scope.row,'N')" text
                       :name="tm('action.stopUse','停用')"/>

          </template>
        </el-table-column>
      </tg-table-list-plus>
      <ImageView ref="imageView" :page="page"></ImageView>

      <!--      <edit :page="page" editPage popType></edit>-->
    </TgPage>
  </div>
</template>

<script setup name="fileInfoList">
import QueryPage from "@coreQueryPage";
import apis from "@core/apis";
import {defaultQueryProps, defaultTableProps} from '@componentSetting'
import {columns, searchFormSchema} from "./FileInfoList.set"
// import ImageView from "@coreComponents/TgUploadPlus/part/ImageView"
import {TgHttp} from "@coreHttp";
import {getCurrentInstance, nextTick} from "vue";
import ImageView from "@coreComponents/TgUploadPlus/part/ImageView.vue";
import {SettingCore} from "@core/setting";
import {tm} from '@i18nHelper'

const {proxy} = getCurrentInstance()

defineExpose({setReadonly, getFileNum, clearTable, search, setParam})


const props = defineProps({
  hasCatalog: {
    type: Boolean,
    default: false,
  },

  catalog: {
    type: String,
    default: "",
  },
  entity: {
    default: () => null
  },
  editConfig: {
    default: () => null
  },
  param: {
    default: () => null
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  bizNo: {
    type: String,
    default: "",
  },
  bizType: {
    type: String,
    default: "",
  },
  pNo: {
    type: String,
    default: "",
  },
  fileType: {
    default: [],
  },
})

let {page} = QueryPage()
page.context.name = 'eciFileInfoList'
page.data.deleteApi = apis.fileInfo.delete
page.data.searchApi = apis.fileInfo.selectComponentPageList
page.data.sort = "createDate"
page.data.editConfig.click = false
//将页面数据挂在到page.data上面，便于其他界面的使用page.data.xxx
page.useData({
  //xxx:''
  bizNo: '',
  bizType: '',
  pNo: '',
  catalog: ''
})

page.searching = (req) => {
  req.entity.bizNo = page.data.bizNo
  req.entity.bizType = page.data.bizType
  req.entity.pNo = page.data.pNo
  req.entity.catalog = props.catalog

}

function setParam(param) {
  page.data.bizNo = param.bizNo
  page.data.bizType = param.bizType
  page.data.pNo = param.pNo

}

page.useQueryForm({
  ...defaultQueryProps,
  schema: searchFormSchema,
})

page.useTable({
  ...defaultTableProps,
  columns: columns,
  rowkey: 'guid',
  immediate: false,//一打开界面就查询
})

//执行查询
function search() {
  page.search()
}

function clearTable() {
  page.clearTable()
}

function showImg(tag) {
  tag.id = tag.guid
  tag.name = tag.fileNameOri
  let extendName = tag.name
      .substring(tag.name.lastIndexOf(".") + 1)
      .toLowerCase()
  let fileType = [""]
  if (SettingCore.VITE_TG_FILEINFO_IMAGEVIEWTYPE) {
    fileType = SettingCore.VITE_TG_FILEINFO_IMAGEVIEWTYPE.split(",")
  }
  const cont = fileType.indexOf(extendName) > -1
  if (fileType.indexOf(extendName) > -1) {
    nextTick(() => {
      proxy.$refs.imageView.show(tag)
    })
  } else {
    nextTick(() => {
      let req = new TgHttp()
      req.entity.guid = tag.id
      req.build(apis.fileInfo.export, {file: true}).post()
    })
  }
}


page.deleted = (res) => {
  let param = {}
  param.bizNo = props.bizNo
  param.bizType = props.bizType
  param.pNo = props.pNo
  page.context.getContextInstance().exposed.updateEciFile(param);
}
// function deleted(response) {
//   this.$vnode.context.updateEciFile(this.param)
// }

function getFileNum() {
  return proxy.$refs.table.rows.length
}


async function enableAct(row) {
  let request = new TgHttp();
  request.entity.guid = row.guid
  request.build(apis.fileInfo.enable).post().then(() => {
    page.search()
  })
}

function disableAct(row) {
  let request = new TgHttp();
  request.entity.guid = row.guid
  request.build(apis.fileInfo.disable).post().then(() => {
    page.search()
  })
}

function setReadonly(value) {
  this.readonly = value
}

function lockStatus(row, value) {
  if (!this.readonly) {
    return row.status == value
  } else {
    return true
  }
}
</script>

