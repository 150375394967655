<template>
    <div class="tg_choose_list_ex">
        <tg-table-list-plus ref="table" :page="page" :showFilterSetting="false" :showPage="false"
                            :height="height" :table="page.data.table" :stripe="false"
        ></tg-table-list-plus>
    </div>
</template>
<script setup name="TgChooseListEx">
import {nextTick, onMounted, reactive, ref} from 'vue'
import QueryPage from "@core/models/QueryPage/QueryPage";
import apis from '@core/apis'
import {TgHttp} from "@coreHttp";
import TgTableListPlus from "@coreComponents/crudPlus/TgTableListPlus/TgTableListPlus.vue";
import {defaultTableProps} from "@componentSetting";
import {isExists, loadData, searchByCache} from '@core/store/modules/dataHelperCache.js'

const tgChooseDialogRef = ref()
const emits = defineEmits(['update:modelValue'])

let {page} = QueryPage()
page.data.searchApi = apis.coreChoose.search
page.context.name = 'TgChooseListEx'
let data = reactive({
    visible: false,
    obj: null,
    field: "",
    index: 0,//默认不要选中数据
    first: false,
    queryData: "",//默认的查询条件
    tgPageSize: "",//默认一页显示条数
    compareType: "",//数据检索方式： = 或者 like
})

page.data.editConfig.click = false

const props = defineProps({
    dataType: {
        type: String,
        default: ''
    },
    obj: {
        type: Object,
        default: null,
    },
    isCache: {
        type: Boolean,
        default: true
    },
    height: {
        type: Number,
        default: 500
    },
    //查询以后数据排序，从左边开始
    isSort: {
        type: Boolean,
        default: false
    }
})

onMounted(async () => {

    if (data.field != undefined && data.field != "") {
        let text = data.obj.getCode()
        if (text != '') {
            page.data.entity.conn = text
        }
    }

    //如果配置了从缓存读取，就判断缓存是否存在，不存在拉数据
    if (props.isCache) {
        let result = await isExists(props.dataType)
        if (!result) {
            await loadData(props.dataType)
        }
    }

})

page.useTable({
    ...defaultTableProps,
    columns: [],
    rowkey: 'id',
    immediate: true,//默认界面一加载，执行查询
    initUI: getChooseConfig
})

//获取表格列的信息
async function getChooseConfig() {
    let tableColumns = []
    let request = new TgHttp();
    request.entity["queryKey"] = props.dataType
    let response = await request.build(apis.coreChoose.getConfig).post();
    if (response?.success) {
        let columns = response.data?.chooseColumns
        columns?.forEach((item) => {
            let column = {
                label: item.name,
                field: item.code,
                width: item.width ? item.width + 'px' : '150px',
                show: true
            }
            tableColumns.push(column)
        })

        //获取其他配置信息，用于前端数据库检索数据
        data.queryData = response.data.queryData ?? ""
        data.compareType = response.data.compareType ?? "like"
        data.tgPageSize = 20 //默认显示20条
        if (response.data.tgPageSize) {
            data.tgPageSize = parseInt(response.data.tgPageSize)
        }
    }
    return tableColumns
}

//在放大镜校验的时候，需要获取配置信息，来判断查询数据是精确匹配还是模糊查询
const getConfig = () => {
    return {
        queryData: data.queryData,
        compareType: data.compareType,
        tgPageSize: data.tgPageSize
    }
}
const getTableRows = () => {
    return page.data.table.rows
}

//执行查询
const search = async () => {
    page.data.table.rows = []
    if (props.isCache) {
        let msg = page.data.entity.conn != "" ? page.data.entity.conn : data.queryData
        let result = await searchByCache(props.dataType, msg, data.compareType, data.tgPageSize, props.isSort)
        page.data.table.rows = result.data
        page.searched()
    } else {
        page.search()
    }
}

//执行查询之前
page.searching = (request) => {
    request.entity.queryKey = props.dataType
    request.entity.msg = page.data.entity.conn
}
//执行查询之后
page.searched = () => {
    //查询以后，如果输入框有值，就默认选中第一
    if (data.first) {
        data.index = 0
        if (page.data.table.rows.length > 0) {
            page.data.table.rows[data.index]._className = "eci-edit-row"
        }
    } else {
        //默认索引是-1,这样不会出现，回车自动带第一条数据
        data.index = -1
    }

    for (let index in page.data.table.rows) {
        page.data.table.rows[index].index = index
    }
    props.obj.exposed?.searched()
}

//表格单击，选中数据
page.rowClickEx = row => {
    //选中数据
    props.obj.exposed?.selectData(row)
};

//回车选中数据
function enterSelect() {
    let row = page.data.table.rows[data.index]
    props.obj.exposed?.selectData(row)
}


//键盘下移
function moveNext() {
    if (data.index >= 0) {
        page.data.table.rows[data.index]._className = ""
    }

    data.index++

    if (data.index >= page.data.table.rows.length) {
        //最后一条数据的时候
        data.index = page.data.table.rows.length - 1
    }

    let currentRow = page.data.table.rows[data.index]
    if (currentRow) {
        currentRow._className = "eci-edit-row"
        scrollToRow(currentRow)
    }
}

//键盘上移
function movePre() {
    page.data.table.rows[data.index]._className = ""
    data.index--
    if (data.index < 0) {
        data.index = 0;
    }

    let currentRow = page.data.table.rows[data.index]
    if (currentRow) {
        currentRow._className = "eci-edit-row"
        scrollToRow(currentRow)
    }
}

//表格有滚动条，按鼠标上下键的时候，滚动条也跟着滚动
function scrollToRow(row) {
    nextTick(() => {
        let rows = {}
        if (page.context.getRef("table").$el.nextElementSibling) {
            rows = page.context.getRef("table").$el.nextElementSibling.querySelectorAll('.el-table__row')
        } else {
            rows = page.context.getRef("table").$el.querySelectorAll('.el-table__row');
        }
        // const rows = page.context.getRef("table").$el.nextElementSibling.querySelectorAll('.el-table__row')
        // const rows = page.context.getRef("table").$el.querySelectorAll('.el-table__row');
        const index = page.data.table.rows.indexOf(row);
        if (rows[index]) {
            rows[index].scrollIntoView({behavior: 'smooth', block: 'nearest'});
        }
    });
}

//设置查询参数
function setConn(value) {
    if (value.length > 0) {
        data.first = true
    } else {
        data.first = false
    }
    page.data.entity.conn = value
}

defineExpose({getTableRows, moveNext, movePre, enterSelect, setConn, search, getConfig})

</script>
<style lang="less">

/*去掉表格默认的margin-top*/
.tg_choose_list_ex {
  .tg_tableList_plus {
    margin-top: 0px !important;

    .tg_btnWap {
      padding-bottom: 0px !important;
    }
  }

  .eci-table {

    .eci-edit-row {
      background: rgb(201, 224, 245) !important;
    }

    .eci-checked-row {
      background: lightYellow !important;
    }

  }

  .el-card__body {
    padding: 10px !important;
  }


}


</style>
