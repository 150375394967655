const state = {
  // 请求的接口
  requestList: []
}

const mutations = {
  tgRequestListUpdate(state,request){
    state.requestList.unshift(request)
  },
  tgRequestListClear(state) {
    state.requestList = []
  }
}
export default {
  state,
  mutations
}
