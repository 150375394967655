/**
 * wujie主应用公共bus
 */
import { busMain } from '.';

/**
 * 触发子应用事件
 * 
 * @param {string} eventName 事件名称
 * @param {object} params 传递的参数
 */
export const emitMain = (eventName, params) => {
    busMain.$emit(eventName, params);
}

/**
 * 监听子应用触发的事件
 * 
 * @param {string} eventName 事件名称
 * @param {function} callback 事件回调
 */
export const onMain = (eventName, callback) => {
    busMain.$on(eventName, callback);
}

/**
 * 触发子应用themeSettingChange事件
 * @param {object} param 用户主题
 */
export const emitThemeSetting = param => {
    emitMain('themeSettingChange', param);
};

export const emitExcelImportId = param => {
    emitMain('excelImportId', param);
};

/**
 *  触发子应用路由跳转
 * @param {object} params 子应用路由跳转参数
 * @param {string} params.path 子应用跳转路径
 * @param {string} params.appName 子应用名称
 * @param {object} [params.qury] 其它参数
 */
export const emitRouterChange = params => {
    emitMain('routeChange', params);
};

/** 关闭所有事件监听 */
export const offAllEventListener = () => {
    busMain.$offAll();
}

/**
 * 子应用跳转登录页
 * @param {() => void} callback
 */
export const onRedirectLogin = callback => {
    onMain('redirectLogin', callback);
};

export const onAppRouterPush = callback => {
    onMain('routerPush', callback);
}