import {useI18n, I18nGlobalTranslation, I18nTranslationRestParameters} from './useI18n'


export function tmn(key: string, memo: string, namespace: string, ...arg: any[]) {
    const tmn = useI18n(namespace).t
    const localValue = tmn(key, ...(arg as I18nTranslationRestParameters))
    if (localValue == key || localValue == '') {
        return memo
    } else {
        return localValue
    }
}

export function tm(key: string, memo: string, ...arg: any[]) {
    const tm = useI18n().t
    const localValue = tm(key, ...(arg as I18nTranslationRestParameters))
    if (localValue == key || localValue == '') {
        return memo
    } else {
        return localValue
    }
}

export function t(key: string, ...arg: any[]) {
    const t = useI18n().t
    const localValue = t(key, ...(arg as I18nTranslationRestParameters))
    if (localValue == key || localValue == '') {
        return key
    } else {
        return localValue
    }
}

