import * as extension from '@/web/extension'
/**
 *  @description: 用于业务扩展 main.js
 *   @param { Object } app
 */
export default function (app) {
  if(extension.default?.appMain?.default) {
    return extension.default.appMain?.default(app)
  }
}
