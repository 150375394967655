export default {
	"cbiCustRtnMsg":"跨境统一版进口（总署回执表）",
	"cbiCustRtnMsg_kj5PartionKey":"创建日期",
	"cbiCustRtnMsg_platNo":"平台编号",
	"cbiCustRtnMsg_syMark":"状态",
	"cbiCustRtnMsg_guid":"系统生产36位唯一序号（英文字母大写）",
	"cbiCustRtnMsg_messageType":"业务类型",
	"cbiCustRtnMsg_logisticsCode":"物流企业",
	"cbiCustRtnMsg_logisticsNo":"物流运单编号",
	"cbiCustRtnMsg_returnStatus":"回执状态",
	"cbiCustRtnMsg_returnTime":"回执时间",
	"cbiCustRtnMsg_returnInfo":"回执信息",
	"cbiCustRtnMsg_inputDate":"平台入库时间",
	"cbiCustRtnMsg_isProcess":"是否处理过 否0、 是1",
	"cbiCustRtnMsg_serviceFlag":"处理服务标识符（入库由随机函数产生）",
	"cbiCustRtnMsg_autoId":"autoId",
	"cbiCustRtnMsg_copNo":"企业内部编号",
	"cbiCustRtnMsg_invtNo":"清单编号",
	"cbiCustRtnMsg_orderNo":"原始订单编号",
	"cbiCustRtnMsg_ebcCode":"电商企业",
	"cbiCustRtnMsg_preNo":"预录入编号/离境单编号",
	"cbiCustRtnMsg_payTransactionId":"支付交易编号",
	"cbiCustRtnMsg_payCode":"支付企业编号",
	"cbiCustRtnMsg_payName":"支付企业名称",
	"cbiCustRtnMsg_rkdNo":"入库单编号",
	"cbiCustRtnMsg_billNo":"提运单号"
}
