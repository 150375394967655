import {Recordable, IPage, IEciExecuteLog, IEciRuntime, ILogCatchAccessInfo} from "../../../types/ecitrans";
import {ResultEnum} from "../../../enums/httpEnum";


/**
 * @description: 接口通用返回数据
 */
export class TgResponse {
    code: number;
    success: boolean;
    statusCode: ResultEnum;
    message: string;
    msg: string;
    stackTrace?: string;
    result?: any;
    data?: Recordable;
    exExtInfo?: Recordable;
    entity?: Recordable;
    table?: Recordable[];
    summaryInfo?: string;
    sumData?: Recordable;
    paging?: IPage;
    executeInfo?: IEciExecuteLog;
    runtime?: IEciRuntime;
    logCatchInfo?: ILogCatchAccessInfo;
    cacheVersion: string;

    // @ts-ignore
    constructor(data) {
        this.code = data.code;
        this.success = data.success;
        this.statusCode = data.statusCode;
        this.message = data.msg;
        this.msg = data.msg;
        this.stackTrace = data.stackTrace;
        this.result = data.result;
        this.data = data.data;
        this.exExtInfo = data.exExtInfo;
        this.entity = data.entity;
        this.table = data.table;
        this.summaryInfo = data.summaryInfo;
        this.sumData = data.sumData;
        this.paging = data.paging;
        this.executeInfo = data.executeInfo;
        this.runtime = data.runtime;
        this.logCatchInfo = data.logCatchInfo;
        this.cacheVersion = data.cacheVersion;
    }
}
