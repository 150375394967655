const getLanguage = (item) => {
    var arr
    if (!item || !item.description) {
        return item.name || 'more_menus' // 未配置显示undefined & 这里先默认填了一个
    } else {
        arr = item.description.split(',')
        if (!arr.length || arr.length < 3) {
            return item.name || 'more_menus' // 未配置显示undefined
        } else {
            return arr[2]
        }
    }
}

export default getLanguage 