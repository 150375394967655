const routerWhiteList = [
    // {
    //     path: "/login2",
    //     name: "系统登录2",
    //     component: () => import("@appDemo/login/login.vue"),
    // }
    {
        path: "/xnLogin",
        name: "西宁跨境用户登录",
        component: () => import("@appKJ/views/Login/index.vue"),
    },
    {
        path: "/xnRegister",
        name: "西宁跨境用户注册",
        component: () => import("@appKJ/views/Login/register.vue"),
    },
    {
        path: "/findLogin",
        name: "西宁跨境找回密码",
        component: () => import("@appKJ/views/Login/findLogin.vue"),
    }
]
const routes = [
    // {
    //     path: "/testPage",
    //     name: "测试页面2",
    //     component: () => import("@appKJ/Login/index.vue"),
    // }
]

function setRouteWhiteList() {
    return routerWhiteList
}

function setRoutes() {
    return routes
}

export default {
    setRoutes,
    setRouteWhiteList
}
