import * as extension from '@/web/extension'
/**
 *  @description: 清除缓存成功后的回调方法
 * */
const clearAllCache = ()=>{
  if(extension.default?.clearCache?.default?.clearAllCache) {
    return new Promise(async (resolve)=>{
      await extension.default.clearCache.default.clearAllCache()
      resolve( true)
    })
  }
  return new Promise((resolve)=>{
    resolve( true)
  })
}

export {
  clearAllCache
}
