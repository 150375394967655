<!-- 放大镜 -->
<template>
    <div class="tg-choose-page">
        <div class="tg-choose" v-bind:class="{ error: data.isError }">
            <ElInput ref="chooseRef" class="tg-choose__input"
                     :model-value="data.row.displayText" v-bind="$attrs"
                     :disabled="disabled" :readonly="readonly"
                     :focustypes="nanoid()" :placeholder="props.placeholder"
                     @focus="focusAction" @blur="blurAction" @dblclick="show"
                     @input="changeInput"
                     clearable @clear="clearAction">
                <template #suffix>
                    <el-icon :disabled="disabled" class="el-input__icon" style="cursor: pointer;margin-right:2px;"
                             @click="show">
                        <search/>
                    </el-icon>
                </template>
            </ElInput>
        </div>
    </div>
</template>

<script setup name="TgChoosePlus">
import {getCurrentInstance, inject, onMounted, reactive, ref, watch} from 'vue';
import tg from '@coreTgPlus'
import {nanoid} from 'nanoid'
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import apis from "@core/apis";
import {tmn, tm, t} from '@i18nHelper'
import TgButton from "@core/components/base/TgButton.vue";
import {Search} from "@element-plus/icons-vue";

const {proxy} = getCurrentInstance()
const props = defineProps({
    /** 搜索表单数据 code */
    modelValue: {
        type: [String],
        default: () => '',
        required: true
    },
    dataType: {
        type: [String],
        default: () => ''
    },
    //页面
    page: {
        type: Object,
        default: null
    },
    //实体
    entity: {
        type: Object,
        default: null
    },
    //代码字段
    codeField: {
        type: String,
        default: ''
    },
    //名称字段
    nameField: {
        type: String,
        default: ''
    },
    addon: {
        default() {
            return ""
        },
    },

    //自定义放大镜
    target: {
        default() {
            return ""
        },
    },
    //清空的默认值
    clearValue: {
        default() {
            return ""
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },

    readonly: {
        type: Boolean,
        default: false,
    },
    //是否出现清空按钮
    canClear: {
        type: Boolean,
        default: false,
    },
    canChoose: {
        type: Boolean,
        default: false,
    },

    //只显示code
    codeOnly: {
        type: Boolean,
        default: false,
    },
    //是否校验放大镜，默认：true
    validate: {
        type: Boolean,
        default: true,
    },
    //code隐藏
    codeBehind: {
        type: Boolean,
        default() {
            return false
        },
    },
    placeholder: {
        type: String,
        default: ''
    },
    /*
   * 扩展
   * */
    extend: {
        type: Object,
        default: () => {
            url: ''
        }
    },
});


let data = reactive({
    row: {displayText: "11", code: "aa"},
    codeValue: "",
    nameValue: "",
    focusValue: "",
    chooseCodeField: "",
    chooseNameField: "",
    conn: {},
    field: "",
    nameField: "",
    isError: false
})

let thisInstance = tg.vue.instance()
let chooseList = inject('tg-choose-list')

const emits = defineEmits(['update:modelValue', 'update',
    'conn', 'change', 'choose', 'error']);

let initChoose = () => {
    if (!props.target && !props.dataType && props.extend.url == '') {
        return tg.msg.error(t('tg.page.msg.M00094', [props.codeField]))
    }
    if (!data.field) {
        let propsCodeFiled = props.codeField;
        //如果配置了扩展，同时extend中配置了code
        if (props.extend?.url && props.extend?.code) {
            propsCodeFiled = props.extend.code
        }

        data.chooseCodeField = propsCodeFiled
        data.chooseNameField = propsCodeFiled + 'Name'
        if (props.codeField) {
            data.chooseCodeField = props.codeField
        }
        if (props.nameField) {
            data.chooseNameField = props.nameField
        }
        data.field = data.chooseCodeField
    } else {
        data.chooseCodeField = data.field
        data.chooseNameField = data.chooseCodeField + 'Name'
        //如果定义了nameField，就不需要设置Name
        if (props.nameField) {
            data.chooseNameField = props.nameField
        }
    }
}

initChoose()

onMounted(() => {
    //注册放大镜
    if (props.page?.regChoose) {
        if (thisInstance) {
            props.page.regChoose(thisInstance, data.chooseCodeField)
        }
    }
    // 绑定数据
    bindData()
})


function bindData() {
    if (props.entity) {
        if (Object.keys(props.entity).includes(data.chooseCodeField)) {
            data.codeValue = props.entity[data.chooseCodeField]
        } else {
            data.codeValue = ''
        }
        if (data.chooseNameField) {
            if (Object.keys(props.entity).includes(data.chooseNameField)) {
                data.nameValue = props.entity[data.chooseNameField]
            } else {
                data.nameValue = ''
            }
        }
    }
    if (data.codeValue == '' && data.nameValue != '') {
        clearAction()
    } else {
        setDisplayText()
    }
}

function setDisplayText() {
    //设置code隐藏，nameValue有值。

    if (props.codeBehind && data.nameValue != '') {
        data.row.displayText = data.nameValue
    } else {
        if (data.nameValue) {
            //update
            if (props.codeOnly) {
                data.row.displayText = data.codeValue
            } else {
                data.row.displayText = data.codeValue + '|' + data.nameValue
            }
        } else {
            data.row.displayText = data.codeValue
        }
    }
}

function show() {
    if (!props.canChoose) {
        if (props.readonly) {
            return
        }
        if (props.disabled) {
            return
        }
    }
    data.conn = {addon: '', message: ''}
    emits('conn', props.page, data.conn)

    if (!data.conn.addon) {
        data.conn.addon = props.addon
    }

    if (data.conn.message) {
        return tg.msg.error(data.conn.message)
    }

    if (props.target) {
        return showTarget()
    } else {
        if (!props.dataType && props.extend.url == '') {
            return tg.msg.error(t('tg.page.msg.M00094', [props.codeField]))
        }
    }

    let instance = chooseList({
        dataType: props.dataType,
        field: data.field,
        obj: thisInstance,
        extend: props.extend,
    })

    //调用chooseList的show
    if (instance && instance.exposed) {
        instance.exposed.show()
    }
}

function showTarget() {
    if (props.target) {
        let ref = props.page?.context.getRefByCode(props.target)
        if (ref) {
            setDisplayText()
            return ref.show(thisInstance)
        } else {
            //TODO:
            tg.log.warning(t('tg.page.msg.M00096', [props.target]))
            tg.msg.warning(t('tg.page.msg.M00095'), true)
        }
    }
}

//输入框输入的的事件
const changeInput = (e) => {
    data.row.displayText = e;
}

const focusAction = () => {
    data.focusValue = data.row.displayText
}
const selectDataError = (row) => {
    //设置数据
    setFormValue(row)

    //自定义事件
    emits("error", row, {})
}

//更新实体和界面显示的数据
const setFormValue = (row = {}) => {

    props.entity[data.chooseCodeField] = row.code
    if (!props.codeOnly) {
        props.entity[data.chooseNameField] = row.name
    }

    data.row.code = row.code

    if (props.codeBehind) {
        data.row.displayText = row.name
    } else {
        if (row.name) {
            data.row.displayText = props.codeOnly
                ? row.code
                : row.code + "|" + row.name
        } else {
            data.row.displayText = row.code
        }
    }
    //设置codeValue,用于codeBehind的时候
    data.codeValue = row.code

}

const clearAction = () => {
    let row = {code: '', name: ""}

    if (props.clearValue) {
        let array = (props.clearValue + "|").split("|")
        row.code = array[0]
        row.name = array[1]
    }
    props.entity[data.chooseCodeField] = row.code
    if (!props.codeOnly) {
        props.entity[data.chooseNameField] = row.name
    }

    setFormValue(row)
}

function removeColor() {
    data.isError = false

    if (props.page.data.entityCheck) {
        props.page.data.entityCheck[data.chooseCodeField] = ""
    }
}

function setColor() {
    data.isError = true
    if (props.page.data.entityCheck) {
        props.page.data.entityCheck[data.chooseCodeField] = t('tg.page.msg.M00097')
    }
}

watch(
    () => data.row.displayText,
    (val, oldValue) => {
        let code = ""
        let name = ""
        if (props.codeBehind && val != "") {
            if (data.codeValue) {
                code = data.codeValue
                name = data.row.displayText
            } else {
                code = data.row.displayText
            }
        } else {
            if (data.row.displayText == undefined) {
                data.row.displayText = ""
            }

            let array = data.row.displayText.toString().split("|")
            if (array.length > 1) {
                code = array[0]
                name = array[1]
            } else {
                code = array[0]
                name = ""
            }
        }

        if (!props.codeOnly) {
            props.entity[data.chooseNameField] = name
        }

        props.entity[data.chooseCodeField] = code
    },
    {
        deep: true
    }
);

watch(() => props.entity, (val, oldValue) => {
    data.codeValue = val[data.chooseCodeField]
    data.nameValue = val[data.chooseNameField]
    if (data.codeValue == undefined) {
        data.codeValue = ""
    }

    if (data.codeValue == "") {
        data.nameValue = ""
    }

    if (data.nameValue == undefined) {
        data.nameValue = ""
    }
    setDisplayText()
}, {deep: true});


//失去焦点，进行放大镜查询数据
async function blurAction() {
    //调用数据库校验数据
    if (data.row.displayText == '') {
        removeColor()
    }
    if (data.row.displayText != data.focusValue && data.row.displayText != '') {
        if (props.validate) {
            //添加放大镜校验
            setColor()
        }
        let searchData = data.row.displayText
        let array = searchData.split('|')
        if (array.length > 0) {
            searchData = array[0]
        }
        data.conn = {}

        emits('conn', props.page, data.conn)

        let res = await validateData(searchData)

        if (res && res.data?.length > 1) {
            //查询多条，显示弹框
            show()
        } else if (res && res.data?.length == 1) {
            //查询一条，设置值
            let row = res.data[0]
            selectData(row)
            removeColor()
        } else {
            let array = data.row.displayText.split('|')
            let code = ''
            let name = ''
            if (props.validate) {
                if (props.codeBehind) {
                    if (array.length > 1) {
                        code = array[0]
                        name = array[1]
                    } else {
                        code = array[0]
                        name = array[1]
                    }
                } else {
                    if (array.length > 1) {
                        code = array[0]
                        name = array[1]
                    } else {
                        code = array[0]
                        name = array[0]
                    }
                }
                let row = {code: code, name: name}

                if (!props.codeBehind && array.length == 1) {
                    row.name = ''
                }

                selectDataError(row)
                setColor()
            }

        }
        //编辑Form中，出发放大镜校验
        if (props.page.context.getRef("editForm")) {
            props.page.context.getRef("editForm").validateField(data.field, (errorMessage) => {

            })
        }

        //编辑Form中，出发放大镜校验
        if (props.page.context.getRef("formRef")) {
            props.page.context.getRef("formRef").validateField(data.field, (errorMessage) => {

            })
        }
        //查询Form中，出发放大镜校验
        if (props.page.context.getRef("queryForm")) {
            props.page.context.getRef("queryForm").validateField(data.field)
        }
    }
}

function getCode() {
    let code = ''
    if (data.row.displayText == undefined) {
        data.row.displayText = ''
    }
    let array = data.row.displayText.split('|')
    if (array.length > 1) {
        code = array[0]
    } else {
        code = array[0]
    }
    return code
}

const selectData = (row) => {

    removeColor()

    setFormValue(row)
    //自定义事件
    emits("choose", row, props.entity, {})
}

/**
 * @description 设置查询参数
 * @param {*} msg 输入的查询内容
 * @param {*} pagination 分页数据
 **/

const validateData = async (searchData) => {
    if (props.extend?.url) {
        return getSysDataHelpListEx(searchData, props.extend.url)
    } else {
        let request = new TgHttp();
        request.entity["queryKey"] = props.dataType
        request.entity["msg"] = searchData
        let response = await request.build(apis.coreChoose.validate).post();
        if (response?.success) {
            return response
        }
    }
}

//通过自定义的api接口，返回数据
const getSysDataHelpListEx = async (searchData, url) => {
    let result = {data: []}

    const queryParams = ref({
        queryKey: props.dataType,
        msg: searchData,

    });
    if (props.where) {
        queryParams.value.where = props.where;
    }
    // 扩展部分支持用户传入查询key
    let key = 'msg'
    try {
        key = props.extend.where.key
        // 匹配规则： 默认匹配规则模糊查询
        queryParams.value.queryField = `${key}|l`
    } catch (e) {
    }
    let sendData = {
        ...queryParams.value,
        [key]: queryParams.value.msg,
        ...(props.extend && props.extend.data || {})
    }

    const request = new TgHttp();
    request.entity = sendData
    request.paging.pageSize = 10
    request.paging.pageNum = 1

    const response = await request.build(SettingCore.VITE_BASE_URL + url).post()

    if (response?.success) {
        if (response.code === 10001) {
            let lists = response.data?.list
            //将查询的出来的数据，添加code或者name属性
            lists?.forEach((item) => {
                let row = {
                    ...item
                }
                if (props.extend.code) {
                    //如果不存在code,就给code赋值
                    if (!row.hasOwnProperty('code')) {
                        row["code"] = item[props.extend.code]
                    }
                }
                //如果不存在name,就给code赋值
                if (props.extend.name) {
                    if (!row.hasOwnProperty('name')) {
                        row["name"] = item[props.extend.name]
                    }
                }
                result.data.push(row)
            })
        }
    }

    return result;
};

/**
 * 请求数据
 * @param msg
 * @param pagination
 * @returns {Promise<*|{}>}
 */
const getSysDataHelpList = async (data, url = "/sysDataHelp/list") => {
    const request = new TgHttp();
    request.entity = data
    const res = await request
        .build(SettingCore.VITE_BASE_URL + url)
        .post()

    if (res.code === 10001) {
        return res.data;
    }
    return {};
};

defineExpose({selectData, getCode})

</script>

<style lang="less" scoped>

.tg-choose {
  width: 100%;
  display: flex;

  &__button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :deep(.el-input__wrapper) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
<style lang="less">
.tg-choose-page {
  width: 100%;

  .error {
    .el-input__inner {
      color: red !important;
    }
  }
}

// 放大镜校验
.tg-choose ~ .el-form-item__error {
  right: 38px !important;
}


</style>
