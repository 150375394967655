export default {
	"cbiOrder":"跨境统一版进口订单表头（操作表）",
	"cbiOrderItem":"跨境统一版进口订单表体（操作表）",
	"cbiOrder_kj5PartionKey":"kj5PartionKey",
	"cbiOrder_platNo":"平台编号",
	"cbiOrder_buyertelephone":"订购人电话",
	"cbiOrder_inputDate":"创建日期",
	"cbiOrder_syMark":"状态",
	"cbiOrder_copCode":"传输企业代码",
	"cbiOrder_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbiOrder_appType":"企业报送类型",
	"cbiOrder_appTime":"企业报送时间",
	"cbiOrder_appStatus":"企业报送状态",
	"cbiOrder_orderType":"电商订单类型",
	"cbiOrder_orderNo":"订单编号",
	"cbiOrder_ebpCode":"电商平台代码",
	"cbiOrder_ebpName":"电商平台名称",
	"cbiOrder_ebcCode":"电商企业编号",
	"cbiOrder_ebcName":"电商企业名称",
	"cbiOrder_goodsValue":"商品实际成交价",
	"cbiOrder_freight":"运杂费",
	"cbiOrder_discount":"非现金支付金额",
	"cbiOrder_taxTotal":"订单商品税款",
	"cbiOrder_acturalPaid":"实际支付金额",
	"cbiOrder_currency":"币制",
	"cbiOrder_buyerRegNo":"订购人的交易平台注册号",
	"cbiOrder_buyerName":"订购人",
	"cbiOrder_buyerIdType":"订购人身份类型",
	"cbiOrder_buyerIdNumber":"订购人的身份证件号码",
	"cbiOrder_payCode":"支付企业编号",
	"cbiOrder_payName":"支付企业名称",
	"cbiOrder_payTransactionId":"支付企业唯一的支付流水号",
	"cbiOrder_batchNumbers":"商品批次号",
	"cbiOrder_consignee":"收货人姓名",
	"cbiOrder_consigneeTelephone":"收货人联系电话",
	"cbiOrder_consigneeAddress":"收货地址",
	"cbiOrder_consigneeDitrict":"收货人行政区域代码",
	"cbiOrder_note":"备注",
	"cbiOrder_messageId":"消息ID"
}
