export default {
	"cbiTaxstatus":"跨境统一版进口电子税单表（总署回执状态数据状态表）",
	"cbiTaxstatus_kj5PartionKey":"kj5PartionKey",
	"cbiTaxstatus_platNo":"平台编号",
	"cbiTaxstatus_syMark":"状态",
	"cbiTaxstatus_autoId":"表体唯一主键",
	"cbiTaxstatus_guid":"总署返回GUID",
	"cbiTaxstatus_returnTime":"回执时间",
	"cbiTaxstatus_invtNo":"进境清单编号",
	"cbiTaxstatus_taxNo":"电子税单编号",
	"cbiTaxstatus_status":"税单状态",
	"cbiTaxstatus_entdutyNo":"缴款书编号",
	"cbiTaxstatus_assureCode":"担保企业代码",
	"cbiTaxstatus_isProcess":"是否处理过 否0、 是1",
	"cbiTaxstatus_inputDate":"创建时间",
	"cbiTaxstatus_serviceFlag":"处理服务标识符（入库由随机函数产生）"
}
