import {tm} from '@/tiangongCore/locale/i18nHelper'


const defaultTableProps = {
    showTableSetting: true,
    actionColumn: {
        width: 140,
        title: tm('action.oper', ''),
        dataIndex: 'action'
    },
    rowSelection: {
        fixed: true,
        type: 'checkbox'
    },
    pageSizeOptions: window.website.componentSetting.table.pageSizeOptions
        ? window.website.componentSetting.table.pageSizeOptions
        : [10, 50, 80, 100],
    // 默认每页显示多少条
    defaultPageSize: window.website.componentSetting.table.defaultPageSize ? window.website.componentSetting.table.defaultPageSize : 10,
    // 默认排序方法
    size: 'middle',
    pagination: true,
    immediate: false,
    //是否自动添加序号列，默认不加
    showIndexColumn: false
}
const defaultFormProps = {
    labelWidth: 120,
    baseColProps: {span: 24},
    showActionButtonGroup: false,
    actionColOptions: {span: 23},
    itemMarginBottom: '2px'
}
const defaultQueryProps = {
    labelWidth: 120,
    showActionButtonGroup: false,
    itemMarginBottom: '2px'
}

function userSettingInit() {
}

export {userSettingInit, defaultFormProps, defaultQueryProps, defaultTableProps}
