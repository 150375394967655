﻿import $ from "jquery"
import eciStorage from "../TgLocalStorage/index"
import EciDebug from "./EciDebug"
import JSEncrypt from "jsencrypt"
import systemKey from "./systemKey"
import {Base64} from 'js-base64'
import EciMsg from './EciMsg'
import sizeTable from "@core/utils/common/sizeTable";
import {createVNode, getCurrentInstance, reactive, ref, toRaw, toRefs, unref} from 'vue'
import localStorage from "@core/utils/TgLocalStorage";
import {isFunction} from './is'
import log from "@core/utils/common/util.log";

const LoginStatus = "loginStatus"


export default {
    msg: new EciMsg(),
    iconIndex: -1,
    store: eciStorage,
    debug: new EciDebug(),
    log: log,
    vue: {
        toRefs,
        reactive,
        unref,
        instance: getCurrentInstance,
        toRaw,
        ref,
        createVNode
    },
    convertApi(modulesFiles) {
        const modules = Object.keys(modulesFiles).reduce((modules, modulePath) => {
            let bizName = modulePath.replace(/\.\/|\.js/g, '')
            try {
                const array = bizName.split('/')
                if (array.length > 0) {
                    const pop = array.pop()
                    bizName = pop ? pop : ''
                }
                const value = modulesFiles[modulePath]
                const data = value.default
                const baseUrl = data.baseUrl
                const api = {}

                for (const item in data.api) {
                    let x = data.api[item].trim()
                    if (x == '' || x.indexOf('*') >= 0) {
                        x = bizName + '/' + item
                    }
                    api[item] = baseUrl + '/' + x
                    modules[bizName] = api
                }
            } catch (error) {
                console.error("apis加载异常:[" + bizName + "]请检查")
            }
            return modules
        }, Promise.resolve({}))

        return modules
    },


    getIcon() {
        eci.iconIndex++
        let icons = [
            "system",
            "tree",
            "education",
            "dict",
            "list",
            "tool",
            "table",
            "guide",
            "example"
        ]

        let select = this.iconIndex % icons.length
        return icons[select]
    },
    timeStampToDate(timestamp) {
        let date = new Date(parseInt(timestamp) * 1000)
        let Year = date.getFullYear()
        let Moth =
            date.getMonth() + 1 < 10 ?
                "0" + (date.getMonth() + 1) :
                date.getMonth() + 1
        let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
        let Hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
        let Minute =
            date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        let Sechond =
            date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
        let GMT =
            Year +
            "-" +
            Moth +
            "-" +
            Day +
            "   " +
            Hour +
            ":" +
            Minute +
            ":" +
            Sechond
        return GMT
    },
    getHomePageName() {
        let array = website.home.url.split("/")
        let name = array.pop()
        return name
    },

    req(param, options) {
        let url = window.location.href

        if (options && options.url) {
            url = options.url
        }

        url = url.replace("?", "&")

        url = decodeURIComponent(url)

        let result = ""

        let reg = new RegExp("(^|&)" + param + "=([^&]*)(&|$)", "i")
        let r = url.match(reg)
        if (r !== null) {
            result = r[2]
        }

        return result
    },

    extendJoin(options1, options2, options3, options4) {
        if (options1 == undefined) options1 = {}
        if (options2 == undefined) options2 = {}
        if (options3 == undefined) options3 = {}
        if (options4 == undefined) options4 = {}

        return $.extend(options1, options2, options3, options4)
    },

    extend(options1, options2, options3, options4) {
        if (options1 == undefined) options1 = {}
        if (options2 == undefined) options2 = {}
        if (options3 == undefined) options3 = {}
        if (options4 == undefined) options4 = {}

        return $.extend({}, options1, options2, options3, options4)
    },

    s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    },

    guid() {
        return (
            this.s4() +
            this.s4() +
            "-" +
            this.s4() +
            "-" +
            this.s4() +
            "-" +
            this.s4() +
            "-" +
            this.s4() +
            this.s4() +
            this.s4()
        )
    },

    guid16() {
        return this.s4() + this.s4() + this.s4() + this.s4()
    },

    guid32() {
        return this.guid16() + this.guid16()
    },

    //rsa加密
    rsa(data) {
        let encrypt = new JSEncrypt()
        encrypt.setPublicKey(systemKey.publicKey)
        return encrypt.encrypt(data)
    },


    propertyToField(property) {
        if (property == null) {
            return ""
        }
        let stringsArray = property.split("")
        let filed = ""
        stringsArray.forEach(function (item) {
            if (/[A-Z]/.test(item)) {
                filed += "_" + item.toUpperCase()
            } else {
                filed += item.toUpperCase()
            }
        })
        if (filed.indexOf("_") == 0) {
            filed = filed.substr(1, filed.length)
        }
        return filed
    },

    fieldToProperty(input) {
        if (input == null || input == "" || input == undefined) {
            return ""
        }
        let stringsArray = input
            .trim()
            .toLowerCase()
            .split("_")
        let value = ""
        stringsArray.forEach(function (item) {
            value += item.substr(0, 1).toUpperCase() + item.substr(1, item.length)
        })

        value = value.substr(0, 1).toLowerCase() + value.substr(1, value.length)

        return value
    },

    copy(data) {
        let content = JSON.stringify(data)
        return JSON.parse(content)
    },

    fitAction(el, binding, vnode) {
        let winHeight = $(window).height()
        let offset = $(el).offset()
        let id = el.id

        let offsetTop = $(el).offset().top
        let height = winHeight - offsetTop - 2
        let offsetHeight = this.getOffsetHeight(binding) //指定扣除
        height = height - offsetHeight
        height -= 1

        let dialog = $(el).parents(".eci-dialog")

        if (dialog.length > 0) {
            let bottom = 0
            let dialogHeight = dialog.height()
            let dialogOffsetTop = dialog.offset().top
            bottom = winHeight - (dialogHeight + dialogOffsetTop)
            height -= bottom
            height -= 5
            height -= 20
        }

        $(el).height(height)
        // $(el).css("border", "2px solid red")
    },

    resize() {
        setTimeout(() => {
            let myEvent = new Event("resize")
            window.dispatchEvent(myEvent)
        }, 1)
    },

    errorHandler(error, vm, info) {
        let message = error
        let isAppException = false
        let isString = false

        if (typeof error == "string") {
            message = error
        } else {
            if (error.type == "AppException" || error.type == "Exception") {
                isAppException = true
            }
            message = error.message
        }

        //只抛出throw Exception 或者AppException的异常
        if (isAppException) {
            if (message == "IGNORE") {
                return
            }
            tg.msg.alertError(message)
        } else {
            // tg.msg.notifyError(message, {position: "bottom-right"})

            if (process.env.NODE_ENV !== 'development') return

            tg.log.capsule("TG", "ErrorHandler", "danger")
            tg.log.danger(">>>>>> 错误信息 >>>>>>")
            console.log(info)
            tg.log.danger(">>>>>> Vue 实例 >>>>>>")
            console.log(vm)
            tg.log.danger(">>>>>> Error >>>>>>")
            console.log(error)
        }
    },

    call(request) {
        return request.axios()
    },

    getAppAlias() {
        let appAlias = window.$vm.$t("ead.appAlias")
        let appName = window.$vm.$t("ead.appName")

        return appAlias ? appAlias : appName
    },

    watchInstance() {
        return {
            startTime: "",
            message: "",
            trace: [],
            start: function (message) {
                this.startTime = Date.now()
                this.message = message

                let output = "start:" + message + ""

                this.trace.push(output)

                console.info(output)
            },
            stop: function (message) {
                if (message) {
                    this.message = message
                }
                let endTime = Date.now()
                let cost = endTime - this.startTime
                let output = "stop: " + this.message + " 耗时: " + cost + " 毫秒"

                this.trace.push(output)

                console.info(output)
            }
        }
    },

    watch(message) {
        let instance = this.watchInstance()
        instance.start(message)
        return instance
    },

    getOffsetHeight(binding) {
        // 获取调用传递过来的数据
        const {value} = binding

        let offsetHeight = 0

        if (value) {
            let tableOffset = value.offset

            if (typeof tableOffset == "string") {
                let array = tableOffset.split(",")

                offsetHeight = 0

                for (let x of array) {
                    if (parseInt(x) > 0) {
                        offsetHeight += parseInt(x)
                    } else {
                        if ($(x).length) {
                            offsetHeight += $(x)[0].getBoundingClientRect().height
                        }
                    }
                }
            }
        }

        return offsetHeight
    },

    async doResize(el, binding, vnode, state) {
        sizeTable.doResize(el, binding, vnode, state);
    },

    // async doResizeTab(el, binding, vnode) {
    //     setTimeout(() => {
    //         sizeTab.doResize(el, binding, vnode);
    //     }, 200);
    // },

    arrayContains(array, element) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] == element) {
                return true
            }
        }
        return false
    },

    init() {
        // this.msg=new EciMsg()
        website.site = website.site || {}
        website.debug = website.debug || {page: false}

        if (website.autoGetPath == undefined) {
            website.autoGetPath = true;
        }

        if (website.autoGetPath) {
            let url = window.location.pathname.substring(1)
            let path = url.substring(0, url.indexOf("/"))
            if (path == "") {
                website.path = "/";
            } else {
                website.path = "/" + path + "/";
            }
        }

        // if (!website.yuyan) {
        //     website.yuyan = {};
        // }

        // if (website.yuyan.enable) {
        //   if (website.yuyan.sysInfo) {
        //     this.store.set("sys", website.yuyan.sysInfo)
        //   }
        // }

        // if (!website.ead) {
        //     website.ead = {}
        // }
        //
        // if (!website.table) {
        //     website.table = {}
        // }
        //
        // if (website.table.clearTableBeforeSearch == undefined) {
        //     website.table.clearTableBeforeSearch = true
        // }

        if (!website.site.loginUrl) {
            website.site.loginUrl = "AppEAD/Login/login"
        }

        // if (!website.site.loginPageUrl) {
        //     website.site.loginPageUrl = website.site.loginUrl
        // }
        //
        // if (!website.site.redirectToLoginPage) {
        //     website.site.redirectToLoginPage = []
        // }

        if (!website.site.token) {
            website.site.token = {}
        }

        website.eciFile = $.extend({
            imageViewType: "png,jpg,gif",
            fileViewType: ""
        }, website.eciFile)

        window.session = {
            userInfo: null,
            save: function (val) {
                val = val || session.userInfo
                this.store.set("userInfo", val)
                session.userInfo = val
            },

            update() {
                let user = this.store.get("userInfo");
                this.store.set("userInfo", user)
            },

            delete: function () {
                this.store.delete("userInfo")
            },

            getApiToken() {
                if (this.userInfo) {
                    return this.userInfo.apiToken
                } else {
                    return ""
                }
            },

            companyCode() {
                return this.userInfo.companyCode
            },

            companyName() {
                return this.userInfo.companyName
            },

            companyCodeName() {
                return this.userInfo.companyCode + "|" + this.userInfo.companyName
            },

            init: function () {
                let userinfo = localStorage.get("userinfo")
                if (userinfo && userinfo.userInfo) {
                    session.userInfo = userinfo.userInfo
                }
            }
        }

        window.AppException = function (message, p1, p2) {
            if (p1) {
                message = message.replace("{0}", p1)
            }

            if (p2) {
                message = message.replace("{1}", p2)
            }

            return {type: "AppException", message: message}
        }

        window.Exception = function (message, p1, p2) {
            if (p1) {
                message = message.replace("{0}", p1)
            }

            if (p2) {
                message = message.replace("{1}", p2)
            }

            return {type: "Exception", message: message}
        }

        // 日期，在原有日期基础上，增加 days 天数，默认增加 1 天
        Date.prototype.addDay = function (days) {
            let date = this;
            if (days == undefined || days == '') {
                days = 1;
            }
            date = new Date(date);
            date.setDate(date.getDate() + days);

            return date;
        }

        //月份，在原有的日期基础上，增加 months 月份，默认增加 1 月
        Date.prototype.addMonth = function (months) {
            let date = this;
            if (months == undefined || months == '')
                months = 1;
            date = new Date(date);
            date.setMonth(date.getMonth() + months);

            return date;
        }

        //月份，在原有的日期基础上，增加 years 年，默认增加 1 年
        Date.prototype.addYear = function (years) {
            let date = this;
            if (years == undefined || years == '')
                years = 1;
            date = new Date(date);
            date.setFullYear(date.getFullYear() + years);

            return date;
        }

        Date.prototype.format = function (format) {
            if (!format) {
                format = "yyyy-MM-dd"
            }

            if (format == "-ss") {
                format = "yyyy-MM-dd HH:mm:ss"
            } else if (format == "-mm") {
                format = "yyyy-MM-dd HH:mm"
            } else if (format == "-HH") {
                format = "yyyy-MM-dd HH"
            } else if (format == "-dd") {
                format = "yyyy-MM-dd"
            } else if (format == "-MM") {
                format = "yyyy-MM"
            } else if (format == "-yyyy") {
                format = "yyyy"
            }

            let o = {
                "M+": this.getMonth() + 1, //month
                "d+": this.getDate(), //day
                "H+": this.getHours(), //hour
                "m+": this.getMinutes(), //minute
                "s+": this.getSeconds(), //second
                "q+": Math.floor((this.getMonth() + 3) / 3), //quarter
                f: this.getMilliseconds() //millisecond
            }
            if (/(y+)/.test(format))
                format = format.replace(
                    RegExp.$1,
                    (this.getFullYear() + "").substr(4 - RegExp.$1.length)
                )
            for (let k in o)
                if (new RegExp("(" + k + ")").test(format))
                    format = format.replace(
                        RegExp.$1,
                        RegExp.$1.length == 1 ?
                            o[k] :
                            ("00" + o[k]).substr(("" + o[k]).length)
                    )
            return format
        }

        String.prototype.addUrl = function (key, value) {
            let url = this

            if (url.indexOf(key + "=") >= 0) {
                return this
            }

            if (url.indexOf("?") >= 0) {
                if (value == undefined) {
                    url = url + "&" + key
                } else {
                    url = url + "&" + key + "=" + value
                }
            } else {
                if (value == undefined) {
                    url = url + "?" + key
                } else {
                    url = url + "?" + key + "=" + value
                }
            }

            return url
        }

        String.prototype.name = function () {
            let ss = new Array()
            ss = this.split("|")
            if (ss.length < 2) return ""
            return ss[1]
        }

        String.prototype.code = function () {
            let ss = new Array()
            ss = this.split("|")
            return ss[0]
        }

        // 判断值是否为空
        String.prototype.isEmpty = function () {
            let value = this
            return value === null || typeof value == "undefined" || !value.trim()
        }

        //判断是否有值
        String.prototype.hasValue = function () {
            return this && this.length > 0
        }

        String.prototype.trim = function () {
            return this.replace(/(^\s*)|(\s*$)/g, "")
        }

        String.prototype.replaceAll = function (s1, s2) {
            return this.replace(new RegExp(s1, "gm"), s2)
        }

        function isEmpty(value) {
            return (
                (typeof value == "string" && !value.trim()) ||
                typeof value == "undefined" ||
                value === null
            )
        }
    },

    initWeb() {
        if (website.start == undefined) {
            website.start = {url: "AppEAD/Login/token"}
        }
    },


    hasLogin() {
        let status = this.store.get("web", {})[LoginStatus];
        return status == true
    },

    loginOK(flag) {
        if (flag == undefined) {
            flag = true
        }

        let web = this.store.get("web", {});
        if (flag) {
            web[LoginStatus] = true;
            window.first = true
        } else {
            web[LoginStatus] = false;
            delete web.menu;//清空菜单
        }

        this.store.set("web", web);
    },

    goMain(vue) {
        vue.$router
            .push({
                path: "/"
            })
            .catch(() => {
            })
    },

    replace(s1, s2) {
        return this.replace(new RegExp(s1, "gm"), s2)
    },

    hasFunction(funcName) {
        try {
            if (typeof eval(funcName) == "function") {
                return true
            }
        } catch (e) {
        }
        return false
    },

    getParam(input) {
        if (
            input &&
            input != "" &&
            input.indexOf("{") > -1 &&
            input.indexOf("}") > -1
        ) {
            let arr = input.split(/[{}]/)
            let resultArr = new Array()
            if (arr.length > 0) {
                $.each(arr, function (i, item) {
                    if (item != "" && input.indexOf("{" + item + "}") > -1) {
                        resultArr.push(item)
                    }
                })
            }
            return resultArr
        }
        return null
    },

    now() {
        return new Date()
    },

    getLang() {
        if (!website.lang) {
            return "zh"
        }

        if (!website.lang.enable) {
            return "zh"
        }

        let lang = this.store.get("lang", "zh")

        return lang
    },

    enableLang() {
        if (!website.lang || !website.lang.enable) {
            return false
        }

        return true
    },

    getHomeName() {
        if (this.enableLang) {
            return window.$vm.$t("ead.page.main.home")
        } else {
            return website.home.name
        }
    },

    deepClone(source) {
        if (!source && typeof source !== "object") {
            throw new Error("error arguments", "deepClone")
        }
        const targetObj = source.constructor === Array ? [] : {}
        Object.keys(source).forEach(keys => {
            if (source[keys] && typeof source[keys] === "object") {
                targetObj[keys] = deepClone(source[keys])
            } else {
                targetObj[keys] = source[keys]
            }
        })
        return targetObj
    },

    byteLength(str) {
        // returns the byte length of an utf8 string
        let s = str.length
        for (let i = str.length - 1; i >= 0; i--) {
            const code = str.charCodeAt(i)
            if (code > 0x7f && code <= 0x7ff) s++
            else if (code > 0x7ff && code <= 0xffff) s += 2
            if (code >= 0xdc00 && code <= 0xdfff) i--
        }
        return s
    },

    isNumberStr(str) {
        return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
    },

    debounce(func, wait, immediate) {
        let timeout, args, context, timestamp, result

        const later = function () {
            // 据上一次触发时间间隔
            const last = +new Date() - timestamp

            // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
            if (last < wait && last > 0) {
                timeout = setTimeout(later, wait - last)
            } else {
                timeout = null
                // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
                if (!immediate) {
                    result = func.apply(context, args)
                    if (!timeout) context = args = null
                }
            }
        }

        return function (...args) {
            context = this
            timestamp = +new Date()
            const callNow = immediate && !timeout
            // 如果延时不存在，重新设定延时
            if (!timeout) timeout = setTimeout(later, wait)
            if (callNow) {
                result = func.apply(context, args)
                context = args = null
            }

            return result
        }
    },

    rowEditEnterTab(obj, options) {
        options = this.extend(options)
        setTimeout(() => {
            this.rowEditEnterTabExecute(obj)
        }, 200)
    },

    rowEditEnterTabExecute(obj) {
        let el = obj.$el
        let vnode = obj.$vnode

        let inputs = el.querySelectorAll("input")

        for (let i = 0; i < inputs.length; i++) {
            let readonly = inputs[i].getAttribute("readonly")
            let hasClass = $(inputs[i]).hasClass("el-select__input")
            if (hasClass) {
                continue
            }

            if (readonly == null) {
                window.$eciFocusInput = inputs[i]
                break
            }
        }

        let array = []

        for (let i = 0; i < inputs.length; i++) {
            let readonly = inputs[i].getAttribute("readonly")
            if (readonly == "readonly") {
                continue
            }

            array.push(inputs[i])
        }

        inputs = array

        if (inputs.length > 0) {
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].setAttribute("focusIndex", i)
                inputs[i].addEventListener("keyup", ev => {
                    if (ev.keyCode === 13) {
                        if ($(ev.srcElement).parents(".el-select").length == 1) {
                            $(".el-select-dropdown").hide()
                        }

                        if ($(ev.srcElement).parents(".el-date-editor").length == 1) {
                            $(".el-picker-panel").hide()
                        }

                        let targetTo = ev.srcElement.getAttribute("focusTo")
                        if (targetTo) {
                            this.$refs[targetTo].$el.focus()
                        } else {
                            let focusIndex = ev.srcElement.getAttribute("focusIndex")
                            focusIndex = parseInt(focusIndex)

                            focusIndex++

                            if (focusIndex >= inputs.length) {
                                focusIndex = 0
                            }

                            inputs[focusIndex].focus()
                        }
                    }
                })
            }
        }
    },

    mergeJSON(main, minor) {
        for (let key in minor) {
            if (main[key] === undefined) {
                // 不冲突的，直接赋值
                main[key] = minor[key]
                continue
            }
            // 冲突了，如果是Object，看看有么有不冲突的属性
            // 不是Object 则以（minor）为准为主，
            if (this.isJSON(main[key]) || this.isArray(main[key])) {
                // arguments.callee 递归调用，并且与函数名解耦
                this.mergeJSON(main[key], minor[key])
            } else {
                main[key] = minor[key]
            }
        }
    },

    isJSON(target) {
        return typeof target == "object" && target.constructor == Object
    },

    isArray(o) {
        return Object.prototype.toString.call(o) == "[object Array]"
    },

    // getDataHelp(type, options) {
    //     if (options == undefined) {
    //         options = {}
    //     }
    //
    //     let me = options
    //     me.dataType = type
    //     let request = new EciRequest(website.apiServer.base + "/coreCombox/search", {
    //         wait: true
    //     })
    //     request.type = me.dataType
    //     request.EciType = me.dataType
    //     request.entity = {}
    //     request.conn = me.dataConn
    //
    //     if (request.conn == "none") {
    //         request.conn == ""
    //     }
    //
    //     me.datasource = []
    //
    //     request.execute(function (response) {
    //         if (response.table.length > 0 && response.table != null) {
    //             for (let index in response.table) {
    //                 me.datasource.push(response.table[index])
    //             }
    //             for (let index in me.datasource) {
    //                 if (me.datasource[index].value != "") {
    //                     me.datasource[index].textEx =
    //                         me.datasource[index].value + "-" + me.datasource[index].text
    //                 }
    //             }
    //         }
    //
    //         // if (me.dataCache) {
    //         //   me.setToCache()
    //         // }
    //     })
    //
    //     if (options && options.checkGroup) {
    //         return this.getCheckGroupData(me.datasource)
    //     } else {
    //         return me.datasource
    //     }
    // },

    getCheckGroupData(data) {
        let result = []
        data.forEach(item => {
            result.push({checked: false, label: item.text, value: item.value})
        })

        return result
    },

    // checkVersion() {
    //
    //     if (!website.checkVersion) {
    //         return;
    //     }
    //
    //     let request = new EciRequest(this.getApi("core/getSysVersion"))
    //
    //     request.onSuccess = function (res) {
    //
    //         let web = this.store.get("web", {})
    //         let clientVersion = web.webVersion
    //         if (!clientVersion) {
    //             clientVersion = "";
    //         }
    //
    //         let serverVersion = res.result.version;
    //         web.webVersion = serverVersion;
    //
    //         this.store.set("web", web);
    //
    //         if (clientVersion != serverVersion) {
    //             let url = window.location.href;
    //             url = url.addUrl("version", serverVersion)
    //             window.location.href = url;
    //         }
    //
    //         res.cancel = true;
    //     }
    //
    //
    //     request.onError = function (res) {
    //         res.cancel = true;
    //     }
    //
    //     request.execute();
    // },


    tryBuildCacheByAPI(response) {

        const apiToken = session.getApiToken()
        if (!apiToken) {
            return;
        }

        let selectVersion = response.runtime.selectVersion;
        let web = this.store.get("web", {})
        let clientVersion = web.selectVersion;

        if (clientVersion != selectVersion) {
            this.buildCache({api: true});
        }
    },

    buildCache() {

    },
    // buildCache(state) {
    //     state = state || {};
    //
    //     let now = this.now();
    //
    //     if (window.lastCheckSelectVersionTime && !state.api) {
    //         let second = (parseInt(now - window.lastCheckSelectVersionTime) / 1000)
    //
    //         if (second <= 5) {
    //             if (state.page) {
    //                 console.info("执行太频繁,本次操作被取消,5秒后尝试")
    //                 this.msg.warning("执行太频繁,本次操作被取消,5秒后尝试");
    //             }
    //             return;
    //         }
    //     }
    //
    //
    //     if (state.api) {
    //         console.info("本次检测由API调用触发");
    //     }
    //
    //     window.lastCheckSelectVersionTime = this.now();
    //
    //     let web = eciStorage.get("web", {})
    //     let clientVersion = web.selectVersion;
    //
    //     console.info("开始构建缓存信息,本地版本:" + clientVersion)
    //
    //     let request = new EciRequest(window.website.apiServer.base + "/core/GetEciSelectVersion", {noMsg: true});
    //     request.clientVersion = clientVersion;
    //
    //     request.execute().then((response) => {
    //         let res = response;
    //         let serverVersion = res.result.currentVersion;
    //         let changeList = res.result.changeList;
    //         changeList = changeList || []
    //
    //         console.info("服务器端查询完毕,最新版本:" + serverVersion + " 修改数量:" + changeList.length)
    //
    //         web.selectVersion = serverVersion;
    //
    //         eciStorage.set("web", web)
    //
    //         this.clearSelectData(changeList);
    //
    //         let index = 0;
    //         changeList.forEach(dataType => {
    //             index++;
    //             console.info(" " + index + ". 构建缓存:" + dataType)
    //
    //             let request = new EciRequest(window.website.apiServer.base + "/coreCombox/search?type=" + dataType)
    //
    //             request.type = dataType
    //             request.EciType = dataType
    //             request.entity = {}
    //             request.index = index;
    //
    //             let datasource = [];
    //
    //             request.execute().then((response) => {
    //                 if (response.table.length > 0 && response.table != null) {
    //                     for (let index in response.table) {
    //                         datasource.push(response.table[index])
    //                     }
    //                 }
    //
    //                 this.setSelectToCache(dataType, datasource)
    //
    //                 console.info(" " + request.index + ". 构建缓存:" + dataType + " completed 总记录数:" + datasource.length)
    //
    //             }).catch((response) => {
    //                 console.info(" " + request.index + ". 构建缓存失败:" + response.message)
    //                 response.cancel = true;
    //             })
    //
    //         });
    //     })
    // },

    checkEciSelectVersion() {
        console.info("打开页面触发检测缓存版本");
        this.buildCache();
    },

    clearSelectData(list) {
        for (let index in list) {
            let queryType = list[index]
            this.clearSelectFromCache(queryType)
        }
    },
    //下拉框：清空版本低的缓存数据
    clearSelectFromCache(queryType) {
        let langList = website.lang.list

        if (!langList) {
            langList = [{code: "zh", name: "中文"}]
        }

        for (let key in langList) {
            let lang = langList[key]["code"]
            let cacheData = eciStorage.get("select_" + lang, {})

            if (cacheData[queryType]) {

                console.info("*清除下拉框缓存(" + lang + "):" + queryType)

                delete cacheData[queryType];

                eciStorage.set("select_" + lang, cacheData)
            }
        }
    },

    //下拉框：从缓存读取
    getSelectFromCache(queryType) {
        let lang = this.getLang()
        let selectKey = "select"
        if (lang) {
            selectKey = selectKey + "_" + lang
        }
        let datasource = []
        let cacheData = eciStorage.get(selectKey, {})

        if (cacheData[queryType]) {
            datasource = cacheData[queryType];
        }

        return datasource
    },
    //下拉框：设置缓存
    setSelectToCache(queryType, datasource) {
        let lang = this.getLang()
        let selectKey = "select"
        if (lang) {
            selectKey = selectKey + "_" + lang
        }
        let cacheData = eciStorage.get(selectKey, {})

        cacheData[queryType] = datasource;

        eciStorage.set(selectKey, cacheData)
    },

    getApi(api) {
        return window.website.apiServer.base + "/" + api
    },

    printWeb(url, options) {
        window.open(this.getApi("/core/printWeb?url="), "_blank", "top:0")
    },

    openWeb(url, options) {
        window.open(url, "openWeb", "top:0")
    },

    closeImport() {
        let instance = window.eciImportInstance
        instance.vm.$destroy() // 在时间结束后将当前实例卸载
        instance.vm.$el.parentNode.removeChild(instance.vm.$el) // 使用原生API将当前实例生成的DOM节点在真实的DOM树中删除
        window.eciImportInstance = null
    },

    sleep(time) {
        let timeStamp = new Date().getTime();
        let endTime = timeStamp + time;
        while (true) {
            if (new Date().getTime() > endTime) {
                return;
            }
        }
    },

    enterTab(el, flag, focusEl) {
        let inputs = el.querySelectorAll("input");

        inputs.forEach(input => {
            this.enterTabItem(input, focusEl)
        })

        //用来判断，如果是查询界面，就不用默认第一个focus
        if (flag) {
            this.firstFocus(el);
        }


    },

    enterTabItem(el, focusEl) {
        let input = $(el);

        let flag = input.hasClass("v-eci-input")

        if (flag) {
            return;
        }

        input.addClass("v-eci-input")

        input[0].addEventListener("keyup", (ev) => {
            if (ev.keyCode === 13) {
                this.enterInput(el, ev, focusEl);


            }
        });


    },

    enterInput(el, ev, focusEl) {
        let input = $(el);

        if (window.enterTab == false) {
            return;
        }

        let sourceType = document.activeElement.type;

        if (sourceType == "textarea") {
            let value = input.val();
            if (value) {
                value = value.replaceAll("\n", "")
            }

            if (!ev.ctrlKey && value != "") {
                return;
            }
        }


        if ($(ev.srcElement).parents(".el-select").length == 1) {
            $(".el-select__popper").hide();
        }

        if ($(ev.srcElement).parents(".el-date-editor").length == 1) {
            $(".el-picker-panel").hide();
        }

        let eciForm = input.parents(".tg_form")

        let all = eciForm.find(".v-eci-input")

        let index = all.index(input[0])

        let focusIndex = index;

        let count = 0;

        while (true) {
            count++;

            if (count >= 20) {
                break;
            }

            focusIndex++;

            if (focusIndex == all.length) {
                focusIndex = 0;
            }

            let next = eciForm.find(".v-eci-input").eq(focusIndex)

            let type = next[0].type

            let readonly = next.attr("readonly");
            let disabled = next.attr("disabled");

            if (next.parents(".el-select").length) {
                readonly = false
            }

            if (readonly) {
                continue;
            }

            if (disabled) {
                continue;
            }

            if (type == "radio" || type == "checkbox") {
                continue;
            }


            next.focus()

            if (focusEl && isFunction(focusEl)) {
                focusEl(next)
            }

            type = next[0].type;

            if (type == "textarea") {
                let value = next.val();
                if (value) {
                    value = value.replaceAll("\n", "")
                }

                if (value == "") {
                    next.val("");
                }


            }

            // next.css("border", "1px solid red");

            let activeElement = document.activeElement;
            if (activeElement == next[0]) {


                break;
            }
        }

    },


    enterSelect(el, ev) {
        let input = $(el);

        if (window.enterTab == false) {
            return;
        }

        let eciForm = input.parents(".tg_form")

        let all = eciForm.find(".v-eci-input")

        let index = all.index(input[0])

        let focusIndex = index;

        let count = 0;

        while (true) {
            count++;

            if (count >= 20) {
                break;
            }

            focusIndex++;

            if (focusIndex == all.length) {
                focusIndex = 0;
            }

            let next = eciForm.find(".v-eci-input").eq(focusIndex)

            let type = next[0].type

            let readonly = next.attr("readonly");
            let disabled = next.attr("disabled");

            if (next.parents(".el-select").length) {
                readonly = false
            }

            if (readonly) {
                continue;
            }

            if (disabled) {
                continue;
            }

            if (type == "radio" || type == "checkbox") {
                continue;
            }

            next.focus();

            type = next[0].type;

            if (type == "textarea") {
                let value = next.val();
                if (value) {
                    value = value.replaceAll("\n", "")
                }

                if (value == "") {
                    next.val("");
                }


            }

            // next.css("border", "1px solid red");

            let activeElement = document.activeElement;
            if (activeElement == next[0]) {


                break;
            }
        }

    },

    firstFocus(el) {

        let inputs = $(el).find(".v-eci-input")

        for (let index = 0; index < inputs.length; index++) {

            let item = inputs[index];
            let input = $(item);

            let type = item.type;

            if (type != "text") {
                continue;
            }

            let readonly = input.attr("readonly");
            let disabled = input.attr("disabled");

            if (input.parents(".el-select").length) {
                readonly = false
            }

            if (readonly) {
                continue;
            }

            if (disabled) {
                continue;
            }

            if (input.parents(".el-date-editor").length == 1) {
                break;
            }


            if (input.parents(".el-select").length == 1) {
                //修复，编辑界面一打开，第一个框是下拉框的情况，会出现设置焦点，但是数据没有获取的到的情况。
                //如果当前select设置,需要延时设置焦点，如果不是用延时的情况，默认是10ms设置焦点
                let delay = input.parents(".el-select").attr("delay")
                let timeout = 10
                if (delay == "true") {
                    timeout = input.parents(".el-select").attr("timeout") || 500
                }
                setTimeout(() => {
                    input.focus();
                }, timeout);

                setTimeout(() => {
                    $(".el-select__popper").hide();
                }, timeout);

                break;
            } else {
                //input的框设置焦点
                setTimeout(() => {
                    input.focus();
                }, 10);

                break;
            }


            break;
        }
    },

    getRequestToken() {
        let id = this.guid16();
        let key = this.rsa(id);
        let result = id + key;
        return result;
    },


    hashCode(strKey) {
        let hash = 0;
        if (!this.isNull(strKey)) {
            for (let i = 0; i < strKey.length; i++) {
                hash = hash * 31 + strKey.charCodeAt(i);
                hash = this.intValue(hash);
            }
        }
        return hash;
    },


    isNull(str) {
        return str == null || str.value == "";
    },

    intValue(num) {
        let MAX_VALUE = 0x7fffffff;
        let MIN_VALUE = -0x80000000;
        if (num > MAX_VALUE || num < MIN_VALUE) {
            return num &= 0xFFFFFFFF;
        }
        return num;
    },

    base64encode(data) {
        return Base64.encode(data);
    },

    // getServerConfig() {
    //     let req = new EciRequest(this.getApi("core/GetServerConfig"))
    //     req.execute().then((response) => {
    //         if (response.success) {
    //             window.$serverConfig = response.result
    //         }
    //     })
    // },

    delayAction: (function () {
        let timer = 0
        return function (callback, ms) {
            clearTimeout(timer)
            timer = setTimeout(callback, ms)
        }
    })()


}


