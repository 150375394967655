export default class EciDebug {

  createPage(vue) {
    if (website.debug.page) {
      let tag = vue.getTag()
      if (tag != "EciAutoManage" && tag != "RightToolbar" && tag != "EciToolbar") {
        console.info((new Date()).format("yyyy-MM-dd HH:mm:ss f") + " 创建页面:" + tag);
      }
    }
  }

  destroyPage(vue) {
    if (website.debug.page) {
      let tag = vue.getTag()
      if (tag != "EciAutoManage" && tag != "RightToolbar" && tag != "EciToolbar") {
        console.info((new Date()).format("yyyy-MM-dd HH:mm:ss f") + " 销毁页面:" + tag);
      }
    }
  }
}
