/**
 * @description 其他路由配置。
 * @description 1.需要添加的路由，写在数组内。 2.此文件不可删除。tiangongCore框架已引入，删除会报错。
 */
import * as extension from '@/web/extension'

// 路由列表
const setRoutes = () => {
    if (extension.default?.router?.default?.setRoutes) {
        return extension.default?.router?.default?.setRoutes()
    }

    return []
}

const setRouteWhiteList = () => {
    if (extension.default?.router?.default?.setRouteWhiteList) {
        return extension.default?.router?.default?.setRouteWhiteList()
    }
    return []
}

export {setRoutes, setRouteWhiteList}
