export default {
	"cbiOrderItem":"跨境统一版进口订单表体（操作表）",
	"cbiOrderItem_kj5PartionKey":"kj5PartionKey",
	"cbiOrderItem_platNo":"平台编号",
	"cbiOrderItem_messageId":"消息ID",
	"cbiOrderItem_gmodel":"规格型号",
	"cbiOrderItem_autoId":"autoId",
	"cbiOrderItem_gNum":"商品序号",
	"cbiOrderItem_itemNo":"企业商品货号",
	"cbiOrderItem_itemName":"企业商品名称",
	"cbiOrderItem_itemDescribe":"企业商品描述信息",
	"cbiOrderItem_barCode":"条形码",
	"cbiOrderItem_unit":"单位",
	"cbiOrderItem_qty":"数量",
	"cbiOrderItem_price":"单价",
	"cbiOrderItem_totalPrice":"总价",
	"cbiOrderItem_currency":"币制",
	"cbiOrderItem_country":"原产国",
	"cbiOrderItem_note":"备注"
}