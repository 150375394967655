import * as extension from '@/web/extension'

/**
 *  @description: 系统菜单项是否显示
 *  @param { Object } item - 菜单数据
 *  @returns { boolean } 系统菜单项是否显示：true 显示  false  不显示
 */
const menuItemShow = (item) => {
    if (extension.default?.menu?.default?.menuItemShow) {
        return extension.default.menu.default.menuItemShow(item)
    }
    return true
}

/**
 * @description: 系统菜单显示数字
 *  @param { Object } item - - 菜单数据
 *  @returns { boolean | Number } - 返回值  数字 显示  false  不显示
 *     参数：item  系统菜单 对应数据
 * */
const menuItemNum = (item) => {
    if (extension.default?.menu?.default?.menuItemNum) {
        return extension.default.menu.default.menuItemNum(item)
    }
    return false
}


const beforeMenuClick = (item) => {
    if (extension.default?.menu?.default?.beforeMenuClick) {
        return extension.default.menu.default.beforeMenuClick(item)
    }
    return true
}
export {
    menuItemShow,
    menuItemNum,
    beforeMenuClick
}
