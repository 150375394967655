﻿import {t} from '@i18nHelper'

let pageUtils = {

    getExportColumn(child, exportColumns) {

        let me = this
        if (child.length > 0) {
            child.forEach(function (obj) {
                me.getExportColumn(obj, exportColumns)
            })
        } else {
            if (child.columnConfig != undefined && child.columnConfig.property != undefined) {
                if (!(child.$attrs['export'] == "false")) {
                    var label = child.columnConfig.label
                    var property = child.columnConfig.property

                    var column = {

                        "DataField": property,
                        "HeaderText": label
                    }
                    if (child.columnConfig.formatter) {
                        let name = child.columnConfig.formatter.name
                        name = name.replace("bound ", "")
                        if (name === "formatDateSS") {
                            column.IsDate = true
                            column.DataFormatString = website.dateFormat.dateSS
                        } else if (name === "formatDateDD") {
                            column.IsDate = true
                            column.DataFormatString = website.dateFormat.dateDD
                        }
                    }
                    exportColumns.push(column)
                }
            }

            if (child.$children.length > 0) {
                child.$children.forEach(function (obj) {
                    me.getExportColumn(obj, exportColumns)
                })
            }
        }
    },

    getConfigColumn(vue, child) {
        let me = this
        if (child.length > 0) {
            child.forEach(function (obj) {
                me.getConfigColumn(obj)
            })
        } else {
            if (child.columnConfig) {

                var config = child.$attrs['config'];
                var label = child.columnConfig.label

                if (config == "") {
                    config = true;
                }

                var label = child.columnConfig.label
                var property = child.columnConfig.property


                if (label == "" || label == null) {
                    return;
                }

                // child.columnConfig.label = "XXXXXXXXXXXXXX"
                // child.columnConfig.fixed = true


                if (property == "" || property == null || property == undefined) {
                    return;
                }

                if (config) {

                    var flag = vue.config[property];

                    vue.config[property] = true

                    var column = {
                        key: property,
                        prop: property,
                        label: label,
                        isExport: "1",
                        onlyExport: "0",
                        visible: vue.config[property]
                    }

                    if (!vue.config.columns) {
                        vue.config.columns = []
                    }

                    vue.config.columns.push(column)
                } else {
                    vue.config[property] = true
                }
            }

            if (child.$children.length > 0) {
                child.$children.forEach(function (obj) {
                    me.getConfigColumn(vue, obj)
                })
            }
        }
    },

    convertToUIEntity(area, data) {
        let entityConfig=area.data.entityConfig

        if (!entityConfig) {
            return data;
        }

        for (let item in entityConfig) {
            let config = entityConfig[item];
            let itemValue = data[item]

            if (config.isArray) {
                data[item] = [];
                if (itemValue && itemValue != "[object Object]") {
                    data[item] = itemValue.split(',');
                }
            }
        }
        return data;
    },

    convertToAPIEntity(page, data, isQuery) {
        if (page) {
            if (!page.data.entityConfig) {
                return data;
            }
        }

        for (let item in page.data.entityConfig) {
            let config = page.data.entityConfig[item];
            let itemValue = data[item]

            if (config.isArray) {
                if (!Array.isArray(itemValue)) {
                    //实体{0}设置了isArray，但是当前数据不是数组
                    throw new Exception(t('ead.page.eciPage.basePage.M00301'), item)                    //实体{0}设置了isArray，但是当前数据不是数组
                }

                data[item] = [];
                if (itemValue) {
                    data[item] = itemValue.join(',');
                }
            }
        }

        //查询条件，去空格，去null值
        if (isQuery) {
            for (let key in data) {
                //如果是数组就不转换
                if (data[key] instanceof Array) {
                    continue;
                }
                data[key] = String(data[key]).trim();

                //去除null值
                if (data[key] == "null" || data[key] == null) {
                    data[key] = "";
                }
            }
        }

        return data;
    },
}

export default pageUtils;
