const api = {
    save: "fileInfo/save",
    load: "fileInfo/selectOneById",
    search: "fileInfo/selectPageList",
    export: "fileInfo/export",
    enable: "fileInfo/enable",
    disable: "fileInfo/disable",
    delete: "fileInfo/deleteByIds",

    saveBizType: "fileBizType/save",
    loadBizType: "fileBizType/selectOneById",
    searchBizType: "fileBizType/selectPageList",
    deleteBizType: "fileBizType/deleteByIds",
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
