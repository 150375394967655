export default {
	"cbeWayBillItem":"跨境统一版出口清单总分单表体（操作表）",
	"cbeWayBillItem_kj5PartionKey":"kj5PartionKey",
	"cbeWayBillItem_platNo":"平台编号",
	"cbeWayBillItem_autoId":"表体主键",
	"cbeWayBillItem_messageId":"messageId",
	"cbeWayBillItem_gNum":"商品序号",
	"cbeWayBillItem_totalPackageNo":"总包数",
	"cbeWayBillItem_logisticsNo":"物流运单编号",
	"cbeWayBillItem_invtNo":"出口清单编号",
	"cbeWayBillItem_note":"备注"
}