// @ts-ignore
const ENV = import.meta.env;

// 获取值
function getValue(viteKey, windowKey, defaultKey): string {
    let _windowValue = ''
    try {
        windowKey = windowKey.split('.')

        // 目前层级最多 3 层
        // @ts-ignore
        if (windowKey.length == 1) {
            _windowValue = window.website[windowKey[0]]
        } else if (windowKey.length == 2) {
            _windowValue = window.website[windowKey[0]][windowKey[1]]
        } else if (windowKey.length == 3) {
            _windowValue = window.website[windowKey[0]][windowKey[1]][windowKey[2]]
        }
        // _windowValue = windowKey.length = 1 ? window.website[windowKey[0]][windowKey[1]] : window.website[windowKey[0]]

    } catch (e) {
    }

    return _windowValue || ENV[viteKey] || defaultKey
}

export {
    getValue
}
