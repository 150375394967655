import { createStore } from 'vuex'
import layout from './modules/layout'
import sysParameters from './modules/sysParameters'
// 开发助手
import TgAssistant from "@core/store/modules/TgAssistant";
// 扩展的存储
// import professiona from '@webApp/extension/store'
import {setStore} from "@webApp/extension/store";
// import AppCoreExtension from "@core/utils/appCoreExtension";

// let _professiona = AppCoreExtension.setName('store').getObject()

export default createStore({
    modules: {
        sysParameters,
        layout,
        TgAssistant,
        professiona:{
            namespaced: true,
            ...setStore()
        }
    }
})
