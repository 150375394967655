const api = {
    save: "eciNsTrace/save",
    load: "eciNsTrace/selectOneById",
    search: "eciNsTrace/selectPageList",
    delete: "eciNsTrace/deleteByIds"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
