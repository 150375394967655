export default {
	"gdsSkuOdsLz":"商品备案表",
	"gdsSkuOdsLz_autoId":"AUTO_ID",
	"gdsSkuOdsLz_entCode":"电商企业代码",
	"gdsSkuOdsLz_entName":"企业名称",
	"gdsSkuOdsLz_commiodityItemNo":"商品料号",
	"gdsSkuOdsLz_commiodityArticleNo":"商品货号",
	"gdsSkuOdsLz_commiodityNo":"商品项号",
	"gdsSkuOdsLz_commiodityCnName":"商品中文名称",
	"gdsSkuOdsLz_commiodityName":"商品名称",
	"gdsSkuOdsLz_commiodityCode":"商品编码",
	"gdsSkuOdsLz_commioditySpecification":"商品规格型号",
	"gdsSkuOdsLz_measurementUnit":"计量单位",
	"gdsSkuOdsLz_legalUnit":"法定单位",
	"gdsSkuOdsLz_legalQuantity":"法定数量",
	"gdsSkuOdsLz_secLegalUnit":"第二法定单位",
	"gdsSkuOdsLz_secLegalQuantity":"第二法定数量",
	"gdsSkuOdsLz_weight":"重量",
	"gdsSkuOdsLz_tax":"税率",
	"gdsSkuOdsLz_createUser":"创建人",
	"gdsSkuOdsLz_createDate":"创建时间",
	"gdsSkuOdsLz_updateUser":"最后修改人",
	"gdsSkuOdsLz_updateDate":"最后修改时间",
	"gdsSkuOdsLz_accountNo":"账册号",
	"gdsSkuOdsLz_country":"原产国"
}