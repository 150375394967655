import * as extension from '@/web/extension'

/**
 *  @description: 获取收藏
 *  @param { Object }  index
 *  @param { Object }  item
 *  @returns { Promise } flag 返回值 true 使用业务扩展  false 使用框架功能
 *  @returns { Promise } core 使用框架功能 使用框架逻辑  true 是   false 否
 *  @returns { Promise } data 接口返回数据
 */
const getMyCollection = (param) => {
  const {index,item} = param
  if(extension.default?.menuTag?.default.getMyCollection) {
    return new Promise(async (resolve)=>{
      let rtn = await extension.default?.menuTag?.default.getMyCollection(param)
      resolve(rtn)
    })
  }


  return new Promise( (resolve, reject) =>{
    resolve({
      flag: false,
      core: false,
      data: {}
    })
  })
}

/**
 *  @description: 新增收藏
 *  @param { Object }  param 此条数据
 *  @returns { Promise } flag 返回值 true 使用业务扩展  false 使用框架功能
 * @returns { Promise } core 使用框架功能 使用框架逻辑  true 是   false 否
 * @returns { Promise } data 接口返回数据
 */
const handleMyCollectionInsert =(param)=> {

  if(extension.default?.menuTag?.default?.handleMyCollectionInsert) {
    return new Promise(async (resolve)=>{
      let rtn = await extension.default?.menuTag?.default.handleMyCollectionInsert(param)
      resolve(rtn)
    })
  }


  return new Promise( (resolve, reject) =>{
    resolve({
      flag: false,
      core: false,
      data: {}
    })
  })
}

/**
 * @description: 删除收藏
 * @param { Object }  param 此条数据
 * @returns { Promise } flag 返回值 true 使用业务扩展  false 使用框架功能
 * @returns { Promise } core 使用框架功能 使用框架逻辑  true 是   false 否
 * @returns { Promise } data 接口返回数据
 */
const handleMyCollectionDelete = (param)=>{

  if(extension.default?.menuTag?.default?.handleMyCollectionDelete) {
    return new Promise(async (resolve)=>{
      let rtn = await extension.default?.menuTag?.default.handleMyCollectionDelete(param)
      resolve(rtn)
    })
  }

  return new Promise( (resolve, reject) =>{
    resolve({
      flag: false,
      core: false,
      data: {}
    })
  })
}

export {
  getMyCollection,
  handleMyCollectionInsert,
  handleMyCollectionDelete
}
