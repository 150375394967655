export default {
	"cbiOneCheck":"cbiOneCheck",
	"cbiOneCheck_guid":"guid",
	"cbiOneCheck_appType":"申报类型",
	"cbiOneCheck_appTime":"申报时间",
	"cbiOneCheck_appStatus":"申报状态",
	"cbiOneCheck_logisticsCode":"物流企业代码",
	"cbiOneCheck_logisticsName":"物流企业名称",
	"cbiOneCheck_logisticsNo":"物流运单编号",
	"cbiOneCheck_copNo":"企业内部编号",
	"cbiOneCheck_freight":"运费",
	"cbiOneCheck_insuredFee":"保费",
	"cbiOneCheck_currency":"币制",
	"cbiOneCheck_weight":"重量",
	"cbiOneCheck_packNo":"货品数量",
	"cbiOneCheck_goodsInfo":"商品信息",
	"cbiOneCheck_consingee":"收货人",
	"cbiOneCheck_consigneeAddress":"收货人地址",
	"cbiOneCheck_consigneeTelephone":"收货人电话",
	"cbiOneCheck_note":"备注",
	"cbiOneCheck_messageId":"messageId",
	"cbiOneCheck_inputDate":"创建日期",
	"cbiOneCheck_copCode":"传输企业代码",
	"cbiOneCheck_orderno":"订单编号",
	"cbiOneCheck_importDate":"导入日期",
	"cbiOneCheck_isOk":"isOk",
	"cbiOneCheck_wrongRs":"wrongRs",
	"cbiOneCheck_impId":"impId",
	"cbiOneCheck_ebpCode":"电商平台代码",
	"cbiOneCheck_ebpName":"电商平台名称",
	"cbiOneCheck_ebcCode":"电商企业代码",
	"cbiOneCheck_ebcName":"电商企业名称",
	"cbiOneCheck_payCode":"支付企业代码",
	"cbiOneCheck_payName":"支付企业名称",
	"cbiOneCheck_assureCode":"担保企业代码",
	"cbiOneCheck_assureName":"担保企业名称",
	"cbiOneCheck_areaCode":"区内仓储企业代码",
	"cbiOneCheck_areaName":"区内仓储企业名称",
	"cbiOneCheck_emsNo":"账册编号",
	"cbiOneCheck_itemNo":"账册备案料号",
	"cbiOneCheck_itemName":"itemName",
	"cbiOneCheck_gCode":"商品编码",
	"cbiOneCheck_gModel":"规格型号",
	"cbiOneCheck_emsFee":"emsFee",
	"cbiOneCheck_price":"货品成交价",
	"cbiOneCheck_totalPrice":"货品成交总价",
	"cbiOneCheck_consigneeIdNumber":"收货人证件号码",
	"cbiOneCheck_consigneeArea":"",
	"cbiOneCheck_realWeight":"实际重量",
	"cbiOneCheck_itemNum":"序号",
	"cbiOneCheck_payerIdNumber":"支付人证件号码",
	"cbiOneCheck_payerName":"支付人",
	"cbiOneCheck_payerTelephone":"支付人电话",
	"cbiOneCheck_buyerIdNumber":"订购人证件号码",
	"cbiOneCheck_buyerName":"订购人",
	"cbiOneCheck_buyerTelephone":"订购人电话",
	"cbiOneCheck_trafMode":"运输方式",
	"cbiOneCheck_trafNo":"运输工具编码",
	"cbiOneCheck_voyageNo":"航班航次号",
	"cbiOneCheck_billNo":"提运单号",
	"cbiOneCheck_customsCode":"申报海关代码",
	"cbiOneCheck_portCode":"进口口岸代码",
	"cbiOneCheck_iEDate":"进口日期",
	"cbiOneCheck_agentCode":"申报企业代码",
	"cbiOneCheck_agentName":"申报企业名称",
	"cbiOneCheck_wrapType":"包装种类代码",
	"cbiOneCheck_grossWeight":"grossWeight",
	"cbiOneCheck_discount":"折扣金额",
	"cbiOneCheck_payTransactionId":"支付流水号",
	"cbiOneCheck_buyerRegNo":"交易平台注册号",
	"cbiOneCheck_payTime":"支付时间"
}
