<template>
  <div>
    <div style="float:left; width: 100%" @dblclick="show" v-if="!custom">

      <div style="float: left;border:0px solid red" class="mr4">
        <slot name="cmd"></slot>
      </div>
      <el-tag icon="el-icon-view" :key="tag.id" v-for="tag in files" style="margin-right: 5px">
        <el-link @click.native.prevent="downloadAct(tag.id)">{{ tag.name }}</el-link>
        <el-button v-if="showIcon(tag.name)" style="cursor: pointer; width: 2px; padding: 2px 8px 2px 4px" type="text"
                   @click.native.prevent="showPreview(tag)">
          <el-icon style="margin-left: 10px">
            <View/>
          </el-icon>
        </el-button>
      </el-tag>

      <div style="float: right" class="mr4">
        <el-button type="primary" v-if="showCommand && cmd" icon="Plus"
                   size="small" @click="show()">
          {{ title }}
        </el-button>
      </div>
    </div>

    <Manage :readonly="readonly" ref="manage" :bizEntity="bizEntity" :page="page"></Manage>

    <ImageView ref="imageView" :page="page"></ImageView>
    <DocView ref="docView" :page="page"></DocView>
    <VideoView ref="videoView" :page="page"></VideoView>

  </div>
</template>

<script setup name="TgUploadPlus">
import {computed, getCurrentInstance, nextTick, onMounted, reactive, ref, watch} from 'vue';
import Manage from "./part/Manage.vue"
import ImageView from "./part/ImageView.vue";
import VideoView from "./part/VideoView.vue";
import apis from "@core/apis";
import {TgHttp} from "@coreHttp";
import {tmn, tm, t} from '@i18nHelper'
import {SettingCore} from '@core/setting';
import DocView from "./part/DocView.vue";

const {proxy} = getCurrentInstance()

import CommonPage from "@coreCommonPage";
import {object} from "underscore";

let {page} = CommonPage()
let conn = {}
let files = ref([]) //"操作手册.docx", "需求规格说明.docx"
let bindProp = ""
let isError = false
let inputVisible = false
let title = t("tg.page.components.tgUploadPlus.manage") //管理,
let pNo = ""
const fileType = ["img", "doc", "video"]
let databindingFiled = ""
defineExpose({initData, change})

const props = defineProps({
  modelValue: {
    type: String,
    default: () => '',
    required: true
  },
  //代码字段
  codeField: {
    type: String,
    default: '',
    required: true
  },
  bizEntity: {
    default: () => null,
  },
  bizNo: {
    type: String,
    default: () => '',
  },
  bizType: {
    type: String,
    required: true
  },
  advanced: {
    type: Boolean,
    default: false
  },
  custom: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  cmd: {
    type: Boolean,
    default: true
  },
})
const emits = defineEmits(['update:modelValue', "change"]);

const initTgUploadPlus = () => {
  // if (!props.modelValue === undefined) {
  //   tg.msg.error(t("tg.page.components.tgUploadPlus.M00168")) //"EciFile组件配置异常:没有设置 v-model 属性"
  // } else {
  //   let expression = $vnode.data.model.expression
  //   expression = expression.replace("entity.", "")
  //   bindProp = expression
  // }
  bindProp = props.codeField
  if (props.readonly) {
    title = t("tg.page.components.tgUploadPlus.view") //查看
  }
}
initTgUploadPlus()

onMounted(async () => {
  // fileType = []
  // if (SettingCore.VITE_TG_FILEINFO_FILEVIEWTYPE) {
  //   fileType = SettingCore.VITE_TG_FILEINFO_FILEVIEWTYPE.split(",")
  // }

  await getConfig()
  await nextTick(() => {
    // page.getContextPage().eciFiles.push(this)
  })
})

watch(() => props.entityConfig, (val, oldValue) => {

}, {deep: true})
watch(() => props.bizNo, (val, oldValue) => {
  if (val) {
    if (!props.custom) {
      initData()
    }
  }
}, {immediate: true})

const showCommand = computed(() => {
  if (props.readonly) {
    return false
  }

  if (props.advanced) {
    return true
  } else {
    return props.bizNo.length > 0
  }
})


function show(args) {
  if (!props.bizType) {
    tg.msg.error(tm('tg.page.title.M00325', '附件上传组件没有设置业务类型'))
    return
  }

  if (!props.advanced) {
    if (!props.bizNo) {
      tg.msg.error(tm('tg.page.title.M00326', '附件上传组件没有设置业务编号'))
      return
    }
  }

  if (props.bizEntity == undefined || props.bizEntity == null) {
    return tg.msg.error(t("tg.page.components.tgUploadPlus.M00169")) //EciFile组件配置异常:没有设置 bizEntity 属性
  }
  pNo = props.bizEntity[bindProp]
  let param = {bizNo: props.bizNo, bizType: props.bizType, pNo: pNo}

  if (args) {
    proxy.$refs.manage.show(param, args)
  } else {
    proxy.$refs.manage.show(param)
  }
}

function change() {
  emits("change")
}

function pageClear() {
  files.value.splice(0, files.value.length)
}

async function getConfig() {
  let request = new TgHttp()
  request.entity["bizType"] = props.bizType
  const res = await request.build(apis.fileInfo.getConfig).post()
  if (res.data == null) {
    tg.msg.error(t("tg.page.components.tgUploadPlus.M00170", [props.bizType])) //"文件类型:" + bizType + "未定义"
  } else {
    setTimeout(() => {
      setCatalog(res.data.catalog)
      proxy.$refs.manage && proxy.$refs.manage.setConfig(res.data)
    }, 500)
  }

}

function setCatalog(catalog) {
  proxy.$refs.manage && proxy.$refs.manage.setCatalog(catalog)
}

function initData() {
  files.value.splice(0, files.value.length)

  if (props.bizNo == "" && props.bizType == "") {
    return
  }

  let request = new TgHttp();
  request.entity.bizNo = props.bizNo
  request.entity.bizType = props.bizType
  request.entity.pNo = pNo
  request.paging = {pageNum: 1, pageSize: 10}
  request.build(apis.fileInfo.selectComponentPageList).post().then((response) => {
    files.value.splice(0, files.value.length)
    for (let i = 0; i < response.data.list.length; i++) {
      let row = {
        id: response.data.list[i]["guid"],
        name: response.data.list[i]["fileNameOri"],
      }
      files.value.push(row)
    }
  })
}

function downloadAct(id) {
  let req = new TgHttp()
  req.entity.guid = id
  req.build(apis.fileInfo.export, {file: true}).post()
}


function showIcon(name) {
  return fileType.includes(getFileType(name))
}

function showPreview(tag) {
  const type = getFileType(tag.name)
  nextTick(() => {
    switch (type) {
      case "img":
        proxy.$refs.imageView.show(tag)
        break
      case "doc":
        proxy.$refs.docView.show(tag)
        break
      case "video":
        proxy.$refs.videoView.show(tag)
        break
      case "audio":
        proxy.$refs.imageView.show(tag)
        break
      default:
        break
    }
  })
}

function getFileType(name) {
  const imgTypes = ["jpg", "jpeg", "png", "gif", "bmp"]
  const docTypes = ["docx", "xlsx", "ppt", "pptx", "pdf", "txt"]
  const videoTypes = ["mp4", "avi", "wmv", "flv", "rmvb", "mkv"]
  const audioTypes = ["mp3", "m4a", "wav", "flac", "wma", "aac", "ogg", "ape"]

  const extendName = name.substring(name.lastIndexOf(".") + 1).toLowerCase()
  if (extendName) {
    if (imgTypes.includes(extendName)) {
      return "img"
    } else if (docTypes.includes(extendName)) {
      return "doc"
    } else if (videoTypes.includes(extendName)) {
      return "video"
    } else if (audioTypes.includes(extendName)) {
      return "audio"
    } else {
      return "other"
    }
  } else {
    return "other"
  }
}
</script>
<style lang="less" scoped>
.mr4 {
  margin-right: 4px;
}

.eci-mulitchoose-tag-button {
  margin-left: 2px !important;
  margin-top: 2px;
  margin-bottom: 2px;
  height: 23px;
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
