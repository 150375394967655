<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { scrollTo } from './scroll-to';

const props = defineProps({
  total: {
    required: true,
    type: Number,
    default: 0
  },
  // 当前页数
  page: {
    type: Number,
    default: 1
  },
  // 每页的条数
  limit: {
    type: Number,
    default: 20
  },
  pageSizes: {
    type: Array,
    default() {
      return [10, 20, 30, 50];
    }
  },
  layout: {
    type: String,
    default: 'total, sizes, prev, pager, next, jumper'
  },
  background: {
    type: Boolean,
    default: true
  },
  autoScroll: {
    type: Boolean,
    default: true
  },
  hidden: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['update:page', 'update:limit', 'pagination']);

const currentPage = computed({
  get: () => props.page,
  set: value => {
    emit('update:page', value);
  }
});

const pageSize = computed({
  get() {
    return props.limit;
  },
  set(val) {
    emit('update:limit', val);
  }
});

function handleSizeChange(val) {
  emit('pagination', { page: currentPage, limit: val });
  if (props.autoScroll) {
    scrollTo(0, 800);
  }
}

function handleCurrentChange(val) {
  currentPage.value = val;
  emit('pagination', { page: val, limit: props.limit });
  if (props.autoScroll) {
    scrollTo(0, 800);
  }
}
</script>

<style scoped lang="less">
.pagination-container {
  /*background: #fff;*/
  padding: 0 16px;
  :deep(.el-select) {
    height: 28px !important;
    line-height: 28px !important;
  }
  :deep(.el-input__inner) {
    height: 28px !important;
    line-height: 28px !important;
  }
  :deep(.el-pagination .el-input) {
    height: 28px !important;
    line-height: 28px !important;
  }
  :deep(.el-pagination__jump) {
    .el-input {
      height: 28px !important;
      line-height: 28px !important;
    }
  }
}

.pagination-container.hidden {
  display: none;
}
</style>

<style>
div.el-pagination.is-background .el-pager li.active {
  background-color: var(--theme-color) !important;
}
</style>
