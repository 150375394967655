<!--
全局弹框：
-->
<template>
  <!-- 快捷工作区 -->
  <tg-quick-work />

  <!-- 首页其他扩展功能：（产品到期提醒） -->
<!--  <tg-home-exn v-if="isShowHomeExn" />-->
  <DynamicComponent v-if="isShowHomeExn" :url="SettingCore.VITE_TG_COMPONENT_HOME_PAGE_EXTEND"  />
</template>
<script setup>
import { computed } from "vue";
import TgQuickWork from "../TgQuickWork/index.vue";
// import TgHomeExn from "@webApp/slot/HomePageExn/HomePageExnIndex.vue";
import { SettingCore } from "@core/setting";
import DynamicComponent from "@/tiangongCore/components/web/DynamicComponent.vue";

const isShowHomeExn = computed(() => {
  // 首页其他扩展功能：（产品到期提醒） 配置
  return (
    (SettingCore.isShowHomeExn && SettingCore.isShowHomeExn === true) ||
    SettingCore.isShowHomeExn === "true"
  );
});
</script>
