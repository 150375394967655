<template>
    <tg-dialog ref="tgdialog" :visible.sync="dialogVisible" :title="title">

        <video-player :src="srcVideo"
                      controls
                      :loop="true"
                      :volume="0.6" style="height: 90vh;">
            <template v-slot="{ player, state }">
                <div class="custom-player-controls">
                    <button @click="state.playing ? player.pause() : player.play()">
                        {{ state.playing ? 'Pause' : 'Play' }}
                    </button>
                    <button @click="player.muted(!state.muted)">
                        {{ state.muted ? 'UnMute' : 'Mute' }}
                    </button>
                    <!-- more custom controls elements ... -->
                </div>
            </template>
        </video-player>

    </tg-dialog>

</template>

<script setup name="VideoView">
import apis from "@core/apis";
import {TgHttp} from "@coreHttp";
import {getCurrentInstance, ref} from "vue";
import {tm} from '@i18nHelper'
import {SettingCore} from "@core/setting";
import {VideoPlayer} from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

const {proxy} = getCurrentInstance()


let title = tm('tg.page.title.previewVideo', '预览视频')
let srcVideo = ref("")

let dialogVisible = false

let dp = ref(null)
let options = ref({
    autoplay: false, // 设置自动播放
    muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
    preload: "auto", // 预加载
    controls: true, // 显示播放的控件
})
defineExpose({show})

async function show(file) {
    srcVideo.value = ""
    title = tm('tg.page.title.preview+', '预览:{0}', [file.name])
    dialogVisible = true
    proxy.$refs.tgdialog.show()

    await initData(file.id)
}

async function initData(id) {
    if (id == null || id == undefined) {
        return tg.msg.error(tm('tg.page.msg.M00319', '获取文件信息失败：传参为空'))
    }
    let request = new TgHttp();
    request.entity.guid = id

    const response = await request.build(apis.fileInfo.getFileInfo).post()

    srcVideo.value = SettingCore.VITE_BASE_URL + "/static/" + response.data.fileUrl
    // srcVideo.value = SettingCore.VITE_BASE_URL + "/static/" + "XXX_XXX/63dbed3247019db0000372dbeab908ba8502/P02P2. 02.Electron技术架构.m3u8"
}


</script>

<style lang="less" scoped>
</style>
