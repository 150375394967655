import {i18n} from './setupI18n'
import {SettingCore} from "@core/setting";

const sysCode = SettingCore.VITE_TG_LANG_SYS_CODE

export type I18nGlobalTranslation = {
    (key: string): string
    (key: string, locale: string): string
    (key: string, locale: string, list: unknown[]): string
    (key: string, locale: string, named: Record<string, unknown>): string
    (key: string, list: unknown[]): string
    (key: string, named: Record<string, unknown>): string
}

export type I18nTranslationRestParameters = [string, any]

function getKey(namespace: string | undefined, key: string) {
    if (!namespace) {
        return key
    }
    if (key.startsWith(namespace)) {
        return key
    }
    return `${namespace}.${key}`
}

export function useI18n(namespace?: string): {
    t: I18nGlobalTranslation
} {
    let newNamespace = namespace
    if (namespace?.startsWith('tg')) {
        newNamespace = 'tg.' + namespace
    }

    if (namespace?.startsWith(sysCode.toLowerCase())) {
        newNamespace = sysCode.toLowerCase() + '.' + namespace
    }
    const normalFn = {
        t: (key: string) => {
            return getKey(newNamespace, key)
        }
    }
    if (!i18n) {
        return normalFn
    }

    const {t, ...methods} = i18n.global

    const tFn: I18nGlobalTranslation = (key: string, ...arg: any[]) => {
        if (!key) return ''
        if (!key.includes('.') && !namespace) return key
        //@ts-ignore
        return t(getKey(namespace, key), ...(arg as I18nTranslationRestParameters))
    }
    return {
        ...methods,
        t: tFn
    }
}


// Why write this function？
// Mainly to configure the vscode i18nn ally plugin. This function is only used for routing and menus. Please use useI18n for other places

// 为什么要编写此函数？
// 主要用于配合vscode i18nn ally插件。此功能仅用于路由和菜单。请在其他地方使用useI18n
export const t = (key: string) => key
