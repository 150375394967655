import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { emitRouterChange } from '@core/utils/wujie/wujieMain/busMain';
import { destroyApp } from '@core/utils/wujie/wujieMain';
import { SettingCore } from '@core/setting';
import { __POWERED_BY_WUJIE__ } from '@core/utils/wujie/wujieSub';
import { emitRouterPush } from '@core/utils/wujie/wujieSub/busSub';

/**
 * @typedef AppUrls
 * @type {object}
 * @property {string} development - 开发模式地址.
 * @property {string} testing - 测试环境地址.
 * @property {string} production - 生产环境地址.
 */
/**
 * 加载子应用相关配置
 * @typedef PropertiesConfig
 * @type {object}
 * @property {string} sysName - 子应用路由名称.
 * @property {string} appName - 子应用唯一标识.
 * @property {boolean} [isAlive = true] - 子应用是否保活模式.可选配置，默认为true
 * @property {boolean} [isHash = true] - 子应用是否hash路由 可选配置，默认为true.
 * @property {AppUrls} appUrl - 加载的子应用的url，根据环境变量配置，子应用需允许跨域。
 */
/** @type {PropertiesConfig[]} */
const wujieConfig = SettingCore.wujieConfig;

/**
 * 子应用路由相关hook
 * @returns {{routerPush: (params)=> void}}  子应用路由相关方法
 */
const useWujieRouter = () => {
    const router = useRouter();

    /** 子应用路由 */
    const wujieRoutes = computed(() =>
        wujieConfig.map(config => {
            const { sysName: name, appName } = config;
            return {
                path: `/${appName}/:page*`,
                name,
                // 如下写绝对路径报错
                component: () => import(`../../../web/appTiangong/wujie/WujieView.vue`),
                props: {
                    appName,
                    key: appName
                },
                children: []
            };
        })
    );

    /**
     * @description 用于跳转子应用和主应用路由的方法，使用场景：使用路由菜单数据跳转路由
     * @description 1.主应用环境，跳转的菜单主应用子应用都有；
     * @description 2.子应用环境，跳转路由需要通知主应用切换标签和路由地址
     *
     * @param {Object} menu - 跳转的路由菜单
     * @param {string} [menu.path] - 跳转的路由路径
     * @param {string} [menu.menuPath] - 跳转的路由路径
     * @param {string} [menu.options] - 跳转的路由路由配置，如果options配置appName,则表示跳转子应用路由方法
     * @param {string} [menu.appName] - 跳转的子路由名称，如果传了appName,options的appName可不传
     * @param {string} [menu.query] - 跳转的路由路由参数
     * @param {string} [menu.replace] - 替换路由
     * @param {string} [menu._blank] - 新窗口打开
     */
    const customRouterPush = ({
        options: menuOptions,
        appName,
        _blank,
        path,
        menuPath,
        query
    }) => {
        const options = JSON.parse(menuOptions || '{}');
        options.appName = appName || options.appName;
        const paramPath = path || menuPath || '';
        const reg = new RegExp(`^\/${options.appName}`);
        const withAppName = options.appName && reg.test(paramPath);
        const routePath = withAppName
            ? paramPath.replace(`/${options.appName}`, '')
            : paramPath;
        if (options.appName && !__POWERED_BY_WUJIE__) {
            // 主应用环境且需要跳转到子应用路由
            routerPush({
                appName: options.appName,
                path: routePath,
                query,
                _blank
            });
            return;
        }
        if (options.appName && __POWERED_BY_WUJIE__) {
            // 子应用环境跳转时，通知主应用切换标签和路径
            emitRouterPush({
                path: routePath,
                appName: options.appName,
                query
            });
            return;
        }
        _blank
            ? window.open(router.resolve({ path: routePath, query }).href)
            : router.push({ path: routePath, query });
    };

    /**
     * 跳转子应用内部指定页面路由的方法
     *
     * @param {Object} options - The function options.
     * @param {string} options.path - 子应用内部的路由路径.
     * @param {string} options.appName - 子应用名称，唯一标识.
     * @param {Object} [options.query] - 需要传给子应用的其它参数.
     * @param {Object} [options.reload] - 子应用是否刷新页面.
     * @return {void}
     */
    const routerPush = ({
        path,
        appName,
        query,
        replace = false,
        _blank = false,
        reload = false
    }) => {
        if (reload) {
            location.reload();
            return;
        }

        // 子应用内容，无论是否保活模式，只有未打开过的子应用content才是undefined
        const content = window.document.querySelector(
            `iframe[name=${appName}]`
        )?.contentWindow;
        const currentUrl = window.location.href;
        const appPath = `/${appName}${path}`;
        const currentConfig = wujieConfig.find(
            item => item.appName === appName
        );
        const alive = currentConfig?.isAlive ?? true;
        const isHash = currentConfig?.isHash ?? true;
        // 非保活模式但在子应用页面内 或 保活模式但第一次打开子应用 跳转路由方式
        if (
            content == null ||
            (!alive && !currentUrl.includes(appPath)) ||
            _blank
        ) {
            // 非保活模式且单例模式下，子应用无法唤起，先销毁
            !alive && destroyApp(appName);
            // 跳转到主应用B页面
            if (!_blank) {
                replace ? router.replace(appPath) : router.push(appPath);
            }
            const url = new URL(window.location.href);
            url.hash = _blank ? appPath : url.hash;
            url.search = `?${appName}=${encodeURIComponent(
                `${isHash ? '/#' : ''}${path}`
            )}`;
            const searchParams = url.searchParams;
            query &&
                Object.entries(query).forEach(([key, value]) => {
                    searchParams.set(key, encodeURIComponent(value));
                });
            // 手动的挂载url查询参数
            if (_blank) {
                window.open(url.href, '_blank');
                return;
            }
            window.history.replaceState(null, '', url.href);
            return;
        }
        // 保活模式但不在子应用页面，先从主应用跳至子应用路由
        if (alive && !currentUrl.includes(appPath)) {
            replace ? router.replace(appPath) : router.push(appPath);
        }
        // 通知子应用内部切换路由路径
        emitRouterChange({ path, appName, query });
    };

    const routerReplace = params => {
        routerPush({ ...params, replace: true });
    };

    return {
        routerPush,
        routerReplace,
        /** 子应用路由 */
        wujieRoutes: wujieRoutes.value,
        customRouterPush
    };
};

export default useWujieRouter;
