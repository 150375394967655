/**
 *  @description 提供给子应用环境使用的全局方法事件等
 */

import { onBeforeUnmount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { SettingCore } from '@core/setting';

/** 判断是否为wujie微前端子应用环境 */
export const __POWERED_BY_WUJIE__ = window.__POWERED_BY_WUJIE__;
/** 作为子应用时，此子应用名称 */
export const APP_NAME = SettingCore.subAppName;

/**
 * @description 子应用生命周期改造
 * @param {() => APP} mountApp 入口文件的挂载app事件，需将app实例返回
 * @returns {void}
 */
export const rebuildLifecycle = mountApp => {
    if (__POWERED_BY_WUJIE__) {
        let instance;
        window.__WUJIE_MOUNT = () => {
            instance = mountApp();
        };
        window.__WUJIE_UNMOUNT = () => {
            instance.unmount();
        };
        /*
        由于vite是异步加载，而无界可能采用fiber执行机制
        所以mount的调用时机无法确认，框架调用时可能vite
        还没有加载回来，这里采用主动调用防止用没有mount
        无界mount函数内置标记，不用担心重复mount
      */
        window.__WUJIE.mount();
        return;
    }
    mountApp();
};

/**
 * @description 监听主应用修改主题
 */
export const onUserThemeSettingChange = cb => {
    const store = useStore();
    const { userThemesetting } = store.state.sysParameters;
    window.$wujie?.bus.$on('themeSettingChange', params => {
        if (params != null) {
            store.commit('changeUserThemeSet', {
                ...userThemesetting,
                ...params
            });
            localStorage.setItem(
                'tg_wujieThemeSetting',
                JSON.stringify(params)
            );
            cb?.(params);
        }
    });
};

export const routerFilter = (vueRouter) => {
    if (__POWERED_BY_WUJIE__) {
        // 子应用菜单，不配置路由
        vueRouter.getRoutes().forEach(route => {
            if (
                route.meta.appName !== APP_NAME &&
                route.components?.default == null
            ) {
                vueRouter.removeRoute(route.name);
            }
        });
    }
};

const useWujiApp = () => {
    if (!__POWERED_BY_WUJIE__) {
        return;
    }

    /**
     * @description 子应用样式设置，body设置相对定位，否则element-plus的pooper会错位；
     *              如果子应用有fixed定位，内容会跳出子应用，需改成绝对定位或其它非视口定位
     */
    const setWujieStyle = () => {
        const head = document.querySelector('head');
        let styleEle = document.createElement('style');
        styleEle.textContent = `
            html {
                pointer-events: none;
            }
            body {
                position: relative;
                overflow: visible;
                pointer-events: all;
            }
            .el-popup-parent--hidden {
                overflow: visible;
            }
            .tg-full-body {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                z-index: 99;
            }
            #app .head-nav-box .head-nav.leftModel-head{
                position: absolute;
            }
        `;
        head.appendChild(styleEle);
    };

    const router = useRouter();
    const onRouterChange = () => {
        window.$wujie?.bus.$on('routeChange', ({ path, appName, query }) => {
            if (appName === APP_NAME) {
                router.replace({ path, query });
            }
        });
    };

    const initApp = () => {
        setWujieStyle();
        onUserThemeSettingChange();
        // rewriteWarn();
        onRouterChange();
    };

    initApp();

    onBeforeUnmount(() => {
        window.$wujie?.bus.$offAll();
    });
};

export default useWujiApp;
