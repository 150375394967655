<template>
    <div>
        <router-view v-slot="{ Component }" v-if="store.getters.tabIsClick">
            <!-- <transition transition name="el-fade-in"> -->
<!--            <keep-alive>-->
                <component :is="Component" :key="$route.name"/>
<!--            </keep-alive>-->
        </router-view>
        <router-view v-slot="{ Component }" v-if="!store.getters.tabIsClick">
            <component :is="Component" :key="$route.name"/>
        </router-view>
    </div>
</template>

<script setup>
import {useStore} from 'vuex'

const store = useStore()
</script>

<style scoped lang='less'>
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

</style>
