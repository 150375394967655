// import { useStore } from 'vuex'
import store from '@core/store'
import {reactive,toRefs,onMounted,computed} from 'vue'
import {SettingCore} from "@core/setting";

export default function (state) {
  // const store = useStore()

  const requestList = computed(()=>{
    return store.state.TgAssistant.requestList
  })

  // 获取请求 Sql baseUrl
  const getSqlUrl = ()=>{
    return SettingCore.VITE_BASE_URL + getSoaBaseUrl() + '/traceDetail'
  }

  // 此方法在 请求方法返回的函数中调用
  const getRequest = (obj ={})=>{
    // 排除查看 SQL 语句的请求
    if( !(obj && obj.config && (obj.config.url === '/traceDetail' || obj.config.url.endsWith('/traceDetail') )) ) {
      store.commit('tgRequestListUpdate',obj)
    }
  }

  // 清空
  const clearRequest = (()=>{
    store.commit('tgRequestListClear')
    // state.requestList = []
  })

  // 获取当前的对象
  const getCurrent = ()=>{
    let rtn = false
    try{
      rtn = requestList.value[state.current]
    }catch(e){}

    return rtn
  }

  // 当是微服务的时候，需要获取微服务的 baseUrl
  const getSoaBaseUrl = ()=>{
    let baseUrl = ''
    try{
      if(SettingCore.SOAframework && (SettingCore.SOAframework === true || SettingCore.SOAframework === 'true')) {
        let url = getCurrent().config.url
        baseUrl = url.split('/').filter(item=>item!=='')[0]
        baseUrl =  '/' + baseUrl
      }
    }catch(e){
      console.log('~~~~~getSoaBaseUrl~~~',e)
    }

    return baseUrl
  }

  // 接口响应时间
  const getResponseTime = ()=>{
    let time = 0
    try{
      time = getCurrent().config.headers['TgRequestEndTime'] - getCurrent().config.headers['TgRequestStartTime']
    }catch(e){}
    return {
      time,
      flag: time<1000
    }
  }

  return {
    requestList,
    getRequest,
    clearRequest,
    getSqlUrl,
    getCurrent,
    getResponseTime
  }
}
