const api = {
    save: "eciNsTask/save",
    load: "eciNsTask/selectOneById",
    search: "eciNsTask/selectPageList",
    delete: "eciNsTask/deleteByIds",
    auditPass: "eciNsTask/auditPass",
    auditReturn: "eciNsTask/auditReturn",
    auditCancel: "eciNsTask/auditCancel",
    applyHg: "eciNsTask/applyHg",
    applyHgZf: "eciNsTask/applyHgZf",

}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
