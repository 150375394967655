<!--
  form 的方法没有继承
-->
<template>
    <el-form v-loading="props.page.data.formLoading" ref="formRef" :rules="props.page.data.rules"
             :model="props.page.data.entity"
             @validate="props.page.formValidate"
             v-bind="$attrs" label-position="right" :label-width=props.page.data.formProps.labelWidth>
        <slot/>
    </el-form>
</template>
<script setup>
import {getCurrentInstance, nextTick, onMounted, ref} from "vue";
import tg from "@/tiangongCore/utils/common/tg";

const props = defineProps({
    page: {
        type: Object,
        default: null,
        required: true
    },
    //页面打开，第一个控件是否获取焦点
    isFirstFocus: {
        type: Boolean,
        default: true
    }
})
const instance = getCurrentInstance()
const formRef = ref()

async function validate(callback) {
    await formRef.value.validate(callback)
}

async function validateField(props, callback) {
    await formRef.value.validateField(props, callback)
}

async function resetFields(props) {
    await formRef.value.resetFields(props)
}

function scrollToField(prop) {
    formRef.value.scrollToField(prop)
}

function clearValidate(props) {
    formRef.value.clearValidate(props)
}

onMounted(() => {

    //回车
    // setAutoFocus();
})

//设置回车,记住当前鼠标的文本框的位置
let focusEl = null
let isFirst = props.isFirstFocus

//鼠标回车的时候调用，设置当前文本框的位置
function setfocusEl(el) {
    focusEl = el
}

/**
 * 表单回车
 */
async function setAutoFocus() {
    await nextTick()
    let el = instance?.proxy.$el
    if (el) {
        tg.enterTab(el, isFirst, setfocusEl)
        if (focusEl != null && focusEl.focus) {
            focusEl.focus()
        }
        isFirst = false
    }
}

defineExpose({validate, validateField, resetFields, scrollToField, clearValidate, setAutoFocus})

</script>
