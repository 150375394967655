<script setup>
import TgPagination from '@core/components/base/tgPagination/index.vue';
import {ref, watch, computed, reactive} from 'vue';
import {tmn, tm, t} from '@i18nHelper'

const tgChooseDialogRef = ref(null);
const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false
    },
    chooseData: {
        type: Object,
        default: () => ({list: [], total: 0})
    },
    searchVal: {
        type: String,
        default: ''
    },
    shouldDefault: {
        type: Boolean,
        default: true
    },
    /*
  * 扩展
  * */
    extend: {
        type: Object,
        default: () => {
        }
    }
});
const emits = defineEmits([
    'update:modelValue',
    'queryData',
    'setFormData',
]);

const state = reactive({
    form: {}
})

const chooseSearch = ref('');

const tableData = ref(props.chooseData);
watch(
    () => props.modelValue,
    val => {
        val ? tgChooseDialogRef.value?.show() : tgChooseDialogRef.value?.close();
    }
);

watch(
    () => props.chooseData,
    () => {
        tableData.value = props.chooseData;
    }
);

/**
 * @description 弹窗打开将外面输入的值带入查询条件
 */
const onDialogOpen = () => {
    chooseSearch.value = props.shouldDefault ? props.searchVal : '';
};

const queryData = pagination => {
    emits('queryData', chooseSearch.value, pagination);
};

// 查询
const search = () => {
    queryData({
        page: props.chooseData.pageNum,
        limit: props.chooseData.pageSize
    })
}

const resetSearchVal = () => {
    chooseSearch.value = '';
    // 查询
    search()

    // emits('setFormData');
    // emits('update:modelValue', false);
};

const rowDblclick = row => {
    // 设置行：会触发输入框查询弹框条件，同时会触发对应的弹框
    emits('setFormData', row);
    // 关闭弹框
    emits('update:modelValue', false);
};

const pagination = pagination => {
    queryData(pagination);
};
</script>

<template>
    <TgDialog
            :title="t('tg.page.common.dataChoose')"
            ref="tgChooseDialogRef"
            width="1000px"
            @close="emits('update:modelValue', false)"
            @open="onDialogOpen"
    >
        <ElForm>
            <ElRow>
                <ElCol :span="12">
                    <ElFormItem :label="extend && extend.where && extend.where.label ? extend.where.label :'查询条件'">
                        <!--            <ElInput v-model="chooseSearch" />-->
                        <tg-input v-model="chooseSearch"/>
                    </ElFormItem>
                </ElCol>
                <ElCol :span="12">
                    <ElFormItem label-width="20px">
                        <tg-button @click="search" icon="Search" name="查询"/>
                        <tg-button @click="resetSearchVal" icon="Brush" plain name="重置"/>
                    </ElFormItem>
                </ElCol>
            </ElRow>
        </ElForm>
        <el-table
                ref="tableRef"
                :data="tableData.list"
                class="base-params-table"
                border
                stripe
                @rowDblclick="rowDblclick"
        >
            <template v-if="extend && extend.table && extend.table.length>0">
                <el-table-column :prop="item.prop" :label="item.label" v-for="(item,index) in extend.table"/>
            </template>
            <template v-else>
                <el-table-column prop="code" label="代码" min-width="200"/>
                <el-table-column prop="name" label="名称" min-width="160"/>
            </template>
        </el-table>
        <!-- 分页工具条 -->
        <TgPagination
                :total="tableData.total"
                v-model:page="tableData.pageNum"
                :limit="tableData.pageSize"
                @pagination="pagination"
        />
    </TgDialog>
</template>

<style lang="less" scoped></style>
