export default {
	"cbeWayBill":"跨境统一版出口清单总分单表头（操作表）",
	"cbeWayBillItem":"跨境统一版出口清单总分单表体（操作表）",
	"cbeWayBill_kj5PartionKey":"kj5PartionKey",
	"cbeWayBill_platNo":"平台编号",
	"cbeWayBill_inputDate":"创建日期",
	"cbeWayBill_copCode":"传输企业代码",
	"cbeWayBill_syMark":"状态",
	"cbeWayBill_b2bWorkNo":"b2bWorkNo",
	"cbeWayBill_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeWayBill_appType":"企业报送类型",
	"cbeWayBill_appTime":"企业报送时间",
	"cbeWayBill_appStatus":"企业报送状态",
	"cbeWayBill_customsCode":"申报海关",
	"cbeWayBill_copNo":"企业唯一编号",
	"cbeWayBill_preNo":"电子口岸标志唯一编号",
	"cbeWayBill_agentCode":"申报单位代码",
	"cbeWayBill_agentName":"申报单位名称",
	"cbeWayBill_loctNo":"监管场所",
	"cbeWayBill_trafMode":"运输方式",
	"cbeWayBill_trafName":"运输工具名称",
	"cbeWayBill_voyageNo":"航次编号",
	"cbeWayBill_billNo":"提运单号",
	"cbeWayBill_domesticTrafNo":"运输工具编号",
	"cbeWayBill_grossWeight":"毛重",
	"cbeWayBill_logisticsCode":"物流企业代码",
	"cbeWayBill_logisticsName":"物流企业名称",
	"cbeWayBill_msgCount":"报文总数",
	"cbeWayBill_msgSeqNo":"报文序号",
	"cbeWayBill_note":"备注",
	"cbeWayBill_messageId":"消息ID"
}
