<!--
 * @Author: eci jerome.luo
 * @Date: 2023-03-02 13:56:33
 * @LastEditors: leason
 * @LastEditTime: 2023-08-10 10:37:03
 * @Description: 首页
-->
<template>
    <div class="wrapper" :class="[SidebarClass,menu_layout === '1'?'system-wrapper':'']"
         :style="{backgroundColor:bgColor}"
         v-if="!__POWERED_BY_WUJIE__">
        <div v-show="webLayout !== '2'"
             :style="{backgroundColor: webSideTypeBg}"
             class="menu-logo" :class="[menu_is_top, tabs_box_left, webLayout === '3' ? 'updown-model' : '']">
            <div class='logo' v-if="webLayout !== '3'">
<!--                <img :src="state.companyInfoObj.epLogoUrl" v-if="state.companyInfoObj.epLogoUrl" alt="">-->
<!--                <img v-else :src="getAssetsFile(`${getLogoUrl('login_img')}.png`)" alt="">-->
                <p v-if="!isCollapse && state.companyInfoObj.epSysName" :style="{color: text_color}"
                   :title="state.companyInfoObj.epSysName">{{ state.companyInfoObj.epSysName }}</p>
                <p v-if="!isCollapse && !state.companyInfoObj.epSysName" :style="{color: text_color}"
                   :title="getTitle()">{{ getTitle() }}</p>
            </div>
            <LeftMenuApp/>
        </div>
        <div class="container-class" :class="[content_class]">
            <head-nav :class="menu_is_top" :sign="tabs_box_left"/>
            <Tags class="tabs-box" :class="tabs_box_left" v-show="useTagsSwitch"/>
            <div
                    class="content-box"
                    style="display: flex;flex-direction: column;"
                    :style="{ top: useTagsSwitch ? getContentTopVal(webTabType) : 'var(--tg-head-height)' }">
                <!--        <div :style="{ minHeight: calcMinHeight + 'px' }">  :style="{ minHeight:   'calc(100vh - var(--tg-head-height) - 36px - 65px)' }"-->
                <div style="flex: 1;">
                    <router-view></router-view>
                </div>
                <Footer id="tg-page-footer"/>
            </div>
        </div>
    </div>
    <div
            class="wrapper tg-wujie-wrapper"
            :style="{ backgroundColor: bgColor }"
            v-else
    >
        <div class="container-class">
            <div class="wujie-app">
                <router-view></router-view>
                <Footer/>
            </div>
        </div>
    </div>
  <!--  全局弹框 -->
    <TgGlobalDialog/>
  <!--  数字助手 -->
<!--    <TgNumberHelper/>-->
  <!--  开发助手 -->
    <dev-assistant/>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, watch} from 'vue'
import HeadNav from '../header/HeadNav.vue'
import LeftMenuApp from '../menu/LeftMenuApp.vue'
import Footer from '../footer/index.vue'
import Tags from '../tags/index.vue'
// 全局弹框
import TgGlobalDialog from "@appTiangong/TgGlobalDialog/index.vue";

import {useStore} from 'vuex'
import {__POWERED_BY_WUJIE__} from '@core/utils/wujie/wujieSub';
import useFullPopper from '@core/utils/wujie/wujieSub/useFullPopper';
import TgNumberHelper from "@coreComponents/TgNumberHelper/TgNumberHelper.vue";
import DevAssistant from "@appTiangong/DevPlatform/assistant/devAssistant.vue";
import {SettingCore} from "@core/setting";

const store = useStore()
const {proxy} = getCurrentInstance()

const state = reactive({
    companyInfoObj: {
        epLogoUrl: '',
        epSysName: ''
    }
})
const menu_layout = computed(() => {
    return store.state.sysParameters.userInfo.MENU_LAYOUT
})

const getTitle = () => {
    return SettingCore.sysName ? SettingCore.sysName : t('tg.page.main.sysName')
};

// 控制logo文案显隐
const isCollapse = ref(false)
watch(() => store.state.layout.collapse, (newValue, oldValue) => {
    if (!newValue) {
        setTimeout(() => {
            isCollapse.value = false
        }, 150);
    } else {
        isCollapse.value = true
    }
})

const SidebarClass = computed(() => {
    if (store.state.sysParameters.userInfo.SYS_SKIN === '#fdfdfd') {
        return 'white'
    } else if (store.state.sysParameters.userInfo.SYS_SKIN === '#2F9688') {
        return 'sea'
    } else if (store.state.sysParameters.userInfo.SYS_SKIN === '#50314f') {
        return 'purple'
    } else if (store.state.sysParameters.userInfo.SYS_SKIN === '#23bb72') {
        return 'light'
    } else if (store.state.sysParameters.userInfo.SYS_SKIN === '#48a1af') {
        return 'info'
    } else if (store.state.sysParameters.userInfo.SYS_SKIN === '#435d78') {
        return 'hou'
    } else if (store.state.sysParameters.userInfo.SYS_SKIN === '#409EFF') {
        return 'default'
    } else {
        return 'concise'
    }
})

const bgColor = computed(() => {
    return store.state.sysParameters.userInfo.IS_GRAY
})

const tabs_box_left = computed(() => {
    if (store.state.layout.layoutMenu === '2') {
        return 'tabs-box-left-none'
    } else {
        if (store.state.layout.collapse) {
            return 'tabs-box-collapse'
        } else {
            return 'tabs-box-left'
        }
    }
})

const menu_is_top = computed(() => {
    if (store.state.sysParameters.userInfo.IS_FIXED_TOP === '1' ||
        store.state.sysParameters.userInfo.IS_FIXED_TOP === '') {
        return 'menu-on-top'
    } else {
        return 'menu-on-normal'
    }
})

const content_class = computed(() => {
    if (store.state.sysParameters.userThemesetting.webLayout === '2') {
        return 'content-box-app-none';
    } else {
        if (!store.state.layout.collapse) {
            return 'content-box-app'
        } else {
            return 'content-box-app-collapse'
        }
    }
})

// 获取vuex menu布局模式
const webLayout = computed(() => {
    return store.state.sysParameters.userThemesetting.webLayout
})

// 获取vuex 是否启用tags开关
const useTagsSwitch = computed(() => {
    return !!(store.state.sysParameters.userThemesetting.webIsTab === '1')
})

// 获取vuex tags显示风格
const webTabType = computed(() => {
    return store.state.sysParameters.userThemesetting.webTabType
})

// 获取vuex中bar背景色
const webSideTypeBg = computed(() => {
    if (store.state.sysParameters.userThemesetting.webSideType === '1') {
        return '#2e3549'
    } else {
        return '#fff'
    }
})

// 获取左侧背景色，配置字体色
const text_color = computed(() => {
   return store.state.sysParameters.userThemesetting.webSideType === '2' ? '#313e3e' : '#fff'
})

// 获取内容区域最小高度
const calcMinHeight = computed(() => {
    return window.innerHeight - 153
})
watch(() => store.state.sysParameters.companyInfo, (newValue, oldValue) => { // 获取用户头像，名称
    if (newValue) {
        state.companyInfoObj.epLogoUrl = newValue.epLogoUrl
        state.companyInfoObj.epSysName = newValue.epSysName ? newValue.epSysName : ''
    }
}, {
    immediate: true,
    deep: true
})
const getLogoUrl = computed(() => { // 获取logo
    return (val) => {
        const {webSideType} = store.state.sysParameters.userThemesetting
        if (webSideType === '1') {
            return `${val}_c`
        } else if (webSideType === '2') {
            return `${val}_w`
        } else {
            return `${val}_w`
        }
    }
})
const getContentTopVal = computed(() => { // 获取 content top值
    return (val) => {
        if (val === '3') {
            // return '92px'
            return 'calc(var(--tg-head-height ) + 42px)'
        } else if (val === '4') {
            // return '106px'
            return 'calc(var(--tg-head-height)  + 51px)'
        } else {
            // return '86px'
            return 'calc(var(--tg-head-height ) + 36px)'
        }
    }
})

watch(() => store.state.sysParameters.companyInfo, (newValue, oldValue) => { // 获取用户头像，名称
    if (newValue) {
        state.companyInfoObj.epLogoUrl = newValue.epLogoUrl
        state.companyInfoObj.epSysName = newValue.epSysName ? newValue.epSysName : ''
    }
}, {
    immediate: true,
    deep: true
})

const getAssetsFile = (url) => {
    return new URL(`../../../../tiangongCore/assets/images/${url}`, import.meta.url).href
}
useFullPopper();
</script>

<style scoped lang='less'>
.tabs-box {
  position: absolute;
  right: 0;
  min-width: 700px;
  //top: 50px;
  top: var(--tg-head-height);
  z-index: 5;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
}

.tabs-box-left {
  left: 0;
}

.tabs-box-left-none {
  left: 0;
}

.tabs-box-collapse {
  left: 0;
}

.content-box {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out;
  padding: 5px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.content-box-default {
  top: 50px;
  left: calc(5% + 170px);
  padding-right: 5%;
}

.content-box-crosswise {
  top: 90px;
  left: 0;
  padding-right: 10px;
}

.content-box-app {
  //width: calc(100% - 183px);
  width: calc(100% - var(--tg-menu-width));
  top: 0;
  left: 230px;
  background: #ECECEC;
  padding: 5px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.container-class {
  height: 100%;
  position: relative;
  //left: 190px;
  left: var(--tg-menu-width);
  // z-index: 8;
}

.tg-wujie-wrapper {
  .container-class {
    position: initial;
    padding: 0px;
    height: 100%;
    overflow-y: auto;
  }
}

.content-box-app-none {
  top: 0;
  left: 0;
  background: #ECECEC;
  padding: 15px;
  box-sizing: border-box;
}

.content-box-app-collapse {
  width: calc(100% - 52px);
  top: 0;
  //left: 68px;
  // 6px 为滚动条的宽度
  left: calc(var(--el-menu-icon-width) + var(--el-menu-base-level-padding) * 2 + 6px);
  //left: calc(var(--el-menu-icon-width) + var(--el-menu-base-level-padding) * 2);
  box-sizing: border-box;
  padding: 16px;
  background: #f0f2f5;
}

.content {
  width: auto;
  min-height: 100%;
  position: relative;
  padding: 14px;
  max-width: 100%;
  // overflow: hidden;
}

:deep(.el-row) {
  margin: 0;
}


//兼容主框架登录的样式
.content-box-main-frame-B {
  top: 0px;
  left: 0px;
}

.menu-logo {
  background-color: #4063e3 !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  //width: 190px;
  width: var(--tg-menu-width);
  transition: all 0.1s ease-in-out;
  z-index: 10;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);

  .logo {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 11px;
    box-sizing: border-box;
    transition: .3s all;
zhu
    img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 4px;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      transition: all 0.3s ease-in-out;
      width: 159px;
      overflow: hidden;
      text-overflow: ellipsis;
      //white-space: nowrap;
    }
  }
}

.tabs-box-collapse.menu-logo {
  //width: 68px;
  // 6px 为滚动条的宽度
  width: calc(var(--el-menu-icon-width) + var(--el-menu-base-level-padding) * 2 + 6px
    //var(--el-menu-icon-width) + var(--el-menu-base-level-padding) * 2
  );
}

.menu-logo.updown-model {
  //top: 50px !important;
  //height: calc(100% - 52px);
  top: var(--tg-head-height) !important;
  height: calc(100% - var(--tg-head-height));
  overflow: auto;
}

.global-tips {
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  background-color: #fce0c6;
  z-index: 99999;
  opacity: .8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  // box-shadow: 0 -10px 10px rgba(0, 0, 0, .06);
  .ct {
    display: flex;
    align-items: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin-left: 5px;
  }

  p, i {
    color: #f54e00;
  }

  i {
    font-size: 16px;

    &.el-icon-close {
      cursor: pointer;
      font-size: 13px;
    }
  }
}
</style>
