export default {
	"CbeOrder":"跨境统一版出口订单表头（接口表）",
	"CbeOrderItem":"跨境统一版出口订单表体（接口表）",
	"cbeOrder_kj5PartionKey":"kj5PartionKey",
	"cbeOrder_platNo":"平台编号",
	"cbeOrder_orderType":"订单类型",
	"cbeOrder_copCode":"传输企业代码",
	"cbeOrder_syMark":"状态",
	"cbeOrder_b2bWorkNo":"b2bWorkNo",
	"cbeOrder_messageId":"系统主键",
	"cbeOrder_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeOrder_appType":"企业报送类型",
	"cbeOrder_appTime":"企业报送时间",
	"cbeOrder_appStatus":"企业报送状态",
	"cbeOrder_orderNo":"订单编号",
	"cbeOrder_ebpCode":"电商平台代码",
	"cbeOrder_ebpName":"电商平台名称",
	"cbeOrder_ebcCode":"电商企业代码",
	"cbeOrder_ebcName":"电商企业名称",
	"cbeOrder_goodsValue":"商品金额",
	"cbeOrder_freight":"运杂费",
	"cbeOrder_currency":"币制",
	"cbeOrder_note":"备注",
	"cbeOrder_inputDate":"创建日期"
}
