export default {
	"cbeInventoryItem":"跨境统一版出口清单表体(操作表)",
	"cbeInventoryItem_kj5PartionKey":"kj5PartionKey",
	"cbeInventoryItem_platNo":"平台编号",
	"cbeInventoryItem_autoId":"autoId",
	"cbeInventoryItem_gNum":"序号",
	"cbeInventoryItem_itemNo":"企业商品货号",
	"cbeInventoryItem_itemRecordNo":"保税出口模式必填",
	"cbeInventoryItem_itemName":"企业商品名称",
	"cbeInventoryItem_gCode":"商品编码",
	"cbeInventoryItem_gName":"商品名称",
	"cbeInventoryItem_gModel":"规格型号",
	"cbeInventoryItem_barCode":"条形码",
	"cbeInventoryItem_country":"目的国",
	"cbeInventoryItem_currency":"币制",
	"cbeInventoryItem_qty":"数量",
	"cbeInventoryItem_qty1":"法定数量",
	"cbeInventoryItem_qty2":"第二数量",
	"cbeInventoryItem_unit":"计量单位",
	"cbeInventoryItem_unit1":"法定计量单位",
	"cbeInventoryItem_unit2":"第二计量单位",
	"cbeInventoryItem_price":"单价",
	"cbeInventoryItem_totalPrice":"总价",
	"cbeInventoryItem_note":"备注",
	"cbeInventoryItem_messageId":"messageId"
}