/*
 * @Author: eci jerome.luo
 * @Date: 2022-07-06 09:32:48
 * @LastEditors: eci jerome.luo@ecidh.com
 * @LastEditTime: 2023-02-17 13:50:31
 * @FilePath: /ecilingjing-web/src/language/global/global-map.js
 * @Description:
 */

import {SettingCore} from '@core/setting';
export default {
        // Logo: SettingCore.sysName,
        // Home: '主页',
        // sysrem_login: '系统登录', // 系统登录
        // close_current_t: '关闭当前', // 关闭当前
        // close_other_t: '关闭其他', // 关闭其他
        // close_all_t: '关闭所有', // 关闭所有
        // add_collection: '添加收藏', // 添加收藏
        // cancel_collect: '取消收藏', // 取消收藏
        // user_setting: '用户设置', // 用户设置
        // theme_set: '主题设置', // 主题设置
        // collection: '收藏', // 收藏
        // version: '版本信息', // 版本信息
        // help: '帮助', // 帮助
        // sidebar_set: '侧边栏设置', // 侧边栏设置
        // topBar_set: '顶栏设置', // 顶栏设置
        // layout_mode: '布局模式', // 布局模式
        // content_fill: '内容区域铺满', // 内容区域铺满
        // other_config: '其他配置', // 其他配置
        // fixed_logo: '固定Logo栏', // 固定Logo栏
        // sidebar_dis_color: '侧栏彩色图标', // 侧栏彩色图标
        // sidebar_unique_opened: '侧栏排他展开', // 侧栏排他展开
        // enable_tab: '启用标签页', // 启用标签页
        // tabs_display_style: '标签显示风格', // 标签显示风格
        // default_tab_show: '默认', // 标签显示风格 - 默认
        // dots_tab_show: '圆点', // 标签显示风格 - 圆点
        // card_tab_show: '卡片', // 标签显示风格 - 卡片
        // sys_tab_show: '系统', // 标签显示风格 - 卡片
        // work_bench: '工作台', // 工作台
        // change_password: '修改密码', // 修改密码
        // login_out: '退出系统', // 退出系统
        // ask_questions: '提问题', // 提问题
        // help_version: '帮助', // 昆盟通管理系统
        // the_toolkit: '工具箱', // 工具箱
        // change_firm_info: '修改企业信息', // 修改企业信息
        // language_switch: '语言切换', // 语言切换
        // language_zh: '中', // zh
        // language_en: '英', // en
        // user_information: "用户信息",
        // clear_all_cache: "清除缓存",
}
