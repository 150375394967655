const api = {
    save: "eciNoticeType/save",
    load: "eciNoticeType/selectOneById",
    search: "eciNoticeType/selectPageList",
    delete: "eciNoticeType/deleteByIds"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
