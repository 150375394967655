export default {
	"cbeReceipts":"跨境统一版出口收款单（操作表）",
	"cbeReceipts_kj5PartionKey":"kj5PartionKey",
	"cbeReceipts_platNo":"platNo",
	"cbeReceipts_b2bWorkNo":"b2bWorkNo",
	"cbeReceipts_syMark":"状态",
	"cbeReceipts_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeReceipts_appType":"申报类型",
	"cbeReceipts_appTime":"企业报送时间",
	"cbeReceipts_appStatus":"企业报送状态",
	"cbeReceipts_ebpName":"电商平台名称",
	"cbeReceipts_ebpCode":"电商平台代码",
	"cbeReceipts_ebcCode":"电商企业代码",
	"cbeReceipts_ebcName":"电商企业名称",
	"cbeReceipts_orderNo":"订单编号",
	"cbeReceipts_payCode":"支付企业代码",
	"cbeReceipts_payName":"支付企业名称",
	"cbeReceipts_payNo":"支付交易编号",
	"cbeReceipts_charge":"收款金额",
	"cbeReceipts_currency":"币制",
	"cbeReceipts_accountingDate":"到账时间",
	"cbeReceipts_note":"备注",
	"cbeReceipts_messageId":"消息ID",
	"cbeReceipts_copCode":"传输企业代码",
	"cbeReceipts_inputDate":"创建日期"
}
