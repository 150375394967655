const ENV = import.meta.env;
import {getValue} from "@core/utils/setting/setting";

const _GlobalCore = {
    // 系统名
    sysName: "天宫系统",
    showProtocol: 0, // 登录协议显隐 0隐藏 1展示
    Copyright: "版权所有：华东信息科技有限公司 Copyright @ 2020-2030",
    ICP: "",
    version: null,
    // 国际化连接方式：AUTO STATIC CONNECT
    // Internation: "CONNECT",
    // InternationType: "web_eciweb",
    //可选配置// url: 'http://192.168.103.166:5001/sysSettingLanguage/exportLanguage' 'public/tiangongPublic/language/language.json'
    // InternationUrl: "",
    // IndexedDB TODO
    // FIRST_LEVEL_CACHE: "FIRST_LEVEL_CACHE", // 一级缓存，表名称
    // localStorage 前缀
    localStoragePrefix: "tg_",
    // 首页其他扩展功能：（产品到期提醒）
    isShowHomeExn: true,
    // 国际化: header
    language: {
        enable: true,
        // 默认语种
        default: "zh-CN",
        // 是否显示
        baseNavigator: false,
        // 国际化：菜单
        menu: false,
    },
    systemSwitchEnable: false,
    // 是否显示修改密码
    password: true,
    /*
     *   数字魔方报表
     *       kj  pd6 跨境
     *       lj  灵境关务
     * */
    szmfForm: "kj",
    // 是否使用框架的收藏
    coreMyFavrite: true,
    // 设置默认跳转页面 （当系统不需要使用工作台时）
    homeUrl: "/desk/desk",
    /*
     * 后端是否采用微服务： 用于开发助手抓取接口sql
     *   目前灵境关务采用的是微服务模式
     * */
    SOAframework: false,
    // 登录
    login: {
        h4a: false, // 是否开启 h4a
    },
    loginH4a: false,
    // 加密  空不加密   AES 对称加密  RSA 非对称加密
    encrypt: "",
};

const _change = (key, viteKey) => {
    let rtn = {};
    if (ENV[viteKey] || ENV[viteKey] === "") {
        rtn[key] = ENV[viteKey];
    }
    return rtn;
};

const getWujieConfig = () => {
    const tgExcelConfig = JSON.parse(ENV.VITE_TG_WUJIE_EXCEL || "[]");
    const wujieConfig = JSON.parse(ENV.VITE_TG_WUJIE_CONFIG || "[]");
    return {
        /** 是否接入微前端框架 */
        useWujie: ENV.VITE_TG_USE_WUJIE || "0",

        /** 此系统作为子应用时的名称, 请保持名称唯一，不要和其它子应用的名称重复 */
        subAppName: ENV.VITE_TG_SUB_APP_NAME || "",

        /** 是否配置了excel应用 */
        hasWujieExcelApp: tgExcelConfig.length > 0,

        /** 主应用配置需要加载的子应用 */
        wujieConfig:
            ENV.VITE_TG_USE_WUJIE === "1" ? [...tgExcelConfig, ...wujieConfig] : [],
    };
};

const _ENV_SETTING = () => {
    let rtn = {

        // 登录按钮下方协议显隐 0隐藏 1展示
        ..._change("showProtocol", "VITE_TG_SHOW_PROTOCOL"),

        // 版权
        // ..._change("Copyright", "VITE_TG_COPY_RIGHT"),
        Copyright: getValue('VITE_TG_COPY_RIGHT', 'site.copyright'),
        //版本号
        version: getValue('VITE_TG_VERSION', 'site.version'),

        // 备案号
        ..._change("ICP", "VITE_TG_ICP"),

        // 系统切换
        ..._change("systemSwitchEnable", "VITE_TG_SYSTEM_SWITCH"),

        // 是否显示修改密码  true  false
        ..._change("password", "VITE_TG_PASSWORD"),

        // 是否使用框架的收藏  true  false
        ..._change("coreMyFavrite", "VITE_TG_MY_FAVRITE"),

        // 设置默认跳转页面 （当系统不需要使用工作台时）
        ..._change("homeUrl", "VITE_TG_HOME_URL"),

        // 数字魔方报表
        ..._change("szmfForm", "VITE_TG_SZMF_FORM"),

        /*
       * 后端是否采用微服务： 用于开发助手抓取接口sql
       *   目前灵境关务采用的是微服务模式
       * */
        ..._change("SOAframework", "VITE_TG_SOA_FRAMEWORK"),

        // 登录：是否开启 h4a
        ..._change("loginH4a", "VITE_TG_LOGIN_H4A"),

        // 加密
        ..._change("encrypt", "VITE_TG_ENCRYPT"),

        // excel导入id
        ..._change("tgExcelId", "VITE_TG_EXCEL_IMPORT_ID"),

        // 加密的key
        VITE_TG_AES_KEY: ENV.VITE_TG_AES_KEY || "1234567891011666",

        // 用户信息：是否显示
        VITE_TG_USER_INFO: ENV.VITE_TG_USER_INFO || false,

        // 手机号码登录
        VITE_TG_PHONE_LOGIN: ENV.VITE_TG_PHONE_LOGIN || false,

        // 系统标志
        VITE_TG_SYS_CODE: ENV.VITE_TG_SYS_CODE || "MAIN",

        tgCssVariable: JSON.parse(
            ENV.VITE_TG_CSS_VARIABLE ||
            '{"--tg-menu-width": 190, "--tg-head-height": 50}'
        ),

        // 国际化配置使用业务：灵境  1 框架   2 业务
        // VITE_TG_LANGUAGE_DEFAULT: ENV.VITE_TG_LANGUAGE_DEFAULT || "1",

        // 微应用配置
        ...getWujieConfig(),

        // 主题设置 1 显示   2 不显示
        VITE_TG_THEME: ENV.VITE_TG_THEME || "1",

        // 用户信息等下拉框是否显示： 1 显示   2 不显示
        VITE_TG_USER_INFO_ALL: ENV.VITE_TG_USER_INFO_ALL || "1",

        // 筛选配置： 1 显示   2 不显示
        VITE_TG_FILTER_SETTING: ENV.VITE_TG_FILTER_SETTING || "1",

        // 表格配置： 1 显示   2 不显示
        VITE_TG_TABLE_LIST_SETTING: ENV.VITE_TG_TABLE_LIST_SETTING || "1",

        // 表格配置： 1 原来的风格   2 显示新的风格
        VITE_TG_TABLE_SETTING_STYLE: getValue('VITE_TG_TABLE_SETTING_STYLE', 'componentSetting.table.settingStyle') || "2",
        // 筛选配置： 1 原来的风格   2 显示新的风格
        VITE_TG_FILTER_SETTING_STYLE: getValue('VITE_TG_FILTER_SETTING_STYLE', 'componentSetting.filter.settingStyle') || "2",

        // 筛选配置： 表单的labelPosition：默认left
        VITE_TG_FILTER_LABEL_POSITION: getValue('VITE_TG_FILTER_LABEL_POSITION', 'componentSetting.filter.labelPosition') || "left",

        // 筛选配置： 表单的labelWidth：150px
        VITE_TG_FILTER_LABEL_WIDTH: getValue('VITE_TG_FILTER_LABEL_WIDTH', 'componentSetting.filter.labelWidth') || "150px",

        // 快捷工作区（我的收藏）： 1 显示   2 不显示
        VITE_TG_SHORTCUT_WORKSPAGE: ENV.VITE_TG_SHORTCUT_WORKSPAGE || "1",

        // 是否需要权限：比如系统不需要登录 ： 1 是   2 否
        VITE_TG_AUTH: ENV.VITE_TG_AUTH || "1",

        // 登录名称默认显示： trueName:权限平台真实名称；loginName：权限平台用户名编码(默认值)
        VITE_TG_LOGIN_NAME: ENV.VITE_TG_LOGIN_NAME || 'loginName',

        // 单点登录地址，默认是:/tiangongCore/login/sso/ModelDemo.vue
        // VITE_TG_TOKEN_LOGIN_URL : ENV.VITE_TG_TOKEN_LOGIN_URL || '/tiangongCore/login/sso/ModelDemo.vue',
        VITE_TG_TOKEN_LOGIN_URL: ENV.VITE_TG_TOKEN_LOGIN_URL || '/appTiangong/login/sso/TgIndex',
        //登录界面,默认值：@appTiangong/login/customLogin/index.vue
        VITE_TG_LOGIN_URL: getValue('ENV.VITE_TG_LOGIN_URL', 'site.loginUrl') || '/appTiangong/login/customLogin/index',
        //登录以后：跳转的的界面
        VITE_TG_HOME_URL: ENV.VITE_TG_HOME_URL || '/desk/desk',
        //登录以后的，首页配置vue
        VITE_TG_HOME_INDEX: getValue('VITE_TG_HOME_INDEX', 'home.url') || '/home/homeIndex',
        // 系统名称
        // ..._change("sysName", "VITE_TG_SYS_NAME"),
        sysName: getValue('VITE_TG_SYS_NAME', 'appName'),
        VITE_BASE_URL: getValue('VITE_BASE_URL', 'apiServer.base'),
        VITE_H4A_URL: getValue('VITE_H4A_URL', 'h4aServer.server'),
        // 1 focusNext
        VITE_TG_ENTER: ENV.VITE_TG_ENTER || '1',
        VITE_TG_LOAD_USE_APP_TYPE: ENV.VITE_TG_LOAD_USE_APP_TYPE || '1',
        // 快捷工作区：快捷键
        VITE_TG_EVENT_KEY: getValue('VITE_TG_EVENT_KEY', 'quickWork.key'),
        // 快捷工作区：提示信息
        VITE_TG_EVENT_KEY_NAME: getValue('VITE_TG_EVENT_KEY_NAME', 'quickWork.name', ''),
        // 插槽: headerDropDownDown
        VITE_TG_COMPONENT_HEARDER_DROP_DOWN_DOWN: ENV.VITE_TG_COMPONENT_HEARDER_DROP_DOWN_DOWN,
        // 插槽 headerDropDownUp
        VITE_TG_COMPONENT_HEARDER_DROP_DOWN_UP: ENV.VITE_TG_COMPONENT_HEARDER_DROP_DOWN_UP,
        // 插槽 headerNotice
        VITE_TG_COMPONENT_HEARDER_NOTICE: ENV.VITE_TG_COMPONENT_HEARDER_NOTICE,
        // 插槽 homePageExtend
        VITE_TG_COMPONENT_HOME_PAGE_EXTEND: ENV.VITE_TG_COMPONENT_HOME_PAGE_EXTEND,
        // 插槽 quickWork
        VITE_TG_COMPONENT_QUICK_WORK: ENV.VITE_TG_COMPONENT_QUICK_WORK,

        //lang参数
        // VITE_LANG_ENABLE: getValue('VITE_LANG_ENABLE', 'lang.enable'),
        VITE_TG_LANG_MODE: getValue('VITE_TG_LANG_MODE', 'lang.mode'),
        VITE_TG_LANG_SWITCHINNAVBAR: getValue('VITE_TG_LANG_SWITCHINNAVBAR', 'lang.switchInNavbar'),
        VITE_TG_LANG_SWITCHINSETTING: getValue('VITE_TG_LANG_SWITCHINSETTING', 'lang.switchInSetting'),
        VITE_TG_LANG_REFRESHWHENSWITCH: getValue('VITE_TG_LANG_REFRESHWHENSWITCH', 'lang.refreshWhenSwitch'),
        VITE_TG_LANG_LIST: getValue('VITE_TG_LANG_LIST', 'lang.list'),
        VITE_TG_LANG_SERVER: getValue('VITE_TG_LANG_SERVER', 'lang.server'),
        VITE_TG_LANG_SYS_CODE: getValue('VITE_TG_LANG_SYS_CODE', 'lang.sysCode'),
        VITE_TG_LANG_PATH: getValue('VITE_TG_LANG_PATH', 'path'),

        //设置默认主题参数
        VITE_TG_WEB_SIDETYPE:getValue('VITE_TG_LANG_MODE', 'themeConfig.webSideType') || "2",

        //fileInfo参数
        VITE_TG_FILEINFO_IMAGEVIEWTYPE: getValue('VITE_TG_FILEINFO_IMAGEVIEWTYPE', 'fileInfo.imageViewType'),
        VITE_TG_FILEINFO_FILEVIEWTYPE: getValue('VITE_TG_FILEINFO_FILEVIEWTYPE', 'fileInfo.fileViewType'),


        // 路由基础路劲前缀
        VITE_TG_ROUTER_BASIC_PATH: ENV.VITE_TG_ROUTER_BASIC_PATH || '',

        // 退出系统
        VITE_TG_LOGIN_OUT: ENV.VITE_TG_LOGIN_OUT || 'true',
        // 数字助手
        VITE_TG_NUMBER_HELPER: getValue('VITE_TG_NUMBER_HELPER', 'numberHelper.url'),
        // 是否显示序号列
        VITE_TG_TABLE_LIST_PLUS_SHOW_INDEX: ENV.VITE_TG_TABLE_LIST_PLUS_SHOW_INDEX || 'true',
    };
    return rtn;
};
export const SettingCore = (function () {
    let rtn = Object.assign({}, _GlobalCore || {}, _ENV_SETTING());
    return rtn;
})();
