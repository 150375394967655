<template>
  <el-button
    v-bind="$attrs"
    :plain="plain"
    :circle="circle"
    :type="type"
    :link="link"
    :class="[link ? '' : isThemeColor, circle ? 'circle' : '']"
    :loading="loading"
    :disabled="disabled">
    <slot>
      <el-icon v-if="icon">
        <!-- 动态图标，父组件传入name -->
        <component :is="icon" />
      </el-icon>
      <span v-if="name">{{ name }}</span>
      <el-icon v-if="iconRight">
        <!-- 动态图标，父组件传入name -->
        <component :is="iconRight" />
      </el-icon>
    </slot>
  </el-button>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from "vue";
import { debounce } from "@core/utils/TgTools";
import { t } from "@i18nHelper";

const { proxy } = getCurrentInstance();

defineProps({
  name: {
    // 按钮名称
    type: String,
    default: () => {
      return t("action.search");
    },
  }, // 按钮名称
  plain: Boolean, // 是否plain 类型
  type: {
    // 按钮类型
    type: String,
    default: "primary",
  },
  icon: String, // 图标
  iconRight: String, // 右边图标
  link: Boolean, // 是否链接
  loading: {
    // loading
    type: Boolean,
    default: false,
  },
  circle: Boolean, // 开启圆形
  disabled: {
    // 禁用
    type: Boolean,
    default: null,
  },
});

const isThemeColor = computed(() => {
  // 如果是 ‘Primary’, 设置主题颜色
  if (proxy.plain && proxy.type === "primary") {
    return "p-color";
  }
  if (proxy.type === "primary" && !proxy.plain) {
    return "t-color";
  }
});
</script>

<style lang="less" scoped></style>
