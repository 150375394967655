<!-- 放大镜高级 -->
<template>
    <el-popover :width="width" :visible="data.showList" popper-class="eci-choose-ex-list-popover"
                placement="bottom-start" auto-placement>
        <template #reference>
            <div class="tg-choose-page">
                <div class="tg-choose" v-bind:class="{ error: data.isError }">
                    <ElInput ref="chooseRef" class="tg-choose__input" clearable
                             :suffix-icon="data.suffixIcon"
                             :model-value="data.row.displayText" v-bind="$attrs"
                             :disabled="disabled" :readonly="readonly"
                             :focustypes="nanoid()" :placeholder="props.placeholder"
                             @focus="focusAction" @blur="blurAction"
                             @input="changeInput" @keydown="keydown($event)"
                             @keyup="keyup($event)" @clear="clearAction">
                    </ElInput>

                </div>
            </div>
        </template>

        <tg-choose-list :width="width" :height="listHeight" :isSort="isSort" :obj="thisInstance" :isCache="isCache"
                        :dataType="dataType"
                        ref="list"></tg-choose-list>
    </el-popover>
</template>

<script setup name="TgChoosePlus">
import {getCurrentInstance, nextTick, onMounted, reactive, ref, watch} from 'vue';
import tg from '@coreTgPlus'
import {TgHttp} from "@coreHttp";
import apis from "@core/apis";
import TgChooseList from './TgChooseList.vue'
import {nanoid} from "nanoid";
import {isExists, loadData, validateByCache} from '@core/store/modules/dataHelperCache.js'
import {tmn, tm, t} from '@i18nHelper'

const {proxy} = getCurrentInstance()

const props = defineProps({
    /** 搜索表单数据 code */
    modelValue: {
        type: [String],
        default: () => '',
        required: true
    },
    dataType: {
        type: [String],
        default: () => ''
    },
    //页面
    page: {
        type: Object,
        default: null
    },
    //实体
    entity: {
        type: Object,
        default: null
    },
    //代码字段
    codeField: {
        type: String,
        default: ''
    },
    //名称字段
    nameField: {
        type: String,
        default: ''
    },
    addon: {
        default() {
            return ""
        },
    },
    //自定义放大镜
    target: {
        default() {
            return ""
        },
    },
    //清空的默认值
    clearValue: {
        default() {
            return ""
        },
    },
    disabled: {
        type: Boolean,
        default: false,
    },

    readonly: {
        type: Boolean,
        default: false,
    },
    //是否出现清空按钮
    canClear: {
        type: Boolean,
        default: false,
    },
    canChoose: {
        type: Boolean,
        default: false,
    },

    //只显示code
    codeOnly: {
        type: Boolean,
        default: false,
    },
    //是否校验放大镜，默认：true
    validate: {
        type: Boolean,
        default: true,
    },
    //code隐藏
    codeBehind: {
        type: Boolean,
        default() {
            return false
        },
    },
    placeholder: {
        type: String,
        default: ''
    },
    width: {
        type: Number,
        default: 600,
    },
    //show出来table的高度
    listHeight: {
        type: Number,
        default: 500,
    },
    isCache: {
        type: Boolean,
        default: true,
    },
    //获取焦点，自动全选
    focusSelect: {
        type: Boolean,
        default: false,
    },
    //查询以后数据排序，从左边开始
    isSort: {
        type: Boolean,
        default: false,
    }
});


let data = reactive({
    row: {displayText: "11", code: "aa"},
    codeValue: "",
    nameValue: "",
    focusValue: "",
    chooseCodeField: "",
    chooseNameField: "",
    conn: {},
    field: "",
    nameField: "",
    isError: false,
    showList: false,
    keydownValue: '',
    suffixIcon: ref("ArrowDown"),//图标
})

let thisInstance = tg.vue.instance()

const emits = defineEmits(['update:modelValue', 'update',
    'conn', 'change', 'choose', 'error']);

let initChoose = () => {
    if (!props.target && !props.dataType) {
        return tg.msg.error(t('tg.page.msg.M00094', props.codeField))
    }
    if (!data.field) {
        data.chooseCodeField = props.codeField
        data.chooseNameField = props.codeField + 'Name'
        if (props.codeField) {
            data.chooseCodeField = props.codeField
        }
        if (props.nameField) {
            data.chooseNameField = props.nameField
        }
        data.field = data.chooseCodeField
    } else {
        data.chooseCodeField = data.field
        data.chooseNameField = data.chooseCodeField + 'Name'
        //如果定义了nameField，就不需要设置Name
        if (props.nameField) {
            data.chooseNameField = props.nameField
        }
    }
}

initChoose()

onMounted(() => {
    //注册放大镜
    if (props.page?.regChoose) {
        if (thisInstance) {
            props.page.regChoose(thisInstance, data.chooseCodeField)
        }
    }
    // 绑定数据
    bindData()
})


function bindData() {
    if (props.entity) {
        if (Object.keys(props.entity).includes(data.chooseCodeField)) {
            data.codeValue = props.entity[data.chooseCodeField]
        } else {
            data.codeValue = ''
        }
        if (data.chooseNameField) {
            if (Object.keys(props.entity).includes(data.chooseNameField)) {
                data.nameValue = props.entity[data.chooseNameField]
            } else {
                data.nameValue = ''
            }
        }
    }
    if (data.codeValue == '' && data.nameValue != '') {
        clearAction()
    } else {
        setDisplayText()
    }
}

function setDisplayText() {
    //设置code隐藏，nameValue有值。
    if (props.codeBehind && data.nameValue != '') {
        data.row.displayText = data.nameValue
    } else {
        if (data.nameValue) {
            //update
            if (props.codeOnly) {
                data.row.displayText = data.codeValue
            } else {
                data.row.displayText = data.codeValue + '|' + data.nameValue
            }
        } else {
            data.row.displayText = data.codeValue
        }
    }
}

function show() {
    if (!props.canChoose) {
        if (props.readonly) {
            return
        }
        if (props.disabled) {
            return
        }
    }
    data.conn = {addon: '', message: ''}
    emits('conn', props.page, data.conn)

    if (!data.conn.addon) {
        data.conn.addon = props.addon
    }

    if (data.conn.message) {
        return tg.msg.error(data.conn.message)
    }

    data.showList = true

    return true
}

//输入框输入的的事件
const changeInput = (e) => {
    data.row.displayText = e;
}

const focusAction = () => {
    data.focusValue = data.row.displayText


    if (props.focusSelect == true) {
        proxy.$refs.chooseRef.select()
    }
}
const selectDataError = (row) => {
    //设置数据
    setFormValue(row)

    //自定义事件
    emits("error", row, {})
}

//更新实体和界面显示的数据
const setFormValue = (row = {}) => {

    props.entity[data.chooseCodeField] = row.code
    if (!props.codeOnly) {
        props.entity[data.chooseNameField] = row.name
    }

    data.row.code = row.code
    if (props.codeBehind) {
        data.row.displayText = row.name
    } else {
        if (row.name) {
            data.row.displayText = props.codeOnly
                ? row.code
                : row.code + "|" + row.name
        } else {
            data.row.displayText = row.code
        }
    }

    //设置codeValue,用于codeBehind的时候
    data.codeValue = row.code
}

const clearAction = () => {
    let row = {code: '', name: ""}

    if (props.clearValue) {
        let array = (props.clearValue + "|").split("|")
        row.code = array[0]
        row.name = array[1]
    }
    props.entity[data.chooseCodeField] = row.code
    if (!props.codeOnly) {
        props.entity[data.chooseNameField] = row.name
    }

    setFormValue(row)

    //点击清空按钮，重新查询
    search()

}

function removeColor() {
    data.isError = false

    if (props.page.data.entityCheck) {
        props.page.data.entityCheck[data.chooseCodeField] = ""
    }
}

function setColor() {
    data.isError = true
    if (props.page.data.entityCheck) {
        props.page.data.entityCheck[data.chooseCodeField] = t("tg.page.msg.M00097")  //填写错误
    }
}

watch(
    () => data.row.displayText,
    (val, oldValue) => {
        let code = ""
        let name = ""
        //去空格
        data.row.displayText = data.row.displayText.trim()
        if (props.codeBehind && val != "") {
            if (data.codeValue) {
                code = data.codeValue
                name = data.row.displayText
            } else {
                code = data.row.displayText
            }
        } else {
            if (data.row.displayText == undefined) {
                data.row.displayText = ""
            }

            let array = data.row.displayText.toString().split("|")
            if (array.length > 1) {
                code = array[0]
                name = array[1]
            } else {
                code = array[0]
                name = ""
            }
        }

        if (!props.codeOnly) {
            props.entity[data.chooseNameField] = name
        }

        props.entity[data.chooseCodeField] = code
    },
    {
        deep: true
    }
);

watch(() => props.entity, (val, oldValue) => {
    data.codeValue = val[data.chooseCodeField]
    data.nameValue = val[data.chooseNameField]
    if (data.codeValue == undefined) {
        data.codeValue = ""
    }

    if (data.codeValue == "") {
        data.nameValue = ""
    }

    if (data.nameValue == undefined) {
        data.nameValue = ""
    }
    setDisplayText()
}, {deep: true});

watch(() => data.showList, (val, oldValue) => {
    if (val) {
        nextTick(() => {
            let flag = show()
            if (flag) {
                data.suffixIcon = "ArrowUp"
                search()
            } else {
                data.suffixIcon = "ArrowDown"
                data.showList = false

            }
        })
    } else {
        data.suffixIcon = "ArrowDown"
    }

}, {deep: true})

//失去焦点，进行放大镜查询数据
async function blurAction() {
    //调用数据库校验数据
    if (data.row.displayText == '') {
        removeColor()
    }
    if (data.row.displayText != data.focusValue && data.row.displayText != '') {
        if (props.validate) {
            //添加放大镜校验
            setColor()
        }
        let searchData = data.row.displayText
        let array = searchData.split('|')
        if (array.length > 0) {
            searchData = array[0]
        }
        data.conn = {}

        emits('conn', props.page, data.conn)

        let res = await validateData(searchData)

        if (res && res.data?.length > 1) {
            //查询多条，显示弹框
            // show()
            // data.showList = true
        } else if (res && res.data?.length == 1) {
            //查询一条，设置值
            let row = res.data[0]
            selectData(row)
            removeColor()
        } else {
            let array = data.row.displayText.split('|')
            let code = ''
            let name = ''
            if (props.validate) {
                if (props.codeBehind) {
                    if (array.length > 1) {
                        code = array[0]
                        name = array[1]
                    } else {
                        code = array[0]
                        name = array[1]
                    }
                } else {
                    if (array.length > 1) {
                        code = array[0]
                        name = array[1]
                    } else {
                        code = array[0]
                        name = array[0]
                    }
                }
                let row = {code: code, name: name}
                if (!props.codeBehind && array.length == 1) {
                    row.name = ''
                }

                selectDataError(row)
                setColor()
            }

        }
        //编辑Form中，出发放大镜校验
        if (props.page.context.getRef("editForm")) {
            props.page.context.getRef("editForm").validateField(data.field, (errorMessage) => {

            })
        }
        //查询Form中，出发放大镜校验
        if (props.page.context.getRef("queryForm")) {
            props.page.context.getRef("queryForm").validateField(data.field)
        }
    } else {
        hideList()
    }
}

function search() {
    if (props.isCache) {
        proxy.$refs.list.setConn(data.row.displayText.code().trim())
        proxy.$refs.list.search({})
    } else {
        //延时查询
        tg.delayAction(() => {
            proxy.$refs.list.setConn(data.row.displayText.code().trim())
            proxy.$refs.list.search({})
        }, 300);
    }

}

function hideList() {
    data.showList = false
}

function keydown(event) {

    data.keydownValue = data.row.displayText

    if (proxy.$refs.list.getTableRows().length == 0) {
        return
    }

    let up = event.keyCode == 38
    let down = event.keyCode == 40

    let tab = event.keyCode == 9

    //空格显示列表
    if (event.keyCode == 32) {
        //显示表格弹框
        show()
    }

    //按键盘up和down
    if (up || down) {

        if (!data.showList) {
            search()
            data.showList = true
        }

        if (down) {
            proxy.$refs.list.moveNext()
        } else if (up) {
            proxy.$refs.list.movePre()
        }
    }

    if (tab) {
        data.showList = false
        return
    }
}

function keyup(event) {
    let up = event.keyCode == 38
    let down = event.keyCode == 40
    let ok = event.keyCode == 13
    let tab = event.keyCode == 9

    if (event.keyCode == 27) {
        return
    }

    if (tab) {
        data.showList = false
        return
    }

    if (ok) {
        if (data.showList) {
            if (proxy.$refs.list.getTableRows().length > 0) {
                proxy.$refs.list.enterSelect()
                proxy.$refs.chooseRef.focus()
            } else {
                if (props.validate) {
                    setColor()
                }
            }
        }

        data.showList = false

        return
    }

    if (up || down || ok) {
        return
    }

    show()

    if (data.row.displayText != data.keydownValue) {
        //执行查询
        search()
    }
}

function getCode() {
    let code = ''
    if (data.row.displayText == undefined) {
        data.row.displayText = ''
    }
    let array = data.row.displayText.split('|')
    if (array.length > 1) {
        code = array[0]
    } else {
        code = array[0]
    }
    return code
}

//选中数据
const selectData = (row) => {

    removeColor()

    setFormValue(row)

    //自定义事件
    emits("choose", row, props.entity, {})

    //关闭
    data.showList = false

}

//list表格中执行查询以后
function searched() {
    if (props.validate && proxy.$refs.list?.getTableRows()?.length == 0) {
        setColor()
    }

    if (data.row.displayText == "") {
        removeColor()
    }
}

//输入值，鼠标离开，校验数据
const validateData = async (searchData) => {
    if (props.isCache) {
        let config = proxy.$refs.list.getConfig();
        //如果不存在，先拉数据，再校验
        let result = await isExists(props.dataType)
        if (!result) {
            await loadData(props.dataType)
        }
        return await validateByCache(props.dataType, searchData, config.compareType)
    } else {
        let request = new TgHttp();
        request.entity["queryKey"] = props.dataType
        request.entity["msg"] = searchData
        let response = await request.build(apis.coreChoose.validate).post();
        if (response?.success) {
            return response
        }
    }

}


defineExpose({selectData, getCode, hideList, searched})

</script>

<style lang="less" scoped>

.tg-choose {
  width: 100%;
  display: flex;

  &__button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  :deep(.el-input__wrapper) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
<style lang="less">
.tg-choose-page {
  width: 100%;

  .error {
    .el-input__inner {
      color: red !important;
    }
  }
}

// 放大镜校验
.tg-choose ~ .el-form-item__error {
  right: 38px !important;
}

.el-popover.el-popper.eci-choose-ex-list-popover {
  padding: 0;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 35px;
}


</style>
