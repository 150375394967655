<template>
  <div class="h4aErrorMsg" v-if="errorFlag">
    <div>
      <el-empty :description="errorMsg" />
      <div class="">
        <tg-button link  icon="Refresh" name="刷新页面" @click="reload()" />
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted,reactive} from 'vue'
import { ElLoading } from 'element-plus'
import useH4aLogin from "@appTiangong/login/H4A/useH4aLoginHooks";
import login from '../customLogin/login'

const {
  errorFlag,
  errorMsg,
  h4aMain
} = useH4aLogin()

const {
  h4aLoginSuccess
} = login()

const reload = ()=>{
  location.reload();
}

onMounted(()=>{
  const loadingInstance = ElLoading.service({
    fullscreen: true,
    text: '加载中...'
  })
  h4aMain((res)=>{
    res ?  h4aLoginSuccess(res) : ''
    loadingInstance.close()
  })
})
</script>

<style lang="less" scoped>
.h4aErrorMsg {
  margin-top: 20vh;
  text-align: center;
}
</style>
