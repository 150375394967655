import type {App} from 'vue'
import type {I18n, I18nOptions} from 'vue-i18n'
import {createI18n} from 'vue-i18n'
import {localeSetting} from './localeSetting'
import axios from 'axios'
import {LocaleType} from './localeType'
import {SettingCore} from '@core/setting';
import TgLocalStorage from "@core/utils/TgLocalStorage";
import getLanguage from "@core/utils/TgLanguage";
import {setHtmlPageLang, setLoadLocalePool} from './helper'
import {useLocaleStore} from './locale'
import languagePack from '@core/language/language'


const {fallback, availableLocales} = localeSetting

export let i18n: ReturnType<typeof createI18n>

async function createI18nOptions(): Promise<I18nOptions> {
    //const locale = TgLocalStorage.get('lang') || 'zh_CN';

    const locale = useLocaleStore.getters.getLocale
    // const message = defaultLocal.default?.message ?? {}
    //const elementLocal = (await import(`element-plus/es/locale/lang/${elementKey}`)).default?.message

    const defaultLocal = await genLangMessage(locale)
    const defaultLocalMegre = {...defaultLocal, ...languagePack[locale]}
    const message = defaultLocalMegre ?? {}

    setHtmlPageLang(locale)
    setLoadLocalePool((loadLocalePool) => {
        loadLocalePool.push(locale)
    })
    TgLocalStorage.set('lang', locale)

    return {
        legacy: false,
        locale,
        fallbackLocale: fallback,
        messages: {
            [locale]: message
        },
        globalInjection: true,
        availableLocales: availableLocales,
        sync: true, //If you don’t want to inherit locale from global scope, you need to set sync of i18n component option to false.
        silentTranslationWarn: true, // true - warning off
        missingWarn: false,
        silentFallbackWarn: true
    }
}

// setup i18n instance with glob
export async function setupI18n(app: App<Element>) {
    const options = await createI18nOptions()
    i18n = createI18n(options) as I18n
    app.use(i18n)
}

//获取18n
export async function genLangMessage(prefix: LocaleType = 'zh_CN') {
    const mode = SettingCore.VITE_TG_LANG_MODE;
    if (mode === 'online') {
        return genLangMessageOnline(prefix)
    } else {
        return genLangMessageOffLine(prefix)
    }
}

//获取离线语言资源
async function genLangMessageOffLine(prefix: LocaleType = 'zh_CN') {
    let obj = {}
    //const sysCode = window.server.ui.server.sysCode
    const path = SettingCore.VITE_TG_LANG_PATH || ''

    let lang = ''
    const url = path + '/web/lang/langUI.json'

    let message = {}
    let errorMsg = ''
    try {
        const response = await axios.get(url)
        message = response.data.data
        switch (prefix) {
            case 'zh_CN':
                lang = 'zh'
                break
            default:
                lang = prefix
        }
        obj = message[lang]
    } catch (error: any) {
        if (error.response.status == 404) {
            errorMsg = 'UI离线语言包不存在'
        } else {
            errorMsg = '离线语言包加载异常' + error
        }
        console.log(errorMsg)
    }

    return obj
}

//获取在线语言资源
async function genLangMessageOnline(prefix = 'zh_CN') {
    let obj = {}
    const sysCode = SettingCore.VITE_TG_LANG_SYS_CODE
    const baseUrl = Array.isArray(SettingCore.VITE_TG_LANG_SERVER) ? SettingCore.VITE_TG_LANG_SERVER[0] : SettingCore.VITE_TG_LANG_SERVER
    const url = baseUrl + '/lang/get'

    let message = {}
    let errorMsg = ''
    let lang = ''

    try {
        //let request = new EciRequest()

        const response = await axios.post(url, {
            sysCode: sysCode,
            baseCode: 'TG'
        })
        message = response.data.result.data
        switch (prefix) {
            case 'zh_CN':
                lang = 'zh'
                break
            case 'vi_VN':
                lang = 'vi'
                break
            default:
                lang = prefix
        }
        obj = message[lang]
    } catch (error: any) {
        if (error.response.status == 404) {
            errorMsg = 'UI在线语言包不存在'
        } else {
            errorMsg = '在线语言包加载异常' + error
        }
        console.log(errorMsg)
    }

    return obj
}


const message = ((modules) => {
    let obj = {}
    for (const key in modules) {
        obj = {
            ...obj,
            ...modules[key].default
        }
    }
    return obj
})

async function createI18nOptionsOld() {
    let locale = TgLocalStorage.get('lang') || 'ch';
    locale = 'ch'
    let defaultLocal = import.meta.globEager('../../language/ch/**/*.js');
    const localEn = import.meta.globEager('../../language/en/**/*.js');
    // let defaultLocal = import.meta.globEager('@core/language/ch/**/*.js');
    // const localEn = import.meta.globEager('@core/language/en/**/*.js');

    let defaultObj = message(defaultLocal)
    try {
        let defaultLocalSelf = import.meta.globEager('../../../web/app/language/ch/**/*.js');
        let defaultLocalObj = message(defaultLocalSelf)
        defaultObj = Object.assign({}, defaultObj, defaultLocalObj)
    } catch (e) {
    }

    return {
        fallbackLocale: 'ch',
        globalInjection: true,
        legacy: false, // you must specify 'legacy: false' option
        // locale: 'en',
        locale: locale,
        messages: {
            // [locale]: message(defaultLocal)
            ch: defaultObj,
            en: message(localEn),
        },
        silentTranslationWarn: true // 关闭vue-i18n warnning提示
    };
}

export async function setupI18nOld(app) {
    const options = await createI18nOptionsOld();
    app.config.globalProperties.getLanguage = getLanguage
    i18n = createI18n(options);
    app.use(i18n);
}
