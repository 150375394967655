<template>
  <tg-dialog ref="tgdialog" :visible.sync="dialogVisible" :title="title">
    <img :src="imgUrl" style="display: block; max-width: 100%; margin: 0 auto;">
  </tg-dialog>

</template>

<script setup name="ImageView">
import apis from "@core/apis";
import {TgHttp} from "@coreHttp";
import {getCurrentInstance, ref} from "vue";
import {tm} from '@i18nHelper'
import {fileTypeFromFile} from "type-teller";

const {proxy} = getCurrentInstance()


let title = tm('tg.page.title.previewPic', '预览图片')
let imgUrl = ref("https://sso.ccorp.cn:8800/ssomanage/QR_IMG/HDOA.png")
let dialogVisible = false

defineExpose({show})

async function show(file) {
  imgUrl.value = ""
  title = tm('tg.page.title.preview+', '预览:{0}', [file.name])
  dialogVisible = true
  proxy.$refs.tgdialog.show()

  await initData(file.id)
}

async function initData(id) {
  if (id == null || id == undefined) {
    return tg.msg.error(tm('tg.page.msg.M00319', '获取图片信息失败：传参为空'))
  }
  let request = new TgHttp();
  request.entity.guid = id

  const response = await request.build(apis.fileInfo.getFileInfo).post()
  const type = fileTypeFromFile(response.data.fileName)
  let typeHeader = 'data:' + type.mime + ';base64,' // 定义base64 头部文件类型

  let converedBase64 = typeHeader + response.data.fileData;  // 拼接最终的base64
  imgUrl.value = converedBase64
}
</script>
