const api = {
    save: "CoreEciBaseData/save",
    load: "CoreEciBaseData/load",
    search: "CoreEciBaseData/search",
    delete: "CoreEciBaseData/delete"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
