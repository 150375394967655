import type {RequestOptions} from "@core/types/axios";

type Recordable<T = any> = Record<string, T>;
import {IPage} from "../../types/ecitrans";
import {TgHttpBase} from "./axios/TgHttpBase";
import {BusinessType} from "../../enums/httpEnum";

/**
 * 通用传输请求
 */
export class TgHttp {
    entity: Recordable = {};
    paging: IPage = {
        index: 0,
        size: 0,
    };
    // 操作类型
    businessType: string = BusinessType.OTHER;
    exportName = "";
    options = {
        file: false,
    };
    progress = false;
    api = "";
    dto: any;
    // 新版接口标志
    tgTime = Date.parse(new Date().toString());

    [idx: string]: any;

    // get 请求参数
    queryString = {}

    constructor() {
        // const me = this;
    }

    prepareData() {
        let requestData: any = {};

        const exclude = ["vue", "option", 'queryString'];

        requestData = {entity: this.entity, paging: this.paging};
        for (const x in this) {
            if (typeof this[x] != "function" && !exclude.includes(x)) {
                requestData[x] = this[x];
            }
        }
        let reqData = requestData;

        if (this.dto) {
            reqData = this.dto;
        }
        return reqData;
    }

    build(api, opt: RequestOptions = {}) {
        let me = this;
        this.api = api;

        // const http = new TgHttpBase(api, this.prepareData(), { joinPrefix: false },{ responseType: "arraybuffer" });
        const http = new TgHttpBase(api, this.prepareData(), {
            joinPrefix: false,
            urlParam: me.queryString,
            file: opt.file,
        });
        return http;
    }
}
