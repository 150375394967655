<template>
    <ElSelect
            ref="TgSelectRef"
            v-bind="$attrs"
            :multiple="multiple"
            :model-value="myValue"
            @change="change"
            @keyup="keyup($event)"
            @click.once="clickAction"
            filterable
            popper-class="tg-select-popper"
            clearable
            :filter-method="filterMethod"
            :placeholder="disabled ? '' : placeholder"
            :disabled="disabled"
            :focustypes="nanoid()"
    >

        <el-option
                v-for="option in options"
                :key="option.value"
                :label="getText(option)"
                :value="option.value">
        </el-option>
        <slot/>
    </ElSelect>

</template>

<script setup name="TgSelectPlus">
import {computed, getCurrentInstance, nextTick, onMounted, ref, watch} from 'vue';
import useTgSelect from './useTgSelect';
import $ from "jquery"
import {nanoid} from 'nanoid'
import {tmn, tm, t} from '@i18nHelper'

const {proxy} = getCurrentInstance()
const TgSelectRef = ref()
const props = defineProps({
    modelValue: {
        type: [String, Array],
        default: () => ''
    },
    //页面模型
    page: {
        type: Object,
        default: {}
    },
    //获取数据entity
    entity: {
        type: Object,
        default: {}
    },
    //绑定name字段
    nameField: {
        type: String,
        default: () => ''
    },
    //自定义options的label和value
    customOptions: {
        type: [Array, String],
        default: () => []
    },
    //通过类型，从api获取数据
    dataType: {
        type: [String],
        default: () => ''
    },
    //条件
    dataConn: {
        type: String,
        default: "",
    },
    // 在数据源中，根据正则表达式筛选数据
    dataRegExp: {
        type: [String],
        default: () => ''
    },
    //多选
    multiple: {
        type: [Boolean],
        default: () => false
    },
    //是否只显示值
    codeOnly: {
        type: Boolean,
        default: false
    },
    //是否显示value-label
    dataJoin: {
        type: Boolean,
        default: false
    },
    // 自定义数据源
    dataCustom: {
        type: [Array, String],
        default: () => []
    },
    placeholder: {
        type: String,
        default: () => {
            return t('tg.page.common.chooseHint')
        }
    },
    //默认选中第一个
    dataFirst: {
        type: Boolean,
        default: false,
    },
    //是否添加空选项
    addEmpty: {
        type: Boolean,
        default: false,
    },
    //空选项的显示的text,默认：全部
    emptyText: {
        default() {
            return t('tg.enum.YN.ALL')
        },
    },
    disabled: {
        type: Boolean,
        default: () => false
    },
});
const emits = defineEmits(['update:modelValue', 'update']);

const {options, originalOptions, addEmptyAction, setOptions, filterMethod} = useTgSelect(props, emits);

/**
 * @description 下拉框change事件
 * @param {string | string[]} e
 */
const change = e => {
    emits('update:modelValue', props.multiple ? e.join(',') : e);

    /*
    * 多选返回数组，单选返回对象
    * */
    let arr = e.constructor === String ? [e] : e
    let temp = JSON.parse(JSON.stringify(options.value.filter(item => {
        return arr.filter(itemA => {
            return item.value === itemA
        }).length !== 0
    })))

    emits('update', props.multiple ? temp : temp.length === 0 ? {} : temp[0]);

    calcName(e)

};

//计算值
const myValue = computed(() => {
    return props.multiple ? props.modelValue.split(',').filter(item => item !== '') : props.modelValue
})

onMounted(() => {
    //计算name属性的值
    calcName(myValue)
})

//计算name属性的值
const calcName = (val) => {
    if (props.multiple) {
        calcMultipleName(val)
    } else {
        calcSingleName(val)
    }

}
const calcSingleName = (val) => {
    if (props.nameField) {
        props.entity[props.nameField] = ""
        if (val) {
            options.value.forEach((item) => {
                if (item.value == val) {
                    props.entity[props.nameField] = item.label
                    return
                }
            })
        }
    }
}
const calcMultipleName = (val) => {
    if (props.nameField) {
        props.entity[props.nameField] = ""
        if (val) {
            let valarray = val.toString().replace(/\[|]|"/g, "").split(",")
            let ret = []
            valarray.forEach((item) => {
                options.value.forEach((sourceItem) => {
                    if (item == sourceItem.value) {
                        ret.push(sourceItem.label)
                    }
                })
            })
            props.entity[props.nameField] = ret.join(",")
            return
        }
    }
}
const getText = (option) => {
    if (props.codeOnly) {
        return option.value
    } else {
        if (option.value) {
            return props.dataJoin ? `${option.value}-${option.label}` : option.label
        } else {
            return option.label
        }
    }
}
const clickAction = () => {
    //主要解决表单第一个框是下拉框的时候，会出现点击下拉框，不出列表的情况
    //只执行一次
    let visible = $(".el-select__popper").is(":visible")
    if (visible == false) {
        nextTick(() => {
            proxy.$refs.TgSelectRef.blur()
            setTimeout(() => {
                proxy.$refs.TgSelectRef.focus()
            }, 1)
        });
    }
}

const keyup = (event) => {
    if (event.keyCode == 13) {
        //鼠标回车时候，使 input 失去焦点，并隐藏下拉框
        proxy.$refs.TgSelectRef.blur()
    }
}

watch(
    () => props.dataType,
    () => {
        setOptions();
    },
    {
        immediate: true
    }
);

const setData = function (data) {
    options.value = []
    addEmptyAction()
    data.forEach((item) => {
        options.value.push(item)
        originalOptions.value.push(item)
    })

}
const convertDataCustom = function () {
    let result = []

    try {
        if (typeof props.dataCustom == 'string') {
            let array = props.dataCustom.toString().split(",")

            result = []

            array.forEach((element) => {
                let section = element.split("|")
                let value = section[0]
                let label = section[1]

                result.push({
                    label: label,
                    value: value,
                })
            })
        } else {
            result = JSON.parse(JSON.stringify(props.dataCustom))
        }
    } catch (e) {

    }
    return result
}

const getSelectItem = function (val) {
    let selectItem = {}
    if (val) {
        options.value.forEach((item) => {
            if (item.value == val) {
                selectItem = item
            }
        })
    }
    return selectItem
}

watch(
    () => props.dataCustom,
    (data) => {
        if (props.dataCustom) {
            let result = convertDataCustom()
            if (result.length > 0) {
                setData(result)
            }

        }

    },
    {
        immediate: true
    }
);

defineExpose({getSelectItem})

</script>

<style lang="less">
.tg-select-popper {
  .el-select-dropdown__wrap {
    .el-select-dropdown__list {
      .el-select-dropdown__item {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
