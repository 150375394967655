﻿import eci from '@coreTgPlus'

let queryHelper = {

    getQueryInfo(queryEntity, schemas) {
        let area = ""
        schemas.forEach((item) => {
            let query = item.query ? item.query : ''
            const expression = item.field
            const isEciCheckBoxGroup = item.component == 'CheckboxGroup'

            const isSelect = item.component == 'Select'


            let isMuiltSelect = false

            if (isSelect && item.componentProps) {
                isMuiltSelect = item.componentProps['multiple'] == true
            }

            const isDateRange = item.component == 'DateRange' || item.component == 'DateTimeRange'
            const isTextArea = item.component == 'InputTextArea'

            let alias = 'A.'

            let ntext = false

            if (query && query.indexOf('auton') >= 0) {
                ntext = true
                query = query.replace('n', '')
            }

            if (query == 'auto') {
                let field = eci.propertyToField(expression)
                if (item.dataFiled) {
                    field = item.dataFiled
                    alias = ''
                }
                if (isMuiltSelect) {
                    query = alias + field + '|s|in|and'
                } else if (isEciCheckBoxGroup) {
                    query = alias + field + '|s|in|and'
                } else if (isSelect) {
                    query = alias + field + '|s|=|and'
                } else if (isTextArea) {
                    query = alias + field + '|s|m|and'
                } else if (isDateRange) {
                    query = alias + field + '|d|between|and'
                } else {
                    if (ntext) {
                        query = alias + field + '|sn|l|and'
                    } else {
                        query = alias + field + '|s|l|and'
                    }
                }
            }
            if (query == 'value') {
                if (isDateRange) {
                    delete queryEntity[expression + 'Start']
                    delete queryEntity[expression + 'End']
                    if (queryEntity[expression]) {
                        queryEntity[expression + 'Start'] = queryEntity[expression][0]
                        queryEntity[expression + 'End'] = queryEntity[expression][1]
                        if (queryEntity[expression + 'Start'] == undefined) {
                            queryEntity[expression + 'Start'] = ''
                        }
                        if (queryEntity[expression + 'End'] == undefined) {
                            queryEntity[expression + 'End'] = ''
                        }
                    }
                    delete queryEntity[expression]
                }
            }

            if (isDateRange) {
                // area += ',' + expression + 'Start' + '|' + query.replace("|end", "|start");
                // area += ',' + expression + 'End' + '|' + query.replace('|start', '|end')
                // delete queryEntity[expression + 'Start']
                // delete queryEntity[expression + 'End']
                // if (queryEntity[expression]) {
                //     queryEntity[expression + 'Start'] = queryEntity[expression][0]
                //     queryEntity[expression + 'End'] = queryEntity[expression][1]
                //     if (queryEntity[expression + 'Start'] == undefined) {
                //         queryEntity[expression + 'Start'] = ''
                //     }
                //     if (queryEntity[expression + 'End'] == undefined) {
                //         queryEntity[expression + 'End'] = ''
                //     }
                // }
                if (query) {
                    area += ',' + expression + '|' + query
                }
                delete queryEntity[expression]
            } else {
                if (query) {
                    area += ',' + expression + '|' + query
                }
            }
        })
        return area
    }


}

export default queryHelper;
