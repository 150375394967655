import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";

export const getSysDataHelpList = async (data, url = "/sysDataHelp/list") => {
    const request = new TgHttp();
    request.entity = data
    const res = await request
        .build(SettingCore.VITE_BASE_URL + url)
        .post()

    if (res.code === 10001) {
        return res.data;
    }
    return {};
};

//单独的放大镜的请求接口
//兼容url，是开发自己写的，接口是以前的格式，对分页进行处理，不然界面的分页显示错误
export const getSysDataHelpListEx = async (data, url = "/sysDataHelp/list") => {

    const request = new TgHttp();
    request.entity = data

    //针对以前的请求，如果没有分页，就加上分页
    if (url != "/sysDataHelp/list") {
        if (request.paging == undefined) {
            request.paging = {}
        }
        if (data.pageSize) {
            request.paging.pageSize = data?.pageSize
        }
        if (data.pageNum) {
            request.paging.pageNum = data?.pageNum
        }
    }

    const res = await request
        .build(SettingCore.VITE_BASE_URL + url)
        .post()

    if (res.code === 10001) {
        return res.data;
    }
    return {};
};
