export default {
	"cbeDeparture":"跨境统一版出口离境单表头（操作表）",
	"cbeDepartureItem":"跨境统一版出口离境单表体（操作表）",
	"cbeDeparture_kj5PartionKey":"kj5PartionKey",
	"cbeDeparture_platNo":"平台编号",
	"cbeDeparture_copCode":"传输企业代码",
	"cbeDeparture_syMark":"状态",
	"cbeDeparture_b2bWorkNo":"b2bWorkNo",
	"cbeDeparture_inputDate":"创建日期",
	"cbeDeparture_guid":"企业系统生成36 位唯一序号（英文字母大写）",
	"cbeDeparture_appType":"企业报送类型",
	"cbeDeparture_appTime":"企业报送时间",
	"cbeDeparture_appStatus":"企业报送状态",
	"cbeDeparture_customsCode":"申报海关",
	"cbeDeparture_copNo":"企业内部编号",
	"cbeDeparture_preNo":"电子口岸标志单证的编号",
	"cbeDeparture_logisticsCode":"物流企业编号",
	"cbeDeparture_logisticsName":"物流企业名称",
	"cbeDeparture_trafMode":"运输方式",
	"cbeDeparture_trafName":"运输工具名称",
	"cbeDeparture_voyageNo":"航次编号",
	"cbeDeparture_billNo":"提运单号",
	"cbeDeparture_leaveTime":"离境时间",
	"cbeDeparture_msgCount":"报文总数",
	"cbeDeparture_msgSeqNo":"报文序号",
	"cbeDeparture_note":"备注",
	"cbeDeparture_messageId":"消息ID"
}
