<template>
    <div>
        <TgDialog title="数据选择"
                  ref="tgChooseDialogRef"
                  width="1000px"
                  @close="onDialogClose" :fullScreen="false" :can-mini="false"
        >
            <ElCard style="box-shadow: none !important">
                <ElForm :model="page.data.entity">
                    <ElRow>
                        <ElCol :span="12">
                            <ElFormItem label="查询条件">
                                <!--                                <ElInput v-model="page.data.entity.msg"/>-->
                                <tg-input-plus v-model="page.data.entity.msg"/>
                            </ElFormItem>
                        </ElCol>
                        <ElCol :span="12">
                            <ElFormItem label-width="20px">
                                <TgButton @click="page.search" icon="Search" name="查询"/>
                                <TgButton @click="page.reset" icon="Brush" plain name="重置"/>
                            </ElFormItem>
                        </ElCol>
                    </ElRow>
                </ElForm>
            </ElCard>

            <tg-table-list-plus :showFilterSetting="false" ref="table" :page="page" :table="page.data.table"
                                class="fit">
            </tg-table-list-plus>

        </TgDialog>
    </div>
</template>
<script setup name="TgChooseList">
import {onMounted, reactive, ref, getCurrentInstance, inject} from 'vue'
import QueryPage from "@core/models/QueryPage/QueryPage";
import apis from '@core/apis'
import {TgHttp} from "@coreHttp";
import {defaultTableProps} from "@componentSetting";
import {t} from '@/tiangongCore/locale/i18nHelper';
import {SettingCore} from "@core/setting";

const tgChooseDialogRef = ref()
const emits = defineEmits(['update:modelValue'])

//获取当前实例，挂载在realInstance
let instance = getCurrentInstance()
window.realInstance = instance

let {page} = QueryPage()
page.data.searchApi = apis.coreChoose.search
let data = reactive({
    title: t('tg.page.common.dataChoose')
})

const props = defineProps({
    dataType: {
        type: String,
        default: ''
    },
    field: {
        type: String,
        default: ''
    },
    obj: {
        type: Object,
        default: ''
    },
    extend: {
        type: Object,
        default: {}
    }
})

//后续是需要从数据库获取的
let columns = reactive([])

onMounted(async () => {
    let conn = ''
    if (props.obj) {
        let vueInstance = props.obj
        if (vueInstance && vueInstance.exposed && vueInstance.exposed.getCode) {
            conn = vueInstance.exposed.getCode()
        }
    }
    if (conn == undefined) {
        conn = ''
    }
    //设置查询条件默认值
    page.data.entity.msg = conn

    //如果有扩展的url,searchApi修改成扩展的
    if (props.extend?.url) {
        page.data.searchApi = SettingCore.VITE_BASE_URL + props.extend.url
    }

})


page.useTable({
    ...defaultTableProps,
    columns: [],
    rowkey: 'code',
    initUI: getChooseConfig
})


//执行查询之前
page.searching = (request) => {
    request.entity.queryKey = props.dataType
    request.entity.msg = page.data.entity.msg.trim()

    //如果有扩展的url,查询条件改成扩展的
    if (props.extend?.url) {
        request.entity = searchByExtend();
    }
}

function searchByExtend() {
    page.data.searchApi = SettingCore.VITE_BASE_URL + props.extend.url

    const queryParams = ref({
        queryKey: props.dataType,
        msg: page.data.entity.msg.trim(),

    });
    if (props.where) {
        queryParams.value.where = props.where;
    }
    // 扩展部分支持用户传入查询key
    let key = 'msg'
    try {
        key = props.extend.where.key
        // 匹配规则： 默认匹配规则模糊查询
        // queryParams.value.queryField = `${key}|l`
    } catch (e) {
    }
    let sendData = {
        ...queryParams.value,
        [key]: queryParams.value.msg,
        ...(props.extend && props.extend.data || {})
    }

    return sendData;

}

function show() {
    page.context.getRef("tgChooseDialogRef").show()
    page.search({})
}

//获取表格列的信息
async function getChooseConfig() {
    //如果有自定义url的情况，通过自定义的table获取columns
    if (props.extend?.url) {
        return getTableColumnsByExtend();
    } else {
        let tableColumns = []
        let request = new TgHttp();
        request.entity["queryKey"] = props.dataType
        let response = await request.build(apis.coreChoose.getConfig).post();
        if (response?.success) {
            let columns = response.data?.chooseColumns
            columns?.forEach((item) => {
                let column = {
                    label: item.name,
                    field: item.code,
                    width: item.width ? item.width + 'px' : '150px',
                    show: true
                }
                tableColumns.push(column)
            })
        }
        return tableColumns
    }
}

//通过扩展获取指定的columns
function getTableColumnsByExtend() {
    let tableColumns = []
    if (props.extend?.table) {
        let columns = props.extend?.table
        columns?.forEach((item) => {
            let column = {
                label: item.label,
                field: item.prop,
                width: '150px',
                show: true
            }
            tableColumns.push(column)
        })
    }
    return tableColumns
}


//重置
page.reseted = () => {
    page.search({})
}

//表格双击行选中数据
page.rowClick = row => {
    let newRow = {...row}
    if (props.extend.url) {
        if (props.extend.code) {
            //如果不存在code,就给code赋值
            if (!row.hasOwnProperty('code')) {
                newRow["code"] = row[props.extend.code]
            }
        }
        //如果不存在name,就给code赋值
        if (props.extend.name) {
            if (!row.hasOwnProperty('name')) {
                newRow["name"] = row[props.extend.name]
            }
        }
    }
    // 设置行：会触发输入框查询弹框条件，同时会触发对应的弹框
    if (props.obj) {
        let vueInstance = props.obj
        if (vueInstance && vueInstance.exposed && vueInstance.exposed.selectData) {
            vueInstance.exposed.selectData(newRow)
        }

    }
    //关闭弹框
    page.context.getRef("tgChooseDialogRef").close()

    //dialog实例销毁
    onDialogClose()
};

//关闭弹框的时候，清空window.chooseInstance
function onDialogClose() {
    if (window.chooseInstance?._instance) {
        document.body.removeChild(window.chooseInstance._instance.vnode.el.parentElement)
        window.chooseInstance.unmount()
        window.chooseInstance = null
    }
}

defineExpose({show})
</script>
