import Tgbase from "@core/components/base"; // 全局组件(TG)
import TgFilter from "@core/components/business/crud/TgFilter/TgFilter.vue";
import TgFilterPlus from "@coreComponents/crudPlus/TgFilterPlus/TgFilterPlus.vue";
import TgTableList from "@core/components/business/crud/TgTableList/TgTableList.vue";
import TgTableListPlus from "@coreComponents/crudPlus/TgTableListPlus/TgTableListPlus.vue";
import TgChoosePlus from "@coreComponents/TgChoosePlus/TgChoosePlus.vue";
import TgChoosePlusEx from "@coreComponents/TgChoosePlusEx/TgChoosePlusEx.vue";

import TgSelectPlus from "@core/components/base/TgSelectPlus/TgSelectPlus.vue";
import TgChoose from "@core/components/business/TgChoose/index.vue";
import TgPage from "@coreComponents/crudPlus/TgPage/TgPage.vue";
import TgUploadPlus from "@coreComponents/TgUploadPlus";
import {TgCrontab} from "@core/components/base/TgCrontab";

// import TgTree from "@coreComponents/TgTree/TgTree.vue"

export function setupComponents(app) {
    app.component("TgFilter", TgFilter);
    app.component("TgFilterPlus", TgFilterPlus);
    app.component("TgTableList", TgTableList);
    app.component("TgTableListPlus", TgTableListPlus);
    app.component("TgChoosePlus", TgChoosePlus);
    app.component("TgChoosePlusEx", TgChoosePlusEx);
    app.component("TgSelectPlus", TgSelectPlus);
    app.component('TgChoose', TgChoose);
    app.component('TgPage', TgPage);
    app.component("TgUploadPlus", TgUploadPlus);
    app.component("TgCrontab", TgCrontab);

    // app.component('TgTree', TgTree);
    app.use(Tgbase);
    /*
     * 业务组件
     * */
    // 查询条件
    app.use(TgFilter);
    app.use(TgFilterPlus);
    // 查询结果
    app.use(TgTableList);
    // 放大镜
    app.use(TgChoose)
    app.use(TgChoosePlus);
    app.use(TgChoosePlusEx)
    app.use(TgSelectPlus);
    // app.use(TgTree);
    app.use(TgUploadPlus)
    app.use(TgCrontab)
}
