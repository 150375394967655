﻿import tg from '@coreTgPlus'


const tableDirective = {
    updated(el, binding, vnode) {
        //监听主题中的“启用标签页”，在tg-filter-plus中的显示更多
        tg.doResize(el, binding, vnode)
    },
    async mounted(el, binding, vnode) {
        const resizeHandler = async () => {
            await tg.doResize(el, binding, vnode);
        };
        // window窗体的监听,只能监听windows的变化
        window.addEventListener('resize', tg.debounce(resizeHandler, 100));

        // 监听当前元素 table的变化
        const resizeObserver = new ResizeObserver(async entries => {
            for (let entry of entries) {
                // 处理元素大小变化的逻辑
                if (entry.target === el) {
                    tg.debounce(resizeHandler, 100)
                    // await tg.doResize(el, binding, vnode);
                }
            }
        });
        resizeObserver.observe(el);
        el._resizeObserver_ = resizeObserver;

        const page = vnode.appContext?.app;
        if (page?.eciruntime) {
            page?.eciruntime.eciTable.push({el: el, binding: binding, vnode});
        }
    },
    unmounted(el) {
        //销毁窗体监听
        window.removeEventListener('resize', el.resizeListener);

        //销毁元素监听
        if (el._resizeObserver_) {
            el._resizeObserver_.disconnect();
            delete el._resizeObserver_;
        }

    }
}

export function setupTableDirective(app) {
    // console.log("setupTableDirective");
    app.directive('tg-table', tableDirective)
}
