import { useStore } from "vuex";
import {
  ref,
  reactive,
  getCurrentInstance,
  computed,
  watch,
  onMounted,
} from "vue";
// import { getSqlCommandData } from "../../apis/user";
import useAnimationBall from "../../hooks/event/useAnimationBall";
import {useMyCollectionInsert,useMyCollectionDelete,useMyCollectionSelectList} from "@core/apisPlus/hooks/useUser";
import $ from "jquery";
import useWujieRouter from "@core/utils/wujie/useWujieRouter";
import { SettingCore } from "@core/setting";
// 业务扩展
import {
  getMyCollection,
  handleMyCollectionInsert,
  handleMyCollectionDelete,
} from "@webApp/extension/menuTag";
// import AppCoreExtension from "@/tiangongCore/utils/appCoreExtension";

import { getTagAfter ,addCollectAfter, cancelCollectAfter} from "@coreTools";

export default function () {
  // 动画球 hooks
  const { ballPramas, beforeEnter, enter, afterEnter, handleJoinModal } =
    useAnimationBall();
  const { proxy } = getCurrentInstance();
  const store = useStore();
  const menuLeft = ref(0);
  const menuTop = ref(0);
  const rhCurrentObj = reactive({
    path: "",
    id: "",
    rIndex: 0,
    parentid: "",
    name: "",
    description: "",
  });

  const state = reactive({
    myCollectionList: [],
  });

  const currentTagsIndex = computed(() => {
    return store.state.layout.currentTagsIndex;
  });
  const TopBarBg = computed(() => {
    return store.state.layout.topBarBg === "#fff"
      ? "#337DF7"
      : store.state.layout.topBarBg;
  });
  const webTabType = computed(() => {
    // 标签显示风格
    return store.state.sysParameters.userThemesetting.webTabType;
  });
  const newTagsList = computed(() => {
    // 获取tags标签数据
    return store.state.layout.tagsList;
  });

  const upToShowMouseMenu = ref(false);
  const clearRightHandMenu = () => {
    // 清除所有右键菜单
    const { tagsList } = store.state.layout;
    tagsList.forEach((item, index) => {
      $(`.rhMenu${index}`) &&
        $(`.rhMenu${index}`)[0] &&
        ($(`.rhMenu${index}`)[0].style.display = "none");
    });
    upToShowMouseMenu.value = false;
  };

  const addCollect = async (item) => {
    // 添加收藏
    if (!item || !item.id) return;
    // let rtn = await AppCoreExtension.setName( 'menuTag.handleMyCollectionInsert').getMethod({data: item})
    let rtn = await handleMyCollectionInsert({data: item})
    if (rtn.flag) {
      if(rtn.core) {
        addCollectAfter(rtn.data)
        clearRightHandMenu();
        handleJoinModal();
      }
      return;
    }
    useMyCollectionInsert({
      menuId: item.id || "",
      menuName: item.name || "",
      menuRoute: item.path || "",
    })
      .then((data) => {
        addCollectAfter(data)
        clearRightHandMenu();
        handleJoinModal();
      })
      .catch((err) => {
        tg.msg.error(err);
      });
  };

  const cancelCollect =async (item) => {
    // let rtn = await AppCoreExtension.setName('menuTag.handleMyCollectionDelete').getMethod({data: item})
    let rtn = await handleMyCollectionDelete({data: item})
    if (rtn.flag) {
      if(rtn.core) {
        cancelCollectAfter(rtn.data || {})
        upToShowMouseMenu.value = false;
      }
      return false;
    }
    // 取消收藏
    useMyCollectionDelete({ id: item.myCollId })
      .then((data) => {
        cancelCollectAfter(data)
        upToShowMouseMenu.value = false;
      })
      .catch((err) => {
        tg.msg.error(err);
      });
  };

  const { routerPush } = useWujieRouter();

  const handleClickFolder = (flag, item, index) => {
    clearRightHandMenu();
    store.commit("updateCurrentTagsIndex", index);
    const path = flag === "home" ? "/desk/desk" : item.path;
    if (path === "/statisticsLeft/ordersQuery.vue") {
      proxy.$router.push("/orders-total");
    } else {
      // proxy.$router.push(path)
      // if (item.options && item.options !== '') {
      //     try {
      //         customRouterPush({
      //             path,
      //             ...item,
      //             query: JSON.parse(item.options ?? '{}'),
      //         })
      //     } catch (e) {
      //         proxy.$router.push(path);
      //     }
      // } else {
      //     proxy.$router.push(path);
      // }
      try {
        customRouterPush({
          path,
          ...item,
          query: JSON.parse(item.options ?? "{}"),
        });
      } catch (e) {
        proxy.$router.push(path);
      }
    }
  };

  const handleTagsFn = (arr, flag, val) => {
    // 处理tags缓存，vuex逻辑
    clearRightHandMenu();
    if (!arr.length) {
      proxy.$router.push("/desk/desk");
    } else {
      if (flag === "close-o" && val.path === proxy.$route.path) {
        /*
         * 兼容数据魔方的路由
         *   query 数据 从权限平台获取来，格式为 JSON 字符串
         * */
        let item = arr[arr.length - 1];
        try {
          customRouterPush({
            ...item,
            query: JSON.parse(item.options ?? "{}"),
          });
        } catch (e) {
          proxy.$router.push(item.path);
        }
      }
      let tIndex = 0;
      let sIndex = 0;
      const { tagsList } = store.state.layout;
      tagsList.forEach((item, index) => {
        if (item.path === val.path) tIndex = index; // 点击的索引
        if (item.path === proxy.$route.path) sIndex = index; // 路由所在的索引
      });
      if (tIndex <= sIndex) {
        // 如果点击的在当前索引之前
        store.commit(
          "updateCurrentTagsIndex",
          !arr.length ? -1 : arr.length - 1
        );
      }
    }
    store.commit("updateTagsList", arr);
  };

  const removeTag = () => {
    // 关闭当前页面 -- 下拉icon
    const cPath = rhCurrentObj.path || proxy.$route.path;
    const tagsListArr = store.state.layout.tagsList.slice(0);
    if (tagsListArr.length === 1) {
      proxy.$router.push("/desk/desk");
      return;
    }
    clearRightHandMenu();
    const ctIndex = store.state.layout.currentTagsIndex; // 当前tag index
    let arr = tagsListArr.filter((item) => item.path !== cPath);
    if (rhCurrentObj.path === proxy.$route.path) {
      // 如果删除的是当前页
      let item = arr[arr.length - 1];
      try {
        customRouterPush({
          ...item,
          query: JSON.parse(item.options ?? "{}"),
        });
      } catch (e) {
        proxy.$router.push(item.path);
      }
    } else {
      if (rhCurrentObj.rIndex < ctIndex) {
        store.commit("updateCurrentTagsIndex", ctIndex - 1);
      }
    }
    store.commit("updateTagsList", arr);
  };

  const closeTag = (val) => {
    // 关闭当前tags -- icon x
    const tagsListArr = store.state.layout.tagsList.slice(0);
    let newArr = tagsListArr.filter((item) => item.id !== val.id);
    handleTagsFn(newArr, "close-o", val);
  };

  const removeOtherTag = () => {
    // 关闭其他
    const tagsListArr = store.state.layout.tagsList.slice(0);
    let arr = tagsListArr.filter((item) => item.path === rhCurrentObj.path);

    clearRightHandMenu();
    let rhcObj =
      rhCurrentObj.path === proxy.$route.path ? arr[0] : rhCurrentObj;
    if (rhCurrentObj.path !== proxy.$route.path) {
      proxy.$router.push(rhcObj.path);
    }

    store.commit("updateCurrentTagsIndex", 0);
    store.commit("updateTagsList", arr);
  };

  const removeAllTag = () => handleTagsFn([]); // 关闭所有

  const scrollLeftFn = (num) => {
    // 计算tags滚动距离
    let ul = $(".tabs"); // 获取ul dom元素
    let scrollWidth = ul.scrollLeft(); // 滚动条卷去宽度
    let flag = !!(
      $(".tab-list") &&
      $(".tab-list").eq(num - 1) &&
      $(".tab-list")
        .eq(num - 1)
        .position()
    );
    let offsetLeft =
      $(".tab-list") &&
      (flag
        ? $(".tab-list")
            .eq(num - 1)
            .position().left
        : 0) +
        scrollWidth -
        60;
    ul.animate({ scrollLeft: offsetLeft }, 500);
  };

  const handleScrollTo = (flag) => {
    // tags列表 -> 滚动
    if (flag === "left") {
      let ul = $(".tabs"); // 获取ul dom元素
      ul.animate({ scrollLeft: 0 }, 500);
    } else {
      scrollLeftFn($(".tab-list").length);
    }
  };

  const showMenu =async (item, e, index) => {
    rhCurrentObj.path = item.path;
    rhCurrentObj.id = item.id;
    rhCurrentObj.rIndex = index;
    rhCurrentObj.parentid = item.parentid;
    rhCurrentObj.name = item.name;
    rhCurrentObj.description = item.description;
    e.preventDefault();
    clearRightHandMenu();
    upToShowMouseMenu.value = true;
    $(`.rhMenu${index}`)[0].style.display = "block";
    $(`.rhMenu${index}`)[0].style.top = e.clientY + 12 + "px";
    $(`.rhMenu${index}`)[0].style.left = e.clientX - 12 + "px";

    // 不显示快捷工作区：对应的添加收藏也不显示
    if (SettingCore.VITE_TG_SHORTCUT_WORKSPAGE != "1") {
      return;
    }
    // 挂载业务代码
    // const rtn = await AppCoreExtension.setName('menuTag.getMyCollection').getMethod({data:{index,item}})
    const rtn = await getMyCollection({data:{index,item}})
    if (rtn.flag) {
      if(rtn.core) {
        getTagAfter(rtn.data, {index,item});
      }
      return false;
    }
    useMyCollectionSelectList()
      .then((data) => {
        getTagAfter(data, {index,item});
      })
      .catch((err) => {
        err.msg &&  tg.msg.error(err.msg);
      });
  };

  const handleOpenModal = () => {
    // 打开浮窗
    store.commit("updateOpenModal", true);
  };

  const goToDesk = () => {
    // 跳转首页
    proxy.$router.push("/desk/desk");
  };

  watch(
    () => store.state.layout.currentTagsIndex,
    (newValue, oldValue) => {
      let num = 1;
      const { webTabType } = store.state.sysParameters.userThemesetting;
      let sum = webTabType === "4" ? 0 : 1;
      if (newValue > 0) num = newValue + sum;
      scrollLeftFn(num);
    }
  );
  onMounted(() => {
    // 初始化 tags滚动位置
    const { currentTagsIndex } = store.state.layout;
    let num = 1;
    if (currentTagsIndex > 0) num = currentTagsIndex + 1;
    scrollLeftFn(num);

    window.addEventListener("click", () => {
      clearRightHandMenu();
    });
  });

  return {
    newTagsList, // 标签数据
    menuLeft,
    menuTop,
    addCollect,
    cancelCollect,
    removeTag,
    TopBarBg,
    webTabType,
    handleClickFolder,
    currentTagsIndex,
    closeTag,
    removeOtherTag,
    removeAllTag,
    handleScrollTo,
    showMenu,
    handleOpenModal,
    goToDesk,
    ballPramas,
    beforeEnter,
    enter,
    afterEnter,
    upToShowMouseMenu,
  };
}
