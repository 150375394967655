const api = {
    save: "eciNoticeTypeAttach/save",
    load: "eciNoticeTypeAttach/selectOneById",
    search: "eciNoticeTypeAttach/selectPageList",
    delete: "eciNoticeTypeAttach/deleteByIds",
    export: "eciNoticeTypeAttach/export",
    saveFile: "eciNoticeTypeAttach/saveFile",
    download: "eciNoticeTypeAttach/download"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

export default regApi(api, app.name)
