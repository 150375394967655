import type {LocaleSetting, LocaleType} from './localeType'
import {createStore} from 'vuex'
// import { store } from '@/ead/store'
// import { LOCALE_KEY } from '@/ead/enums/cacheEnum'
// import { createLocalStorage } from '@/ead/utils/cache'
import {localeSetting} from './localeSetting'
import TgLocalStorage from "@core/utils/TgLocalStorage";

const myLocal = (TgLocalStorage.get('lang') || 'zh_CN') as LocaleType

const info: LocaleSetting = {
    showPicker: false,
    // Current language
    locale: myLocal,
    // default language
    fallback: myLocal,
    // available Locales
    availableLocales: [],

}
// const ls = createLocalStorage()
const lsLocaleSetting = (info || localeSetting) as LocaleSetting

interface LocaleState {
    localInfo: LocaleSetting
}


export const useLocaleStore = createStore({
    state: (): LocaleState => ({
        localInfo: lsLocaleSetting
    }),
    getters: {
        getShowPicker(state): boolean {
            return !!state.localInfo?.showPicker
        },
        getLocale(state): LocaleType {
            return state.localInfo?.locale
        }
    },
    actions: {}
})

// Need to be used outside the setup
// export function useLocaleStoreWithOut() {
//     return useLocaleStore(store).getters
// }
