<!--验证码登录-->
<template>
  <div v-loading="loginLoading" element-loading-text="数据加载中..."></div>
  <el-form ref="formRef" :rules="state.rules" :model="entity">
    <el-form-item prop="bindPhoneNo">
      <el-input v-model="entity.bindPhoneNo" placeholder="手机号码" />
    </el-form-item>
    <el-form-item prop="valCode" class="codeWapTip">
      <div class="codeWap">
        <el-input v-model="entity.valCode" placeholder="图形验证码" />
      </div>
      <img :src="codeImgUrlPhone" @click="getValCodeFn" class="codeImgUrlPhone" />
    </el-form-item>
    <el-form-item prop="sysCode" class="codeWapTip">
      <div class="codeWap">
        <el-input v-model="entity.sysCode" placeholder="短信验证码" />
      </div>
      <span class="sysCode" v-if="state.show" @click="getPhoneCodeFn()">获取验证码</span>
      <span class="sysCode grayColor" v-else>{{ state.count }}秒后重新登录</span>
    </el-form-item>
  </el-form>
  <div  class="login-btn">
    <el-button type="primary" @click='loginCodeFn()'>登录</el-button>
  </div>
</template>
<script setup>
import {ref,reactive,onMounted,getCurrentInstance} from 'vue'
import login from './login'
import TgRequest from "@coreRequest";
import { ElMessage } from 'element-plus'
const { proxy } = getCurrentInstance();
import {SettingCore} from "@core/setting";
import {TgHttp} from "@coreHttp";

const {
  entity,
  LoginFn,
  // getPhoneCodeFn,
  getValCodeFn,
  codeImgUrlPhone,
  loginSuccess,
  loginError,
  loginLoading
} = login()

const formRef = ref()

const validateValCode = (rule,value,callback)=>{
  if(!value && state.clickFlag === '1' ) {
    callback(new Error('请输入图形验证码'))
  }else {
    callback()
  }
}

// 手机校验码
const validateSysCode = (rule,value,callback)=>{
  if(!value && state.clickFlag === '2' ) {
    callback(new Error('请输入手机验证码'))
  }else {
    callback()
  }
}

const state = reactive({
  dis: false,
  show: true,
  isGrey: false, //按钮样式
  timer: null, //设置计时器
  count: "",
  clickFlag : '', // 1 发送短信校验码   2 登录
  rules:{
    bindPhoneNo: [
      { required: true, message: '请输入手机号码', trigger: 'click' },
      {pattern: /^[1][3,4,5,6.7,8,9][0-9]{9}$/,message: '手机号格式不对',trigger: 'blur'},
    ],
    valCode: [{ validator: validateValCode, trigger: 'blur' }
    ],
    sysCode: [{ validator: validateSysCode, trigger: 'blur' }]
  }
})

//获取手机验证码
const getPhoneCodeFn =  ()=>{
  state.clickFlag = '1'
  formRef.value.validate( (valid)=>{
    if(valid) {
      // 请求验证码
      const request = new TgHttp();
      request.entity = {
        bindPhoneNo: entity.value.bindPhoneNo,
        valCode: entity.value.valCode,
        uuid: entity.value.uuid
      }
      request
          .build(SettingCore.VITE_BASE_URL + '/sendSmsCode')
          .post()
          .then((rtn)=>{
            ElMessage({
              type: rtn.code !== 10001 ? 'warning': 'success',
              message: rtn.code !== 10001 ? rtn.msg : '发送成功',
            })
            if( rtn.code === 10001 || (rtn.code ===2000 && rtn.data) ) {
              // 倒计时
              Countdown(60)
            }
            // 校验码过期，重新获取
            if(rtn.code === 20002 ) {
              getValCodeFn()
            }
          })
    }
  })
}

// 登录：手机验证码登录
const loginCodeFn = ()=>{
  loginLoading.value = true
  state.clickFlag = '2'
  formRef.value.validate( (valid)=>{
    if(valid) {
      const request = new TgHttp();
      request.entity = {
        bindPhoneNo: entity.value.bindPhoneNo,
        smsCode: entity.value.sysCode,
        // sys: 'MAIN'
        sys: SettingCore.VITE_TG_SYS_CODE
      }
      request
          .build(SettingCore.VITE_BASE_URL + '/loginBySmsCode')
          .post()
          .then((res)=>{
            loginSuccess(res)
          }).catch(e=>{
        loginError(e)
      })
    }else{
      loginLoading.value = false
    }
  })
}

// 验证码：倒计时
const Countdown = (count)=>{
  let TIME_COUNT = count || 60;
  // clearInterval(state.timer)
  // state.timer = null
  if( !state.timer) {
    state.count = TIME_COUNT
    state.isGrey = true
    state.show = false
    state.dis = true

    state.timer = setInterval(()=>{
      if( state.count>0 && state.count <= TIME_COUNT ) {
        state.count --
      }else{
        state.dis = false
        state.isGrey = false
        state.show = true
        clearInterval(state.timer)
        state.timer = null
      }
    },1000)
  }
}

defineExpose({
  getValCodeFn
})
</script>
<style lang="less" scoped>
@import url('@core/assets/less/login/login.less');
:deep(.el-form-item__content) {
  position: relative;
}
.codeImgUrlPhone,.sysCode {
  position: absolute;
  right: 0;
  top: 0;
  height: 46px;
  cursor: pointer;
}
// 发送验证码
.sysCode {
  padding: 0 10px;
  &.grayColor {
    color: #999aaa;
  }
}
.codeWap {
  width: calc(100% - 140px);
}

:deep(.codeWapTip) {
  &.el-form-item  .el-form-item__error {
    right: 130px;
  }
}
.el-form-item {
  background: #f5f5f5;
}
</style>

