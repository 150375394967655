export default {
	"cbeSignTrace":"跨境统一版出口订单 发送加签客户端",
	"cbeSignTrace_kj5PartionKey":"kj5PartionKey",
	"cbeSignTrace_msgType":"消息类型",
	"cbeSignTrace_messageId":"messageId",
	"cbeSignTrace_workNo":"workNo",
	"cbeSignTrace_sendMark":"发送状态",
	"cbeSignTrace_number":"发送次数 ",
	"cbeSignTrace_createDate":"创建日期",
	"cbeSignTrace_serviceFlag":"服务处理标志",
	"cbeSignTrace_batId":"批次号"
}