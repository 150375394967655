import {createRouter, createWebHashHistory} from "vue-router";
import Login from "@appTiangong/login/customLogin/index.vue";
// import Home from "../layout/home/index.vue";
// import Content from "../layout/home/content.vue";
import layout from "@webApp/extension/layout";
import localStorage from "../utils/TgLocalStorage";
// 用户自定义路由
// import {setRoutes} from "@webApp/extension/router";
import {setRoutes, setRouteWhiteList} from "@webApp/extension/router";

// import AppCoreExtension from "@core/utils/appCoreExtension";
// 清除开发助手
import useAssistantHooks from "@appTiangong/DevPlatform/assistant/useAssistantHooks.js";
import {SettingCore} from "@core/setting";
import useWujieRouter from "@core/utils/wujie/useWujieRouter";
import {
    __POWERED_BY_WUJIE__,
    APP_NAME,
    routerFilter,
} from "@core/utils/wujie/wujieSub";
import {emitRedirectLogin} from "@core/utils/wujie/wujieSub/busSub";
import {getWujieAppMenu} from "@core/utils/wujie/wujieMain";


import {tmn, t, tm} from '@/tiangongCore/locale/i18nHelper'

const modules = import.meta.glob("../../web/**/**.vue");

const {clearRequest} = useAssistantHooks();

const {wujieRoutes} = useWujieRouter();

// const appRoutes = AppCoreExtension.setName("router").getObject();
const appRoutes = setRoutes();
const appRouteWhiteList = setRouteWhiteList();

// /desk/desk 重定向路由地址
const redirectDesk = () => {
    let url = SettingCore.homeUrl ?? "/desk/desk";
    return url === "/desk/desk" ? null : url;
};
// 路由列表
const routes = [
    {
        path: "/login",
        name: "登录",
        component: () =>
            modules[`../../web${SettingCore.VITE_TG_LOGIN_URL}.vue`]().then((component) => component.default),
    },
    //
    // {
    //     path: "/login",
    //     name: "登录",
    //     component: Login, // 打开产品到期提醒功能
    // },
    {
        path: "/sso-page",
        name: "自定义单点登录",
        component: () =>
            modules[`../../web${SettingCore.VITE_TG_TOKEN_LOGIN_URL}.vue`]().then((component) => component.default),
    },
    // {
    //     path: "/sso-page",
    //     name: "单点登录",
    //     component: () => import(`../..${SettingCore.VITE_TG_TOKEN_LOGIN_URL}`),
    // },
    // {
    //     path: "/token",
    //     name: "单点登录",
    //     component: () => import("@appTiangong/login/sso/TgIndex.vue"),
    // },
    {
        path: "/token",
        name: "框架单点登录",
        component: () => import("@appTiangong/login/sso/TgIndex.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("@appTiangong/error/404.vue"), // 匹配不到路由跳转404页面
    },
    {
        path: "/",
        component: layout.getHome,// Home,
        name: "首页",
        redirect: SettingCore.homeUrl ?? "/desk/desk",
        children: [
            {
                path: "desk",
                component: layout.getContent(),// Content,
                name: "工作台-desk",
                children: [
                    {
                        path: "/desk/desk",
                        name: "工作台",
                        // component: () => import(`../..${SettingCore.VITE_TG_HOME_INDEX}`),
                        component: () => import("@/web/home/homeIndex.vue"),
                        redirect: redirectDesk(),
                    },
                    {
                        path: "/TG/DevPlatform",
                        name: "设计",
                        component: () => import("@appTiangong/DevPlatform/index.vue"),
                    },
                    {
                        path: "/TG/dataList",
                        name: "数据源管理",
                        component: () =>
                            import("@appTiangong/DevPlatform/dataList/index.vue"),
                    },
                    {
                        path: "/TG/dataHelp",
                        name: "数据源管理",
                        component: () =>
                            import("@appTiangong/DevPlatform/dataHelp/DataHelpList.vue"),
                    },
                    {
                        path: "/TG/dataCache",
                        name: "数据缓存管理",
                        component: () =>
                            import("@appTiangong/DevPlatform/dataCache/DataCacheList.vue"),
                    },
                    {
                        path: "/TG/TgSvgPage",
                        name: "图标",
                        component: () => import("@appTiangong/TgSvgPage/TgSvgPage.vue"),
                    },
                    {
                        path: "/TG/Monitoring/API",
                        name: "API性能监控",
                        component: () =>
                            import("@appTiangong/DevPlatform/Monitoring/API/API.vue"),
                    },
                    {
                        path: "/TG/Monitoring/Database",
                        name: " 数据库性能监控",
                        component: () =>
                            import(
                                "@appTiangong/DevPlatform/Monitoring/Database/Database.vue"
                                ),
                    },
                    {
                        path: "/TG/Monitoring/UserLog",
                        name: " 系統日志监控",
                        component: () =>
                            import("@appTiangong/DevPlatform/Monitoring/UserLog/UserLog.vue"),
                    },
                    {
                        path: "/TG_EXCEL/tianGong/importPage",
                        name: "Excel导入",
                        options: '{"appName": "TG_EXCEL"}',
                        // redirect: '/TG_EXCEL/tianGong/importPage',
                        component: () =>
                            import(`../../web/appTiangong/wujie/WujieView.vue`),
                        // component: () => import(`@appTiangong/wujie/WujieView.vue`),
                        props: {
                            appName: "TG_EXCEL",
                            key: "TG_EXCEL",
                        },
                    },
                    {
                        path: "/TG_EXCEL/tianGong/configuration",
                        name: "天宫EXCEL：导入模板管理",
                        options: '{"appName": "TG_EXCEL"}',
                        // redirect: '/TG_EXCEL/tianGong/configuration',
                        component: () =>
                            import(`../../web/appTiangong/wujie/WujieView.vue`),
                        // component: () => import(`@appTiangong/wujie/WujieView.vue`),
                        props: {
                            appName: "TG_EXCEL",
                            key: "TG_EXCEL",
                        },
                    },
                    {
                        path: "/TG/TaskCenter",
                        name: "任务中心",
                        component: () =>
                            import("@appTiangong/TaskCenter/TgTaskInfoList.vue"),
                    },
                    {
                        path: "/TG/ToolCenter",
                        name: tm("tg.page.main.toolCenter.toolCenter", '工具中心'),
                        component: () =>
                            import("@appTiangong/ToolCenter/index.vue"),
                    },
                    {
                        path: "/TG/fileBizType",
                        name: "附件配置管理",
                        component: () =>
                            import("@appTiangong/FileInfo/FileBizTypeList.vue"),
                    },
                ],
            },
        ],
    },
];

/**
 * 封装递归路由的通用请求
 * @param  {array}   arr    需要递归的数组
 */
function recursiveRoute(arr) {
    if (arr.length > 0) {
        arr.forEach((item) => {
            const options = JSON.parse(item.options || "{}");
            // 非子应用环境，跳过设置为子应用的菜单
            if (!__POWERED_BY_WUJIE__ && options.appName) {
                item.meta = {
                    appName: options.appName,
                    path: item.path,
                    name: item.name,
                };
                item.name = item.path || item.id;
                item.path = "";
                recursiveRoute(item.children);
                return;
            }
            // 作为子应用时，菜单路由配置
            if (__POWERED_BY_WUJIE__ && options.appName === APP_NAME) {
                item.meta = {
                    appName: options.appName,
                    path: item.path,
                    name: item.name,
                };
            }
            if (item.name === "工作台") {
                item.component = () => import("@core/layout/home/index.vue");
            } else if (item.options && item.options !== "" && options.formId) {
                //  数字魔方：报表配置
                // item.component = () => import('@appTiangong/statAnalysis/index_02.vue')
                item.component = () =>
                    import(
                        `../../web/appTiangong/statAnalysis/${SettingCore.szmfForm}.vue`
                        );
            }
            // 同一个页面，不同的路由
            else if (item.options && item.options !== "" && options.TgRouterPage) {
                item.component = modules[`../../web${SettingCore.VITE_TG_ROUTER_BASIC_PATH}${options.TgRouterPage}.vue`];
            } else {
                item.component = modules[`../../web${SettingCore.VITE_TG_ROUTER_BASIC_PATH}${item.path}.vue`];
            }

            item.meta = Object.assign({}, item.meta || {}, {
                options: item.options || {},
            });

            item.note = item.path;
            // 名称一样，会导致页面无法渲染出来
            item.name = item.path;
            if (item.children.length > 0) {
                item.component = layout.getContent(),// Content;
                    recursiveRoute(item.children);
            }
        });
    }
}
//路由白名单的path拼接
let routerWhitePath = ""
// 处理业务添加后的路由配置: secondLevel 二级路由
export function initAppRoutes() {
    if (appRoutes) {
        appRoutes.forEach((item) => {
            if (item.meta && item.meta.secondLevel) {
                let index = routes.findIndex((f) => f.path === "/");
                if (index !== -1) {
                    routes[index].children && routes[index].children.push(item);
                }
            } else {
                routes.push(item);
            }
        });
    }

    if (appRouteWhiteList) {

        routerWhitePath = appRouteWhiteList.map(obj => obj.path).join(',');

        appRouteWhiteList.forEach((item) => {
            routes.push(item);
        });
    }
    return routes;
}

const vueRouter = createRouter({
    history: createWebHashHistory("/"),
    routes: initAppRoutes(),
});

/**
 * 动态获取路由
 */
export function extendsRoutesFn() {
    getWujieAppMenu();
    let userInfo = localStorage.get("userinfo");

    let extendsRoutes = {};
    if (userInfo && userInfo !== undefined && typeof userInfo !== "string") {
        if (userInfo.menuInfo) {
            let getRoutes = userInfo.menuInfo;

            getRoutes.component = layout.getHome(),// Home;
                getRoutes.path = "/";
            getRoutes.redirect = "/desk/desk";
            getRoutes["children"].forEach((item) => {
                item.component = layout.getContent();// Content;
                if (item.name === "工作台") {
                    item.component = () => import("@core/layout/home/index.vue");
                } else {
                    recursiveRoute(item.children);
                }
            });
            getRoutes["children"] = [...getRoutes["children"], ...wujieRoutes];
            extendsRoutes = getRoutes;
        }
    }
    if (Object.keys(extendsRoutes).length > 0) vueRouter.addRoute(extendsRoutes);
    // vueRouter.addRoute({
    //     path: '/TG_EXCEL/tianGong/importPage',
    //     name: 'Excel导入',
    //     component: () => import(`../views/wujie/WujieView.vue`),
    //     props: {
    //         appName: 'TG_EXCEL',
    //         key: 'TG_EXCEL'
    //     }
    // });
}

extendsRoutesFn();

routerFilter(vueRouter);

/**
 * 路由守卫
 * @description 未登录状态跳转登录页
 * @param { to, from, next }
 */
vueRouter.beforeEach((to, from, next) => {
    // 清除开发助手
    clearRequest();

    let userInfo = localStorage.get("userinfo");
    if (userInfo && userInfo !== undefined && typeof userInfo !== "string") {
        // 登录状态
        if (to.path === "/login" || to.path === "/") {
            next("/desk/desk");
        } else {
            next();
        }
    } else {
        // 未登录
        if (__POWERED_BY_WUJIE__) {
            emitRedirectLogin();
            return;
        }
        if (to.path !== "/login" && to.path !== "/sso-page" && to.path !== '/token' && routerWhitePath.indexOf(to.path) === -1) {
            next("/login");
        } else {
            next();
        }
    }
});

export default vueRouter;
