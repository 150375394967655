export default {
	"cbeDepartureItem":"跨境统一版出口离境单表体（操作表）",
	"cbeDepartureItem_kj5PartionKey":"kj5PartionKey",
	"cbeDepartureItem_platNo":"平台编号",
	"cbeDepartureItem_autoId":"表体主键",
	"cbeDepartureItem_messageId":"messageId",
	"cbeDepartureItem_gNum":"商品序号",
	"cbeDepartureItem_totalPackageNo":"总包号",
	"cbeDepartureItem_logisticsNo":"物流运单编号",
	"cbeDepartureItem_note":"备注"
}