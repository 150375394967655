import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import 'highlight.js/styles/stackoverflow-light.css'
import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import java from 'highlight.js/lib/languages/java';
import xml from 'highlight.js/lib/languages/xml';
import html from 'highlight.js/lib/languages/xml';
import vue from 'highlight.js/lib/languages/xml';
import sql from 'highlight.js/lib/languages/sql';
import hljsVuePlugin from "@highlightjs/vue-plugin";

// 必须有，否则打包没效果
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage("java",java);
hljs.registerLanguage("xml", xml);
hljs.registerLanguage("html",html );
hljs.registerLanguage("vue",vue);
hljs.registerLanguage("sql", sql);

export function setupCore(app) {
  app.use(hljsVuePlugin)
}
