export default {
	"cbiInventoryItem":"跨境统一版进口清单表体（操作表）",
	"cbiInventoryItem_kj5PartionKey":"创建时间",
	"cbiInventoryItem_platNo":"平台编号",
	"cbiInventoryItem_tradecountry":"贸易国",
	"cbiInventoryItem_autoId":"表体主键",
	"cbiInventoryItem_messageId":"messageId",
	"cbiInventoryItem_note":"备注",
	"cbiInventoryItem_gNum":"序号",
	"cbiInventoryItem_itemRecordNo":"账册备案料号",
	"cbiInventoryItem_itemNo":"企业商品货号",
	"cbiInventoryItem_itemName":"企业商品品名",
	"cbiInventoryItem_gCode":"商品编码",
	"cbiInventoryItem_gName":"商品名称",
	"cbiInventoryItem_gModel":"商品规格型号",
	"cbiInventoryItem_barCode":"商品条形码",
	"cbiInventoryItem_country":"原产国",
	"cbiInventoryItem_currency":"币制",
	"cbiInventoryItem_qty":"商品数量",
	"cbiInventoryItem_qty1":"法定数量",
	"cbiInventoryItem_qty2":"第二数量",
	"cbiInventoryItem_unit":"计量单位",
	"cbiInventoryItem_unit1":"法定计量单位",
	"cbiInventoryItem_unit2":"第二计量单位",
	"cbiInventoryItem_price":"单价",
	"cbiInventoryItem_totalPrice":"总价"
}