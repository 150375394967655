<template>
  <!--    <tg-drawer ref="TgDrawerRef" :title="t('tg.page.components.tgFilter.filterSettings')" size="560px" @confirm="save">-->

    <div class="tg_filterConfig_wap">
        <div class="tg_filterConfig_save">
      <span>
        <el-checkbox v-model="columnAll" :label="tm('tg.page.components.tgFilter.selectAll','全选')"
                     @change="handleAll($event, 'show')"/>
      </span>
            <div class="tg_filterConfig_btn" style="display:flex">
                <tg-button plain :name="tm('action.reset','重置')" @click="reset"/>
                <tg-button plain :name="tm('action.save','保存')" @click="save"/>
            </div>
        </div>
        <div class="tg_filterConfig_drag">
            <!--            <div class="tg_tips">{{t("tg.page.components.tgFilter.dragOrder")}}</div>-->
            <draggable item-key="field" :list="dataList" group="filterConfig" animation="600">
                <template #item="{ element, index }">
                    <div class="tg_col tg_drag_item">
                        <div class="tg_title_wap">
                            <el-icon>
                                <Rank/>
                            </el-icon> &nbsp;
                            <el-checkbox v-model="dataList[index].show" :label="element.label"/>
                        </div>
                    </div>
                </template>
            </draggable>
        </div>
    </div>
  <!--    </tg-drawer>-->
</template>
<script setup>
import {reactive, ref, toRefs, watch, computed} from 'vue'
import Draggable from 'vuedraggable'
import {TgHttp} from "@coreHttp";
import {SettingCore} from "@core/setting";
import {t, tm} from "@i18nHelper";

const TgDrawerRef = ref()

const $emit = defineEmits('updateConfig')

const props = defineProps({
    page: {
        type: Object,
        default: null
    }
})

const state = reactive({
    value1: false,
    dataList: []
})

const {
    dataList
} = toRefs(state)

const columnAll = computed(() => {
    let chooseAll = true;
    state.dataList.forEach((item) => {
        if (!item.show) {
            chooseAll = false
            return
        }
    })
    return chooseAll
})

// 保存列
function handleAll($event, flag) {
    let arr = state.dataList
    for (let p in arr) {
        arr[p][flag] = $event
    }
    state.dataList = arr
}

//重置
const reset = () => {
    const request = new TgHttp();
    request.entity = {
        settingType: "1",
        tableCode: props.page.context.name,
        jsonDetail: JSON.stringify(state.dataList)
    }

    let url = SettingCore.VITE_BASE_URL + '/sysTableSetting/deleteByCodeAndType'
    if (window.website.tgApiPrefix) {
        url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/deleteByCodeAndType";
    }

    request
        .build(url)
        .post()
        .then(data => {
            if (data.code === 10001) {
                tg.msg.success("重置成功，请刷新界面")
            }
        })
}

// 保存
const save = () => {
    const request = new TgHttp();
    request.entity = {
        settingType: "1",
        tableCode: props.page.context.name,
        jsonDetail: JSON.stringify(state.dataList)
    }

    let url = SettingCore.VITE_BASE_URL + '/sysTableSetting/save'
    if (window.website.tgApiPrefix) {
        url = SettingCore.VITE_BASE_URL + "/" + window.website.tgApiPrefix + "/sysTableSetting/save";
    }
    request
        .build(url)
        .post()
        .then(data => {
            if (data.code === 10001) {
                tg.msg.success(data.msg)
                // 更新：刷新外部配置列表
                updateConfig()
            } else {
                tg.msg.warning(data.msg)
            }
        })
}

// 更新：刷新外部配置列表
const updateConfig = () => {
    $emit('updateConfig', state.dataList)
}

const show = () => {
    TgDrawerRef.value?.show()
}

watch(() => props.page.data.querySchemas, (newValue) => {
    if (newValue) {
        // state.dataList = JSON.parse(JSON.stringify(newValue));
        /**
         * 此处去除 JSON.parse(JSON.stringify(newValue))
         *  目的： 配置数据被修改，能实时更新配置列表，但是只有点击按钮 “保存” 后，才会更新配置列表到数据库
         * */
        state.dataList = newValue
    }
}, {
    immediate: true, // 立即执行
})

defineExpose({
    show
})
</script>
<style lang="less" scoped>
@import url('@core/assets/less/crudPlus/FilterQueryConfigPlus.less');
</style>
