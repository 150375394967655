import { useRouter } from "vue-router";
import TgLocalStorage from "../../utils/TgLocalStorage";
import { SettingCore } from "../../setting";
import {setSysParametersStore} from "@core/store/modules/_handleModule";
// initial state
const state = {
  // 用户基础信息
  userInfo: false,

  // 企业信息
  companyInfo: {},

  // 用户主题配置
  userThemesetting: {},

  // 全局statOperList  todo
  // getStatusOperList: [],

  // 按钮菜单权限  todo
  // itemBtnList: [],

  // 我的收藏 & 常用功能
  myCollectionList: [],

  // tab添加、取消收藏 更新工作台显示
  // myCollectionEmnuNum: 0,
};

// getters
const getters = {
  userInfo: (state) => state.userInfo,
};

// mutations
const mutations = {
  UserInfo(state, func) {
    getUserInfo(state, "userInfo", func);
  },
  // getStatusOperList(state, res) {
  //   state.getStatusOperList = res;
  // },
  // itemBtnList(state, res) {
  //   state.itemBtnList = res;
  // },
  changeUserThemeSet(state, data) {
    // 存储用户主题设置
    state.userThemesetting = data;
  },
  changeCompanyInfo(state, data) {
    state.companyInfo = data;
  },
  // changeNoticeList(state, data) {
  //   state.noticeRegList = data;
  // },
  changeMyCollection(state, data) {
    state.myCollectionList = data;
  },
};

const actions = {
  // sysParaInit({ commit }, func) {
  //   commit("EmsNo", func);
  //   commit("YcdxHchxBat", func);
  // },
  sysUserInfo({ commit }, func) {
    commit("UserInfo", func);
  },
  // getSelectOneByUserId(content, { func, comFunc, isUser }) {
  //   // 获取用户主题设置
  //   return new Promise(function (reslove, reject) {
  //     func()
  //       .then((data) => {
  //         if (data.code === 10001) {
  //           // 存储用户主题设置
  //           const newData = JSON.parse(JSON.stringify(data.data));
  //           newData.webSideType = newData.webSideType || "2"; // 侧边栏类型默认位 栏式2
  //           newData.webTheme = newData.webTheme || "#337DF7"; // 默认主题颜色
  //           newData.webLayout = newData.webLayout || "1"; // 默认布局模式（1-侧边）
  //           newData.webSideIsIcon = newData.webSideIsIcon || "1"; // 默认侧边栏显示彩色图标
  //           newData.webSideIsOpen = newData.webSideIsOpen || "1"; // 默认侧栏排他展开
  //           newData.webIsTab = newData.webIsTab || "1"; // 默认启用标签页
  //           newData.webTabType = newData.webTabType || "1"; // 默认标签显示风格（1-默认）
  //           content.commit("changeUserThemeSet", newData);
  //           if (newData.tradeCode && !isUser) {
  //             comFunc({ epCode: newData.tradeCode })
  //               .then((cData) => {
  //                 // 获取企业信息数据
  //                 if (cData.code === 10001) {
  //                   content.commit("changeCompanyInfo", cData.data);
  //                 } else {
  //                   cData.msg && proxy.$message.warning(cData.msg);
  //                 }
  //               })
  //               .catch((err) => {
  //                 proxy.$message.error(err);
  //               });
  //           }
  //         }
  //         reslove(data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         reject(err);
  //       });
  //   });
  // },
  getThemeByUserInfo(content, { userInfo, comFunc, isUser }) {
    // 获取用户主题设置
    // 存储用户主题设置
    // debugger
    const newData = JSON.parse(JSON.stringify(userInfo));
    const userinfo = TgLocalStorage.get("userinfo");
    TgLocalStorage.set("userinfo", { ...userinfo, userInfo: newData });
    newData.webSideType = newData.webSideType || SettingCore.VITE_TG_WEB_SIDETYPE; // 侧边栏类型默认位 栏式2
    newData.webTheme = newData.webTheme || "#337DF7"; // 默认主题颜色
    newData.webLayout = newData.webLayout || "1"; // 默认布局模式（1-侧边）
    newData.webSideIsIcon = newData.webSideIsIcon || "1"; // 默认侧边栏显示彩色图标
    newData.webSideIsOpen = newData.webSideIsOpen || "1"; // 默认侧栏排他展开
    newData.webIsTab = newData.webIsTab || "1"; // 默认启用标签页
    newData.webTabType = newData.webTabType || "1"; // 默认标签显示风格（1-默认）
    newData.webHeadType = newData.webHeadType || "1";//默认1
    content.commit("changeUserThemeSet", newData);
    if (SettingCore.VITE_TG_AUTH === "1" && newData.companyCode && !isUser) {
      comFunc({ companyCode: newData.companyCode })
        .then((cData) => {
          // 获取企业信息数据
          if (cData.code === 10001) {
            content.commit("changeCompanyInfo", cData.data);
          } else {
            cData.msg && tg.msg.warning(cData.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          // 如下 err 打印的时候没有内容，空的 error 提示信息
          // ElMessage.error(err)
        });
    }
  },
};

/**
 * indexdb 前端数据库参数存入
 * 表1 用于数据版本更新
 * 表2 组装后数据，用于存储到vuex中
 */
function getUserInfo(state, skey, func) {
  const router = useRouter();
  func({
    data: {},
    fn: (data) => {
      if (data.success) {
        var uInfo = data.result;
        if (uInfo) {
          if (uInfo.SYS_SKIN === "" || uInfo.SYS_SKIN === null) {
            uInfo.SYS_SKIN = "#409EFF";
          }
          state[skey] = {};
          state[skey] = uInfo;
        }
      } else {
        router.push({
          path: "/login",
        });
        state[skey] = false;
      }
    },
    errFn: (err) => {
      console.log(err);
    },
  });
}
let _s = setSysParametersStore;
let sysParameters = {
  state: Object.assign({}, state, _s.state),
  // getters: Object.assign({},getters,_s.getters),
  getters: getters,
  actions: Object.assign({}, actions, _s.actions),
  mutations: Object.assign({}, mutations, _s.mutations),
};

export default sysParameters;
