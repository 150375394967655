<template>
  <div class="span-cell user-info">
    <el-dropdown @command="handleCommand" trigger="hover">
      <i class="iconfont icon-zhongyingwen" :style="{ color: getHeadIcon().color, opacity: getHeadIcon().opacity }"></i>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="item in state.list" :command="item.value" :disabled="state.language === item.value">
            {{ item.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script setup>
import {reactive} from 'vue'
import HeadNav from "../../HeadNav";
import TgLocalStorage from "@core/utils/TgLocalStorage";
// import {LocaleType} from "./locale";
// import {changeLocale} from "./useLocale";

const state = reactive({
  language: TgLocalStorage.get('lang') || 'zh_CN',
  list: [
    {name: '简体中文', value: 'zh_CN'},
    {name: 'English', value: 'en'},
    // {name: 'Việt nam',value: 'vi'},
  ]
})
const {
  getHeadIcon
} = HeadNav()

const handleCommand = (command) => {
  let language = command
  TgLocalStorage.set('lang', language)
  location.reload();
}
</script>
