/** 子应用内通信方式 */

/** 触发主应用事件 */
export const emitSub = (eventName, params) => {
    window.$wujie.bus.$emit(eventName, params);
};
/** 监听主应用事件 */
export const onSub = (eventName, callback) => {
    window.$wujie.bus.$on(eventName, callback);
};

/**
 * @description 通知主应用跳转登录页
 */
export const emitRedirectLogin = () => {
    emitSub('redirectLogin');
};

/** 通知主应用路由跳转 */
export const emitRouterPush = params => {
    emitSub('routerPush', params);
};
