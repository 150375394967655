const api = {
    save: "dataHelp/save",
    load: "dataHelp/selectOneById",
    search: "dataHelp/selectPageList",
    delete: "dataHelp/deleteByIds",
    clear: "dataHelp/clear",
    download: "dataHelp/download"
}

import app from '../app'

import regApi from '@/tiangongCore/utils/regApi'

//微服务部署，业务系统自动配置前缀
export default regApi(api, app.name, window.website.tgApiPrefix)

