/**
 * @description 国际化文件导入合并
 */
// const tiangongLanguage = import.meta.globEager('/public/tiangongPublic/language/**/*.js')
const zhCnCore = import.meta.globEager('./ch/**/*.js')
const en = import.meta.globEager('./en/**/*.js')
const vi = import.meta.globEager('./vi/**/*.js')

function forEachModules(modules) { // 获取文件路径的数据
    let obj = {}
    for (const key in modules) {
        obj = {
            ...obj,
            ...modules[key].default
        }
    }
    return obj
}

const language = {
    zh_CN: {...forEachModules(zhCnCore)},
    en: {...forEachModules(en)},
    vi: {...forEachModules(vi)}
}
export default language
