/*
*  对外开放的方法入口
*   使用方法
*      import {getUserInfo} from '@coreTg'
* */
// 请求方法
// import TgRequest from '@core/utils/TgRequest';
// 公共方法
// import TgMethods from '@core/utils/TgMethods';
import TgLocalStorage from '@core/utils/TgLocalStorage'
import TgSessionStorage  from '@core/utils/TgSessionStorage'
// 如下请求，修改内容时，实时刷新会报错
// export const Request = TgRequest
export const localStorage = TgLocalStorage
// export const TgSessionStorage =    TgSessionStorage
export const  sessionStorage = TgSessionStorage

// export default {
//   Request: Request,
//   /*
//   * 公共方法
//   *   命名方法：以 get 开始，避免与其他的方法冲突
//   * */
//   // ...TgMethods,
//   localStorage: localStorage,
//   sessionStorage: sessionStorage
// }
