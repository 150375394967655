import * as extension from '@/web/extension'

const setStore = ()=>{
    if(extension.default?.store?.default?.setStore) {
        return extension.default?.store?.default?.setStore()
    }

    return {
        state: {},
        getters: {},
        mutations: {},
        actions: {},
    }
}
/**
 * @description 如下方法用于灵境框架升级使用，后续会删除，请不要使用
 * @deprecated 后续会删除，请不要使用
 * */
const setSysParametersStore = ()=>{
    if(extension.default?.store?.default?.setSysParametersStore) {
        return extension.default?.store?.default?.setSysParametersStore()
    }

    return {
        state: {},
        getters: {},
        mutations: {},
        actions: {},
    }
}

/**
 * @description 如下方法用于灵境框架升级使用，后续会删除，请不要使用
 * @deprecated 后续会删除，请不要使用
 * */
const setLayoutStore = ()=>{
    if(extension.default?.store?.default?.setLayoutStore) {
        return extension.default?.store?.default?.setLayoutStore()
    }

    return {
        state: {},
        getters: {},
        mutations: {},
        actions: {},
    }
}

export  {
    setStore,
    setSysParametersStore,
    setLayoutStore
}
